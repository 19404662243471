import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import { socketContext, SocketContext} from "../../shared/SocketContext"

interface SocketContextProps {
  children: ReactNode;
}

export default function SocketContextProvider(props: SocketContextProps) {
  return (
    <SocketContext.Provider value={socketContext}>
      {props.children}
    </SocketContext.Provider>
  );
}
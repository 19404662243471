import React, { useContext, useEffect, useRef, useState } from "react";

import FrontPageLogo from "../Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import QuestionHeader from "../AlternateQuestionHeader/AlternateQuestionHeader";
import Switch from "@mui/material/Switch";
import Feedback from "../../shared/api/FeedbackApi";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  InputLabel,
  FormControl,
  MenuItem,
  Autocomplete,
  AutocompleteChangeReason,
  Backdrop,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Slider,
  Tab,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import _ from "underscore";
import CardDeck from "../CardDeck/CardDeck";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import PreviewCard from "../PreviewCard/PreviewCard";
import CardWarning from "../CardWarning/CardWarning";

import RefreshIcon from "@mui/icons-material/Refresh";
import {
  QuestionCardInfo,
  AnswerCardInfo,
  CardInfo,
  CardType,
  Language,
} from "../../shared/interfaces/GameLogicTypes";
import {
  convertDimensionsToPixels,
  getElementHeight,
  getRandomTsarName,
  getLanguageByLanguageId,
} from "../../shared/helper/functions";
import CardCreationWidget from "../CardCreationWidget/CardCreationWidget";
import { Languages } from "../../shared/language/languages";
import { GameContext } from "../../shared/GameContext";
import { useAuth0 } from "@auth0/auth0-react";
import Deck from "../../shared/api/DeckApi";

export interface CardPoolProps {
  previewDeckId: string;
  setClose: () => void;
}

export default function CardDeckViewer(props: CardPoolProps) {
  const [tsarName, setTsarName] = useState(getRandomTsarName());
  const [cardPoolTab, setCardPoolTab] = useState<string>("questions");
  const [cardPoolSubTab, setCardPoolSubTab] = useState<string>("for");
  const [previewCategory, setPreviewCategory] = useState("");
  const [questionCards, setQuestionCards] = useState<Array<QuestionCardInfo>>([]);
  const [answerCards, setAnswerCards] = useState<Array<AnswerCardInfo>>([]);
  const [deckDescription, setDeckDescription] = useState("");
  const [deckName, setDeckName] = useState("");
  const [deckCreatorName, setDeckCreatorName] = useState("");
  const [deckLanguage, setDeckLanguage] = useState<Language>(Languages.english as Language);

  const handleCardPoolTabChange = (event: React.SyntheticEvent, newTabValue: string) => {
    setCardPoolTab(newTabValue);
  };

  const handleCardPoolSubTabChange = (event: React.SyntheticEvent, newTabValue: string) => {
    setCardPoolSubTab(newTabValue);
  };

  useEffect(() => {
    if (props.previewDeckId != "") {
      const fetchDeck = async () => {
        console.log("fetchin deck to deckId " + props.previewDeckId);
        let deck = await Deck.loadDeck(props.previewDeckId);
        setQuestionCards(deck.questionCards);
        setAnswerCards(deck.answerCards);
        setDeckDescription(
          deck.deckInfo.description ? deck.deckInfo.description : "No Description"
        );
        setDeckName(deck.deckInfo.name ? deck.deckInfo.name : "No Name");
        setDeckCreatorName(deck.deckInfo.creatorName);
        let newLanguage = getLanguageByLanguageId(deck.deckInfo.language);
        if (newLanguage !== undefined) {
          setDeckLanguage(newLanguage);
        }
      };
      fetchDeck();
    }
  }, [props.previewDeckId]);

  return (
    <>
      <Stack
        direction={{ md: "row", xs: "column" }}
        sx={{
          width: { xs: "90%", md: "75%" },
          height: {md:"80%", xs: "100%"},
          overflow:"auto"
        }}
        spacing={2}
      >
        <Paper
          id="new"
          sx={{
            width: { xs: "calc(100% - 2em)", md: "calc(25% - 2em)" },

            padding: "1em",
          }}
        >
          <Stack direction="column" alignItems="center" spacing={2} sx={{ width: "100%" }}>
            <TextField
              id="outlined-name"
              label="Name"
              value={deckName}
              sx={{ width: "100%" }}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="outlined-name"
              label="Language"
              value={deckLanguage.name}
              sx={{ width: "100%" }}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              fullWidth
              value={deckDescription}
              InputProps={{
                readOnly: true,
              }}
              id="deckDescription"
              label={"Description"}
              multiline
              rows={4}
              sx={{ width: "100%" }}
            />
            <TextField
              id="outlined-name"
              label="created by"
              value={deckCreatorName}
              sx={{ width: "100%" }}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button fullWidth variant="contained" onClick={props.setClose}>
              Close
            </Button>
          </Stack>
        </Paper>
        <Paper
          id="new"
          sx={{
            width: { xs: "100%", md: "75%" },
            
            height: {md:"100%", xs:"max-content"},
            
            overflow: {md:"auto"},
          }}
        >
          <Stack>
            <TabContext value={cardPoolTab}>
              <TabList
                id="tab-list"
                centered
                variant="fullWidth"
                onChange={handleCardPoolTabChange}
              >
                <Tab sx={{ textTransform: "none" }} label="Questions" value="questions" />
                <Tab sx={{ textTransform: "none" }} label="Answers" value="answers" />
              </TabList>
              <TabPanel sx={{ padding: "0 !important" }} value="questions">
                <TabContext value={cardPoolSubTab}>
                  <TabList
                    id="tab-list"
                    centered
                    variant="fullWidth"
                    onChange={handleCardPoolSubTabChange}
                    sx={{ width: "100% !important" }}
                  >
                    <Tab sx={{ textTransform: "none" }} label="for the tsar" value="for" />
                    <Tab sx={{ textTransform: "none" }} label="about the tsar" value="about" />
                  </TabList>
                  <TabPanel sx={{ overflow: { md: "scroll", xs: "auto" }, padding: 0 }} value="for">
                    {/* "calc(" + convertHeightToPixels(getElementHeight("card-deck-creator-rows")) + " - 2em)" */}
                    <Box
                      sx={{
                        overflowX: "hidden",
                        overflowY: "scroll",
                        height:"100%"
                        //   height: {
                        //       md: "calc( " +
                        //           cardDeckCreatorElementHeight +
                        //           " - " +
                        //           convertHeightToPixels(getElementHeight("tab-list")) +
                        //           " - " +
                        //           convertHeightToPixels(getElementHeight("tab-list")) +
                        //           " ) ",
                        //   },
                      }}
                    >
                      <Grid
                        alignItems={"center"}
                        container
                        wrap="wrap"
                        justifyContent="flex-start"
                        spacing={2}
                        columns={{ xs: 2, sm: 4, md: 3, lg: 4, xl: 5 }}
                        sx={{ paddingBlock: ".5em", paddingInline: { md: "2em", xs: "1em" } }}
                      >
                        {questionCards.map((questionCard) => {
                          return (
                            <Grid item xs={1} key={"questionCard-no-" + questionCard.id}>
                              <Stack alignItems={"center"}>
                                <PreviewCard
                                  key={"questionCard-no" + questionCard.id}
                                  text={questionCard.forTsar}
                                  tsarName={tsarName}
                                  categories={questionCard.categories}
                                  previewCategory={setPreviewCategory}
                                  probability={questionCard.probability}
                                />
                              </Stack>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </TabPanel>
                  <TabPanel sx={{ overflow: "scroll", padding: 0 }} value="about">
                    {/* "calc(" + convertHeightToPixels(getElementHeight("card-deck-creator-rows")) + " - 2em)" */}
                    <Box
                      sx={{
                        overflowX: "hidden",
                        overflowY: "scroll",
                        //   height: {
                        //       md: "calc( " +
                        //           cardDeckCreatorElementHeight +
                        //           " - " +
                        //           convertHeightToPixels(getElementHeight("tab-list")) +
                        //           " - " +
                        //           convertHeightToPixels(getElementHeight("tab-list")) +
                        //           " ) ",
                        //   },
                      }}
                    >
                      <Grid
                        alignItems={"center"}
                        container
                        wrap="wrap"
                        justifyContent="flex-start"
                        spacing={2}
                        columns={{ xs: 2, sm: 4, md: 3, lg: 4, xl: 5 }}
                        sx={{ paddingBlock: ".5em", paddingInline: { md: "2em", xs: "1em" } }}
                      >
                        {questionCards.map((questionCard) => {
                          return (
                            <Grid item xs={1} key={"questionCard-no-" + questionCard.id}>
                              <Stack alignItems={"center"}>
                                <PreviewCard
                                  text={questionCard.aboutTsar}
                                  tsarName={tsarName}
                                  categories={questionCard.categories}
                                  previewCategory={setPreviewCategory}
                                  probability={questionCard.probability}
                                />
                              </Stack>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </TabPanel>
                </TabContext>
              </TabPanel>

              <TabPanel value="answers" sx={{ overflow: { md: "scroll", xs: "auto" }, padding: 0 }}>
                <div style={{ height: "100%" }}>
                  <Box
                    sx={{
                      overflowX: "hidden",
                      overflowY: { md: "scroll", xs: "auto" },
                     
                      //   height: {
                      //       md: "calc( " +
                      //           cardDeckCreatorElementHeight +
                      //           " - " +
                      //           convertHeightToPixels(getElementHeight("tab-list")) +
                      //           " ) ",
                      //   },
                    }}
                  >
                    <Grid
                      alignItems={"center"}
                      container
                      
                      justifyContent="flex-start"
                      spacing={2}
                      columns={{ xs: 2, sm: 4, md: 3, lg: 4, xl: 5 }}
                      sx={{ paddingBlock: ".5em", paddingInline: { md: "2em", xs: "1em" } }}
                    >
                      {answerCards.map((answerCard) => {
                        return (
                          <Grid item xs={1} key={"answerCard-no" + answerCard.id}>
                            <Stack alignItems={"center"}>
                              <PreviewCard
                                text={answerCard.answer}
                                tsarName={tsarName}
                                categories={answerCard.categories}
                                previewCategory={setPreviewCategory}
                                probability={answerCard.probability}
                              />
                            </Stack>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </div>
              </TabPanel>
            </TabContext>
          </Stack>
        </Paper>{" "}
      </Stack>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={previewCategory != ""}
      >
        <IconButton
          id="topRight"
          onClick={() => setPreviewCategory("")}
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            marginRight: { md: "3em", xs: "1em" },
            marginTop: { md: "3em", xs: "1em" },
          }}
        >
          <CloseIcon
            color={"secondary"}
            sx={{ height: { md: "2.8em", xs: "1em" }, width: { md: "2.8em", xs: "1em" } }}
          />
        </IconButton>
        <Stack alignItems={"center"} justifyContent="flex-end" sx={{ height: "100vh" }} spacing={4}>
          <Typography variant="h2">{previewCategory} </Typography>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ height: "75vh", width: { md: "100vw", xs: "100vw" } }}
            justifyContent="center"
            // alignItems={{xs:"center"}}
            spacing={{ md: 8, xs: 2 }}
          >
            <Stack
              direction={{ xs: "row", md: "column" }}
              sx={{
                overflowY: { xs: "auto", md: "scroll" },
                overflowX: { xs: "scroll", md: "auto" },
                paddingInline: "1em",
                paddingBlock: { xs: "1em", md: 0 },
                minWidth: { md: "16em", xs: "" },
              }}
              alignItems="center"
              spacing={2}
            >
              {questionCards
                .filter((card) => {
                  return card.categories.includes(previewCategory);
                })
                .map((card) => {
                  return (
                    <PreviewCard
                      bigPreview
                      text={cardPoolSubTab == "for" ? card.forTsar : card.aboutTsar}
                      tsarName={tsarName}
                    />
                  );
                })}
            </Stack>
            <Divider />
            <Stack
              direction={{ xs: "row", md: "column" }}
              sx={{
                overflowY: { xs: "auto", md: "scroll" },
                overflowX: { xs: "scroll", md: "auto" },
                paddingInline: "1em",
                paddingBlock: { xs: "1em", md: 0 },
                minWidth: { md: "16em", xs: "" },
              }}
              alignItems="center"
              spacing={2}
            >
              {answerCards
                .filter((card) => {
                  return card.categories.includes(previewCategory);
                })
                .map((card) => {
                  return <PreviewCard bigPreview text={card.answer} tsarName={tsarName} />;
                })}
            </Stack>
          </Stack>
        </Stack>
      </Backdrop>
    </>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";

import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import QuestionHeader from "../AlternateQuestionHeader/AlternateQuestionHeader";
import Switch from "@mui/material/Switch";
import Feedback from "../../shared/api/FeedbackApi";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  InputLabel,
  FormControl,
  MenuItem,
  Autocomplete,
  AutocompleteChangeReason,
  Backdrop,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Slider,
  Tab,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import _ from "underscore";
import CardDeck from "../CardDeck/CardDeck";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import "./CardDeckCreator.css";
import PreviewCard from "../PreviewCard/PreviewCard";
import CardWarning from "../CardWarning/CardWarning";

import RefreshIcon from "@mui/icons-material/Refresh";
import {
  QuestionCardInfo,
  AnswerCardInfo,
  CardInfo,
  CardType,
  Language,
  CardDeckJSON,
} from "../../shared/interfaces/GameLogicTypes";
import {
  convertDimensionsToPixels,
  getElementHeight,
  getRandomTsarName,
  getLanguageByLanguageId,
} from "../../shared/helper/functions";
import CardCreationWidget from "../CardCreationWidget/CardCreationWidget";
import { Languages } from "../../shared/language/languages";
import { GameContext } from "../../shared/GameContext";
import { useAuth0 } from "@auth0/auth0-react";
import Deck from "../../shared/api/DeckApi";

export default function CardDeckCreator(props: { goBack: () => void }) {
  const deckId = useContext(GameContext).lastEditedDeckState;
  const [tsarName, setTsarName] = useState(getRandomTsarName());
  const [cardPoolTab, setCardPoolTab] = useState<string>("questions");
  const [cardPoolSubTab, setCardPoolSubTab] = useState<string>("for");
  const [questionCards, setQuestionCards] = useState<Array<QuestionCardInfo>>([]);
  const [answerCards, setAnswerCards] = useState<Array<AnswerCardInfo>>([]);
  const [usedCategories, setUsedCategories] = useState<string[]>(["episodes"]);
  const [showDeleteCardWarning, setShowDeleteCardWarning] = useState(false);
  const [showIncompletenessWarning, setShowIncompletenessWarning] = useState(false);
  const [dontShowDeleteCardWarningAgain, setDontShowDeleteCardWarningAgain] = useState(false);
  const [showNoTitleWarning, setShowNoTitleWarning] = useState(false);
  const [stagedDeletion, setStagedDeletion] = useState<() => void>(() => {});
  const [previewCategory, setPreviewCategory] = useState("");
  const [deckAndCardWidgetTab, setDeckAndCardWidgetTab] = useState("deck");
  const [deckName, setDeckName] = useState("");
  const [deckLanguage, setDeckLanguage] = useState<Language>(Languages.english as Language);
  const [deckDescription, setDeckDescription] = useState("");
  const [returnedDeckName, setReturnedDeckName] = useState("");
  const [returnedDeckLanguage, setReturnedDeckLanguage] = useState<Language>(
    Languages.english as Language
  );
  const [incompleteAnswerCategories, setIncompleteAnswerCategories] = useState<string[]>([]);
  const [incompleteQuestionCategories, setIncompleteQuestionCategories] = useState<string[]>([]);

  const [returnedDeckDescription, setReturnedDeckDescription] = useState("");
  const { isAuthenticated, loginWithPopup, getAccessTokenSilently } = useAuth0();

  //CardDeckWidgetProps
  const [questionOrAnswer, setQuestionOrAnswer] = useState<string>("question");
  const [forOrAbout, setForOrAbout] = useState("for");
  const [forTsarQuestion, setForTsarQuestion] = useState("");
  const [probability, setProbability] = useState(50);
  const [currentCategories, setCurrentCategories] = useState<string[]>([]);
  const [aboutTsarQuestion, setAboutTsarQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  let cardDeckCreatorRowHeight = convertDimensionsToPixels(
    getElementHeight("card-deck-creator-rows")
  );
  let cardDeckCreatorElementHeight =
    "calc(" + convertDimensionsToPixels(getElementHeight("card-deck-creator-rows")) + " - 2em)";

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    cardDeckCreatorRowHeight = convertDimensionsToPixels(
      getElementHeight("card-deck-creator-rows")
    );
    cardDeckCreatorElementHeight =
      "calc(" + convertDimensionsToPixels(getElementHeight("card-deck-creator-rows")) + " - 2em)";
    setFirstRender(false);
  }, [firstRender]);

  const handleCardPoolTabChange = (event: React.SyntheticEvent, newTabValue: string) => {
    setCardPoolTab(newTabValue);
  };

  const handleCardPoolSubTabChange = (event: React.SyntheticEvent, newTabValue: string) => {
    setCardPoolSubTab(newTabValue);
  };

  const handleDeckLanguageChange = (event: SelectChangeEvent) => {
    console.log("trying to handle change with: " + event.target.value);
    let matchingLanguage = _.filter(Languages, (language) => {
      return language.id === event.target.value;
    });
    if (matchingLanguage.length !== 0) {
      setDeckLanguage(_.first(matchingLanguage) as Language);
    }
  };

  const handleDeckAndCardWidgetTabChange = (event: React.SyntheticEvent, newTabValue: string) => {
    setDeckAndCardWidgetTab(newTabValue);
  };

  const handleDeckNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeckName(event.target.value);
  };

  const handleDeckDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeckDescription(event.target.value);
  };

  const handleSave = async () => {
    let token = "";
    token = await getAccessTokenSilently({
      audience: "rank5.io",
    });
    if (returnedDeckName !== deckName)
      setReturnedDeckName(await Deck.changeDeckName(token, deckId, deckName));
    if (returnedDeckDescription !== deckDescription)
      setReturnedDeckDescription(await Deck.changeDeckDescription(token, deckId, deckDescription));
    if (returnedDeckLanguage !== deckLanguage) {
      //TODO: exchange this block with getLanguageByLanguageId after it's proven to be working
      let newLanguageId = await Deck.changeDeckLanguage(token, deckId, deckLanguage.id);
      let newLanguageKey = _.find(Object.keys(Languages), (language) => {
        return (Languages[language]?.id as unknown as string) === newLanguageId;
      });
      if (newLanguageKey !== undefined) {
        setReturnedDeckLanguage(Languages[newLanguageKey]);
      }
    }
  };

  const handleDelete = async (cardId: number, cardType: CardType) => {
    const stagedDeletion = async () => {
      let token = await getAccessTokenSilently({ audience: "rank5.io" });
      if (cardType === CardType.QUESTION) {
        let success = await Deck.deleteQuestionCard(token, cardId);
        if (success) {
          let index = _.findIndex(questionCards, (questionCard) => {
            return questionCard.id == cardId;
          });
          if (index !== -1) {
            let newCardStack = [...questionCards];
            newCardStack.splice(index, 1);
            setQuestionCards(newCardStack);
          }
        }
      } else if (cardType === CardType.ANSWER) {
        console.log("trying to delete answerCard " + cardId);
        let success = await Deck.deleteAnswerCard(token, cardId);
        if (success) {
          let index = _.findIndex(answerCards, (answerCard) => {
            return answerCard.id == cardId;
          });
          if (index !== -1) {
            let newCardStack = [...answerCards];
            newCardStack.splice(index, 1);
            setAnswerCards(newCardStack);
          }
        }
      }
    };
    if (dontShowDeleteCardWarningAgain) {
      stagedDeletion();
    } else {
      setStagedDeletion(() => stagedDeletion);
      setShowDeleteCardWarning(true);
    }
  };

  const handleLoadQuestionCardIntoEditor = (questionCard: QuestionCardInfo) => {
    setDeckAndCardWidgetTab("card");
    setQuestionOrAnswer("question");
    setForTsarQuestion(questionCard.forTsar);
    setAboutTsarQuestion(questionCard.aboutTsar);
    setProbability(questionCard.probability);
    setCurrentCategories(questionCard.categories);
  };
  const handleLoadAnswerCardIntoEditor = (answerCard: AnswerCardInfo) => {
    setDeckAndCardWidgetTab("card");
    setQuestionOrAnswer("answer");
    setAnswer(answerCard.answer);
    setProbability(answerCard.probability);
    setCurrentCategories(answerCard.categories);
  };

  const removeUnusedCategories = (categories: string[]) => {
    categories.forEach((category) => {
      console.log("executing remove for category: " + category);

      if (
        questionCards.filter((card) => {
          return card.categories.includes(category);
        }).length === 0 &&
        answerCards.filter((card) => {
          return card.categories.includes(category);
        }).length === 0
      ) {
        setUsedCategories(_.without(usedCategories, category));
        console.log("removed category: " + category);
      }
    });
  };

  //Category Garbage Collector
  useEffect(() => {
    removeUnusedCategories(usedCategories);
  }, [answerCards, questionCards]);

  const getIncompletCategories = () => {
    let incompleteStacks: { questions: string[]; answers: string[] } = {
      questions: [],
      answers: [],
    };
    if (!(questionCards.length === 0 && answerCards.length === 0)) {
      usedCategories.forEach((category) => {
        if (
          questionCards.filter((card) => {
            return card.categories.includes(category);
          }).length === 0
        ) {
          incompleteStacks.questions.push(category);
        }
        if (
          answerCards.filter((card) => {
            return card.categories.includes(category);
          }).length < 5
        ) {
          incompleteStacks.answers.push(category);
        }
      });
    }
    return incompleteStacks;
  };

  const isCardDeckValid = () => {
    let incomplempleteStack = getIncompletCategories();
    return incomplempleteStack.questions.length === 0 && incomplempleteStack.questions.length === 0;
  };

  //Staged Deletion Garbage collector
  useEffect(() => {
    if (!showDeleteCardWarning) setStagedDeletion(() => {});
  }, [showDeleteCardWarning]);

  //load card Deck on opening
  useEffect(() => {
    async function fetchDeck() {
      console.log("fetchin deck");
      let deck = await Deck.loadDeck(deckId);
      setQuestionCards(deck.questionCards);
      setAnswerCards(deck.answerCards);
      setUsedCategories(deck.categories);
      setDeckDescription(deck.deckInfo.description);
      setReturnedDeckDescription(deck.deckInfo.description);
      setDeckName(deck.deckInfo.name);
      setReturnedDeckName(deck.deckInfo.name);
      let newLanguage = getLanguageByLanguageId(deck.deckInfo.language);
      if (newLanguage !== undefined) {
        setDeckLanguage(newLanguage);
        setReturnedDeckLanguage(newLanguage);
      }
    }
    fetchDeck();
  }, []);

  const downloadFile = () => {
    if (questionCards.length != 0 || answerCards.length !== 0) {
      const myData = {
        deckInfo: {
          id: deckId,
          name: deckName,
          description: deckDescription,
          language: deckLanguage.id,
        },
        questionCards: questionCards,
        answerCards: answerCards,
      }; // I am assuming that "this.state.myData"
      // is an object and I wrote it to file as
      // json

      // create file in browser
      const fileName = deckName + "-back-up";
      const json = JSON.stringify(myData, null, 2);
      const blob = new Blob([json], { type: "application/json" });
      const href = URL.createObjectURL(blob);

      // create "a" HTLM element with href to file
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName + ".json";
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  };

  const [files, setFiles] = useState("");
  const [isFileInvalid, setIsFileInvalid] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    if (e.target?.files == undefined) return;
    const blob: Blob = e.target!.files![0];
    fileReader.readAsText(blob, "UTF-8");
    fileReader.onload = (e: any) => {
      console.log("e.target.result", e.target?.result);
      setFiles(e.target.result);
    };
  };

  useEffect(() => {
    console.log("changed files");
    let object: object;
    if (files !== "") {
      try {
        object = JSON.parse(files);

        if (
          _.has(object, "deckInfo") &&
          _.has(object, "questionCards") &&
          _.has(object, "answerCards")
        ) {
          let importedDeckName = object["deckInfo" as keyof object]["name"] as unknown as string;
          let importedDeckLanguage = getLanguageByLanguageId(
            object["deckInfo" as keyof object]["language"] as unknown as string
          );
          let importedDeckDescription = object["deckInfo" as keyof object][
            "description"
          ] as unknown as string;
          setDeckName(importedDeckName);
          if (importedDeckLanguage !== undefined) {
            setDeckLanguage(importedDeckLanguage);
          }

          setDeckDescription(importedDeckDescription);
          let questionCardsToAdd = object[
            "questionCards" as keyof object
          ] as unknown as QuestionCardInfo[];
          let answerCardsToAdd = object[
            "answerCards" as keyof object
          ] as unknown as AnswerCardInfo[];
          if (questionCardsToAdd) {
            batchAddCards(questionCardsToAdd, answerCardsToAdd);
          }
        } else {
          console.log("invalid");
        }
      } catch {
        console.log("invalid");
        setIsFileInvalid(true);
      }

      return () => {};
    }
    setFiles("");
    console.log("reset files to " + files);
  }, [files]);

  const batchAddCards = async (
    questionCardsToAdd: QuestionCardInfo[],
    answerCardsToAdd: AnswerCardInfo[]
  ) => {
    console.log("answerCardsTo add " + JSON.stringify(answerCardsToAdd));
    if (questionCardsToAdd.length < 100 && answerCardsToAdd.length < 1000) {
      let token = await getAccessTokenSilently({ audience: "rank5.io" });
      _.forEach(questionCardsToAdd, async (questionCard) => {
        try {
          console.log("adding Card " + JSON.stringify(questionCard));
          let id = await Deck.createQuestionCard(
            token,
            deckId,
            questionCard.categories,
            questionCard.forTsar,
            questionCard.aboutTsar,
            questionCard.probability
          );
          console.log("received ID " + id);
          if (id) {
            const newCard: QuestionCardInfo = {
              id: id,
              forTsar: questionCard.forTsar,
              aboutTsar: questionCard.aboutTsar,
              probability: questionCard.probability,
              categories: questionCard.categories,
            };
            const newQuestionCards = questionCards;
            newQuestionCards.push(newCard);
            console.log("settingQuestionCards to " + JSON.stringify(newQuestionCards));

            setQuestionCards([...newQuestionCards]);
            console.log("success");
          }
        } catch (e) {}
      });
      _.forEach(answerCardsToAdd, async (answerCard) => {
        console.log("trying to add answercard " + JSON.stringify(answerCard));
        try {
          let id = await Deck.createAnswerCard(
            token,
            deckId,
            answerCard.categories,
            answerCard.answer,
            answerCard.probability
          );
          if (id) {
            const newCard: AnswerCardInfo = {
              id: id,
              answer: answerCard.answer,
              probability: answerCard.probability,
              categories: answerCard.categories,
            };
            const newAnswerCards = answerCards;
            newAnswerCards.push(newCard);
            setAnswerCards([...newAnswerCards]);
          }
        } catch (e) {}
      });
    }
    console.log("sucesscfully batched q cards " + JSON.stringify(questionCards));
    console.log("sucesscfully batched A cards " + JSON.stringify(answerCards));
  };

  const handleTryExit = () => {
    // if (deckName === "") {
    //   setShowNoTitleWarning(true);
    // } else 
    if (!isCardDeckValid()) {
      let incomplempleteStack = getIncompletCategories();
      setIncompleteQuestionCategories(incomplempleteStack.questions);
      setIncompleteAnswerCategories(incomplempleteStack.answers);
      setShowIncompletenessWarning(true);
    } else {
      handleExit();
    }
  };

  const handleExit = async () => {
    await handleSave();
    props.goBack();
  }

  useEffect(() => {
    console.log("entered change tab effect");
    if (questionCards.length !== 0) {
      console.log("changing");
      setCardPoolTab("questions");
    }
  }, [questionCards]);

  useEffect(() => {
    if (answerCards.length !== 0) {
      setCardPoolTab("answers");
    }
  }, [answerCards]);

  return (
    <Paper
      sx={{
        height: { md: "80vh", xs: "auto" },
        width: "90vw",
        overFlow: { xs: "visible", md: "auto" },
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "flex-start", md: "space-between" }}
        sx={{ padding: "1em", height: "calc(100% - 2em)" }}
        id="card-deck-creator-rows"
        spacing={{ md: "", xs: 2 }}
      >
        <Stack sx={{ width: { xs: "100%", md: "20%" } }}>
          <Paper
            id="deck-and-card-creation-widget"
            sx={{
              maxWidth: "100%",
              maxHeight: { md: cardDeckCreatorElementHeight },
              overflow: { md: "scroll", xs: "auto" },
            }}
          >
            <TabContext value={deckAndCardWidgetTab}>
              <TabList
                id="tab-list"
                centered
                variant="fullWidth"
                onChange={handleDeckAndCardWidgetTabChange}
              >
                <Tab sx={{ textTransform: "none" }} label="Deck" value="deck" />
                <Tab sx={{ textTransform: "none" }} label="Card" value="card" />
              </TabList>
              <TabPanel sx={{ padding: "0 !important" }} value="deck">
                <Stack alignItems={"stretch"} spacing={2} sx={{ margin: "1em" }}>
                  <TextField
                    id="outlined-name"
                    label="Deck Name"
                    value={deckName}
                    onChange={handleDeckNameChange}
                    inputProps={{ maxLength: 60 }}
                  />
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">Language</InputLabel>
                    <Select
                      sx={{}}
                      labelId="select-language"
                      id={"select-language"}
                      value={deckLanguage.id}
                      label="Language"
                      onChange={handleDeckLanguageChange}
                    >
                      {_.toArray(Languages).map((language) => {
                        return (
                          <MenuItem key={language.name} value={language.id}>
                            {language.name}
                          </MenuItem>
                        );
                      })}

                      {/* <MenuItem value={"10"}>10</MenuItem> */}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    value={deckDescription}
                    onChange={handleDeckDescriptionChange}
                    id="deckDescription"
                    label={"Description"}
                    inputProps={{ maxLength: 255 }}
                    multiline
                    rows={4}
                  />

                  <Button
                    fullWidth
                    variant={
                      deckName == returnedDeckName &&
                      deckDescription == returnedDeckDescription &&
                      deckLanguage == returnedDeckLanguage
                        ? "outlined"
                        : "contained"
                    }
                    onClick={handleSave}
                  >
                    {"Save"}
                  </Button>
                  <Button fullWidth variant="contained" onClick={handleTryExit}>
                    {"SAVE & Exit"}
                  </Button>
                  <Button
                    fullWidth
                    variant={
                      answerCards.length !== 0 || questionCards.length !== 0
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => downloadFile()}
                  >
                    {"Export"}
                  </Button>

                  <Button
                    variant={
                      answerCards.length === 0 && questionCards.length == 0
                        ? "contained"
                        : "outlined"
                    }
                    component="label"
                  >
                    {"Import"}
                    <input type="file" onChange={handleFileChange} hidden />
                  </Button>
                </Stack>
              </TabPanel>
              <TabPanel sx={{ padding: "0 !important" }} value="card">
                <CardCreationWidget
                  tsarName={tsarName}
                  setTsarName={(newTsarName: string) => setTsarName(newTsarName)}
                  usedCategories={usedCategories}
                  setUsedCategories={(newCategories: string[]) => setUsedCategories(newCategories)}
                  answerCards={answerCards}
                  setAnswerCards={(newAnswerCards: AnswerCardInfo[]) =>
                    setAnswerCards(newAnswerCards)
                  }
                  questionCards={questionCards}
                  setQuestionCards={(newQuestionCards: QuestionCardInfo[]) =>
                    setQuestionCards(newQuestionCards)
                  }
                  questionOrAnswer={questionOrAnswer}
                  setQuestionOrAnswer={setQuestionOrAnswer}
                  forOrAbout={forOrAbout}
                  setForOrAbout={setForOrAbout}
                  forTsarQuestion={forTsarQuestion}
                  setForTsarQuestion={setForTsarQuestion}
                  aboutTsarQuestion={aboutTsarQuestion}
                  setAboutTsarQuestion={setAboutTsarQuestion}
                  probability={probability}
                  setProbability={setProbability}
                  answer={answer}
                  setAnswer={setAnswer}
                  currentCategories={currentCategories}
                  setCurrentCategories={setCurrentCategories}
                />
              </TabPanel>
            </TabContext>
          </Paper>
        </Stack>
        <Paper
          id="new"
          sx={{
            width: { xs: "100%", md: "75%" },
            height: { md: cardDeckCreatorElementHeight },
          }}
        >
          {/* //Cardpool */}
          <Stack>
            <TabContext value={cardPoolTab}>
              <TabList
                id="tab-list"
                centered
                variant="fullWidth"
                onChange={handleCardPoolTabChange}
              >
                <Tab sx={{ textTransform: "none" }} label="Questions" value="questions" />
                <Tab sx={{ textTransform: "none" }} label="Answers" value="answers" />
              </TabList>
              <TabPanel sx={{ padding: "0 !important" }} value="questions">
                <TabContext value={cardPoolSubTab}>
                  <TabList
                    id="tab-list"
                    centered
                    variant="fullWidth"
                    onChange={handleCardPoolSubTabChange}
                    sx={{ width: "100% !important" }}
                  >
                    <Tab sx={{ textTransform: "none" }} label="for the tsar" value="for" />
                    <Tab sx={{ textTransform: "none" }} label="about the tsar" value="about" />
                  </TabList>
                  <TabPanel sx={{ overflow: { md: "scroll", xs: "auto" }, padding: 0 }} value="for">
                    {/* "calc(" + convertHeightToPixels(getElementHeight("card-deck-creator-rows")) + " - 2em)" */}
                    <Box
                      sx={{
                        overflowX: "hidden",
                        overflowY: "scroll",
                        height: {
                          md:
                            "calc( " +
                            cardDeckCreatorElementHeight +
                            " - " +
                            convertDimensionsToPixels(getElementHeight("tab-list")) +
                            " - " +
                            convertDimensionsToPixels(getElementHeight("tab-list")) +
                            " ) ",
                        },
                      }}
                    >
                      <Grid
                        container
                        wrap="wrap"
                        justifyContent="flex-start"
                        spacing={2}
                        columns={{ xs: 2, sm: 4, md: 4, lg: 5, xl: 6 }}
                        sx={{ paddingBlock: ".5em", paddingInline: { md: "2em", xs: "1em" } }}
                      >
                        {questionCards.map((questionCard) => {
                          return (
                            <Grid item xs={1} key={"questionCard-no-" + questionCard.id}>
                              <PreviewCard
                                key={"questionCard-no" + questionCard.id}
                                text={questionCard.forTsar}
                                tsarName={tsarName}
                                categories={questionCard.categories}
                                previewCategory={setPreviewCategory}
                                onDelete={() => handleDelete(questionCard.id, CardType.QUESTION)}
                                probability={questionCard.probability}
                                options={[
                                  {
                                    name: "delete",
                                    function: async () =>
                                      await handleDelete(questionCard.id, CardType.QUESTION),
                                  },
                                  {
                                    name: "reload",
                                    function: () => handleLoadQuestionCardIntoEditor(questionCard),
                                  },
                                ]}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </TabPanel>
                  <TabPanel sx={{ overflow: "scroll", padding: 0 }} value="about">
                    {/* "calc(" + convertHeightToPixels(getElementHeight("card-deck-creator-rows")) + " - 2em)" */}
                    <Box
                      sx={{
                        overflowX: "hidden",
                        overflowY: "scroll",
                        height: {
                          md:
                            "calc( " +
                            cardDeckCreatorElementHeight +
                            " - " +
                            convertDimensionsToPixels(getElementHeight("tab-list")) +
                            " - " +
                            convertDimensionsToPixels(getElementHeight("tab-list")) +
                            " ) ",
                        },
                      }}
                    >
                      <Grid
                        container
                        wrap="wrap"
                        justifyContent="flex-start"
                        spacing={2}
                        columns={{ xs: 2, sm: 4, md: 4, lg: 5, xl: 6 }}
                        sx={{ paddingBlock: ".5em", paddingInline: { md: "2em", xs: "1em" } }}
                      >
                        {questionCards.map((questionCard) => {
                          return (
                            <Grid item xs={1} key={"questionCard-no-" + questionCard.id}>
                              <PreviewCard
                                text={questionCard.aboutTsar}
                                tsarName={tsarName}
                                categories={questionCard.categories}
                                previewCategory={setPreviewCategory}
                                onDelete={() => handleDelete(questionCard.id, CardType.QUESTION)}
                                probability={questionCard.probability}
                                options={[
                                  {
                                    name: "delete",
                                    function: async () =>
                                      await handleDelete(questionCard.id, CardType.QUESTION),
                                  },
                                  {
                                    name: "reload",
                                    function: () => handleLoadQuestionCardIntoEditor(questionCard),
                                  },
                                ]}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </TabPanel>
                </TabContext>
              </TabPanel>

              <TabPanel value="answers" sx={{ overflow: { md: "scroll", xs: "auto" }, padding: 0 }}>
                <div style={{ height: "100%" }}>
                  <Box
                    sx={{
                      overflowX: "hidden",
                      overflowY: { md: "scroll", xs: "auto" },
                      height: {
                        md:
                          "calc( " +
                          cardDeckCreatorElementHeight +
                          " - " +
                          convertDimensionsToPixels(getElementHeight("tab-list")) +
                          " ) ",
                      },
                    }}
                  >
                    <Grid
                      container
                      wrap="wrap"
                      justifyContent="flex-start"
                      spacing={2}
                      columns={{ xs: 2, sm: 4, md: 4, lg: 5, xl: 6 }}
                      sx={{ paddingBlock: ".5em", paddingInline: { md: "2em", xs: "1em" } }}
                    >
                      {answerCards.map((answerCard) => {
                        return (
                          <Grid item xs={1} key={"answerCard-no" + answerCard.id}>
                            <PreviewCard
                              text={answerCard.answer}
                              tsarName={tsarName}
                              categories={answerCard.categories}
                              previewCategory={setPreviewCategory}
                              onDelete={() => handleDelete(answerCard.id, CardType.ANSWER)}
                              probability={answerCard.probability}
                              options={[
                                {
                                  name: "delete",
                                  function: async () =>
                                    await handleDelete(answerCard.id, CardType.ANSWER),
                                },
                                {
                                  name: "reload",
                                  function: () => handleLoadAnswerCardIntoEditor(answerCard),
                                },
                              ]}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </div>
              </TabPanel>
            </TabContext>
          </Stack>
        </Paper>
      </Stack>

      <CardWarning
        canEnable
        enableFeature={() => {
          stagedDeletion();
        }}
        setDontShowAgain={(dontShowAgain: boolean) =>
          setDontShowDeleteCardWarningAgain(dontShowAgain)
        }
        warningTitle="Delete Card"
        warningDescription="Do you really want to delete this card?"
        showWarning={showDeleteCardWarning}
        setShowWarning={(show: boolean) => setShowDeleteCardWarning(show)}
        enableAnywayField="Delete"
      />
      {/* Maybe reenable? */}
      {/* <CardWarning
        warningTitle="No name"
        warningDescription={"Please give your card deck a name before leaving"}
        showWarning={showNoTitleWarning}
        setShowWarning={(show: boolean) => setShowNoTitleWarning(show)}
      /> */}
      <CardWarning
      canEnable
      enableFeature={()=>{
        handleExit()
      }}
      enableAnywayField="exit anyway"
        warningTitle="Exit before completion?"
        warningDescription={
          "Every category needs at least 1 question and 5 answers to be playable. These Categories: \n" +
          JSON.stringify(_.union(incompleteAnswerCategories, incompleteQuestionCategories))
            .replaceAll(",", ", ")
            .replaceAll("[", "")
            .replaceAll("]", "") +
          " \ndo not fulfill this and won't be playable."
        }
        showWarning={showIncompletenessWarning}
        setShowWarning={(show: boolean) => setShowIncompletenessWarning(show)}
      />
      {/* category preview */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={previewCategory != ""}
      >
        <IconButton
          id="topRight"
          onClick={() => setPreviewCategory("")}
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            marginRight: { md: "3em", xs: "1em" },
            marginTop: { md: "3em", xs: "1em" },
          }}
        >
          <CloseIcon
            color={"secondary"}
            sx={{ height: { md: "2.8em", xs: "1em" }, width: { md: "2.8em", xs: "1em" } }}
          />
        </IconButton>
        <Stack alignItems={"center"} justifyContent="flex-end" sx={{ height: "100vh" }} spacing={4}>
          <Typography variant="h2">{previewCategory} </Typography>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ height: "75vh", width: { md: "100vw", xs: "100vw" } }}
            justifyContent="center"
            // alignItems={{xs:"center"}}
            spacing={{ md: 8, xs: 2 }}
          >
            <Stack
              direction={{ xs: "row", md: "column" }}
              sx={{
                overflowY: { xs: "auto", md: "scroll" },
                overflowX: { xs: "scroll", md: "auto" },
                paddingInline: "1em",
                paddingBlock: { xs: "1em", md: 0 },
                minWidth: { md: "16em", xs: "" },
              }}
              alignItems="center"
              spacing={2}
            >
              {questionCards
                .filter((card) => {
                  return card.categories.includes(previewCategory);
                })
                .map((card) => {
                  return (
                    <PreviewCard
                      bigPreview
                      text={cardPoolSubTab == "for" ? card.forTsar : card.aboutTsar}
                      tsarName={tsarName}
                    />
                  );
                })}
            </Stack>
            <Divider />
            <Stack
              direction={{ xs: "row", md: "column" }}
              sx={{
                overflowY: { xs: "auto", md: "scroll" },
                overflowX: { xs: "scroll", md: "auto" },
                paddingInline: "1em",
                paddingBlock: { xs: "1em", md: 0 },
                minWidth: { md: "16em", xs: "" },
              }}
              alignItems="center"
              spacing={2}
            >
              {answerCards
                .filter((card) => {
                  return card.categories.includes(previewCategory);
                })
                .map((card) => {
                  return <PreviewCard bigPreview text={card.answer} tsarName={tsarName} />;
                })}
            </Stack>
          </Stack>
        </Stack>
      </Backdrop>
      {/* <Backdrop>
            
      </Backdrop> */}
    </Paper>
  );
}

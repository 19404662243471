import { useState } from "react";
import { DialogProps } from "./Dialog";

export default function DialogLogic(props: DialogProps) {
  const [isFading, setFading] = useState(false);
  const maxWidth = window.innerWidth - 80;
  const maxHeight = window.innerHeight - 50;

  const width =
    props.width !== undefined && window.innerWidth < props.width
      ? maxWidth
      : props.width;
  const height =
    props.height !== undefined && window.innerHeight < props.height
      ? maxHeight
      : props.height;

  const handleClose = () => {
    setFading(true);
    props.onClose();
    setTimeout(() => setFading(false), 200);
  };

  return { isFading, handleClose, width, height, maxWidth, maxHeight };
}

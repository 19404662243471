import { questions } from "./People";
import { answers } from "./Answers";
import _ from "underscore";

export function convertToLanguageKeys() {
  var de: { [index: string]: string } = {};
  var en: { [index: string]: string } = {};
  _.forEach(questions, (question) => {
    de["q" + question["id"] + "aboutTsar"] = question["aboutTsarDe"];
    de["q" + question["id"] + "forTsar"] = question["forTsarDe"];
    en["q" + question["id"] + "aboutTsar"] = question["aboutTsarEn"];
    en["q" + question["id"] + "forTsar"] = question["forTsarEn"];
  });
  _.forEach(answers, (answer) => {
    de["a" + answer["id"] ] = answer["de"];
    en["a" + answer["id"] ] = answer["en"];
  });
  return { en: en, de: de };
}

import React, { useContext, useEffect, useRef, useState } from "react";

import Dialog from "../../components/Dialog/Dialog";
import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import QuestionHeader from "../../components/QuestionHeader/QuestionHeader";
import Conditional from "../../components/Conditional/Conditional";
import Feedback from "../../shared/api/FeedbackApi";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CasinoIcon from "@mui/icons-material/Casino";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import {
  Button,
  Backdrop,
  Badge,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MdHeight } from "react-icons/md";
import { width } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SettingSelector from "../../components/SettingSelector/SettingSelector";
import SettingSwitch from "../../components/SettingSwitch/SettingSwitch";
import CardDeck from "../../components/CardDeck/CardDeck";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { GameContext } from "../../shared/GameContext";
import CardDeckBrowser from "../../components/CardDeckBrowser/CardDeckBrowser";
import CardDeckCreator from "../../components/CardDeckCreator/CardDeckCreator";
import Background from "../../shared/img/abstractbackground.webp";
import { useAuth0 } from "@auth0/auth0-react";
import Deck from "../../shared/api/DeckApi";
import {
  CardDeckPreview,
  Cue,
  CueTypes,
  GameConfig,
  Language,
  Player,
  PlayerEndResults,
  Results,
  TurnInfo,
} from "../../shared/interfaces/GameLogicTypes";
import { Languages } from "../../shared/language/languages";
import SpinningLogo from "../../shared/img/spinningBallsUltra.webp";
import useSocketContext from "../../shared/useSocketContext";
import useGameContext from "../../shared/useGameContext";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  convertBackendToFrontendDecks,
  getLanguageByLanguageId,
  isDeckSelected,
} from "../../shared/helper/functions";
import CardDeckViewer from "../../components/CardDeckViewer/CardDeckViewer";
import _ from "underscore";
import PlayerCard from "../../components/PlayerCard/PlayerCard";
import Slider from "@mui/material/Slider";
import { Colors } from "../OldLobby/Colors";
import Game from "../../components/Game/Game";
import EndPanel from "../../components/EndPanel/EndPanel";
import { useTheme } from "@emotion/react";
import { Theme as MuiTheme } from "@mui/material/styles";

export default function Lobby() {
  const { isAuthenticated, loginWithPopup, getAccessTokenSilently, getAccessTokenWithPopup } =
    useAuth0();

  const [canStart, setCanStart] = useState(false);

  const [lobbyPhase, setLobbyPhase] = useState("L");
  const numberOfRoundsChoices = ["1", "2", "3", "5", "10", "15"];
  const roundTimerChoices = ["75", "120", "180", "300"];
  const maxSkipsChoices = ["0", "1", "4", "8", "12"];
  const defaultAllAboutMe = false;
  const defaultWaitForHost = true;
  const defaultEnableJoinDuringRound = true;
  const [selectedDecks, setSelectedDecks] = useState<Array<CardDeckPreview>>([]);
  const [tabValue, setTabValue] = React.useState("decks");
  const [numberOfRounds, setNumberOfRounds] = React.useState(numberOfRoundsChoices[2]);
  const [roundTimer, setRoundTimer] = React.useState(roundTimerChoices[3]);
  const [maxSkips, setMaxSkips] = React.useState(maxSkipsChoices[3]);
  const [selectDecks, setSelectDecks] = useState(false);
  const [createDecks, setCreateDecks] = useState(false);
  const submittedPlayerName = useGameContext().nameState;
  const setSubmittedPlayerName = useGameContext().setNameState;
  const [playerName, setPlayerName] = useState("");
  const setName = useGameContext().setNameState;
  const lastEditedDeck = useGameContext().lastEditedDeckState;
  const setLastEditedDeck = useGameContext().setLastEditedDeckState;
  const lobbyKey = useGameContext().lobbyKey;
  const setLobbyKey = useGameContext().setLobbyKey;
  const socket = useSocketContext().socket;
  const [playerCount, setPlayerCount] = useState(1);
  const [players, setPlayers] = useState<{ [key: string]: Player }>({});
  const [playerId, setPlayerId] = useState("");
  const [hostId, setHostId] = useState("");
  const [isHost, setIsHost] = useState(false);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [previewDeckId, setPreviewDeckId] = useState("");
  const [deckPreferences, setDeckPreferences] = useState<{ [deckId: string]: number }>({});
  const [playerEndResults, setPlayerEndResults] = useState<PlayerEndResults>();
  const [wasDCed, setWasDCed] = useState(false);
  const history = useHistory();
  const baseUrl = useLocation().pathname.replace(/(lobby\/[A-Z]*)/, "");
  let inviteLink = window.location.href.replace(/(lobby.*)/, "") + "join/" + lobbyKey;
  const { t } = useTranslation();

  let theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // connect socket

  useEffect(() => {
    setPlayerCount(Object.keys(players).length);

  }, [players])
  

  useEffect(() => {
    if (playerCount > 1 && selectedDecks.length !== 0) {
      setCanStart(true);
    } else {
      setCanStart(false);
    }
    
  }, [playerCount, selectedDecks]);

  useEffect(() => {
    if (isHost && lobbyPhase === "L") {
      switch (true) {
        case playerCount > 6:
          setNumberOfRounds(numberOfRoundsChoices[0]);
          break;
        case playerCount > 3:
          setNumberOfRounds(numberOfRoundsChoices[1]);
          break;
        default:
          setNumberOfRounds(numberOfRoundsChoices[2]);
          break;
      }
    }
  }, [playerCount]);

  useEffect(() => {
    //eject players without lobby
    if (lobbyKey === "" || submittedPlayerName === "") {
      history.push("/play");
    }
  }, [lobbyKey]);

  useEffect(() => {
    socket.on("gamePhase", (gamePhase: string) => {
      setLobbyPhase(gamePhase);
    });
    socket.on("gameConfig", (gameConfig: GameConfig) => {
      console.log("received conf " + JSON.stringify(gameConfig));
      setNumberOfRounds(gameConfig.roundLimit.toString());
      setRoundTimer(gameConfig.roundTimer.toString());
      setMaxSkips(gameConfig.maxSkips.toString());
      setAllAboutMe(gameConfig.allAboutMe);
      setEnableJoinDuringRound(gameConfig.enableJoinDuringRound);
      setWaitForHost(gameConfig.waitForHost);
    });
    socket.on("playerId", (playerId: string) => {
      console.log("playerId being called");
      setPlayerId(playerId as string);
    });
    socket.on("setPlayerName", (playerName: string) => {
      setPlayerName(playerName);
    });
    socket.on("playerId", (playerId: string) => {
      console.log("playerId being called with " + playerId);
      setPlayerId(playerId as string);
    });
    socket.on("hostId", (newHostId: string) => {
      console.log("received new HostId " + newHostId);
      setHostId(newHostId as string);
    });
    socket.on("players", (players) => {
      console.log("received players object");
      console.log(JSON.stringify(players));
      setPlayers(players);
    });
    socket.on("hostTest", (message) => {
      console.log(message);
    });
    socket.on("invalid", () => setLobbyKey(""));
    socket.on("adminMessage", (adminMessage: string) => {
      alert(adminMessage);
    });
    socket.on("selectedDecks", async (selectedDeckIds) => {
      if (isHost) {
        console.log("WARNING HOST RECEIVING UPDATE MESSAGE");
      }
      let backendDeckInfos = await Deck.loadDeckInfos(selectedDeckIds);

      if (backendDeckInfos) {
        let deckInfos = convertBackendToFrontendDecks(backendDeckInfos);
        setSelectedDecks(deckInfos);
      }
    });
    socket.on("playerEndResults", (playerEndResults: PlayerEndResults) => {
      setPlayerEndResults(playerEndResults);
      console.log("received playerEndResults " + JSON.stringify(playerEndResults));
    });
    socket.on("turnInfo", (turnInfo: TurnInfo) => {
      console.log("received new turnInfo: " + JSON.stringify(turnInfo));
    });
    socket.on("restartLobby", () => {
      setLobbyPhase("L");

      setPlayerEndResults(undefined);
    });
    socket.on("disconnect", () => {
      history.push("/play");
    });
    socket.connect();
    socket.emit("joinLobby", { name: submittedPlayerName, lobbyKey: lobbyKey });

    console.log("trying to connect to lobby " + lobbyKey);
    return () => {
      setLobbyKey("");
      setSubmittedPlayerName("");
      socket.removeAllListeners();
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    setIsHost(hostId === playerId && hostId != "");
    console.log(
      "is Host player Id?: " +
        (hostId == playerId) +
        " with host " +
        hostId +
        " and player " +
        playerId
    );
  }, [hostId]);

  useEffect(() => {
    if (isHost) {
      let deckIds = selectedDecks.map((deck) => deck.id);
      socket.emit("setSelectedDecks", deckIds);
    }
    let newDeckPreferences = deckPreferences;
    _.forEach(Object.keys(deckPreferences), (deckId) => {
      if (
        !_.contains(
          selectedDecks.map((selectDeck) => selectDeck.id),
          deckId
        )
      ) {
        delete newDeckPreferences[deckId];
        console.log("deleted Deck preference for " + deckId);
      }
    });
    setDeckPreferences(newDeckPreferences);
  }, [selectedDecks]);

  //Deck and game Setting states
  const handleTabChange = (event: React.SyntheticEvent, newTabValue: string) => {
    setTabValue(newTabValue);
  };

  const handleNumberOfRoundsChange = (event: SelectChangeEvent) => {
    setNumberOfRounds(event.target.value as string);
  };

  const handleRoundTimerChange = (event: SelectChangeEvent) => {
    setRoundTimer(event.target.value as string);
  };

  const handleMaxSkipsChange = (event: SelectChangeEvent) => {
    setMaxSkips(event.target.value as string);
  };

  const [allAboutMe, setAllAboutMe] = useState(defaultAllAboutMe);
  const handleAllAboutMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllAboutMe(event.target.checked);
  };

  const [waitForHost, setWaitForHost] = useState(defaultWaitForHost);
  const handleWaitForHostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWaitForHost(event.target.checked);
  };

  const [enableJoinDuringRound, setEnableJoinDuringRound] = useState(defaultEnableJoinDuringRound);
  const handleEnableJoinDuringRoundChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableJoinDuringRound(event.target.checked);
  };

  useEffect(() => {
    if (isHost && lobbyPhase === "L") {
      socket.emit("setGameConfig", {
        roundLimit: parseInt(numberOfRounds),
        roundTimer: parseInt(roundTimer),
        maxSkips: parseInt(maxSkips),
        allAboutMe: allAboutMe,
        enableJoinDuringRound: enableJoinDuringRound,
        //not yet implemented
        waitForHost: true,
      });
    }
  }, [numberOfRounds, roundTimer, maxSkips, allAboutMe, enableJoinDuringRound]);

  const handleDeckPreferenceChange = (
    event: React.SyntheticEvent | Event,
    newValue: number | Array<number>,
    deckId: string
  ) => {
    if (typeof newValue === "number") {
      console.log("preference registered " + newValue);
      socket.emit("setDeckPreference", { deckId: deckId, newValue });
    }
  };

  const desktopMarks = [
    {
      value: 1,
      label: "very low",
    },

    {
      value: 4,
      label: "normal",
    },
    {
      value: 7,
      label: "very high",
    },
  ];

  const mobileMarks = [
    {
      value: 1,
      label: "very low",
    },
    {
      value: 7,
      label: "very high",
    },
  ];

  const handleCreateNewDeck = async () => {
    if (isAuthenticated) {
      let token = "";
      console.log("starting token request");
      token = await getAccessTokenSilently({ audience: "rank5.io" });
      console.log("ending token request");
      let deckId = await Deck.createDeck(token, "en");
      if (deckId) {
        console.log("received deck id " + JSON.stringify(deckId));
        setLastEditedDeck(deckId);
        setCreateDecks(true);
      }
    } else {
      loginWithPopup();
    }
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const getDeckOptions = (deck: CardDeckPreview) =>
    [
      {
        name: "preview",
        function: () => {
          setPreviewDeckId(deck.id);
        },
      },
    ].concat(
      isHost
        ? [
            {
              name: isDeckSelected(selectedDecks, deck) ? "deselect" : "select",
              function: () => {
                console.log("isDeck selected?");
                if (isDeckSelected(selectedDecks, deck)) {
                  console.log("trying to remove deck");
                  let index = _.findIndex(selectedDecks, (selectedDeck) => {
                    return deck.id === selectedDeck.id;
                  });
                  if (index !== -1) {
                    console.log("found index to remove " + index);

                    let newSelectedDecks = [...selectedDecks];

                    newSelectedDecks.splice(index, 1);

                    setSelectedDecks(newSelectedDecks);
                  }
                } else {
                  setSelectedDecks(selectedDecks.concat(deck));
                }
              },
            },
          ]
        : []
    );
  if (lobbyPhase === "L") {
    return (
      <>
        <Box
          id="box1"
          sx={{
            position: { xs: "relative", md: "auto" },
            height: { md: "100vh" },
            minHeight: { md: "", xs: "100vh" },
            overflow: { md: "auto", xs: "scroll" },
            backgroundImage: "url(" + Background + ")",
            backgroundSize: "cover",
            paddingBottom: { md: 0, xs: "2em" },
          }}
        >
          <Stack
            id="stack2"
            direction="column"
            spacing={createDecks ? 2 : 0}
            justifyContent="flex-start"
            alignItems="center"
            sx={{ height: "100%", flex: 1, overflow: { md: "scroll", xs: "visible" } }}
          >
            <Box
              sx={{
                backgroundImage: "url(" + SpinningLogo + ")",
                backgroundSize: "contain",
                height: { lg: "20vh", md: "20vh", xs: "20vh" },
                width: { lg: "30vw", md: "40vw", xs: "70vw" },
                marginTop: { lg: "1vh", md: "3vh", xs: "4vh" },
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                backgroundPositionY: "center",
              }}
            />
            {createDecks ? (
              <>
                <CardDeckCreator
                  goBack={() => {
                    setCreateDecks(false);
                  }}
                />
              </>
            ) : (
              <Stack
                sx={{ width: "90vw" }}
                spacing={2}
                direction={{ xs: "column", md: "row" }}
                justifyContent="center"
                alignItems="flex-start"
              >
                <Stack
                  alignItems="center"
                  direction="column"
                  sx={{ width: { xs: "100%", md: "45%" } }}
                  justifyContent="flex-start"
                >
                  <Paper sx={{ width: "100%" }} elevation={3}>
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      sx={{ marginInline: "2em", marginBlock: ".5em" }}
                    >
                      <Typography variant="h6">Players</Typography>

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        justifyContent="flex-start"
                        sx={{ overflow: "scroll", paddingBlock: ".5em" }}
                      >
                        {Object.keys(players).map((playerId) => {
                          return (
                            <PlayerCard
                              name={players[playerId].name}
                              isHost={playerId === hostId}
                            />
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Paper>
                  <Typography variant="h6">Waiting for other Players...</Typography>
                  <Paper sx={{ width: "100%", height: "3em" }}>
                    <Stack
                      justifyContent={"center"}
                      alignItems="center"
                      sx={{ height: "100%", width: "100%" }}
                    >
                      <Paper sx={{ width: "98%" }}>
                        <Stack
                          direction="row"
                          justifyContent={"space-between"}
                          alignItems="center"
                          sx={{ paddingLeft: "1em", paddingRight: ".3em" }}
                        >
                          <Typography
                            fontSize={{ md: 25, xs: 15 }}
                            sx={{ filter: isCodeVisible ? "" : "blur(8px)" }}
                          >
                            {inviteLink}
                          </Typography>

                          <Stack direction={"row"} alignItems="center">
                            <IconButton
                              onClick={() => {
                                setIsCodeVisible(!isCodeVisible);
                              }}
                            >
                              {isCodeVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                            <Divider orientation="vertical" sx={{ height: "2em" }} />
                            <Stack direction={"row"} justifyContent="center">
                              <IconButton onClick={() => navigator.clipboard.writeText(inviteLink)}>
                                <ContentCopyIcon />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Paper>
                    </Stack>
                  </Paper>
                </Stack>
                <Paper
                  elevation={3}
                  color="neutral"
                  sx={{
                    width: { xs: "100%", md: "45%", padding: 0 },
                    height: { md: "70vh", xs: "auto" },
                    overflow: selectDecks ? "hidden" : "scroll",
                  }}
                >
                  {selectDecks ? (
                    <CardDeckBrowser
                      goCreateDecks={() => handleCreateNewDeck()}
                      goBack={() => setSelectDecks(false)}
                      selectedDecks={selectedDecks}
                      setSelectedDecks={(newSelectedDecks: CardDeckPreview[]) =>
                        setSelectedDecks(newSelectedDecks)
                      }
                      setPreviewDeckId={(previewDeckId: string) => setPreviewDeckId(previewDeckId)}
                    />
                  ) : (
                    <>
                      <TabContext value={tabValue}>
                        <TabList centered variant="fullWidth" onChange={handleTabChange}>
                          <Tab label="Decks" value="decks" />
                          <Tab label="Personal" value="personal" />
                        </TabList>

                        <TabPanel
                          sx={{ paddingTop: "1em", paddingBottom: 0, paddingInline: "1.4em" }}
                          value="decks"
                        >
                          <Stack direction="column" alignItems="center" spacing={2}>
                            <Paper sx={{ width: "100%" }}>
                              <Stack sx={{ marginInline: "1em", marginTop: ".5em" }}>
                                <Typography variant="h5">Selected Decks</Typography>
                                <Stack
                                  direction="row"
                                  sx={{
                                    paddingBlock: "1em",
                                    overflowX: "scroll",
                                    overflowY: "hidden",
                                    minHeight: "16em",
                                    width: "100%",
                                  }}
                                  spacing={2}
                                  alignItems="center"
                                  justifyContent={
                                    selectedDecks.length === 0 ? "center" : "flex-start"
                                  }
                                >
                                  {isHost ? (
                                    <Tooltip title="Add Decks">
                                      <IconButton
                                        onClick={() => setSelectDecks(true)}
                                        aria-label="Add"
                                        sx={{ height: "3em", width: "3em", marginInline: "1em" }}
                                      >
                                        <ControlPointIcon
                                          color="primary"
                                          sx={{ height: "2.8em", width: "2.8em" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <></>
                                  )}
                                  {/* <CardDeck title="yeet" questions={10} answers={20} categories={13} /> */}
                                  {/* <CardDeck
                                title="rank5.io featured cards"
                                questions={10}
                                answers={20}
                                categories={13}
                                language={Languages.english}
                                id=""
                              /> */}
                                  {selectedDecks.map((deck) => {
                                    return (
                                      <Box sx={{ minWidth: "15em" }}>
                                        <CardDeck
                                          featured={deck.featured}
                                          title={deck.name}
                                          questions={deck.questionCardCount}
                                          answers={deck.answerCardCount}
                                          categories={deck.categoriesCount}
                                          language={deck.language}
                                          creatorName={deck.creatorName}
                                          id={deck.id}
                                          options={getDeckOptions(deck)}
                                        />
                                      </Box>
                                    );
                                  })}
                                </Stack>
                              </Stack>
                            </Paper>

                            <Typography
                              textAlign={"left"}
                              variant="h6"
                              sx={{ width: "100%", marginTop: ".5em" }}
                            >
                              Settings
                            </Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              sx={{ width: "100%" }}
                            >
                              <Stack direction="column" sx={{ width: "45%" }}>
                                <SettingSelector
                                  inputLabel={"Amount"}
                                  setting={"Number of Rounds:"}
                                  choices={["1", "2", "3", "5", "10", "15"]}
                                  selectedChoice={numberOfRounds}
                                  select={handleNumberOfRoundsChange}
                                  readonly={!isHost}
                                />
                                <SettingSelector
                                  inputLabel={"Seconds"}
                                  setting={"Round Timer:"}
                                  choices={["75", "120", "180", "300"]}
                                  selectedChoice={roundTimer}
                                  select={handleRoundTimerChange}
                                  readonly={!isHost}
                                />
                                <SettingSelector
                                  inputLabel={"Amount"}
                                  setting={"Max Skips:"}
                                  choices={["0", "1", "4", "8", "12"]}
                                  selectedChoice={maxSkips}
                                  select={handleMaxSkipsChange}
                                  readonly={!isHost}
                                />
                              </Stack>
                              <Stack direction="column" sx={{ width: "45%" }}>
                                {/* <SettingSwitch
                                  toolTip={
                                    "All questions are about the host. Perfect for streamers!"
                                  }
                                  setting={"All-about-me:"}
                                  checked={allAboutMe}
                                  check={handleAllAboutMeChange}
                                  readonly={!isHost}
                                /> */}
                                {/* <SettingSwitch
                                    toolTip={
                                      "If selected the only the host decides when to start the next turn."
                                    }
                        
                                    setting={"Wait for host:"}
                                    checked={waitForHost}
                                    check={handleWaitForHostChange}
                                  /> */}
                                <SettingSwitch
                                  toolTip={"Players can still join after game start"}
                                  setting={"Enable join during round:"}
                                  checked={enableJoinDuringRound}
                                  check={handleEnableJoinDuringRoundChange}
                                  readonly={!isHost}
                                />
                              </Stack>
                            </Stack>
                            {isHost ? (
                              <Stack
                                justifyContent={"flex-end"}
                                direction="row"
                                sx={{ height: "5vh", width: "100%" }}
                              >
                                <Button
                                  disabled={!canStart}
                                  variant="contained"
                                  onClick={() => socket.emit("startGame")}
                                >
                                  Start Game
                                </Button>
                              </Stack>
                            ) : (
                              <></>
                            )}
                          </Stack>
                        </TabPanel>
                        <TabPanel value="personal">
                          {selectedDecks.length == 0 ? (
                            <Stack
                              direction="column"
                              justifyContent={"center"}
                              alignItems="center"
                              sx={{ height: "100%", width: "100%", paddingTop: "10vh" }}
                            >
                              <DoNotDisturbAltIcon sx={{ fontSize: { md: 150, xs: 100 } }} />
                              <Typography sx={{ fontSize: 40 }}>No decks selected</Typography>
                            </Stack>
                          ) : (
                            <Stack>
                              <Stack
                                direction="row"
                                justifyContent={"center"}
                                alignItems="center"
                                spacing={4}
                              >
                                <Stack
                                  alignItems="center"
                                  justifyContent={"flex-start"}
                                  sx={{ height: "100%", width: "45%" }}
                                >
                                  <Typography variant="subtitle1" textAlign={"center"}>
                                    Card Deck
                                  </Typography>
                                </Stack>
                                <Stack alignItems="center" sx={{ height: "100%", width: "45%" }}>
                                  <Typography variant="subtitle1" textAlign={"center"}>
                                    Probability of Drawing Card
                                  </Typography>
                                </Stack>
                              </Stack>
                              {selectedDecks.map((deck) => (
                                <Stack
                                  direction="row"
                                  justifyContent={"center"}
                                  alignItems="center"
                                  spacing={4}
                                >
                                  <Box sx={{ width: "45%" }}>
                                    <CardDeck
                                      featured={deck.featured}
                                      title={deck.name}
                                      onlyTitle
                                      id={deck.id}
                                      options={getDeckOptions(deck)}
                                    />
                                  </Box>
                                  <Stack sx={{ width: "calc(45% - 2em)", paddingInline: "2em" }}>
                                    <Slider
                                      aria-label="probablity-slider"
                                      value={
                                        _.has(deckPreferences, deck.id)
                                          ? deckPreferences[deck.id]
                                          : 4
                                      }
                                      onChange={(event: Event, newValue: number | number[]) => {
                                        let newDeckPreferences = deckPreferences;
                                        if (typeof newValue === "number") {
                                          newDeckPreferences[deck.id] = newValue;
                                          setDeckPreferences({ ...newDeckPreferences });
                                        }
                                      }}
                                      valueLabelDisplay="off"
                                      step={1}
                                      marks={isMobile ? mobileMarks : desktopMarks}
                                      min={1}
                                      max={7}
                                      onChangeCommitted={(
                                        event: React.SyntheticEvent | Event,
                                        newValue: number | Array<number>
                                      ) => {
                                        handleDeckPreferenceChange(event, newValue, deck.id);
                                      }}
                                    />
                                  </Stack>
                                </Stack>
                              ))}
                            </Stack>
                          )}
                        </TabPanel>
                      </TabContext>
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        sx={{ marginInline: "2em", marginBlock: ".5em" }}
                      ></Stack>
                    </>
                  )}
                </Paper>
              </Stack>
            )}
          </Stack>
        </Box>
        <Backdrop open={previewDeckId !== ""} sx={{ overflow: "scroll" }}>
          <CardDeckViewer
            previewDeckId={previewDeckId}
            setClose={() => {
              console.log("setting");
              setPreviewDeckId("");
            }}
          />
        </Backdrop>
      </>
    );
  } else if (lobbyPhase === "R") {
    return (
      <Game
        baseUrl={baseUrl}
        players={players}
        playerId={playerId}
        isHost={isHost}
        hostId={hostId}
        playerName={playerName}
      />
    );
  } else {
    return (
      <EndPanel
        playerName={players[playerId]?.name as string}
        isHost={isHost}
        playerEndResults={playerEndResults}
      />
    );
  }
}

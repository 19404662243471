import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import {GameContext} from "../../shared/GameContext"

interface GameContextProps {
  children: ReactNode;
}

export default function GameContextProvider(props: GameContextProps) {
  const [name, setName] = useState("");
  const [lastEditedDeck, setLastEditedDeck] = useState("");
  const [lobbyKey, setLobbyKey] = useState("");
  return (
    <GameContext.Provider value={{nameState:name, setNameState: setName, lastEditedDeckState:lastEditedDeck,setLastEditedDeckState:setLastEditedDeck, lobbyKey:lobbyKey, setLobbyKey:setLobbyKey}}>
      {props.children}
    </GameContext.Provider>
  );
}


import React, { useEffect, useRef, useState } from "react";

import Dialog from "../../components/Dialog/Dialog";
import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import { Languages } from "../../shared/language/languages";
import Switch from "@mui/material/Switch";
import Feedback from "../../shared/api/FeedbackApi";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import {
  QuestionCardInfo,
  AnswerCardInfo,
  CardInfo,
  CardType,
  Language,
  CardDeckPreview,
} from "../../shared/interfaces/GameLogicTypes";

import {
  Backdrop,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "underscore";
import CardDeck from "../CardDeck/CardDeck";
import {
  getElementHeight,
  convertDimensionsToPixels,
  getLanguageByLanguageId,
  isDeckSelected,
} from "../../shared/helper/functions";
import { useAuth0 } from "@auth0/auth0-react";
import { MenuProps } from "react-select";
import Deck from "../../shared/api/DeckApi";
import { sortDecks } from "../../routes/ProfilePage/ProfilePageLogic";
import CardDeckViewer from "../CardDeckViewer/CardDeckViewer";
import useSocketContext from "../../shared/useSocketContext";
import { Theme as MuiTheme} from "@mui/material/styles";
import { useTheme } from "@emotion/react";

interface CardDeckBrowserProps {
  goBack: () => void;
  goCreateDecks: () => void;
  selectedDecks: CardDeckPreview[];
  setSelectedDecks: (newSelectedDecks: CardDeckPreview[]) => void;
  setPreviewDeckId: (previewDeckId: string) => void;
}

function getSearchBarHeight() {
  return (document.getElementById("search-decks")?.clientHeight as unknown as string) + "px";
}
function getDividerHeight() {
  return (document.getElementById("browser-divider")?.clientHeight as unknown as string) + "px";
}
function getBrowserHeight() {
  return (document.getElementById("browser")?.clientHeight as unknown as string) + "px";
}

function getBoxHeight() {
  return (
    getElementHeight("browser") -
    getElementHeight("browser-divider") -
    getElementHeight("search-Height") -
    20
  );
}

export default function CardDeckBrowser(props: CardDeckBrowserProps) {
  const [deckOrCreator, setDeckOrCreator] = React.useState<string>("deck");
  const { getAccessTokenSilently } = useAuth0();
  const [deckLanguageIds, setDeckLanguageIds] = useState<Array<string>>(
    Object.keys(Languages).map((key) => Languages[key].id)
  );
  const [previewDecks, setPreviewDecks] = useState<Array<CardDeckPreview>>([]);

  const [query, setQuery] = useState("");
  let theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let socket = useSocketContext().socket;

  const handleDeckOrCreator = (event: React.MouseEvent<HTMLElement>, choice: string) => {
    if (choice !== null) {
      setDeckOrCreator(choice);
    }
  };

  let searchBarHeight = convertDimensionsToPixels(getElementHeight("search-decks"));

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    handleGetRandomDecks(false);
  }, []);

  useEffect(() => {
    searchBarHeight = convertDimensionsToPixels(getElementHeight("search-decks"));
    setFirstRender(false);
  }, [firstRender]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    console.log(JSON.stringify(value));
    if (typeof value !== "string" && _.contains(value, "deselectAll")) {
      setDeckLanguageIds([]);
    } else if (typeof value !== "string" && _.contains(value, "selectAll")) {
      setDeckLanguageIds(Object.keys(Languages).map((key) => Languages[key].id));
    } else {
      setDeckLanguageIds(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleSearch = async () => {
    let decks = await Deck.searchDecks(deckOrCreator, query, deckLanguageIds);
    console.log("deckInfos " + JSON.stringify(decks));
    if (decks) {
      setPreviewDecks(
        decks.map((deck: any) => {
          let parsedLanguage = getLanguageByLanguageId(deck.language);
          deck.language = parsedLanguage;
          return deck as unknown as CardDeckPreview;
        })
      );
    }
  };

  const handleGetRandomDecks = async (featuredOnly: boolean) => {
    let decks = await Deck.getRandomDecks(deckLanguageIds, featuredOnly);
    console.log("deckInfos " + JSON.stringify(decks));
    if (decks) {
      setPreviewDecks(
        decks.map((deck: any) => {
          let parsedLanguage = getLanguageByLanguageId(deck.language);
          deck.language = parsedLanguage;
          return deck as unknown as CardDeckPreview;
        })
      );
    }
  };

  useEffect(() => {
    console.log("currently selected: " + JSON.stringify(props.selectedDecks));
    console.log("CHANGE DETECTED");
  }, [props.selectedDecks]);

  const searchButton = () => {
    return (
      <IconButton onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
    );
  };

  return (
    <>
      <Stack justifyContent="space-between" alignItems="center" overflow="hidden">
        <Paper id="browser" sx={{ width: "calc(100% - 2em)", overflow: "hidden", margin: "1vh" }}>
          <Stack
            sx={{ margin: "1.5vh", height: { md: "65vh", xs: "auto" } }}
            spacing={1}
            justifyContent="flex-start"
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              spacing={1}
              alignItems="center"
              sx={{ height: "8em" }}
            >
              <Stack sx={{ height: "100%", width: "75%" }} spacing={2}>
                {isMobile ? (
                  <Stack direction="row" spacing={1}>
                    <TextField
                      fullWidth
                      id="search-decks"
                      label="Search"
                      variant="outlined"
                      InputLabelProps={{sx:{zIndex:0}}}
                      value={query}
                      inputProps={{
                        onKeyDown: (
                          e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
                        ) => {
                          if (e.key == "Enter") {
                            handleSearch();
                          }
                        },
                      }}
                      onChange={handleQueryChange}
                      InputProps={{ endAdornment: searchButton() }}
                    />
                    <Button sx={{width:"3em"}}
                      onClick={() => {
                    

                        handleGetRandomDecks(false);
                      }}
                      variant="contained"
                    >
                      Random
                    </Button>
                  </Stack>
                ) : (
                  <><TextField
                  InputLabelProps={{sx:{zIndex:0}}}
                  fullWidth
                  id="search-decks"
                  label="Search"
                  variant="outlined"
                  value={query}
                  inputProps={{
                    onKeyDown: (
                      e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => {
                      if (e.key == "Enter") {
                        handleSearch();
                      }
                    },
                  }}
                  onChange={handleQueryChange}
                  InputProps={{ endAdornment: searchButton() }}
                /></>
                )}

                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  spacing={1}
                  sx={{ height: searchBarHeight }}
                >
                  <ToggleButtonGroup
                    value={deckOrCreator}
                    exclusive
                    onChange={handleDeckOrCreator}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      color="primary"
                      sx={{ textTransform: "none", width: {md:"5em", xs:"4em" }}}
                      value="deck"
                      aria-label="deck"
                    >
                      <Typography variant="body1">{"deck"}</Typography>
                    </ToggleButton>
                    <ToggleButton
                      color="primary"
                      sx={{ textTransform: "none",width: {md:"5em", xs:"4em" } }}
                      value="creator"
                      aria-label="creator"
                    >
                      <Typography variant="body1">{"creator"}</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <FormControl sx={{ width: {md:"40%",xs:"100%"} }}>
                    <InputLabel id="demo-multiple-checkbox-label" sx={{zIndex:0}}>Languages</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={deckLanguageIds}
                      onChange={handleChange}
                      input={<OutlinedInput label="Languages" />}
                      renderValue={(selected) =>
                        selected
                          .map((id) => {
                            let languageName = getLanguageByLanguageId(id)?.name;
                            if (languageName !== undefined) return languageName;
                          })
                          .join(", ")
                      }
                    >
                      {[
                        <MenuItem
                          key={"deselect all"}
                          value={deckLanguageIds.length !== 0 ? "deselectAll" : "selectAll"}
                        >
                          <ListItemText
                            primary={deckLanguageIds.length !== 0 ? "Deselect all" : "Select all"}
                          />
                        </MenuItem>,
                      ].concat(
                        Object.keys(Languages).map((languageKey) => {
                          let language = Languages[languageKey];
                          return (
                            <MenuItem
                              key={language.id}
                              value={language.id}
                              sx={{ display: "inline-flex", width: "20%" }}
                            >
                              <Checkbox checked={_.contains(deckLanguageIds, language.id)} />
                              <ListItemText primary={language.name} />
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </FormControl>
                  {isMobile?<></>:<Button
                    onClick={() => {
                  

                      handleGetRandomDecks(false);
                    }}
                    variant="contained"
                  >
                    Random
                  </Button>}
                </Stack>
              </Stack>

              <Divider orientation="vertical" flexItem />
              <Stack justifyContent="space-between" sx={{ height: "100%", width:{xs:"20%", md:"20%"} }} spacing={2}>
                <Button
                  onClick={props.goCreateDecks}
                  variant="contained"
                  sx={{ height: searchBarHeight }}
                >
                  Create Deck
                </Button>

                <Button variant="outlined" onClick={props.goBack} sx={{ height: searchBarHeight }}>
                  Back
                </Button>
              </Stack>
            </Stack>
            <Divider id="browser-divider" sx={{ height: { xs: "2vh", xl: ".2vh" } }} />
            <Box sx={{ height: convertDimensionsToPixels(getBoxHeight()), overflowY: "scroll" }}>
              <Grid
                container
                wrap="wrap"
                justifyContent="flex-start"
                spacing={2}
                columns={{ xs: 2, sm: 3, md: 2, xl: 3 }}
                sx={{ overflow: "scroll" }}
              >
                {previewDecks.sort(sortDecks).map((deck, index) => {
                  return (
                    <Grid item xs={1} key={"deck-no" + index}>
                      <CardDeck
                        featured={deck.featured}
                        title={deck.name}
                        questions={deck.questionCardCount}
                        answers={deck.answerCardCount}
                        categories={deck.categoriesCount}
                        language={deck.language}
                        id={deck.id}
                        creatorName={deck.creatorName}
                        options={[
                          {
                            name: "preview",
                            function: () => {
                              props.setPreviewDeckId(deck.id);
                            },
                          },
                          {
                            name: isDeckSelected(props.selectedDecks, deck) ? "deselect" : "select",
                            function: () => {
                              console.log("isDeck selected?");
                              if (isDeckSelected(props.selectedDecks, deck)) {
                                console.log("trying to remove deck");
                                let index = _.findIndex(props.selectedDecks, (selectedDeck) => {
                                  return deck.id === selectedDeck.id;
                                });
                                if (index !== -1) {
                                  console.log("found index to remove " + index);

                                  let newSelectedDecks = [...props.selectedDecks];

                                  newSelectedDecks.splice(index, 1);

                                  props.setSelectedDecks(newSelectedDecks);
                                }
                              } else {
                                props.setSelectedDecks(props.selectedDecks.concat(deck));
                              }
                            },
                          },
                        ]}
                        selected={isDeckSelected(props.selectedDecks, deck)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Stack>
        </Paper>
      </Stack>
    </>
  );
}

import FrontPageLogo from "../Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import Feedback from "../../shared/api/FeedbackApi";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import _ from "underscore";
import CardDeck from "../CardDeck/CardDeck";
import { useEffect, useState } from "react";

interface CardWarningProps {
  warningTitle: string;
  warningDescription: string;
  showWarning: boolean;
  setShowWarning: (show: boolean) => void;
  canEnable?: boolean;
  setDontShowAgain?: (dontShowAgain: boolean) => void;
  enableFeature?: () => void;
  enableAnywayField?: string;
}

export default function CardWarning(props: CardWarningProps) {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleChangeDontShowAgain = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDontShowAgain(event.target.checked);
  };

  useEffect(() => {
    if (!props.showWarning) {
      if (props.setDontShowAgain != undefined) {
        props.setDontShowAgain(dontShowAgain);
      }
    }
  }, [props.showWarning]);

  return (
    <Dialog
      open={props.showWarning}
      onClose={() => {
        props.setShowWarning(false);
      }}
      aria-labelledby="highlighting warning"
      aria-describedby="warns the user from highlighting answer cards"
    >
      <DialogTitle id="alert-dialog-title">{props.warningTitle}</DialogTitle>
      <DialogContent>
        <Stack justifyContent={"space-between"} sx={{height:"5em"}}>
          <DialogContentText id="alert-dialog-description">
            {props.warningDescription}
          </DialogContentText>
          {props.setDontShowAgain ? (
            <Stack><Divider /><Stack direction="row" alignItems={"center"} justifyContent="center" spacing={0}>
              <Checkbox checked={dontShowAgain} onChange={handleChangeDontShowAgain} />
              <Typography variant="body1" sx={{ marginBottom: "0", fontWeight: "400", color: "rgba(0, 0, 0, 0.6)" }}>{"Do not show again"}</Typography>
            </Stack><Divider /></Stack>
          ) : (
            <></>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setShowWarning(false);
          }}
          variant="contained"
        >
          Go Back
        </Button>
        {props.canEnable ? (
          <Button
            onClick={() => {
              if (props.enableFeature) {
                props.enableFeature();
              }
              props.setShowWarning(false);
            }}
            autoFocus
          >
            {props.enableAnywayField?props.enableAnywayField:"Enable anyway"}
          </Button>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
}

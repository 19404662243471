import React, { useContext, useEffect, useRef, useState } from "react";

import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import QuestionHeader from "../AlternateQuestionHeader/AlternateQuestionHeader";
import Switch from "@mui/material/Switch";
import Feedback from "../../shared/api/FeedbackApi";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  Tooltip,
  Autocomplete,
  AutocompleteChangeReason,
  Backdrop,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Slider,
  Tab,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery
} from "@mui/material";
import _ from "underscore";
import CardDeck from "../CardDeck/CardDeck";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import PreviewCard from "../PreviewCard/PreviewCard";
import CardWarning from "../CardWarning/CardWarning";
import { getRandomTsarName } from "../../shared/helper/functions";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  QuestionCardInfo,
  AnswerCardInfo,
  CardInfo,
  CardType,
} from "../../shared/interfaces/GameLogicTypes";
import { convertDimensionsToPixels, getElementHeight } from "../../shared/helper/functions";
import Deck from "../../shared/api/DeckApi";
import { useAuth0 } from "@auth0/auth0-react";
import { GameContext } from "../../shared/GameContext";
import { useTheme } from "@emotion/react";
import { Theme as MuiTheme } from "@mui/material/styles";
declare module "@emotion/react" {
  export interface Theme extends MuiTheme {}
}

interface CardCreationWidgetProps {
  tsarName: string;
  setTsarName: (newTsarName: string) => void;
  usedCategories: string[];
  setUsedCategories: (newUsedCategories: string[]) => void;
  answerCards: AnswerCardInfo[];
  setAnswerCards: (newAnswerCards: AnswerCardInfo[]) => void;
  questionCards: QuestionCardInfo[];
  setQuestionCards: (newQuestionCards: QuestionCardInfo[]) => void;
  questionOrAnswer : string;
  setQuestionOrAnswer : (questionOrAnswer: string)=>void;
  forOrAbout : string;
  setForOrAbout : (setForOrAbout: string)=>void;
  forTsarQuestion : string;
  setForTsarQuestion : (forTsarQuestion: string)=>void;
  aboutTsarQuestion : string;
  setAboutTsarQuestion : (aboutTsarQuestion : string)=>void;
  probability : number;
  setProbability : (probability: number)=>void;
  answer : string;
  setAnswer : (questionOrAnswer: string)=>void;
  currentCategories: string[];
  setCurrentCategories : (questionOrAnswer: string[])=>void;
}

export default function CardCreationWidget(props: CardCreationWidgetProps) {
  // const [questionOrAnswer, setQuestionOrAnswer] = useState<string>("question");
  // const [forOrAbout, setForOrAbout] = useState("for");
  // const [forTsarQuestion, setForTsarQuestion] = useState("");
  // const [probability, setProbability] = useState(50);
  // const [currentCategories, setCurrentCategories] = useState<string[]>([]);
  // const [aboutTsarQuestion, setAboutTsarQuestion] = useState("");
  // const [answer, setAnswer] = useState("");
  const [previewCard, setPreviewCard] = useState(false);
  const [enableAnswerHighlighting, setEnableAnswerHighlighting] = useState(false);
  const [enableAnswerTsaring, setEnableAnswerTsaring] = useState(false);
  const [showHighlightingWarning, setShowHighlightingWarning] = useState(false);
  const [showTsaringWarning, setShowTsaringWarning] = useState(false);
  const [showForTsarIncompletenessWarning, setShowForTsarIncompletenessWarning] = useState(false);
  const [showAboutTsarIncompletenessWarning, setShowAboutTsarIncompletenessWarning] =
    useState(false);
  const [showAnswerIncompletenessWarning, setShowAnswerIncompletenessWarning] = useState(false);
  const [showNoCategoriesWarning, setShowNoCategoriesWarning] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const deckId = useContext(GameContext).lastEditedDeckState;
  let theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleProbabilityChange = (event: Event, newValue: number | number[]) => {
    props.setProbability(newValue as number);
  };

  //Ensure that user cannot modify cardText while previewing
  useEffect(() => {
    if (previewCard) {
      setPreviewCard(false);
    }
  }, [props.forTsarQuestion, props.aboutTsarQuestion, props.answer, props.questionOrAnswer, props.forOrAbout]);

  const handleQuestionOrAnswerChange = (event: React.MouseEvent<HTMLElement>, choice: string) => {
    if (choice !== null) {
      props.setQuestionOrAnswer(choice);
    }
  };

  const handleForOrAboutChange = (event: React.MouseEvent<HTMLElement>, choice: string) => {
    if (choice !== null) {
      props.setForOrAbout(choice);
    }
  };

  const handleClose = () => {
    if (previewCard) {
      setPreviewCard(false);
    }
  };
  const handleToggle = () => {
   setPreviewCard(!previewCard);
  };

  const setCardText = (newText: string) => {
    props.questionOrAnswer == "question"
      ? props.forOrAbout == "for"
        ? props.setForTsarQuestion(newText)
        : props.setAboutTsarQuestion(newText)
      : props.setAnswer(newText);
  };

  const handleCardTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCardText(e.target.value);
  };

  const getCurrentCardText = () => {
    return props.questionOrAnswer == "question"
      ? props.forOrAbout == "for"
        ? props.forTsarQuestion
        : props.aboutTsarQuestion
      : props.answer;
  };

  function containsTsar(toCheck: string) {
    return toCheck.includes("{{tsar}}");
  }

  function insertTsar() {
    let input = document.getElementById("cardInput") as HTMLInputElement;
    let cardText = getCurrentCardText();
    let cursor = getCursor();
    console.log("fetched cursor: " + JSON.stringify(cursor));
    if (cursor != null && cursor.start != null && cursor.end != null) {
      let patchedValue =
        cardText.substr(0, cursor.start) + "<h>{{tsar}}</h>" + cardText.substr(cursor.end);
      if (input != null) {
        setCardText(patchedValue);
        input.focus();
      }
    }
  }

  function getCursor() {
    let cursor = document.getElementById("cardInput") as HTMLInputElement;
    if (cursor != null) {
      let start = cursor.selectionStart;
      let end = cursor.selectionEnd;
      return { start, end };
    } else {
      return null;
    }
  }

  function highlightValue() {
    let cardText = getCurrentCardText();
    let input = document.getElementById("cardInput") as HTMLInputElement;
    let cursor = getCursor();
    console.log("fetched cursor: " + JSON.stringify(cursor));
    if (cursor != null && cursor.start != null && cursor.end != null) {
      let patchedValue =
        cardText.substr(0, cursor.start) +
        "<h>" +
        cardText.substring(cursor.start, cursor.end) +
        "</h>" +
        cardText.substr(cursor.end);
      if (input != null) {
        setCardText(patchedValue);
        input.focus();
      }
    }
  }

  //adds the current input into the corresponding card stack
  const handleAdd = async () => {
    if (props.currentCategories.length === 0) {
      setShowNoCategoriesWarning(true);
    } else {
      let token = await getAccessTokenSilently({ audience: "rank5.io" });

      console.log("trying to add card to deck " + deckId);
      if (props.questionOrAnswer == "question") {
        if (props.forTsarQuestion.length === 0) {
          setShowForTsarIncompletenessWarning(true);
        } else if (props.aboutTsarQuestion.length == 0) {
          setShowAboutTsarIncompletenessWarning(true);
        } else {
          let id = await Deck.createQuestionCard(
            token,
            deckId,
            props.currentCategories,
            props.forTsarQuestion,
            props.aboutTsarQuestion,
            props.probability
          );
          if (id) {
            const newCard: QuestionCardInfo = {
              id: id,
              forTsar: props.forTsarQuestion,
              aboutTsar: props.aboutTsarQuestion,
              probability: props.probability,
              categories: props.currentCategories,
            };
            const newQuestionCards = props.questionCards;
            newQuestionCards.push(newCard);
            props.setQuestionCards([...newQuestionCards]);
            props.setForTsarQuestion("");
            props.setAboutTsarQuestion("");
            props.setProbability(50);
            props.setUsedCategories(_.union(props.usedCategories, props.currentCategories));
          }
        }
      } else if (props.questionOrAnswer == "answer") {
        if (props.answer.length === 0) {
          setShowAnswerIncompletenessWarning(true);
        } else {
          let id = await Deck.createAnswerCard(
            token,
            deckId,
            props.currentCategories,
            props.answer,
            props.probability
          );
          if (id) {
            const newCard: AnswerCardInfo = {
           
              id: id,
              answer: props.answer,
              probability: props.probability,
              categories: props.currentCategories,
            };
            const newAnswerCards = props.answerCards;
            newAnswerCards.push(newCard);
            props.setAnswerCards([...newAnswerCards]);
            props.setAnswer("");
            props.setProbability(50);
            props.setUsedCategories(_.union(props.usedCategories, props.currentCategories));
          }
        }
      }
    }
  };



  return (
    <>
      <Stack spacing={2} alignItems={"stretch"} sx={{ margin: "1em", flex: "1" }}>
        <Stack alignItems={"center"} spacing={1} sx={{ width: "100%" }}>
          <ToggleButtonGroup
            fullWidth
            value={props.questionOrAnswer}
            exclusive
            onChange={handleQuestionOrAnswerChange}
            aria-label="text alignment"
            sx={{ height: "3.5em" }}
          >
            <ToggleButton
              color="primary"
              sx={{ textTransform: "none" }}
              value="question"
              aria-label="question"
            >
              <Typography variant="body1" sx={{fontSize:{md:".8em",lg:"1.1em"}}}>{"Question"}</Typography>
            </ToggleButton>
            <ToggleButton
              color="primary"
              sx={{ textTransform: "none" }}
              value="answer"
              aria-label="answer"
            >
              <Typography variant="body1" sx={{fontSize:{md:".8em",lg:"1.1em"}}}>{"Answer"}</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            disabled={props.questionOrAnswer != "question"}
            fullWidth
            value={props.forOrAbout}
            exclusive
            onChange={handleForOrAboutChange}
            aria-label="text alignment"
            sx={{
              height: props.questionOrAnswer == "question" ? "3.5em" : "0px",
              overflow: "hidden",
              transition: "all .15s ease-in",
            }}
            //     className={"smoothCollapseButtons " + (questionOrAnswer=="question"?"":"collapsedButtons")}
          >
            <ToggleButton
              color="primary"
              sx={{ textTransform: "none" }}
              value="for"
              aria-label="for"
            >
              <Typography variant="body1" sx={{fontSize:{md:".8em",lg:"1.1em"}}}>{"for the tsar"}</Typography>
            </ToggleButton>
            <ToggleButton
              color="primary"
              sx={{ textTransform: "none" }}
              value="about"
              aria-label="about"
            >
              <Typography variant="body1" sx={{fontSize:{md:".8em",lg:"1.1em"}}}>{"about the tsar"}</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <TextField
          fullWidth
          value={
            props.questionOrAnswer == "question"
              ? props.forOrAbout == "for"
                ? props.forTsarQuestion
                : props.aboutTsarQuestion
              : props.answer
          }
          onKeyPress={async (e) => {
            if (e.key === "Enter") {
    
              handleAdd();
            }
          }}
          onChange={handleCardTextChange}
          id="cardInput"
          label={"Card text"}
          multiline
          rows={4}
        />

        <Stack spacing={1}>
          <Stack direction="row" justifyContent={"space-between"}>
            <Tooltip placement="top" title="The tsar is the person the round is about.">
              <Button
                variant={
                  props.forOrAbout === "about" && props.questionOrAnswer === "question"
                    ? "contained"
                    : "outlined"
                }
                sx={{ textTransform: "none", width: "48%" }}
              >
                <Typography
                  onClick={() => {
                    if (props.questionOrAnswer != "answer" || enableAnswerTsaring) {
                      insertTsar();
                    } else {
                      setShowTsaringWarning(true);
                    }
                  }}
                  sx={{fontSize:{md:".8em",lg:"1.1em"}}}
                >
                  {"insert"}
                  <br />
                  {"tsar name"}
                </Typography>
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Should be used to highlight the tsars pronouns.">
              <Button
                variant={props.questionOrAnswer==="question"?"contained":"outlined"}
                sx={{ textTransform: "none", width: "48%" }}
                onClick={() => {
                  if (props.questionOrAnswer != "answer" || enableAnswerHighlighting) {
                    highlightValue();
                  } else {
                    setShowHighlightingWarning(true);
                  }
                }}
              >
                <Typography sx={{fontSize:{md:".8em",lg:"1.1em"}}}>{"highlight\nselection"}</Typography>
              </Button>
            </Tooltip>
          </Stack>
          {/* <Box sx={{
                      height: questionOrAnswer == "question" ? "": "0px !important",
                      overflow: "hidden",
                      width: "100%",
                      transition: "all .15s ease-in",
                    }}> */}
          <Button
            onClick={handleToggle}
            variant={getCurrentCardText() ? "outlined" : "text"}
            fullWidth
            disabled={getCurrentCardText() == ""}
            sx={{
              height: getCurrentCardText() ? "3.5em" : "0px !important",
              padding: getCurrentCardText() ? "" : "0px !important",

              overflow: "hidden !important",
              transition: "all .15s ease-in",
            }}
          >
            <Typography>Preview</Typography>
          </Button>
          {/* </Box> */}
          <Autocomplete
            multiple
            id="size-small-standard-multi"
            size="small"
            freeSolo={true}
            options={props.usedCategories}
            getOptionLabel={(option) => option}
            value={props.currentCategories}
            onChange={(
              event: React.SyntheticEvent,
              value: string | Array<string>,
              reason: AutocompleteChangeReason
            ) => {
              if (typeof value == "string") {
                value = [value];
              }
              props.setCurrentCategories(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Categories"
                placeholder="new category"
              />
            )}
          />
          {/* <Stack sx={{border:"solid", borderColor: "primary.main", borderRadius: (theme)=>theme.shape.borderRadius + "px", height: "10vh"}}>
                  
                  <Chip/>
                  <TextField id="standard-basic" label="Categories" variant="standard" sx={{marginBottom:"0", borderRadius: (theme)=>theme.shape.borderRadius, border:"none"}}/>
                </ Stack> */}
          <Stack sx={{ marginTop: "1em !important" }}>
            <Typography variant="subtitle1">Probability:</Typography>
            <Stack alignItems={"center"}>
              <Slider
                sx={{ width: "95%" }}
                min={1}
                aria-label="Volume"
                value={props.probability}
                onChange={handleProbabilityChange}
              />
            </Stack>
          </Stack>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => {
              handleAdd();
            }}
            variant="contained"
          >
            <Typography>Add Card</Typography>
          </Button>
        </Stack>
      </Stack>

      {/* /////////////////////CARDWPREVIEWS//////////////////////////// */}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={previewCard}
      >
        <IconButton
          id="topRight"
          onClick={() => setPreviewCard(false)}
          sx={{ position: "absolute", top: "0", right: "0", marginRight: "3em", marginTop: "3em" }}
        >
          <CloseIcon color={"secondary"} sx={{ height: "2.8em", width: "2.8em" }} />
        </IconButton>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => {
            if (previewCard) {
              setPreviewCard(false);
            }
          }}
        >
          <Stack alignItems={"center"}>
            {/* CardPreviews */}
            {props.questionOrAnswer == "question" ? (
              <QuestionHeader
                question={{ forTsar: props.forTsarQuestion, aboutTsar: props.aboutTsarQuestion }}
                tsarName={props.tsarName}
                isTsar={props.forOrAbout == "for"}
                isMobile={isMobile}
                preview={true}
              />
            ) : (
              <PreviewCard bigPreview text={props.answer} tsarName={props.tsarName} />
            )}
            {containsTsar(getCurrentCardText()) ? (
              <IconButton onClick={() => props.setTsarName(getRandomTsarName())} aria-label="Add">
                <RefreshIcon
                  color="primary"
                  sx={{ height: { md: "2.8em", xs: "3em" }, width: { md: "2.8em", xs: "3em" } }}
                  onClick={() => {
                    console.log("aaa");
                  }}
                />
              </IconButton>
            ) : (
              <></>
            )}
          </Stack>
        </ClickAwayListener>
      </Backdrop>

      {/* /////////////////////CARDWARNINGS//////////////////////////// */}

      <CardWarning
        canEnable
        warningTitle="You cannot highlight Text of Answercards"
        warningDescription="Highlighting Should only be done on Questioncards to highlight the tsars name and pronouns."
        showWarning={showHighlightingWarning}
        setShowWarning={(show: boolean) => setShowHighlightingWarning(show)}
        
      />
      <CardWarning
        canEnable
        warningTitle="Insert tsar name into answer?"
        warningDescription="Inserting the name of the tsar into an answer card goes against convention and should only be done in special cases. In general, the information in the answer card should not have to refer to the name of the tsar."
        showWarning={showTsaringWarning}
        setShowWarning={(show: boolean) => setShowTsaringWarning(show)}
        enableFeature={() => setEnableAnswerTsaring(true)}
      />
      <CardWarning
        canEnable={false}
        warningTitle="Empty text in 'for the tsar' area"
        warningDescription="Question card text can not be empty for either side."
        showWarning={showForTsarIncompletenessWarning}
        setShowWarning={(show: boolean) => setShowForTsarIncompletenessWarning(show)}
      />
      <CardWarning
        canEnable={false}
        warningTitle="Empty text in 'about the tsar' area"
        warningDescription="Question card text can not be empty for either side."
        showWarning={showAboutTsarIncompletenessWarning}
        setShowWarning={(show: boolean) => setShowAboutTsarIncompletenessWarning(show)}
      />
      <CardWarning
        canEnable={false}
        warningTitle="Empty text in 'answer' area"
        warningDescription="Answer card text can not be empty."
        showWarning={showAnswerIncompletenessWarning}
        setShowWarning={(show: boolean) => setShowAnswerIncompletenessWarning(show)}
      />
      <CardWarning
        canEnable={false}
        warningTitle="No categories selected"
        warningDescription="A card needs at least one category tag."
        showWarning={showNoCategoriesWarning}
        setShowWarning={(show: boolean) => setShowNoCategoriesWarning(show)}
      />
    </>
  );
}

import { LanguagesType } from "../interfaces/GameLogicTypes";

export const Languages : LanguagesType = {
  english: {
    id: "en",
    name: "🇺🇸",
  },
  german: {
    id: "de",
    name: "🇩🇪",
  },
  french: {
    id: "fr",
    name: "🇫🇷",
  },
  spanish: {
    id: "es",
    name: "🇪🇸",
  },
  japanese: {
    id: "jp",
    name: "🇯🇵",
  },
  russian: {
    id: "ru",
    name: "🇷🇺",
  },
  italian: {
    id: "it",
    name: "🇮🇹",
  },
  chinese: {
    id: "cn",
    name: "🇨🇳",
  },
  korean: {
    id: "kr",
    name: "🇰🇷",
  },
  filipino: {
    id: "ph",
    name: "🇵🇭",
  },
  indian: {
    id: "in",
    name: "🇮🇳",
  },
  arab: {
    id: "sa",
    name: "🇸🇦",
  },
  portuguese: {
    id: "pt",
    name: "🇵🇹",
  },
  indonesian: {
    id: "id",
    name: "🇮🇩",
  },
  swedish: {
    id: "se",
    name: "🇸🇪",
  },
  polish: {
    id: "pl",
    name: "🇵🇱",
  },
  danish: {
    id: "dn",
    name: "🇩🇰",
  },
  dutch: {
    id: "nl",
    name: "🇳🇱",
  },
};

import { CSSProperties, ReactNode } from "react";
import Button from "../Button/Button";
import "./Dialog.css";
import { AiOutlineClose } from "react-icons/ai";
import ReactDOM from "react-dom";
import DialogLogic from "./DialogLogic";

export interface DialogProps {
  children?: ReactNode;
  title?: string;
  /**
   * If true, the user won't be able to close the dialog when clicking outside of it.
   */
  disableBackdropClick?: boolean;
  /**
   * Fixed width of the dialog in vw. Defaults to wrap its content tight.
   */
  width?: number;
  /**
   * Fixed height of the dialog in vh. Defaults to wrap its content tight.
   */
  height?: number;
  isOpen: boolean;
  style?:CSSProperties;
  useGradientStyle?:boolean;
  onClose: () => void;
}

export default function Dialog(props: DialogProps) {
  const { isFading, handleClose, width, height, maxWidth, maxHeight } =
    DialogLogic(props);
  if (!props.isOpen && !isFading) {
    document.body.style.overflow = "unset";
    return null;
  } else {
    document.body.style.overflow = "hidden";
  }
  return ReactDOM.createPortal(
    <>
      <div
        className="dialog-overlay"
        onClick={props.disableBackdropClick ? undefined : handleClose}
        style={{ opacity: props.isOpen && !isFading ? 1 : 0 }}
      />
      <div
        className="dialog-main"
        style={Object.assign({
          width: `${width}vw`,
          height: `${height}vh`,
          maxHeight: maxHeight,
          maxWidth: maxWidth,
          transform:
            props.isOpen && !isFading
              ? "translate(-50%, -50%)"
              : "translate(-50%, 250%)",
        },props.style)}
      >
        {props.children}
      </div>
    </>,
    document.getElementById("portal") as HTMLElement
  );
}

export const Colors = {
  YELLOW: "#fff355",
  BLUE: "#608ad6",
  RED: "#e75151",
  GREEN: "#92dc67",
  ORANGE: "#e17d47",
  GRAY: "#8c8c8c",
};

const yeet = {
  nZmTIcit3V6HUYnrAAAB: {
    playerInfo: { name: "tsar", score: 0, joinTime: 1639078865361 },
    results: { sequence: [1, 2, 4, 3, 5], swaps: [], points: 2000 },
  },
  eS480_QV8ea5lhG2AAAD: {
    playerInfo: { name: "asasda", score: 0, joinTime: 1639078870877 },
    results: { sequence: [1, 2, 4, 3, 5], swaps: [], points: 0 },
  },
};

import React, { useEffect, useState } from "react";
import FrontPageLogo from "../../components/Logo/Logo";

import Button from "../../components/Button/Button";
import PlayerList from "../../components/PlayerList/PlayerList";
import InvitationLinkField from "../../components/InvitationLinkField/InvitationLinkField";
import { Player, handleStartGame, isTouchOnlyDevice } from "./OldLobbyLogic";

import { GiEntryDoor, GiScaleMail } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useHistory, useLocation } from "react-router";
import { CSSProperties, FC } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ChoiceWidget from "../../components/ChoiceWidget/ChoiceWidget";
import SequenceBar from "../../components/SequenceBar/SequenceBar";
import QuestionHeader from "../../components/QuestionHeader/QuestionHeader";
import ResultsSequence from "../../components/ResultsSequence/ResultsSequence";
import ResultsDialog from "../../components/ResultsDialog/ResultsDialog";
import { Colors } from "./Colors";
import {
  TurnInfo,
  Submissions,
  Results,
  PlayerEndResults,
  GameConfig,
  PlayerConfig,
  Cue,
  CueTypes
} from "../../shared/interfaces/GameLogicTypes";
import "./Game.css";
import Timer from "../../components/Timer/Timer";
import io, { Socket } from "socket.io-client";
import AskNameDialog from "../../components/AskNameDialog/AskNameDialog";
import EndPanel from "../../components/EndPanel/EndPanel";
import GameConfigPanel from "../../components/GameConfig/GameConfigPanel";
import PlayerConfigPanel from "../../components/PlayerConfig/PlayerConfigPanel";
import RoundStartDialog from "../../components/RoundStartDialog/RoundStartDialog";
import { TouchBackend } from "react-dnd-touch-backend";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
let socket: Socket;

const reorder = (
  sequence: Array<string>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(sequence);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function OldLobby() {
  const [canStart, setCanStart] = useState(false);

  const [lobbyPhase, setLobbyPhase] = useState("L");
  const [turnPhase, setTurnPhase] = useState("S");
  const [players, setPlayers] = useState<{ [key: string]: Player }>({});
  const [playerId, setPlayerId] = useState("");
  const [hostId, setHostId] = useState("");
  const [tsarId, setTsarId] = useState("");
  const [isHost, setIsHost] = useState(false);
  const [isTsar, setIsTsar] = useState(false);
  const [tsarName, setTsarName] = useState("");
  const [invalidKey, setInvalidKey] = useState(false);
  const [playerName, setPlayerName] = useState("");
  const [askName, setAskName] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [questionId, setQuestionId] = useState(NaN);
  const [results, setResults] = useState<Results>({});
  const [cue1, setCue1] = useState<Cue>({type: CueTypes.text, content:"..."});
  const [cue2, setCue2] = useState<Cue>({type:CueTypes.text, content:"..."});
  const [cue3, setCue3] = useState<Cue>({type:CueTypes.text, content:"..."});
  const [cue4, setCue4] = useState<Cue>({type:CueTypes.text, content:"..."});
  const [cue5, setCue5] = useState<Cue>({type:CueTypes.text, content:"..."});
  const [showLatestResults, setShowLatestResults] = useState(false);
  const [timeLeft, setTimeLeft] = useState(-1);
  const [timeLimit, setTimeLimit] = useState(0);
  const [timerIsActive, setTimerIsActive] = useState(false);
  const [roundStart, setRoundStart] = useState(Date.now());
  const [currentRound, setCurrentRound] = useState(1);
  const [roundLimit, setRoundLimit] = useState(3);
  const [playerEndResults, setPlayerEndResults] = useState<PlayerEndResults>();
  const [forceSubmit, setForceSubmit] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  const [yellowNest, setYellowNest] = useState<Array<string>>([Colors.YELLOW]);
  const [blueNest, setBlueNest] = useState<Array<string>>([Colors.BLUE]);
  const [redNest, setRedNest] = useState<Array<string>>([Colors.RED]);
  const [greenNest, setGreenNest] = useState<Array<string>>([Colors.GREEN]);
  const [orangeNest, setOrangeNest] = useState<Array<string>>([Colors.ORANGE]);
  const [sequence, setSequence] = useState<Array<string>>([]);
  const [remainingSkips, setRemainingSkips] = useState(3);
  const [canSkip, setCanSkip] = useState(true);
  const history = useHistory();
  const baseUrl = useLocation().pathname.replace(/(lobby\/[A-Z]*)/, "");
  const Latest_Possible_Skip = 30;
  let lobbyKey = useLocation().pathname.split("/").pop();
  const { t } = useTranslation();

  

  const handleNewTurnInfo = (turnInfo: TurnInfo) => {
    console.log("setting up turnInfo");
    setForceSubmit(false);
    setRoundStart(turnInfo["startTime"]);
    setTimeLimit(turnInfo["timer"]);
    setTimeLeft(turnInfo.timer - Math.round((Date.now() - turnInfo.startTime)/1000));
  
    setRoundLimit(turnInfo["roundLimit"]);
    setTsarId(turnInfo["tsarId"]);
    //THIS LINE WAS COMMENTED AWAY AFTER DEPRACATION
    //setQuestionId(turnInfo["questionId"]);

    turnInfo["cues"].forEach((cue)=> {
      if (cue.type === CueTypes.image) {
        cue.content = baseUrl + cue.content;
      }
    })


    setCue1(turnInfo["cues"][0]);
    setCue2(turnInfo["cues"][1]);
    setCue3(turnInfo["cues"][2]);
    setCue4(turnInfo["cues"][3]);
    setCue5(turnInfo["cues"][4]);

  };

  useEffect(() => {
    setCanStart(Object.keys(players).length > 1);
    if (playerId != "") {
    setRemainingSkips(players[playerId]?.remainingSkips);
    }
  }, [players]);


  const handleNewResults = (results: Results) => {
    console.log("called handleNewResults");
    setResults(results);
    setTurnPhase("R");
  };

  const handleNewPlayers = () => {};

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination?.droppableId !== "sequenceBar"
    ) {
      return;
    } else {
      if (result.source.droppableId === "sequenceBar") {
        setSequence(
          reorder(sequence, result.source.index, result.destination.index)
        );
      } else {
        switch (result.source.droppableId) {
          case "yellow": {
            setYellowNest([]);
            break;
          }
          case "blue": {
            setBlueNest([]);
            break;
          }
          case "red": {
            setRedNest([]);
            break;
          }
          case "green": {
            setGreenNest([]);
            break;
          }
          case "orange": {
            setOrangeNest([]);
            break;
          }
        }

        console.log(
          "trying to add " +
            result.draggableId +
            " to sequence: " +
            sequence +
            " at index " +
            result.destination.index
        );
        let newSequence = sequence.slice();
        newSequence.splice(result.destination.index, 0, result.draggableId);
        setSequence(newSequence);
      }
    }
  };

  useEffect(() => {
    // console.log("trying to connect WS");
    // console.log("origin : " + window.location.origin);
    // console.log("host : " + window.location.host);
    // console.log("href : " + window.location.href);
    // console.log("pathname : " + window.location.pathname);
    // console.log("lobbyKey: " + lobbyKey);

    socket = io("/lobby", {
      query: { lobbyKey: lobbyKey },
    });
    socket.on("invalid", () => setInvalidKey(true));
    socket.on("players", (players) => {
      setPlayers(players);
    });
    socket.on("skipped", ()=> {
      reset();
    })
    socket.on("gamePhase", (lobbyPhase: string) => {
      setLobbyPhase(lobbyPhase);
    });
    socket.on("turnPhase", (turnPhase: string) => {
      console.log("setting turnphase by backend request: "+ turnPhase);
      setTurnPhase(turnPhase);
    });
    socket.on("adminMessage", (adminMessage: string) => {
      alert(adminMessage);
    });
    socket.on("canSkip", (canSkip:boolean) => {
      setCanSkip(canSkip);
    });
  
    socket.on("setPlayerName", (playerName: string) => {
      setPlayerName(playerName);
      setAskName(false);
    });
    socket.on("players", (players: Record<string, Player>) => {
      setPlayers(players);
    });
    socket.on("playerId", (playerId: string) => {
      setPlayerId(playerId as string);
    });
    socket.on("hostId", (newHostId: string) => {
      setHostId(newHostId as string);
    });
    //socket.on("turnPhase", (turnPhase) => setTurnPhase(turnPhase));
    socket.on("results", (results) => {
      handleNewResults(results);
    });
    socket.on("turnInfo", (turnInfo: TurnInfo) => {
      handleNewTurnInfo(turnInfo);
    });
    socket.on("playerEndResults", (playerEndResults: PlayerEndResults) => {
      setPlayerEndResults(playerEndResults);
      console.log(
        "received playerEndResults " + JSON.stringify(playerEndResults)
      );
    });
    socket.on("forceSubmit", () => {
      setForceSubmit(true);
    });
    socket.on("restartLobby", () => {
      setLobbyPhase("L");
      setTurnPhase("S");
      setPlayerEndResults(undefined);
    });

    return () => {
      console.log("disconnecting socket");
      socket.disconnect();
    };
  }, []);

  //Opens and closes Resultsdialog
  useEffect(() => {
    if (turnPhase == "S" && lobbyPhase == "R") {
       console.log("enabling timer");
       console.log("limit: " + timeLimit + " and timeLeft:" + timeLeft);
       setShowLatestResults(false);
       setTimerIsActive(true);
       console.log("SHOW latest results no: " +showLatestResults);
    } else if (turnPhase == "R" && lobbyPhase == "R") {
      console.log("rendering results with: " + JSON.stringify(results, null, 4));
      setShowLatestResults(true);
      console.log("SHOW latest results: yes" +showLatestResults);
      setTimerIsActive(false);
    }
  }, [lobbyPhase, turnPhase]);

  //timer controller
  useEffect(() => {
    var intervalId: NodeJS.Timeout;
    //console.log("enter timer effect");
    if (timerIsActive) {
      // exit early when we reach 0
      if (!timeLeft) return;
      // save intervalId to clear the interval when the
      // component re-renders
      //console.log("1 sec until with limit " + timeLimit + " and timeleft: " + timeLeft);
      intervalId = setInterval(() => {
        // console.log("now setting with limit " + timeLimit + " and timeleft: " + timeLeft);
        // console.log("value: " + (timeLimit - Math.floor((Date.now() - roundStart) / 1000)));
        setTimeLeft(timeLimit - Math.floor((Date.now() - roundStart) / 1000));
      }, 1000);
    }
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, timerIsActive]);

  useEffect(() => {
    setIsHost(hostId == playerId && hostId != "");
    console.log(
      "is Host player Id?: " +
        (hostId == playerId) +
        " with host " +
        hostId +
        " and player " +
        playerId
    );
  }, [hostId]);

  useEffect(() => {
    setShowIntro(true);
    let intervalId = setInterval(() => {
      setShowIntro(false);
    }, 20000);
    return () => clearInterval(intervalId);
  }, [tsarName]);

  //new Tsar means new round
  useEffect(() => {
    console.log("setting turnPhase by tsarId");
    setTurnPhase("S");
    setCanSkip(true);
  }, [tsarId]);

  useEffect(() => {
    let tsarInfo = players[tsarId];
    if (tsarId == playerId) {
      setIsTsar(true);
    } else {
      setIsTsar(false);
    }
    if (tsarInfo != undefined) {
      setTsarName(tsarInfo["name"]);
    }
  }, [tsarId]);

  const reset = () => {
  
    setSequence([]);
    setYellowNest([Colors.YELLOW]);
    setBlueNest([Colors.BLUE]);
    setRedNest([Colors.RED]);
    setGreenNest([Colors.GREEN]);
    setOrangeNest([Colors.ORANGE]);

   
    //setSequence([Colors.YELLOW,Colors.BLUE,Colors.RED,Colors.GREEN,Colors.ORANGE]);
  };

  useEffect(() => {
    reset();
  }, [tsarId]);
  
  if (invalidKey) {
    return <div className="column"><h1>{t("invalid_lobbyKey")}</h1><button className="button button-rectangular"onClick={()=>{history.push("/feedback")}}>{t("give_feedback")}</button></div>;
  } else {
    if (lobbyPhase == "L") {
      return (
        <div className="lobbyView">
          <FrontPageLogo />
          <div className="lobbyInformation">
            <div className="playerArea">
              <PlayerList
                displayScore={false}
                players={players}
                style={{
                  width: "45vw",
                  height: "55vh",
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
                tabWith={42}
              />
              <InvitationLinkField />
            </div>
            <div className="gameArea">
              <div
                className="row playerAndGameConfig"
                style={{
                  width: "45vw",
                  height: "55vh",
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
              >
                {isHost ? (
                  <GameConfigPanel
                    sendGameModeConfig={(gameMode:string) => {
                      socket.emit("setGameMode", gameMode);
                    }}
                    sendTimerConfig={(timer:string) => {
                      socket.emit("setTimer", timer);
                    }}
                    sendRoundLimitConfig={(roundLimit:number) => {
                      socket.emit("setTimer", roundLimit);
                    }}
                    style={{ width: "45%" }}
                  />
                ) : (
                  <h1 style={{ fontSize: "1.5rem", width: "45%" }}>
                    {t("host_is_configuring")}
                  </h1>
                )}

                {playerId ? (
                  <PlayerConfigPanel
                    sendConfig={(config) =>
                      socket.emit("setPlayerConfig", config)
                    }
                    style={{ width: "45%" }}
                  />
                ) : (
                  <div />
                )}
              </div>
              <div className="lobbyButtons">
                <button
                  className="button button-rectangular"
                  onClick={() => {
                    if (i18n.language == "en") {
                      i18n.changeLanguage("de");
                    } else {
                      i18n.changeLanguage("en");
                    }
                  }}
                >
                  {t("switch_language")}
                </button>
                {isHost ? (
                  <button
                    className="button button-rectangular"
                    disabled={!canStart}
                    style={{ marginLeft: "1vw" }}
                    onClick={() => {
                      socket.emit("startGame");
                    }}
                  >
                    {t("start_game")}
                  </button>
                ) : (
                  <div />
                )}
                <button
                  className="button button-rectangular"
                  style={{ marginLeft: "1vw", width: "5vw" }}
                  onClick={() => {
                    history.push("");
                  }}
                >
                  <GiEntryDoor style={{ transform: "scale(2)" }} />
                </button>
              </div>
            </div>
          </div>
          <AskNameDialog
            isOpen={askName}
            setName={(playerName: string, config: PlayerConfig) => {
              socket.emit("setPlayerName", {
                playerName: playerName,
                config: config,
              });
            }}
            personalDetails={false}
          />
        </div>
      );
    } else if (lobbyPhase == "R") {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="gamePage">
            <QuestionHeader
             skip={()=>socket.emit("skipQuestion")}
              questionId={questionId}
              tsarName={tsarName}
              isTsar={isTsar}
              remainingSkips={remainingSkips}
              canSkip={ canSkip}
            />
            <div className="userArea">
              <PlayerList
                displayScore={true}
                players={players}
                style={{ width: "20vw", height: "70vh" }}
                tabWith={18}
              />
              <div className="playBoard">
                <div className="choices">
                  <div className="imageRow">
                    <ChoiceWidget
                      cue={cue1}
                      color={Colors.YELLOW}
                      sequence={yellowNest}
                      isImage={true}
                    />
                    <ChoiceWidget
                      cue={cue2}
                      color={Colors.BLUE}
                      sequence={blueNest}
                      isImage={true}
                    />
                    <ChoiceWidget
                      cue={cue3}
                      color={Colors.RED}
                      sequence={redNest}
                      isImage={true}
                    />
                  </div>
                  <div className="imageRow">
                    <ChoiceWidget
                      cue={cue4}
                      color={Colors.GREEN}
                      sequence={greenNest}
                      isImage={false}
                    />
                    <ChoiceWidget
                      cue={cue5}
                      color={Colors.ORANGE}
                      sequence={orangeNest}
                      isImage={true}
                    />
                  </div>
                </div>
                {/* <SequenceBar
                  sequence={sequence}
                  reset={() => reset()}
                  submit={(sequence: Array<number>) => {
                    socket.emit("submit", sequence);
                    console.log("submitting " + JSON.stringify(sequence));
                  }}
                  unsubmit={() => socket.emit("unsubmit")}
                  forceSubmit={forceSubmit}
                /> */}
              </div>
              <Timer
                timeLeft={timeLeft}
                round={currentRound}
                roundLimit={roundLimit}
              />
            </div>
            <RoundStartDialog
              isOpen={showIntro}
              isTsar={isTsar}
              tsarName={tsarName}
              setClose={() => setShowIntro(false)}
            />

            
            <AskNameDialog
              personalDetails={true}
              isOpen={askName}
              setName={(playerName: string, config: PlayerConfig) =>
                socket.emit("setPlayerName", {
                  playerName: playerName,
                  config: config,
                })
              }
            />
          </div>
        </DragDropContext>
      );

      // <div>
      //   {isTsar
      //     ? "Die anderen spieler haben gesagt: " + JSON.stringify(results)
      //     : "hurra du hast folgendes ergebnis erzielt: " +
      //       results[playerId]}
      //   <button onClick={() => socket.emit("nextTurn")}>next round</button>
      // </div>
    } else {
      return (
        <div>
          
          <AskNameDialog
            personalDetails={true}
            isOpen={askName}
            setName={(playerName: string, config: PlayerConfig) =>
              socket.emit("setPlayerName", {
                playerName: playerName,
                config: config,
              })
            }
          />
        </div>
      );
    }
  }
}

function createKek(): void {
  throw new Error("Function not implemented.");
}

export function createResults() {
  return {
    sWBaQF2Xb5DmCbNUAAAH: {
      playerInfo: { name: "asaasas", score: 0, joinTime: 1639237279729 },
      results: { sequence: [1, 2, 3, 4, 5], swaps: [], points: 2000 },
    },
    j3A9BnN9ylJfvZZjAAAJ: {
      playerInfo: { name: "aaaaaaa", score: 0, joinTime: 1639237286637 },
      results: { sequence: [1, 2, 3, 4, 5], swaps: [], points: 0 },
    },
  };
}

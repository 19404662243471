import CSS from "csstype";
import { split } from "lodash";
import { useEffect, useState } from "react";
import "./QuestionHeader.css";
import { questions } from "../../shared/text/People";
import { t } from "i18next";
import { Trans } from "react-i18next";


export interface frontendQuestion {
  id: number;
  aboutTsarKey: string;
  forTsarKey: string;
}

export interface QuestionHeaderProps {
  questionId: number;
  tsarName: string;
  isTsar: boolean;
  skip: ()=>void;
  remainingSkips:number;
  canSkip: boolean;
}

export default function QuestionHeader(props: QuestionHeaderProps) {
  return (
    <div className="questionHeader">
      <p className="question">
        {/* question for tsar and question for player */}
        <Trans
          components={{ h: <span className="highlightedText" /> }}
          values={{ tsar: props.tsarName }}
        >
          {props.isTsar
            ? t("q" + props.questionId + "forTsar")
            : t("q" + props.questionId + "aboutTsar")}
        </Trans>
      </p>
      {props.isTsar?
      <button disabled={!props.canSkip || (props.remainingSkips == 0)} onClick={()=>props.skip()} className="button skipButton">
        <Trans
          values={{ remainingSkips: props.remainingSkips }}
        >
         {t("skip")}
        </Trans>
      </button>
      :<></>}
    </div>
  );
}

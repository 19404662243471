import { ReactNode, useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import "./AskNameDialog.css";
import CSS from "csstype";
import { disconnect, setMaxListeners } from "process";
import Dialog from "../Dialog/Dialog";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import PlayerConfigPanel from "../PlayerConfig/PlayerConfigPanel";
import { PlayerConfig } from "../../shared/interfaces/GameLogicTypes";
import { t } from "i18next";

export interface askNameDialogProps {
  isOpen: boolean;
  setName: (name: string, config: PlayerConfig) => void;
  personalDetails: boolean;
}

export default function AskNameDialog(props: askNameDialogProps) {

  
  const nameInputEl = useRef<HTMLInputElement>(null);
  const [playerConfig, setPlayerConfig] = useState<PlayerConfig>({
    age: 21,
    orientation: 50,
  });
  return (
    <div>
      <Dialog isOpen={props.isOpen} disableBackdropClick={true} onClose={() => {}} width={25}>
        <h1>{t("set_your_name")}</h1>
        <div className="setNameField">
          <input
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if ((nameInputEl.current?.value as string).length > 1) {
                  props.setName(nameInputEl.current?.value as string, playerConfig);
                }
              }
            }}
            maxLength={16}
            className="nameInput"
            ref={nameInputEl}
          ></input>
          <button className="button button-square"
            id="setName"
            style={{ width: "6vw", marginBottom: "0vh", marginTop: "0vh" }}
            onClick={() => {
              props.setName(nameInputEl.current?.value as string, playerConfig);
              console.log(props.setName);
              console.log(nameInputEl.current?.value as string);
              console.log(JSON.stringify(playerConfig));
            }}
          >
            {t("confirm")}
          </button>
        </div>
        <p>{t("recommendation")}</p>
        {props.personalDetails ? (
          <PlayerConfigPanel sendConfig={(config) => setPlayerConfig(config)} />
        ) : (
          <div />
        )}
      </Dialog>
    </div>
  );
}

import { ReactNode, useEffect, useRef, useState } from "react";

import CSS from "csstype";
import "./ResultsDialog.css";
import { disconnect, setMaxListeners } from "process";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import Dialog from "../Dialog/Dialog";
import { getColorStylebyNumber, getFontSizeByAnswer } from "../../shared/helper/functions";

import {
  TurnInfo,
  Submissions,
  Results,
  PlayerEndResults,
  PlayerResultInfo,
  Cue,
  ScoredSwap,
  CueTypes,
  Question,
} from "../../shared/interfaces/GameLogicTypes";
import {
  hasPlayerAnswered,
  getPlayerName,
  getPlayerResults,
  getPlayerSequence,
  getPlayerSwaps,
  getPlayerScore,
  applyScoredSwap,
  getPlayerSubmissionResultList,
} from "./ResultsDialogLogic";
import ResultsSequence from "../ResultsSequence/ResultsSequence";
import _ from "underscore";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Flipped, Flipper } from "react-flip-toolkit";
import {
  Backdrop,
  ClickAwayListener,
  Paper,
  Stack,
  Button,
  IconButton,
  Typography,
} from "@mui/material";

import QuestionHeader from "../AlternateQuestionHeader/AlternateQuestionHeader";
import useSocketContext from "../../shared/useSocketContext";

export interface ResultsDialogProps {
  isTsar: boolean;
  isHost: boolean;
  tsarId: string;
  question: Question;
  playerId: string;
  results: Results;
  isOpen: boolean;
  cues: Cue[];
}

export default function ResultsDialog(props: ResultsDialogProps) {
  const [tsarName, setTsarName] = useState("");
  const [tsarAnswered, setTsarAnswered] = useState(false);
  const [tsarSequence, setTsarSequence] = useState<number[]>([]);
  const [playerName, setPlayerName] = useState("");
  const [playerAnswered, setPlayerAnswered] = useState(false);
  const [playerSequence, setPlayerSequence] = useState<number[]>([]);
  const [playerSwaps, setPlayerSwaps] = useState<ScoredSwap[]>([]);
  const [playerScore, setPlayerScore] = useState(2000);
  const [currentSwapIndex, setCurrentSwapIndex] = useState(0);
  //wether the traversion over the swap array is forwards or backwards
  const [swapForward, setSwapForward] = useState(true);
  const [currentScore, setCurrentScore] = useState(2000);
  const [readyForAnimation, setReadyForAnimation] = useState(false);
  //"Myresults, Allresults
  const [currentView, setCurrentView] = useState("M");
  const [changingSequence, setChangingSequence] = useState(false);
  const [imageSequence, setImageSequence] = useState([1, 2, 3, 4, 5]);
  const [sortedNonTsarResults, setSortedNonTsarResults] = useState<
    {
      playerId: string;
      playerName: string;

      score: number;
      sequence: number[];
    }[]
  >([]);
  let socket = useSocketContext().socket;

  useEffect(() => {
    //constructor
    if (props.isOpen) {
      console.log("executing results constructor");
      setSwapForward(true);
      setTsarName(getPlayerName(props.results, props.tsarId));
      setTsarAnswered(hasPlayerAnswered(props.results, props.tsarId));
      setTsarSequence(getPlayerSequence(props.results, props.tsarId));
      setPlayerName(getPlayerName(props.results, props.playerId));
      setPlayerAnswered(hasPlayerAnswered(props.results, props.playerId));
      setPlayerSequence(getPlayerSequence(props.results, props.playerId));
      //inserting Pseudo Element to have an animation startingPoint
      let emptySwapSequence: Array<ScoredSwap> = [];
      setPlayerSwaps(
        _.union(
          [{ score: 0, swap: [0, 0] }],
          hasPlayerAnswered(props.results, props.playerId)
            ? getPlayerSwaps(props.results, props.playerId)
            : emptySwapSequence
        )
      );
      setImageSequence(
        hasPlayerAnswered(props.results, props.tsarId)
          ? getPlayerSequence(props.results, props.tsarId)
          : [1, 2, 3, 4, 5]
      );
      console.log(
        "set images sequence to " +
          JSON.stringify(
            hasPlayerAnswered(props.results, props.tsarId)
              ? getPlayerSequence(props.results, props.tsarId)
              : [1, 2, 3, 4, 5]
          ) +
          " by using id " +
          props.tsarId +
          " and not " +
          props.playerId
      );
      console.log(
        "getplayerSequence for me " +
          JSON.stringify(getPlayerSequence(props.results, props.playerId))
      );
      console.log(
        "getplayerSequence for tsar " +
          JSON.stringify(getPlayerSequence(props.results, props.tsarId))
      );
      setPlayerScore(2000);
      setReadyForAnimation(true);
      setSortedNonTsarResults(getPlayerSubmissionResultList(props.results, props.tsarId));
    } else {
      setSwapForward(true);
      setCurrentSwapIndex(0);
      setCurrentView("M");
    }
    //deconstructor
  }, [props.isOpen]);

  useEffect(() => {
    if (!props.isOpen) {
      setReadyForAnimation(false);
    }
  }, [props.isOpen]);

  useEffect(
    () => {
      let animationTimer: NodeJS.Timeout;
      if (props.isOpen && playerSwaps.length != 0 && readyForAnimation) {
        animationTimer = setTimeout(() => {
          if (playerSwaps.length > currentSwapIndex + 1) {
            setCurrentSwapIndex(currentSwapIndex + 1);
          }
        }, 1100);
      }
      return () => {
        clearTimeout(animationTimer);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [readyForAnimation, playerSequence]
  );

  useEffect(() => {
    if (playerSequence.length != 0) {
      console.log("playerSwaps: " + JSON.stringify(playerSwaps));
      if (swapForward) {
        console.log("trying to swap forward with currentswapIndex: " + currentSwapIndex);
        const currentSwap = playerSwaps[currentSwapIndex];
        console.log("currentswap: " + JSON.stringify(currentSwap));
        const newSequence = applyScoredSwap(playerSequence.slice(), currentSwap);
        setPlayerSequence(newSequence);
        setPlayerScore(playerScore - currentSwap.score);
      } else {
        console.log("trying to swap backwards with currentswapIndex: " + currentSwapIndex);
        const nextSwap = playerSwaps[currentSwapIndex + 1];
        console.log("nextSwap: " + JSON.stringify(nextSwap));
        const newSequence = applyScoredSwap(playerSequence.slice(), nextSwap);
        setPlayerSequence(newSequence);
        setPlayerScore(playerScore + nextSwap.score);
      }
      console.log("completed sequenceChange sucessfully");
    }
  }, [currentSwapIndex]);

  //playerScore taking damage animation
  useEffect(() => {
    let animationTimer: NodeJS.Timeout;
    if (props.isOpen) {
      setChangingSequence(true);
      animationTimer = setTimeout(() => {
        setChangingSequence(false);
      }, 30);
    }
    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(animationTimer);
    };
  }, [playerScore]);

  useEffect(() => {
    console.log("sequence changed " + playerSequence);
  }, [playerSequence]);

  const handleGotoAllResults = () => {
    setCurrentView("A");
    setSwapForward(true);
    setCurrentSwapIndex(0);
    setPlayerSequence(getPlayerSequence(props.results, props.playerId));
    setReadyForAnimation(false);
    setPlayerScore(2000);
    console.log("reset everything");
  };

  return (
    <div>
      <Backdrop open={props.isOpen}>
        <ClickAwayListener onClickAway={() => console.log("yeet")}>
          <Stack justifyContent="center" alignItems="center">
            <Paper
              sx={{
                width: { md: "60vw" },
                height: { md: "80vh" },
                padding: "1em",
              }}
            >
              {currentView == "M" ? (
                //MYRESULTS
                <Stack
                  justifyContent={"space-between"}
                  alignItems="center"
                  sx={{ width: "100%", height: "100%" }}
                >
                  {props.isTsar ? (
                    //TSARVIEW
                    tsarAnswered == true ? (
                      <Stack
                        alignItems={"center"}
                        justifyContent="space-between"
                        sx={{ paddingTop: "1em", width: "100%", minHeight: "60%" }}
                      >
                        <Typography variant="h3">{t("your_answer")}</Typography>
                        <ResultsSequence
                          playerName={tsarName}
                          inputSequence={tsarSequence}
                          cues={props.cues}
                        />
                        <Typography variant="h4">{t("explain_decision")}</Typography>
                      </Stack>
                    ) : (
                      <Stack
                        alignItems={"center"}
                        sx={{ paddingTop: "1em", width: "100%", minHeight: "60%" }}
                      >
                        <Typography variant="h3">{t("did_not_answer")}</Typography>
                      </Stack>
                    )
                  ) : playerAnswered == true ? (
                    //PLAYERVIEW
                    <Stack alignItems={"center"} sx={{ width: "100%", maxHeight: "80%" }}>
                      <ResultsSequence
                        playerName={tsarName}
                        inputSequence={tsarSequence}
                        cues={props.cues}
                      />

                      <Typography
                        textAlign={"center"}
                        sx={{ typography: { md: "h5", lg: "h6", xl: "h4" } }}
                      >
                        <Trans
                          components={{
                            h: (
                              <span
                                className={
                                  "score " +
                                  (changingSequence
                                    ? swapForward
                                      ? "losingPoints"
                                      : "gainingPoints"
                                    : {})
                                }
                              />
                            ),
                          }}
                          values={{ score: playerScore }}
                        >
                          {"you_scored"}
                        </Trans>
                      </Typography>
                      <ResultsSequence
                        playerName={playerName}
                        inputSequence={playerSequence}
                        cues={props.cues}
                      />
                      <Stack
                        direction={"row"}
                        alignItems="flex-start"
                        justifyContent="center"
                        sx={{ width: "100%" }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            width: "7em",
                            height: { md: "3em" },
                            margin: "1vh",
                          }}
                          onClick={() => {
                            if (currentSwapIndex > 0) {
                              setReadyForAnimation(false);
                              setSwapForward(false);
                              setCurrentSwapIndex(currentSwapIndex - 1);
                              console.log("changed swapindex to " + (currentSwapIndex - 1));
                            }
                          }}
                        >
                          <BiArrowToLeft fontSize={"22"} />
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            width: "7em",
                            height: { md: "3em" },
                            margin: "1vh",
                          }}
                          onClick={() => {
                            if (currentSwapIndex < playerSwaps.length - 1) {
                              setReadyForAnimation(false);
                              setSwapForward(true);
                              setCurrentSwapIndex(currentSwapIndex + 1);
                              console.log("changed swapindex to " + (currentSwapIndex + 1));
                            }
                          }}
                        >
                          <BiArrowToRight fontSize={"22"} />
                        </Button>
                      </Stack>
                    </Stack>
                  ) : (
                    <Typography sx={{ fontSize: 40, marginTop: ".5em" }} variant="h1">
                      {t("you_did_not_answer")}
                    </Typography>
                  )}

                  <Button
                    variant="contained"
                    sx={{
                      height: { md: "3em", xl: "3em" },
                      alignSelf: "flex-end",
                    }}
                    onClick={handleGotoAllResults}
                  >
                    {t("see_all")}
                  </Button>
                </Stack>
              ) : (
                //ALL RESULTS

                <>
                  <Stack alignItems={"center"} sx={{ height: "100%", width: "100%" }}>
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      sx={{ width: "100%", height: "100%", overflow: "auto" }}
                    >
                      <Stack sx={{ paddingBlock: "1em", width: "35%", overflow: "auto" }}>
                        <Paper
                          sx={{
                            padding: "1em",
                            width: "calc(100% -2em)",
                            height: "100%",
                            overflow: "auto",
                          }}
                        >
                          <Stack spacing={2} sx={{ height: "100%" }}>
                            <Typography fontSize={26} variant="h1">
                              {t("player_submissons")}
                            </Typography>
                            <Stack
                              justifyContent={"flex-start"}
                              spacing={2}
                              sx={{
                                overflowY: "scroll",
                                height: "calc(100% -1em)",
                                paddingBottom: "1em",
                              }}
                            >
                              {sortedNonTsarResults.map((results) => {
                                return (
                                  <Stack spacing={1}>
                                    <Typography
                                      variant="h1"
                                      sx={{ marginLeft: "1em", fontSize: "1.5em" }}
                                    >
                                      {results.playerName}
                                    </Typography>
                                    <Stack
                                      direction="row"
                                      justifyContent={"space-around"}
                                      alignItems="flex-end"
                                    >
                                      <Paper
                                        sx={{
                                          width: "calc(80% - .4em)",

                                          paddingBlock: { md: ".4em", lg: ".5em" },
                                          paddingInline: ".2em",
                                        }}
                                      >
                                        <Stack
                                          direction={"row"}
                                          alignItems="center"
                                          justifyContent="space-around"
                                          sx={{
                                            width: "calc(100% -1em)",
                                            height: "100%",
                                          }}
                                        >
                                          {results.sequence.map((value) => {
                                            return (
                                              <Paper
                                                sx={{
                                                  width: { md: "16%", lg: "17%" },
                                                  aspectRatio: "11/16",
                                                  backgroundColor: getColorStylebyNumber(value),
                                                }}
                                              ></Paper>
                                            );
                                          })}
                                        </Stack>
                                      </Paper>
                                      <Typography
                                        variant="h1"
                                        sx={{ fontSize: "2em", marginBottom: ".2em" }}
                                      >
                                        {results.score}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                );
                              })}
                            </Stack>
                          </Stack>

                          {/* {sortetNonTsarResults.map((results) => (
                          <ResultsSequence
                            key={results.playerId}
                            inputSequence={results["sequence"]}
                            playerName={results["playerName"]}
                            displayNameInBar={true}
                            score={results["score"]}
                            cues={props.cues}
                          />
                        ))} */}
                        </Paper>
                      </Stack>
                      <Stack
                        justifyContent={"center"}
                        spacing={2}
                        sx={{ paddingTop: "1em", width: "60%" }}
                      >
                        <QuestionHeader
                          resultsDisplay
                          question={props.question}
                          tsarName={tsarName}
                          isTsar={props.isTsar}
                        />

                        <Paper
                          sx={{
                            width: "calc(100%-.2em)",
                            paddingBlock: { md: ".4em", lg: ".5em" },
                            paddingInline: ".2em",
                          }}
                        >
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            justifyContent="space-around"
                            sx={{
                              height: "100%",
                            }}
                          >
                            {tsarSequence.map((value) => {
                              return (
                                <Paper
                                  sx={{
                                    width: { md: "16%", lg: "17%" },
                                    aspectRatio: "11/16",
                                    backgroundColor: getColorStylebyNumber(value),
                                  }}
                                >
                                  {value != 0 ? (
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        margin: ".2em",
                                        fontSize: getFontSizeByAnswer(props.cues[value - 1].content),
                                      }}
                                    >
                                      {props.cues[value - 1].content}
                                    </Typography>
                                  ) : (
                                    <></>
                                  )}
                                </Paper>
                              );
                            })}
                          </Stack>
                        </Paper>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} justifyContent="space-between" sx={{ width: "100%" }}>
                      <Button
                        variant="contained"
                        sx={{
                          height: { md: "3em", xl: "3em" },
                        }}
                        onClick={() => {
                          setCurrentView("M");
                          setReadyForAnimation(true);
                        }}
                      >
                        {t("see_mine")}
                      </Button>
                      {props.isHost ? (
                        <Button
                          variant="contained"
                          sx={{
                            height: { md: "3em", xl: "3em" },
                          }}
                          onClick={() => socket.emit("nextTurn")}
                        >
                          {t("start_next_round")}
                        </Button>
                      ) : (
                        <Typography variant="h6">{t("waiting_next_round")} </Typography>
                      )}
                    </Stack>
                  </Stack>
                </>
              )}
            </Paper>
          </Stack>
        </ClickAwayListener>
      </Backdrop>
    </div>
  );

  // if (interpretedResults != undefined && interpretedResults["iAnswered"]) {
  // }
}

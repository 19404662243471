import _ from "underscore";
import {
  PlayerResultInfo,
  Results,
  ScoredSwap,
} from "../../shared/interfaces/GameLogicTypes";

//Was brauche
export function interpretResultsForPlayer(
  results: Results,
  tsarId: string,
  playerId: string
) {
  if (!("playerTurnResults" in results[tsarId])) {
    return {
      tsarAnswered: false,
      iAnswered: false,
      tsarResults: results[tsarId],
      myResults: results[playerId],
    };
  } else if (!("playerTurnResults" in results[playerId])) {
    return {
      tsarAnswered: true,
      iAnswered: false,
      tsarResults: results[tsarId],
      myResults: results[playerId],
    };
  } else {
    return {
      tsarAnswered: true,
      iAnswered: true,
      tsarResults: results[tsarId],
      myResults: results[playerId],
    };
  }
}
export function interpretResultsForTsar(results: Results, tsarId: string) {
  if (!("playerTurnResults" in results[tsarId])) {
    //sorry
    return { tsarAnswered: false, tsarResults: null };
  } else {
    return { tsarAnswered: true, tsarResults: results[tsarId] };
  }
  // return {tsaranswered: "", onePlayerAnswer:"", multiPlayerAnswer:"", playerResult:"", tsarsequence: "", bestPlayerSequence:"" };
}

export function hasPlayerAnswered(results: Results, playerId: string) {
  if (
    results[playerId] == undefined ||
    JSON.stringify(results[playerId].playerTurnResults.sequence) ==
      JSON.stringify([0, 0, 0, 0, 0])
  ) {
    return false;
  } else {
    return true;
  }
}

export function getPlayerResults(results: Results, playerId: string) {
  // console.log("entered getPlayerResults :)");
  // console.log("trying to getPlayerSequence from results. Calling getPlayersequence using results: " + JSON.stringify(results,null,2));
  if (playerId in results && "playerTurnResults" in results[playerId]) {
  
    const playerResults = results[playerId];
    if (playerResults.playerTurnResults != undefined) {
      return playerResults.playerTurnResults;
    } else {
      console.log("returned undefined playerTurnResults in getPResults with results " + JSON.stringify(results,null,2) + "\n pId" + playerId);
      return undefined;
    }
  }
}
export function getPlayerScore(results: Results, playerId: string) {
  const playerResults = getPlayerResults(results, playerId);
  if (playerResults != undefined) {
    return playerResults["points"];
  } else {
    return 0;
  }
}

export function getPlayerName(results: Results, playerId: string) {
  if (playerId in results) {
    return results[playerId].playerInfo.name;
  } else {
    return "";
  }
}

export function getPlayerSequence(results: Results, playerId: string) {
  //console.log("trying to getPlayerSequence from results. Calling getPlayerResults using results: " + JSON.stringify(results,null,2));
  const playerResults = getPlayerResults(results, playerId);
  if (playerResults != undefined) {
    return playerResults.sequence;
  } else {
    //console.log("cant get playe sequence because results undefined");
    return [0, 0, 0, 0, 0];
  }
}

export function getPlayerSwaps(results: Results, playerId: string) {
  const playerResults = getPlayerResults(results, playerId);
  if (playerResults != undefined) {
    return playerResults["swaps"];
  } else {
    return [];
  }
}

export function applyScoredSwap(sequence: number[], scoredSwap: ScoredSwap) {
  let swapIndices = scoredSwap.swap;
  let temp = sequence[swapIndices[0]];
  sequence[swapIndices[0]] = sequence[swapIndices[1]];
  sequence[swapIndices[1]] = temp;
  return sequence;
}

export function getPlayerSubmissionResultList(
  results: Results,
  tsarId: string
) {
  //Goal get a list of the Playerresults of the form [name,sequence,score] with [0,0,0,0,0] for players that did not submit
  const playerSubmissionResultList = Object.keys(results).map(
    (playerId: string) => {
      //if (playerId != tsarId) {
      const playerName = getPlayerName(results, playerId);
      if (!hasPlayerAnswered(results, playerId)) {
        console.log("player has not answered " + playerId);
        return {
          playerId: playerId,
          playerName: playerName,
          sequence: [0, 0, 0, 0, 0],
          score: 0,
        };
      } else {
        return {
          playerId: playerId,
          playerName: playerName,
          sequence: getPlayerSequence(results, playerId),
          score: getPlayerScore(results, playerId),
        };
      }
      //}
    }
  );
  const tsarEntry = playerSubmissionResultList.filter(
    (entry) => entry["playerId"] == tsarId
  );
  //there's always a tsar entry
  playerSubmissionResultList.splice(
    _.indexOf(playerSubmissionResultList, tsarEntry[0]),
    1
  );
  playerSubmissionResultList.sort((a, b) => b["score"] - a["score"]);
  console.log("after mapping " + JSON.stringify(playerSubmissionResultList));
  return playerSubmissionResultList;
}

import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import QuestionHeader from "../QuestionHeader/QuestionHeader";
import Switch from "@mui/material/Switch";
import Feedback from "../../shared/api/FeedbackApi";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { FormControl, InputLabel, MenuItem, Tooltip, Typography } from "@mui/material";
import _ from "underscore";

export interface SettingSelectorProps {
  setting: string;
  checked: boolean;
  check: (event: React.ChangeEvent<HTMLInputElement>)=>void;
  // defaultBool: boolean;
  toolTip:string;
  readonly: boolean;
}

export default function SettingSelector(props: SettingSelectorProps) {
  

  return (
    
    <Stack 
      direction="row"
      sx={{ width: "100%", height: "3em", margin: ".2em" }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography sx={{ width: "50%", marginBottom: 0 }}>{props.setting}</Typography>
      <Tooltip placement="top" title={props.toolTip} enterTouchDelay={0}>
        <Stack alignItems="center" sx={{ width: "50%" }}>
          <Switch disabled={props.readonly} readOnly={props.readonly} onChange={props.check} checked={props.checked}></Switch>
        </Stack>
        </Tooltip>
    </Stack>
   
  );
}

import { ReactNode, useEffect, useRef, useState } from "react";

import CSS from "csstype";
import { disconnect, setMaxListeners } from "process";
import Dialog from "../Dialog/Dialog";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import PlayerConfigPanel from "../PlayerConfig/PlayerConfigPanel";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { CgArrowLongRight } from "react-icons/cg";
import "./../RoundStartDialog/RoundStartDialog.css";
import {
  Backdrop,
  ClickAwayListener,
  Paper,
  Button,
  Stack,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
export interface MobileRoundStartDialogProps {
  isOpen: boolean;
  isTsar: boolean;
  tsarName: string;
  setClose: () => void;
}

export default function MobileRoundStartDialog(props: MobileRoundStartDialogProps) {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    if (dontShowAgain) {
      setHidden(true);
    }
  }, [props.isOpen]);
  //
  return (
    <Stack justifyContent="center" alignItems="center">
      <Paper sx={{ width: { md: "50rem", xs: "90vw" }, height: { md: "27rem", xs: "70vh" } }}>
        <Stack
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "calc(100% - 4vh)", paddingBlock: "2vh" }}
        >
          <Stack spacing={4} alignItems="center">
            <Typography textAlign="center" variant="h1" sx={{ fontSize: 24 }}>
              <Trans
                components={{
                  h: (
                    <Typography
                      variant="h1"
                      component="span"
                      color="primary"
                      sx={{ fontSize: 24, marginBottom: "0" }}
                    />
                  ),
                }}
                values={{ tsar: props.tsarName }}
              >
                {props.isTsar ? t("intro_for_tsar") : t("intro_about_tsar")}
              </Trans>
            </Typography>
            <Stack alignItems={"center"} sx={{width:"90%"}}>
              <Typography textAlign="center" variant="h4" sx={{ fontSize: 24, marginBottom: "0" }}>
                <Trans
                  components={{
                    h: (
                      <Typography
                        sx={{ fontSize: 24, marginBottom: "0" }}
                        component="span"
                        variant="h4"
                        color="primary"
                      />
                    ),
                  }}
                  values={{ tsar: props.tsarName }}
                >
                  {props.isTsar ? t("how_to_drag_for_tsar") : t("how_to_drag_about_tsar")}
                </Trans>
              </Typography>
              <div className="row ratherScale">
                <FiThumbsDown style={{ transform: "scale(2)", color: "var(--highlighted)" }} />
                <CgArrowLongRight style={{ transform: "scale(3)", color: "var(--highlighted)" }} />
                <FiThumbsUp style={{ transform: "scale(2)", color: "var(--highlighted)" }} />
              </div>
              
            </Stack>
          </Stack>
          <Stack alignItems={"center"} sx={{width:"60vw"}} spacing={2}>
            <Button variant="contained" onClick={() => props.setClose()} sx={{height:"20vw", width:"100%"}}>
              Start
            </Button>
            <FormControlLabel
              label="Don't show again"
              control={
                <Checkbox
                  checked={dontShowAgain}
                  onChange={() => setDontShowAgain(!dontShowAgain)}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                />
              }
            />
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
}

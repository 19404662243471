import React, { useContext, useEffect, useRef, useState } from "react";

import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import QuestionHeader from "../AlternateQuestionHeader/AlternateQuestionHeader";
import Switch from "@mui/material/Switch";
import Feedback from "../../shared/api/FeedbackApi";

import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  InputLabel,
  FormControl,
  MenuItem,
  Autocomplete,
  AutocompleteChangeReason,
  Backdrop,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Slider,
  Tab,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "underscore";
import CardDeck from "../CardDeck/CardDeck";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import PreviewCard from "../PreviewCard/PreviewCard";
import CardWarning from "../CardWarning/CardWarning";

import RefreshIcon from "@mui/icons-material/Refresh";
import {
  QuestionCardInfo,
  AnswerCardInfo,
  CardInfo,
  CardType,
  Language,
  CardDeckJSON,
  Question,
  Player,
} from "../../shared/interfaces/GameLogicTypes";
import {
  convertDimensionsToPixels,
  getElementHeight,
  getRandomTsarName,
  getLanguageByLanguageId,
} from "../../shared/helper/functions";
import CardCreationWidget from "../CardCreationWidget/CardCreationWidget";
import { Languages } from "../../shared/language/languages";
import { GameContext } from "../../shared/GameContext";
import { useAuth0 } from "@auth0/auth0-react";
import Deck from "../../shared/api/DeckApi";

import PlayerList from "../../components/PlayerList/PlayerList";
import InvitationLinkField from "../../components/InvitationLinkField/InvitationLinkField";

import { CSSProperties, FC } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ChoiceWidget from "../../components/ChoiceWidget/ChoiceWidget";
import SequenceBar from "../../components/SequenceBar/SequenceBar";
import ResultsSequence from "../../components/ResultsSequence/ResultsSequence";
import ResultsDialog from "../../components/ResultsDialog/ResultsDialog";

import {
  TurnInfo,
  Submissions,
  Results,
  PlayerEndResults,
  GameConfig,
  Cue,
  CueTypes,
} from "../../shared/interfaces/GameLogicTypes";
import "./Game.css";

import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { Colors } from "../../routes/OldLobby/Colors";
import useGameContext from "../../shared/useGameContext";
import useSocketContext from "../../shared/useSocketContext";
import Background from "../../shared/img/abstractbackground.webp";
import AlternateChoiceWidget from "../AlternateChoiceWidget/AlternateChoiceWidget";
import PlayerCard from "../PlayerCard/PlayerCard";
import { Theme as MuiTheme } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import MobileResults from "../MobileResults/MobileResults";
import RoundStartDialog from "../RoundStartDialog/RoundStartDialog";
import MobileRoundStartDialog from "../MobileRoundStartDialog/MobileRoundStartDialog";
declare module "@emotion/react" {
  export interface Theme extends MuiTheme {}
}

export interface GameProps {
  baseUrl: string;
  players: { [playerId: string]: Player };
  playerId: string;
  isHost: boolean;
  hostId: string;
  playerName: string;
}

const reorder = (sequence: Array<number>, startIndex: number, endIndex: number) => {
  const result = Array.from(sequence);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function Game(props: GameProps) {
  //into Game Component
  const [turnPhase, setTurnPhase] = useState("S");
  const [tsarId, setTsarId] = useState("");
  const [isTsar, setIsTsar] = useState(false);
  const [tsarName, setTsarName] = useState("");
  const [question, setQuestion] = useState<Question>({
    forTsar: "Von welcher dAthle lcher dieser Sportartet  asdasdasd wird?",
    aboutTsar:
      "Von welcher dieser Sportarten fändest <h>du</h> es am besten wenn dein Kind professioneller Athlet wird?",
  });
  const [results, setResults] = useState<Results>({});
  const testWord =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus que.";

  const [cue1, setCue1] = useState<Cue>({ type: CueTypes.text, content: testWord.substring(0, 9) });
  const [cue2, setCue2] = useState<Cue>({
    type: CueTypes.text,
    content: testWord.substring(0, 29),
  });
  const [cue3, setCue3] = useState<Cue>({
    type: CueTypes.text,
    content: testWord.substring(0, 44),
  });
  const [cue4, setCue4] = useState<Cue>({
    type: CueTypes.text,
    content: testWord.substring(0, 79),
  });
  const [cue5, setCue5] = useState<Cue>({
    type: CueTypes.text,
    content: testWord.substring(0, 140),
  });
  // const [cue1, setCue1] = useState<Cue>({ type: CueTypes.text, content: "..." });
  // const [cue2, setCue2] = useState<Cue>({ type: CueTypes.text, content: "..." });
  // const [cue3, setCue3] = useState<Cue>({ type: CueTypes.text, content: "..." });
  // const [cue4, setCue4] = useState<Cue>({ type: CueTypes.text, content: "..." });
  // const [cue5, setCue5] = useState<Cue>({ type: CueTypes.text, content: "..." });

  const [showLatestResults, setShowLatestResults] = useState(false);
  const [timeLeft, setTimeLeft] = useState(-1);
  const [timeLimit, setTimeLimit] = useState(0);
  const [timerIsActive, setTimerIsActive] = useState(false);
  const [roundStart, setRoundStart] = useState(Date.now());

  const [roundLimit, setRoundLimit] = useState(3);

  const [currentRound, setCurrentRound] = useState(1);
  const [showIntro, setShowIntro] = useState(false);
  const [yellowNest, setYellowNest] = useState(true);
  const [blueNest, setBlueNest] = useState(true);
  const [redNest, setRedNest] = useState(true);
  const [greenNest, setGreenNest] = useState(true);
  const [orangeNest, setOrangeNest] = useState(true);
  const [sequence, setSequence] = useState<Array<number>>([]);
  const [remainingSkips, setRemainingSkips] = useState(0);
  const [canSkip, setCanSkip] = useState(true);
  const Latest_Possible_Skip = 30;

  let theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const socket = useSocketContext().socket;

  useEffect(() => {
    console.log(isMobile);
  }, [isMobile]);

  //adding socket listeners
  useEffect(() => {
    socket.on("skipped", () => {
      reset();
    });

    socket.on("turnPhase", (turnPhase: string) => {
      console.log("setting turnphase by backend request: " + turnPhase);
      setTurnPhase(turnPhase);
    });

    socket.on("canSkip", (canSkip: boolean) => {
      setCanSkip(canSkip);
    });

    //socket.on("turnPhase", (turnPhase) => setTurnPhase(turnPhase));
    socket.on("results", (results) => {
      console.log("received results object");
      console.log(JSON.stringify(results));
      handleNewResults(results);
    });
    socket.on("turnInfo", (turnInfo: TurnInfo) => {
      console.log("received new turnInfo: " + JSON.stringify(turnInfo));
      handleNewTurnInfo(turnInfo);
    });
    socket.on("currentRound", (currentRound: number) => {
      
      setCurrentRound(currentRound);
    });
    socket.emit("refresh");

    return () => {
      socket.off("skipped");
      socket.off("turnPhase");
      socket.off("canSkip");
      socket.off("results");
      socket.off("turnInfo");
      socket.off("currentRound");
    };
  }, []);

  const handleNewTurnInfo = (turnInfo: TurnInfo) => {
    console.log("setting up turnInfo");
    setRoundStart(turnInfo["startTime"]);
    setTimeLimit(turnInfo["timer"]);
    setTimeLeft(turnInfo.timer - Math.round((Date.now() - turnInfo.startTime) / 1000));

    setRoundLimit(turnInfo["roundLimit"]);
    setTsarId(turnInfo["tsarId"]);
    setQuestion(turnInfo["question"]);

    turnInfo["cues"].forEach((cue) => {
      if (cue.type === CueTypes.image) {
        cue.content = props.baseUrl + cue.content;
      }
    });

    setCue1(turnInfo["cues"][0]);
    setCue2(turnInfo["cues"][1]);
    setCue3(turnInfo["cues"][2]);
    setCue4(turnInfo["cues"][3]);
    setCue5(turnInfo["cues"][4]);
  };

  useEffect(() => {
    if (props.playerId != "") {
      setRemainingSkips(props.players[props.playerId]?.remainingSkips);
    }
  }, [props.players]);

  const handleNewResults = (results: Results) => {
    console.log("called handleNewResults");
    setResults(results);
    setTurnPhase("R");
  };

  const handleNewPlayers = () => {};

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || result.destination?.droppableId !== "sequenceBar") {
      return;
    } else {
      if (result.source.droppableId === "sequenceBar") {
        setSequence(reorder(sequence, result.source.index, result.destination.index));
      } else {
        let numberToAdd = 0;
        switch (result.source.droppableId) {
          case "yellow": {
            numberToAdd = 1;
            setYellowNest(false);
            break;
          }
          case "blue": {
            numberToAdd = 2;
            console.log("set blue nest");
            setBlueNest(false);
            break;
          }
          case "red": {
            numberToAdd = 3;
            setRedNest(false);
            break;
          }
          case "green": {
            numberToAdd = 4;
            setGreenNest(false);
            break;
          }
          case "orange": {
            numberToAdd = 5;
            setOrangeNest(false);
            break;
          }
        }

        console.log(
          "trying to add " +
            numberToAdd +
            " to sequence: " +
            sequence +
            " at index " +
            result.destination.index
        );
        let newSequence = sequence.slice();
        //dritter parameter muss data sein
        newSequence.splice(result.destination.index, 0, numberToAdd);
        setSequence(newSequence);
      }
    }
  };

  //Opens and closes Resultsdialog
  useEffect(() => {
    if (turnPhase == "S") {
      console.log("enabling timer");
      console.log("limit: " + timeLimit + " and timeLeft:" + timeLeft);
      setShowLatestResults(false);
      setTimerIsActive(true);
      console.log("SHOW latest results no: " + showLatestResults);
    } else if (turnPhase == "R") {
      console.log("rendering results with: " + JSON.stringify(results, null, 4));
      setShowLatestResults(true);
      console.log("SHOW latest results: yes" + showLatestResults);
      setTimerIsActive(false);
    }
  }, [turnPhase]);

  //timer controller
  useEffect(() => {
    var intervalId: NodeJS.Timeout;
    //console.log("enter timer effect");
    if (timerIsActive) {
      // exit early when we reach 0
      if (!timeLeft) return;
      // save intervalId to clear the interval when the
      // component re-renders
      //console.log("1 sec until with limit " + timeLimit + " and timeleft: " + timeLeft);
      intervalId = setInterval(() => {
        // console.log("now setting with limit " + timeLimit + " and timeleft: " + timeLeft);
        // console.log("value: " + (timeLimit - Math.floor((Date.now() - roundStart) / 1000)));
        setTimeLeft(timeLimit - Math.floor((Date.now() - roundStart) / 1000));
      }, 1000);
    }
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, timerIsActive]);

  useEffect(() => {
    setShowIntro(true);
    let intervalId = setInterval(() => {
      setShowIntro(false);
    }, 20000);
    return () => clearInterval(intervalId);
  }, [tsarName]);

  //new Tsar means new round
  useEffect(() => {
    console.log("setting turnPhase by tsarId");
    setTurnPhase("S");
    setCanSkip(true);
  }, [tsarId]);

  useEffect(() => {
    let tsarInfo = props.players[tsarId];
    if (tsarId == props.playerId) {
      setIsTsar(true);
    } else {
      setIsTsar(false);
    }
    if (tsarInfo != undefined) {
      setTsarName(tsarInfo["name"]);
    }
  }, [tsarId]);

  const reset = () => {
    setSequence([]);
    setYellowNest(true);
    setBlueNest(true);
    setRedNest(true);
    setGreenNest(true);
    setOrangeNest(true);

    //setSequence([Colors.YELLOW,Colors.BLUE,Colors.RED,Colors.GREEN,Colors.ORANGE]);
  };

  function handleDecreaseRemainingSkips() {
    let newRemainingSkips = remainingSkips;
    newRemainingSkips--;
    setRemainingSkips(newRemainingSkips);
  }

  useEffect(() => {
    reset();
  }, [tsarId]);
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {isMobile ? (
          <>
            <Box
              id="box1"
              sx={{
                position: { xs: "relative", md: "auto" },

                overflow: { md: "auto", xs: "auto" },
                backgroundImage: "url(" + Background + ")",
                backgroundSize: "cover",
                paddingBottom: 0,
              }}
              className="mobileGameView"
            >
              <Stack
                alignItems={"center"}
                justifyContent="space-around"
                sx={{ height: "100%", overflow: "auto" }}
                spacing={2}
              >
                <Stack alignItems={"center"}>
                  <Typography>
                    {t("round") + " "} {currentRound} {" " + t("out_of") + " "} {roundLimit}
                  </Typography>
                  <Stack alignItems={"center"} sx={{ width: "90%" }}>
                    <QuestionHeader
                      isMobile={true}
                      question={question}
                      tsarName={tsarName}
                      isTsar={isTsar}
                    />
                  </Stack>
                </Stack>

                <Stack
                  justifyContent="flex-end"
                  sx={{ width: "100%", height: "100%" }}
                  alignItems="center"
                  spacing={2}
                >
                  {/* !showLatestResults */}
                  {!showLatestResults ? (
                    // showIntro
                    showIntro ? (
                      <>
                        <MobileRoundStartDialog
                          isOpen={showIntro}
                          isTsar={false}
                          tsarName={tsarName}
                          setClose={() => setShowIntro(false)}
                        />
                      </>
                    ) : (
                      <>
                        <Stack alignItems={"center"} justifyContent="center">
                          <Typography variant="h1" sx={{ fontSize: 30 }}>
                            {timeLeft}
                          </Typography>
                        </Stack>
                        <Paper
                          sx={{
                            height: "calc(100vw - 2vh)",
                            padding: "1vh",
                            width: "calc(99vw - 2vh)",
                          }}
                        >
                          <Stack justifyContent="space-around" sx={{ height: "100%" }}>
                            <Stack
                              direction="row"
                              sx={{ height: { md: "20vh", xs: "47vw" } }}
                              justifyContent="space-around"
                            >
                              <AlternateChoiceWidget
                                cue={cue1}
                                color={Colors.YELLOW}
                                hasCard={yellowNest}
                                isImage={true}
                                isMobile
                              />

                              <AlternateChoiceWidget
                                cue={cue2}
                                color={Colors.BLUE}
                                hasCard={blueNest}
                                isImage={true}
                                isMobile
                              />
                              <AlternateChoiceWidget
                                cue={cue3}
                                color={Colors.RED}
                                hasCard={redNest}
                                isImage={true}
                                isMobile
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ height: { md: "20vh", xs: "47vw" } }}
                              justifyContent={{ md: "space-between", xs: "center" }}
                              spacing={{ xs: 1 }}
                            >
                              <AlternateChoiceWidget
                                cue={cue4}
                                color={Colors.GREEN}
                                hasCard={greenNest}
                                isImage={false}
                                isMobile
                              />
                              <AlternateChoiceWidget
                                cue={cue5}
                                color={Colors.ORANGE}
                                hasCard={orangeNest}
                                isImage={true}
                                isMobile
                              />
                            </Stack>
                          </Stack>
                        </Paper>
                        <SequenceBar
                          sequence={sequence}
                          remainingSkips={remainingSkips}
                          reset={() => reset()}
                          canSkip={isTsar}
                          decreaseRemainingSkips={handleDecreaseRemainingSkips}
                          cue1={cue1}
                          cue2={cue2}
                          cue3={cue3}
                          cue4={cue4}
                          cue5={cue5}
                          isMobile={true}
                        />
                      </>
                    )
                  ) : (
                    <>
                      {/* <MobileResults
                        cues={[cue1, cue2, cue3, cue4, cue5]}
                        isHost={props.isHost}
                        isTsar={false}
                        tsarId={"YeQC_tdXQb-nhe4SAAAj"}
                        playerId={"Xmt1CzR9VcYmGNZUAAAh"}
                        results={{
                          Xmt1CzR9VcYmGNZUAAAh: {
                            playerInfo: {
                              name: "awawwa",
                              score: 0,
                              joinTime: 1669245860015,
                              config: {},
                              remainingSkips: 3,
                            },
                            playerTurnResults: {
                              sequence: [1, 2, 4, 3, 5],
                              swaps: [
                                { score: 100, swap: [1, 0] },
                                { score: 100, swap: [2, 1] },
                                { score: 400, swap: [2, 4] },
                              ],
                              points: 1400,
                            },
                          },
                          "YeQC_tdXQb-nhe4SAAAj": {
                            playerInfo: {
                              name: "sss",
                              score: 0,
                              joinTime: 1669245865485,
                              config: {},
                              remainingSkips: 4,
                            },
                            playerTurnResults: { sequence: [2, 4, 5, 3, 1], swaps: [], points: 0 },
                          },
                        }}
                        question={question}
                      /> */}
                       <MobileResults
                        cues={[cue1, cue2, cue3, cue4, cue5]}
                        isHost={props.isHost}
                        isTsar={isTsar}
                        tsarId={tsarId}
                        playerId={props.playerId}
                        results={results}
                        question={question}
                      /> 
                    </>
                  )}

                  {/* </Box> */}
                </Stack>
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Box
              id="box1"
              sx={{
                position: { xs: "relative", md: "auto" },
                height: { md: "100vh" },
                minHeight: { md: "", xs: "100vh" },
                overflow: { md: "auto", xs: "scroll" },
                backgroundImage: "url(" + Background + ")",
                backgroundSize: "cover",
                paddingBottom: { md: 0, xs: "2em" },
              }}
            >
              <Stack alignItems={"center"} justifyContent="space-around" sx={{ height: "100vh" }}>
                <QuestionHeader question={question} tsarName={tsarName} isTsar={isTsar} />
                <Stack alignItems={"flex-start"} direction="row" spacing={2}>
                  <Paper sx={{ width: "15vw", height: "70vh" }}>
                    <Stack sx={{ padding: ".5em", overflow: "scroll" }}>
                      {Object.keys(props.players).map((playerId) => {
                        return (
                          <PlayerCard
                            inGame
                            name={props.players[playerId].name}
                            score={props.players[playerId].score}
                            isHost={props.hostId === playerId}
                            isTsar={tsarId === playerId}
                          />
                        );
                      })}
                    </Stack>
                  </Paper>
                  <Stack justifyContent={"space-between"} sx={{ width: "60vw", height: "80vh" }}>
                    <Paper sx={{ height: "40vh", paddingBlock: "2vh" }}>
                      <Stack direction="row" sx={{ height: "40vh" }} justifyContent="space-around">
                        <AlternateChoiceWidget
                          cue={cue1}
                          color={Colors.YELLOW}
                          hasCard={yellowNest}
                          isImage={true}
                        />
                        <Box sx={{ alignSelf: "flex-end" }}>
                          <AlternateChoiceWidget
                            cue={cue4}
                            color={Colors.GREEN}
                            hasCard={greenNest}
                            isImage={false}
                          />
                        </Box>
                        <AlternateChoiceWidget
                          cue={cue2}
                          color={Colors.BLUE}
                          hasCard={blueNest}
                          isImage={true}
                        />
                        <Box sx={{ alignSelf: "flex-end" }}>
                          <AlternateChoiceWidget
                            cue={cue5}
                            color={Colors.ORANGE}
                            hasCard={orangeNest}
                            isImage={true}
                          />
                        </Box>

                        <AlternateChoiceWidget
                          cue={cue3}
                          color={Colors.RED}
                          hasCard={redNest}
                          isImage={true}
                        />
                      </Stack>
                    </Paper>
                    <SequenceBar
                      sequence={sequence}
                      remainingSkips={remainingSkips}
                      reset={() => reset()}
                      canSkip={isTsar}
                      decreaseRemainingSkips={handleDecreaseRemainingSkips}
                      cue1={cue1}
                      cue2={cue2}
                      cue3={cue3}
                      cue4={cue4}
                      cue5={cue5}
                    />
                  </Stack>
                  <Paper sx={{ width: "15vw", height: "70vh" }}>
                    <Stack alignItems={"center"}>
                      <Typography>
                        {t("round") + " "} {currentRound} {" " + t("out_of") + " "} {roundLimit}
                      </Typography>
                      <Typography variant="h3" color={"primary.main"}>
                        {timeLeft}
                      </Typography>
                    </Stack>
                  </Paper>
                </Stack>
              </Stack>
            </Box>
            <ResultsDialog
              cues={[cue1, cue2, cue3, cue4, cue5]}
              isHost={props.isHost}
              isTsar={isTsar}
              tsarId={tsarId}
              playerId={props.playerId}
              results={results}
              isOpen={showLatestResults}
              question={question}
            />
            <RoundStartDialog
              isOpen={showIntro}
              isTsar={isTsar}
              tsarName={tsarName}
              setClose={() => setShowIntro(false)}
            />
          </>
        )}
      </DragDropContext>
    </>
  );
}

import { CSSProperties, useEffect, useState } from "react";

import { Colors } from "../../routes/OldLobby/Colors";
import Button from "../Button/Button";
import { Flipper, Flipped, spring } from "react-flip-toolkit";
import { Cue } from "../../shared/interfaces/GameLogicTypes";
import { Stack, Box, Typography, Paper, ClickAwayListener } from "@mui/material";
import { getColorStylebyNumber, getFontSizeByAnswer } from "../../shared/helper/functions";
import zIndex from "@mui/material/styles/zIndex";

export const Positions = {};

export interface MobileResultsSequenceProps {
  inputSequence: number[];
  playerName?: String;
  score?: number;
  style?: CSSProperties;
  ballStyle?: CSSProperties;
  tsarDisplay?: boolean;
  cues: Cue[];

  //swaps: Array<number|number[]>;
}

export default function MobileResultsSequence(props: MobileResultsSequenceProps) {
  //full on gray
  const [sequence, setSequence] = useState<number[]>([]);
  const [focusedCueNumber, setFocusedCueNumber] = useState(NaN);

  useEffect(() => {
    if (props.inputSequence.length == 5) {
      setSequence(props.inputSequence);
    }
  }, [props.inputSequence]);

  return (
    <Stack
      id="ResultsSequence"
      justifyContent="space-between"
      alignItems="center"
      direction="column"
      sx={{ width: "100%" }}
    >
      {props.playerName ? (
        <>
          {props.score ? (
            <Stack direction="row" justifyContent={"space-between"} sx={{ width: "100%" }}>
              <Typography sx={{userSelect:"none"}} textAlign={"left"} variant="h4">
                {props.playerName}
              </Typography>
              <Typography  sx={{userSelect:"none"}} textAlign={"right"} variant="h4">
                {props.score}
              </Typography>
            </Stack>
          ) : (
            <Typography  textAlign={"left"} variant="h4" sx={{ width: "100%", userSelect:"none" }}>
              {props.playerName}
            </Typography>
          )}
        </>
      ) : (
        <></>
      )}

      <Flipper spring={{ stiffness: 280, damping: 22 }} flipKey={sequence.join("")}>
        <Stack
          direction={"row"}
          justifyContent="space-evenly"
          alignItems={"center"}
          sx={{
            width: "calc(100% -.4em)",
            height: "100%",
          }}
        >
          {sequence.map((d: number) => (
            <Flipped key={d} flipId={d} translate={true}>
              {/* <ClickAwayListener onClickAway={()=>setFocusedCueNumber(NaN)}> */}
              <Paper
                onTouchStart={() => {
                  console.log("touched cue: " + d);
                  setFocusedCueNumber(d);
                }}
                onTouchEnd={() => {
                  setFocusedCueNumber(NaN);
                }}
                sx={{
                  width: props.score ? "29vw" : "30vw",
                  aspectRatio: "11/16",
                  backgroundColor: getColorStylebyNumber(d),
                  marginInline: "-7.5vw",
                  transition: "transform .25s ease-out",
                 
                  ...(focusedCueNumber === d && { zIndex: "30",  transform: "translate(0, -1em) scale(1.2)", }),
                }}
              >
                {d != 0 ? (
                  <Stack sx={{ padding: ".2em" }}>
                    <Typography
                      
                      variant="body1"
                      sx={{
                        userSelect:"none",
                        margin: ".2em",
                        fontSize: getFontSizeByAnswer(props.cues[d - 1].content, true),
                      }}
                    >
                      {props.cues[d - 1].content}
                    </Typography>
                  </Stack>
                ) : (
                  <></>
                )}
              </Paper>
              {/* </ClickAwayListener> */}
            </Flipped>
          ))}
        </Stack>
      </Flipper>
    </Stack>
  );
}

// export function getBallGetterSetter(index: number) {
//   switch (index) {
//     case 1: {
//         return (()=>setBall1Pos());
//       }
//     }
//   }
// }

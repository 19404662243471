import Urls from "./Urls";

export default class Feedback {
  static async submitFeedback(message:string, author:string) {
      console.log("sending: " + message +  " ; " +  "author");
    const resp = await fetch(Urls.SUBMIT_FEEDBACK, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        
      },
      body: JSON.stringify({
        message,
        author,
      })
    }).catch((e) => ({ error: e }));
  }
}
import { CSSProperties, useEffect, useState } from "react";
import CSS from "csstype";
import "./ResultsSequence.css";
import { Colors } from "../../routes/OldLobby/Colors";
import Button from "../Button/Button";
import { Flipper, Flipped, spring } from "react-flip-toolkit";
import { Cue } from "../../shared/interfaces/GameLogicTypes";
import { Stack, Box, Typography, Paper } from "@mui/material";
import { getColorStylebyNumber, getFontSizeByAnswer } from "../../shared/helper/functions";

export const Positions = {};

export interface ResultsSequenceProps {
  inputSequence: number[];
  playerName: String;

  score?: number;
  style?: CSSProperties;
  ballStyle?: CSSProperties;
  tsarDisplay?: boolean;
  cues: Cue[];
  //swaps: Array<number|number[]>;
}

export default function ResultsSequence(props: ResultsSequenceProps) {
  //full on gray
  const [sequence, setSequence] = useState<number[]>([]);

  useEffect(() => {
    if (props.inputSequence.length == 5) {
      setSequence(props.inputSequence);
    }
  }, [props.inputSequence]);

  return (
    <Stack
      id="ResultsSequence"
      justifyContent="space-between"
      alignItems="center"
      direction="column"
      sx={{ width: {lg:"40vw",md:"50vw"} }}
    >
      <Typography textAlign={"left"} variant="h4" sx={{ width: "90%" }}>
        {props.playerName}
      </Typography>
      <Flipper spring={{ stiffness: 280, damping: 22 }} flipKey={sequence.join("")}>
        <Paper
          sx={{
            width: { lg: "40vw", md:"50vw" },
          }}
        >
          <Stack
            direction={"row"}
            justifyContent="space-evenly"
            alignItems={"center"}
            sx={{
              width: "calc(100% -.4em)",
              height: "100%",
              paddingBlock: { md: ".4em", lg: ".5em" },
              paddingInline: ".2em",
            }}
          >
            {sequence.map((d: number) => (
              <Flipped key={d} flipId={d} translate={true}>
                <Paper
                  sx={{
                    width: { md: "16%", lg: "17%" },
                    aspectRatio: "11/16",
                    backgroundColor: getColorStylebyNumber(d),
                  }}
                >
                  {d != 0 ? (
                    <Typography
                      variant="body1"
                      sx={{ margin: ".2em", fontSize: getFontSizeByAnswer(props.cues[d - 1].content) }}
                    >
                      {props.cues[d - 1].content}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Paper>
              </Flipped>
            ))}
          </Stack>
        </Paper>
      </Flipper>
    </Stack>
  );
}

// export function getBallGetterSetter(index: number) {
//   switch (index) {
//     case 1: {
//         return (()=>setBall1Pos());
//       }
//     }
//   }

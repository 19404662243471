import _ from "underscore";
import { Colors } from "../../routes/OldLobby/Colors";
import { CardDeckPreview } from "../interfaces/GameLogicTypes";
import { Languages } from "../language/languages";

export function getElementHeight(id: string) {
  return document.getElementById(id)?.clientHeight as number;
}

export function getElementWidth(id: string) {
  return document.getElementById(id)?.clientWidth as number;
}

export function convertDimensionsToPixels(height: number) {
  return (height as unknown as string) + "px";
}

export function getLanguageByLanguageId(languageId: string) {
  let languageKey = _.find(Object.keys(Languages), (language) => {
    return (Languages[language]?.id as unknown as string) === languageId;
  });
  if (languageKey !== undefined) {
    return Languages[languageKey];
  }
}

export function getFontSizeByAnswer(answer: string, extraSmall?: boolean) {
  let length = answer.length;

  if (!extraSmall) {
    switch (true) {
      case length < 10:
        return 22;
        break;
      case length < 30:
        return 20;
        break;
      case length < 45:
        return 18;
        break;
      case length < 80:
        return 12;
        break;
      case length < 120:
        return 11;
      default:
        return 9;
        break;
    }
  } else {
    switch (true) {
      case length < 10:
        return 20;
        break;
      case length < 30:
        return 18;
        break;
      case length < 45:
        return 16;
        break;

      case length < 80:
        return 12;
        break;
      case length < 120:
        return 10;
      default:
        return 9;
        break;
    }
  }
}

export function getFontSizeByTitle(title: string, extraSmall?: boolean) {
  let length = title.length;
  if (!extraSmall) {
    switch (true) {
      case length < 10:
        return 22;
        break;
      case length < 30:
        return 17;
        break;
      case length < 45:
        return 15;
        break;
      case length < 60:
        return 12;
        break;
      case length < 120:
        return 7;
      default:
        return 4;
        break;
    }
  } else {
    switch (true) {
      case length < 10:
        return 20;
        break;
      case length < 30:
        return 14;
        break;
      case length < 45:
        return 11;
        break;
      case length < 60:
        return 9;
        break;
      case length < 120:
        return 6;
      default:
        return 3;
        break;
    }
  }
}

export function getFontSizeByQuestion(question: string) {
  let length = question.length;
  switch (true) {
    case length < 40:
      return 28;
      break;
    case length < 100:
      return 22;
      break;
    // case length < 30:
    //   return 20;
    //   break;
    // case length < 45:
    //   return 18;
    //   break;

    //   case length < 80:
    //   return 14;
    //   break;
    default:
      return 17;
      break;
  }
}

export function isDeckSelected(decks: CardDeckPreview[], deck: CardDeckPreview) {
  return _.contains(
    decks.map((deck) => deck.id),
    deck.id
  );
}

export function convertBackendToFrontendDecks(decks: any) {
  let newDecks = JSON.parse(JSON.stringify(decks));

  newDecks.map((deck: any) => {
    let parsedLanguage = getLanguageByLanguageId(deck.language);
    deck.language = parsedLanguage;
    return deck;
  });
  return newDecks;
}

export const getColorStyle = (color: string, secondary?: boolean) => {
  //   backgroundColor: props.color,
  //   opacity: opacity,
  // };
  let colorStyle = "";
  if (!secondary) {
    colorStyle = getColorName(color) + ".main";
  } else {
    colorStyle = getColorName(color) + ".secondary";
  }
  return colorStyle;
};

export const getColorName = (color: string) => {
  //   backgroundColor: props.color,
  //   opacity: opacity,
  // };
  let colorName = "";
  switch (color) {
    case Colors.BLUE: {
      colorName = "blue";
      break;
    }
    case Colors.GREEN: {
      colorName = "green";
      break;
    }
    case Colors.RED: {
      colorName = "red";
      break;
    }
    case Colors.YELLOW: {
      colorName = "yellow";
      break;
    }
    case Colors.ORANGE: {
      colorName = "orange";
      break;
    }
    default: {
      colorName = "grey";
    }
  }
  return colorName;
};

export const getColorStylebyNumber = (listItem: number) => {
  //   backgroundColor: props.color,
  //   opacity: opacity,
  // };
  let colorStyle = "";
  switch (listItem) {
    case 1: {
      colorStyle = "yellow.main";
      break;
    }
    case 2: {
      colorStyle = "blue.main";
      break;
    }
    case 3: {
      colorStyle = "red.main";
      break;
    }
    case 4: {
      colorStyle = "green.main";
      break;
    }
    case 5: {
      colorStyle = "orange.main";
      break;
    }
    case 0: {
      colorStyle = "gray.main";
      break;
    }
  }
  return colorStyle;
};

export function getRandomTsarName() {
  return _.sample([
    "Cory",
    "Erin",
    "Elizabeth",
    "Brandy",
    "Christopher",
    "Amanda",
    "Brady",
    "Shane",
    "Jennifer",
    "Lisa",
    "Sean",
    "Pamela",
    "Vincent",
    "Steven",
    "Melissa",
    "Patty",
    "Debra",
    "Nicholas",
    "Richard",
    "Linda",
    "Stephen",
    "Keith",
    "Ashley",
    "Adam",
    "Marisa",
    "James",
    "Corey",
    "Laurie",
    "Vickie",
    "Jennifer",
    "Mary",
    "Amber",
    "Stephanie",
    "Brad",
    "Cynthia",
    "Elizabeth",
    "John",
    "William",
    "Danielle",
    "Jill",
    "Daniel",
    "Jared",
    "Courtney",
    "Heather",
    "Shannon",
    "Sara",
    "Crystal",
    "Diane",
    "Samantha",
    "Dr Mettwurst",
    "Rachel",
    "Andrea",
    "Jessica",
    "Rebecca",
    "Evan",
    "Abigail",
    "Jennifer",
    "Debbie",
    "Carolyn",
    "Heather",
    "Joseph",
    "James",
    "Lisa",
    "Scott",
    "Paul",
    "Anthony",
    "Jeffrey",
    "Julia",
    "Jose",
    "Megan",
    "Thomas",
    "Crystal",
    "Amanda",
    "Abigail",
    "Nicholas",
    "Richard",
    "Mary",
    "Kimberly",
    "Jacob",
    "Laura",
    "Misty",
    "Jack",
    "Gerald",
    "Tara",
    "Emily",
    "Amber",
    "Susan",
    "Brett",
    "Kathryn",
    "Wayne",
    "Diana",
    "Jessica",
    "Willie",
    "Lisa",
    "Brittany",
    "Christopher",
    "Jessica",
  ]) as string;
}

export function getRandomPlayerName() {
  return _.sample([
    "Snicker",
    "Buffalo",
    "Gross",
    "Bubble",
    "Sheep",
    "Corset",
    "Lizard",
    "Waffle",
    "Burger",
    "Chimp",
    "Liver",
    "Gorilla",
    "Rhino",
    "Emu",
    "Pizza",
    "Toad",
    "Gerbil",
    "Pickle",
    "Tofu",
    "Chicken",
    "Potato",
    "Hamster",
    "Lemur",
    "Vermin",
    "Savage",
    "Napoleon",
    "Uncreative",
  ]) as string;
}

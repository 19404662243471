const answers = [
  {
    id: 1,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du hast eine schöne Nase"',
    en: '"You have a pretty Nose"',
  },
  {
    id: 2,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist so massiv"',
    en: '"You\'re so massive"',
  },
  {
    id: 3,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du hast einen tollen Musikgeschmack"',
    en: '"You have a great taste in music"',
  },
  {
    id: 4,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du hast so attraktive Kurven"',
    en: '"You\'ve got such attractive curves"',
  },
  {
    id: 5,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist so schön normal"',
    en: '"You\'re so delightfully normal"',
  },
  {
    id: 6,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist so intelligent"',
    en: '"You are so intelligent"',
  },
  {
    id: 7,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist so gut organisiert"',
    en: '"You are so well organized"',
  },
  {
    id: 8,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist so ein beeindruckender Schachspieler"',
    en: '"You are such an impressive chess player"',
  },
  {
    id: 9,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist ein guter Autofahrer"',
    en: '"You are a good driver"',
  },
  {
    id: 10,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du hast einen schönen Namen"',
    en: '"You have a beautiful name"',
  },
  {
    id: 11,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Woah, beeindruckender Lebenslauf!"',
    en: '"Woah, impressive curriculum vitae!"',
  },
  {
    id: 12,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist so politisch korrekt"',
    en: '"You are so politically correct"',
  },
  {
    id: 13,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du hast aber abgenommen!"',
    en: '"But you lost weight!"',
  },
  {
    id: 14,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist süß"',
    en: '"You are cute"',
  },
  {
    id: 15,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du kannst echt gut mit Geld umgehen"',
    en: '"You can handle money really well"',
  },
  {
    id: 16,
    category: "compliments",
    config: { familyFriendly: false },
    de: '"Geiler Arsch!"',
    en: '"Nice Ass!"',
  },
  {
    id: 17,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du tippst sehr schnell"',
    en: '"You type very quickly"',
  },
  {
    id: 18,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Ich wünschte ich wäre so selbstbewusst wie du"',
    en: '"I wish I was as confident as you"',
  },
  {
    id: 19,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Deine Dance-Moves sind atmberaubend"',
    en: '"Your dance moves are breathtaking"',
  },
  {
    id: 20,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du hast einen tollen Buchgeschmack"',
    en: '"You have a great taste in books"',
  },
  {
    id: 21,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist wirklich stylish"',
    en: '"You are really stylish"',
  },
  {
    id: 22,
    category: "compliments",
    config: { familyFriendly: false },
    de: '"Deine Füße sind so sexy"',
    en: '"Your feet are so sexy"',
  },
  {
    id: 23,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du hast einen tollen Animegeschmack"',
    en: '"You have a great anime taste"',
  },
  {
    id: 24,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Dein Zimmer ist wirklich schön eingerichtet"',
    en: '"Your room is really nicely decorated"',
  },
  {
    id: 25,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du kannst gut kochen"',
    en: '"You can cook well"',
  },
  {
    id: 26,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist der/die perfekte/r Schwiegersohn/-tochter"',
    en: '"You are the perfect son/daughter-in-law"',
  },
  {
    id: 27,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du verträgst aber viel Alkohol"',
    en: '"You can really handle a lot of booze"',
  },
  {
    id: 28,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du schreibst wirklich guten Code"',
    en: '"You write really good code"',
  },
  {
    id: 29,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist so aufregend"',
    en: '"You are so exciting"',
  },
  {
    id: 30,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist so zurückhaltend und achtsam"',
    en: '"You are so cautious and careful"',
  },
  {
    id: 31,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Wenn ich mit dir unterwegs bin fühle ich mich sicher"',
    en: '"When I\'m with you I feel safe"',
  },
  {
    id: 32,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du hast ein interessantes Gesicht"',
    en: '"You have an interesting face"',
  },
  {
    id: 33,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist wirklich einfühlsam"',
    en: '"You are really compassionate"',
  },
  {
    id: 34,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du hast so schöne Augen"',
    en: '"You have such pretty eyes"',
  },
  {
    id: 35,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du hast so reine Haut"',
    en: '"You have such pure skin"',
  },
  {
    id: 36,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist der beste Rapper der Welt"',
    en: '"You are the best rapper in the world"',
  },
  {
    id: 37,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Ich wünschte ich hätte so viele Freunde wie du"',
    en: '"I wish I had as many friends as you"',
  },
  {
    id: 38,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Dein Instagram-Profil ist richtig fresh"',
    en: '"Your Instagram profile is really fresh"',
  },
  {
    id: 39,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du weisst wirklich wie man sich ausdrückt"',
    en: '"You really know how to express yourself"',
  },
  {
    id: 40,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Du bist schlauer als ich erwartet habe"',
    en: '"You are smarter than I expected"',
  },
  {
    id: 41,
    category: "compliments",
    config: { familyFriendly: false },
    de: '"WOAAAAH WUFF WUFF *sabbert* UIUIUI MHM MHM MHM *starrt*"',
    en: '"WOAAAAH BARK BARK *starts salivating* OI OI OI MHMMMM *stares*"',
  },
  {
    id: 42,
    category: "compliments",
    config: { familyFriendly: true },
    de: '"Wow, du hast so dünne Beine"',
    en: '"Wow, you have such thin thighs"',
  },
  {
    id: 43,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Kein sex vor der Ehe",
    en: "No sex before marriage",
  },
  {
    id: 44,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Hat 6 Kinder von 6 verschiedenen Partnern",
    en: "They have 6 children of 6 different partners",
  },
  {
    id: 45,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Ist 20 Jahre älter als auf dem Profilbild",
    en: "They're 20 years older than in the profile picture",
  },
  {
    id: 46,
    category: "redFlags",
    config: { familyFriendly: false },
    de: "Benutzt viel zu viel Zunge (Ameisenbär)",
    en: "They use far too much tongue (ant eater style)",
  },
  {
    id: 47,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Muss jedes mal etwas klauen wenn er/sie ein Geschäft betritt",
    en: "Have to steal something every time they enter a business",
  },
  {
    id: 48,
    category: "redFlags",
    config: { familyFriendly: true },
    de: 'Sagt vor jedem Satz "Digga" ',
    en: 'They say "Like" before each sentence',
  },
  {
    id: 49,
    category: "redFlags",
    config: { familyFriendly: false },
    de: "Sagt er/sie sei 18, zeigt dir aber nicht den Ausweis",
    en: "They say they're 18, but refuse to show their ID",
  },
  {
    id: 50,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Hat dieselben Gesichtzüge wie du",
    en: "They have the same faces like you",
  },
  {
    id: 51,
    category: "redFlags",
    config: { familyFriendly: false },
    de: "Ist kokssüchtig",
    en: "They're coke -addicted",
  },
  {
    id: 52,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Baut in JEDEM Satz einen Wortwitz ein",
    en: "They put a pun in EVERY sentence they say",
  },
  {
    id: 53,
    category: "redFlags",
    config: { familyFriendly: true },
    de: 'Sagt dir ins Gesicht dass du eine "4/10" bist',
    en: 'They tell you in the face that you are a "4/10"',
  },
  {
    id: 54,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Versucht überall (egal wo) den Preis herunter zu handeln",
    en: "They try to haggle down the price everywhere (no matter where)",
  },
  {
    id: 55,
    category: "redFlags",
    config: { familyFriendly: false },
    de: "Weigert sich jegliches Haar unterhalb des Halses zu rasieren",
    en: "They refuse to shave any hair below the neck",
  },
  {
    id: 56,
    category: "redFlags",
    config: { familyFriendly: false },
    de: "Das einzige Buch was er/sie gelesen hat ist 50 Shades of Grey",
    en: "The only book they read is 50 Shades of Gray",
  },
  {
    id: 57,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Streng katholisch",
    en: "Strictly Catholic",
  },
  {
    id: 58,
    category: "redFlags",
    config: { familyFriendly: false },
    de: "Hat etwas gegen deine Rasse",
    en: "They dislike your race",
  },
  {
    id: 59,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Hat 16 Piercings im Gesicht",
    en: "Has 16 piercings in their face",
  },
  {
    id: 60,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Hat den Namen jemand anderes auftättowiert",
    en: "Has someone elses name tattoed on their body",
  },
  {
    id: 61,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Hat ein großes Gesichts-Tattoo ",
    en: "They have a large facial tattoo",
  },
  {
    id: 62,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Unfähig jegliche Emotionen auszudrücken",
    en: "Unable to express any emotion",
  },
  {
    id: 63,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Spricht nur in Business-Schlagwörtern",
    en: "They Only speak in business buzzwords",
  },
  {
    id: 64,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Redet nur von sich",
    en: "They only talk about themselves",
  },
  {
    id: 65,
    category: "redFlags",
    config: { familyFriendly: false },
    de: "Hat einen starken Fußfetisch",
    en: "They have a strong foot fetish",
  },
  {
    id: 66,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Spricht nur Klingonisch",
    en: "They only speak Klingonian",
  },
  {
    id: 67,
    category: "redFlags",
    config: { familyFriendly: false },
    de: "Hatte schonmal Sex mit einem lebendigen Bären",
    en: "They've had sex with a live bear before",
  },
  {
    id: 68,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Hält sich für sehr viel klüger als du",
    en: "They consider themselves a lot of smarter than you",
  },
  {
    id: 69,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Ist sehr dumm",
    en: "They're very stupid",
  },
  {
    id: 70,
    category: "redFlags",
    config: { familyFriendly: false },
    de: "Tindert während des dates",
    en: "They tinder during the date",
  },
  {
    id: 71,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Wird vom CIA gesucht",
    en: "They're being searched by the CIA",
  },
  {
    id: 72,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Hasst Kinder",
    en: "They hate children",
  },
  {
    id: 73,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "hat dein Haustier auf ebay Kleinanzeigen verkauft",
    en: "They've sold your pet on ebay",
  },
  {
    id: 74,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Wartet auf Todesstrafe",
    en: "death row inmate",
  },
  {
    id: 75,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Vergisst immer deinen Namen",
    en: "They always forget your name",
  },
  {
    id: 76,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Lügt fast immer",
    en: "They lie a lot",
  },
  {
    id: 77,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Ist mit dir verwandt",
    en: "They're related to you",
  },
  {
    id: 78,
    category: "redFlags",
    config: { familyFriendly: true },
    de: '"leiht" sich die ganze Zeit Geld von dir',
    en: 'They "borrow" money from you all the time',
  },
  {
    id: 79,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Küsst seine/ihre Geschwister auf den Mund",
    en: "They open-mouth kiss their siblings",
  },
  {
    id: 80,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Hat einen Schrein von dir",
    en: "They have a shrine of you",
  },
  {
    id: 81,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Stalkt dich auf jede Weise",
    en: "Stalker",
  },
  {
    id: 82,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Ist so eingeschüchtert vor dir, dass er/sie nicht reden kann",
    en: "They're so intimidated by you that they cannot talk to you",
  },
  {
    id: 83,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Mitglied bei der Mafia",
    en: "Member of the mafia",
  },
  {
    id: 84,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Stinkt nach ranziger Butter",
    en: "They stink like rancid butter",
  },
  {
    id: 85,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Hasst alle deine Freunde",
    en: "They hate all your friends",
  },
  {
    id: 86,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Verbringt mehr als 6 Stunden am Tag auf TikTok",
    en: "They spend more than 6 hours a day on Tiktok",
  },
  {
    id: 87,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Chronisch unnerreichbar",
    en: "They're chronically unreachable",
  },
  {
    id: 88,
    category: "redFlags",
    config: { familyFriendly: true },
    de: "Wohnt mehr als 100km von dir entfernt",
    en: "They live over 80miles away from you",
  },
  {
    id: 89,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Narzissmus",
    en: "narcissism",
  },
  {
    id: 90,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Er/Sie ist zu lau",
    en: "They're is too loud",
  },
  {
    id: 91,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Er/Sie denkt nicht nach",
    en: "They don't think",
  },
  {
    id: 92,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Nervigkeit",
    en: "Annoyance",
  },
  {
    id: 93,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Aufdringlichkeit",
    en: "Insolence",
  },
  {
    id: 94,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Feigheit",
    en: "cowardice",
  },
  {
    id: 95,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Verantwortungslosigkeit",
    en: "Irresponsibility",
  },
  {
    id: 96,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Unerlichkeit",
    en: "They're dishonest",
  },
  {
    id: 97,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Pessimismus",
    en: "pessimism",
  },
  {
    id: 98,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Er/Sie ist Aggresiv",
    en: "They're too aggresive",
  },
  {
    id: 99,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Er/Sie ist anmaßend",
    en: "They're presumptious",
  },
  {
    id: 100,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Er/Sie ist dumm",
    en: "They're dumb",
  },
  {
    id: 101,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Er/Sie ist ein Incel",
    en: "They're an incel",
  },
  {
    id: 102,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Er/Sie ist hässlich",
    en: "They're ugly",
  },
  {
    id: 103,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Er/Sie ist unambitioniert",
    en: "They lack ambition",
  },
  {
    id: 104,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Er/Sie ist versessen auf Politik",
    en: "They're obsessed with politics",
  },
  {
    id: 105,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Unaufmerksam",
    en: "They don't pay attention to you",
  },
  {
    id: 106,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Alkoholiker",
    en: "They're an alcoholic",
  },
  {
    id: 107,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Introvertiertheit",
    en: "They're too introvertet",
  },
  {
    id: 108,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Verklemmtheit",
    en: "They're prude",
  },
  {
    id: 109,
    category: "badTraits",
    config: { familyFriendly: true },
    de: "Er/Sie hat keine Empathie",
    en: "They lack empathy",
  },
  {
    id: 110,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Eine 10/10 geheiratet haben",
    en: "Being married to a 10/&10",
  },
  {
    id: 111,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Doktortitel zu haben",
    en: "To have a Ph.D.",
  },
  {
    id: 112,
    category: "lifeGoals",
    config: { familyFriendly: false },
    de: "Bei einem Dreier dabei gewesen zu sein",
    en: "Having participated in a threesome",
  },
  {
    id: 113,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Ausgewandert sein",
    en: "Having emigrated",
  },
  {
    id: 114,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "über 1 000 000 € auf dem Konto haben",
    en: "Having a million Dollars",
  },
  {
    id: 115,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Auf dem Mount Everest gewesen zu sein",
    en: "Having climbed Mt. Everest",
  },
  {
    id: 116,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Die Person die man in der Schule am meisten gehasst hat verprügelt zu haben",
    en: "Having beaten up the person you hated most in high school",
  },
  {
    id: 117,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Einmal in jedem Land der Welt gewesen zu sein",
    en: "Having been to every country in the world",
  },
  {
    id: 118,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Die wahre Liebe gefunden haben",
    en: "Having found true love",
  },
  {
    id: 119,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "2 Kinder haben",
    en: "Being the parent of 2 children",
  },
  {
    id: 120,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Die Medal of Honor vom Präsidenten verliehen bekommen haben",
    en: "Having received the medal of honor by the president",
  },
  {
    id: 121,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Seinen eigenen Fortnite Tanz haben",
    en: "Having your own Fortnite-Dance",
  },
  {
    id: 122,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Höchst gerateter Schachspieler der Welt gewesen zu sein",
    en: "Having been the highest rated chess player in the world",
  },
  {
    id: 123,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Über den Atlantik gerudert sein",
    en: "Having been rowed across the atlantic",
  },
  {
    id: 124,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Einen Marathon gelaufen sein",
    en: "Having run a marathon",
  },
  {
    id: 125,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Die Nordlichter gesehen haben",
    en: "Having seen the northern lights",
  },
  {
    id: 126,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Fallschirm gesprungen sein",
    en: "To have skydived",
  },
  {
    id: 127,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Inneren Frieden gefunden zu haben",
    en: "To have found inner peace",
  },
  {
    id: 128,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Es gibt eine Wikipedia Seite über dich",
    en: "There is a Wikipedia site about you",
  },
  {
    id: 129,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Auf der Superbowl Bühne performt zu haben",
    en: "To have performed on the Superbowl stage",
  },
  {
    id: 130,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Reich geheiratet zu haben",
    en: "Having married rich",
  },
  {
    id: 131,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Auf dem Mond gewesen sein",
    en: "To have been to the moon",
  },
  {
    id: 132,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Den Israel-Palestina Konflikt gelöst haben",
    en: "Having solved the Israel-Palestina conflict",
  },
  {
    id: 133,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Einen Nobelpreis verliehen bekommen haben",
    en: "To have been awarded a Nobel Prize",
  },
  {
    id: 134,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Am Nordpol ein Iglu gebaut haben",
    en: "To have built an igloo at the North Pole",
  },
  {
    id: 135,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Ein Buch veröffentlicht zu haben",
    en: "To have published a book",
  },
  {
    id: 136,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Flüssig eine (weitere) andere Sprache zu sprechen",
    en: "To be fluent in a(nother) foreign other language",
  },
  {
    id: 137,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Dramatisch jemandem vorm Altar die Braut gestohlen haben",
    en: "To have dramatically stolen someone's bride in front of the altar",
  },
  {
    id: 138,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "In einem Anzug eine Messerstecherei mitgemacht haben",
    en: "Have participated in a stabbing while wearing a suit",
  },
  {
    id: 139,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "LSD genommen haben",
    en: "Having taken LSD",
  },
  {
    id: 140,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Koks gezogen haben",
    en: "Having drawn coke",
  },
  {
    id: 141,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Mit seinen Enkelkindern gespielt haben",
    en: "to have played with his grandchildren",
  },
  {
    id: 142,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Gott gefunden haben",
    en: "Having found God",
  },
  {
    id: 143,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Ein Waisenheim niedergebrandt haben",
    en: "Having burnt down an orphanage",
  },
  {
    id: 144,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Einmal in der Schule cool gewesen sein",
    en: "Having been popular in High school once",
  },
  {
    id: 145,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Organspender sein",
    en: "Being an organ donor",
  },
  {
    id: 146,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Im Gefängnes gewesen zu sein",
    en: "Having been to prison",
  },
  {
    id: 147,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Eine eigene Actionfigur von sich zu haben",
    en: "To have your own action figure",
  },
  {
    id: 148,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Jedes Level in Lego Star Wars auf 100% durchgespielt haben",
    en: "Having played through each level in Lego Star Wars 100%",
  },
  {
    id: 149,
    category: "lifeGoals",
    config: { familyFriendly: false },
    de: "In einem U-Boot Sex gehabt zu haben",
    en: "To have had sex in a submarine",
  },
  {
    id: 150,
    category: "lifeGoals",
    config: { familyFriendly: false },
    de: "Sein Geschlecht gewechselt zu haben",
    en: "To have changed gender",
  },
  {
    id: 151,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Ein Verkehrsschild geklaut zu haben",
    en: "Having stolen a traffic sign",
  },
  {
    id: 152,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Einen eigenen Film gedreht zu haben",
    en: "Having made their own film",
  },
  {
    id: 153,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Einen Elefanten in Afrika geritten sein",
    en: "Having ridden an elephant in africa",
  },
  {
    id: 154,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Sex mit einem Promintenten gehabt haben",
    en: "Having had sex with a celebrity",
  },
  {
    id: 155,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Einen erfolgreichen Friseursalon besitzen",
    en: "Owning a succesful barbershop",
  },
  {
    id: 156,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Die Person des Jahres im Time Magazine gewesen sein",
    en: 'Having been "Person of the year" of the time magazine',
  },
  {
    id: 157,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Selbstmord begangen haben",
    en: "Having committed suicide",
  },
  {
    id: 158,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Im supermarkt immer einen Cent an hungrige Kinder gespendet haben",
    en: "Always having donated 1 cent to hungry kids when leaving the supermarket ",
  },
  {
    id: 159,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Ein Kind adoptiert haben",
    en: "Having adopted a child",
  },
  {
    id: 160,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Von keinen Substanzen abhängig sein",
    en: "Not being reliant on any substances",
  },
  {
    id: 161,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Eine Firma erfolgreich gegründet und verkauft haben",
    en: "Having succesfully founded and sold a company",
  },
  {
    id: 162,
    category: "lifeGoals",
    config: { familyFriendly: true },
    de: "Jedes Buch was du jemals lesen wolltest gelesen haben",
    en: "Having read every book you ever wanted to read",
  },
  {
    id: 163,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "In Bitcoin investieren",
    en: "Invest in Bitcoin",
  },
  {
    id: 164,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Die Geburt von Jesus bezeugen",
    en: "Witness the birth of Jesus",
  },
  {
    id: 165,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Einen Dinosaurier reiten",
    en: "Ride a dinosaurs",
  },
  {
    id: 166,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Cowboy im wilden Westen werden",
    en: "Become a Cowboy in the Wild West",
  },
  {
    id: 167,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Bei der Schlacht um Troja mitkämpfen",
    en: "Fight at the battle for Troy",
  },
  {
    id: 168,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Im alten Rom ein Haus kaufen",
    en: "Buy a house in ancient Rome",
  },
  {
    id: 169,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "In die Zukunft reisen",
    en: "Travel into the future",
  },
  {
    id: 170,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Hitler töten",
    en: "Kill Hitler",
  },
  {
    id: 171,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Den/Die Großmutter/Großvater im Teenager-alter daten",
    en: "Dating your grandmother/grandfather when they've been a teenage",
  },
  {
    id: 172,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Mark Zuckerberg töten bevor facebook entsteht",
    en: "Mark Zuckerberg kill before Facebook is created",
  },
  {
    id: 173,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Ghengis Khan töten und in seine Rolle schlüpfen",
    en: "Kill Ghengis Khan and slip into his role",
  },
  {
    id: 174,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Ein Trollface in zwischen Höhlenmalerei mischen",
    en: "Put a trollface inbetween cave paintings",
  },
  {
    id: 175,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Nach Wuhan, China in 2019 reisen und den Markt in dem COVID-19 ausgebrochen ist in die Luft jagen",
    en: "Travel to Wuhan, China in 2019 and blow up the market where COVID-19 originated from",
  },
  {
    id: 176,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Mohammed treffen",
    en: "Meet Mohammed",
  },
  {
    id: 177,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Für die Polizei ungeklärte Mordfälle lösen",
    en: "Solve unexplained murder cases for the police",
  },
  {
    id: 178,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Verhindern dass TikTok entsteht",
    en: "Stop TikTok from ever existing",
  },
  {
    id: 179,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Leonardo da Vinci dazu überreden dass er dich, anstatt die Mona Lisa porträtiert",
    en: "Convince Leonardo da Vinci of portraying you instead of the Mona Lisa",
  },
  {
    id: 180,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Mozart live Klavierspielen hören",
    en: "Listen to Mozart play piano",
  },
  {
    id: 181,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Den ersten Menschen töten",
    en: "Kill the first human",
  },
  {
    id: 182,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: 'Ein Konzert von "Queen" besuchen',
    en: 'Visit a "Queen" concert',
  },
  {
    id: 183,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Auf eine ECHTE 80er Party gehen",
    en: "Go to a REAL 80s Party",
  },
  {
    id: 184,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Zurückgehen bevor die Gruppe entschieden hat dieses Spiel zu spielen",
    en: "Go back to before when this group decided to play this game",
  },
  {
    id: 185,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: 'Verhindern dass "Among Us" jemals berühmt wird',
    en: 'Prevent "Among Us" of ever becoming famous',
  },
  {
    id: 186,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Ein mittelalterlicher Ritter werden",
    en: "Become a medieval knight",
  },
  {
    id: 187,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Jesus töten",
    en: "Kill Jesus",
  },
  {
    id: 188,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "In der Sovietunion ein normales Leben führen",
    en: "Lead a normal life in the soviet union",
  },
  {
    id: 189,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Einen Sklavenaufstand in den frühen Vereinigten Staaten schaffen",
    en: "Start a slave riot in the early US",
  },
  {
    id: 190,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Zum Urknall zurückreisen",
    en: "Travel back to the big bang",
  },
  {
    id: 191,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Herausfinden was zuerst da war: Das Huhn oder das Ei",
    en: "Find out who came first: The chicken or the egg",
  },
  {
    id: 192,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Mit Buddha unter einem Baum chillen",
    en: "Chill with buddha under a tree",
  },
  {
    id: 193,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Die erste Bibel verbrennen",
    en: "Burn the first bible",
  },
  {
    id: 194,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Karl Marx töten bevor er das kommunistische Manifest verfasst",
    en: "Kill Karl Marx before he publishes the communist manifesto",
  },
  {
    id: 195,
    category: "timeMachine",
    config: { familyFriendly: false },
    de: "Mit Jesus Sex haben",
    en: "Have sex with Jesus",
  },
  {
    id: 196,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Den Höhlenmenschen Technologie bringen",
    en: "Bring the cave men technology",
  },
  {
    id: 197,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "John F. Kennedys Kugel einfangen",
    en: "Jump in front of John F. Kennedy's bullet",
  },
  {
    id: 198,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Auf der Unabhängkeitserklärung unterschreiben ",
    en: "Sign on the declaration of independence",
  },
  {
    id: 199,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Den Bauplan der Pyramiden zu großen Würfeln ändern",
    en: "Change the construction plan of the pyramids to large cubes",
  },
  {
    id: 200,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Die Mode von heute schon 3 Monate im vorhinein tragen",
    en: "Wear the fashion from today 3 months in advance",
  },
  {
    id: 201,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Den ersten Schritt auf dem Mond machen",
    en: "Take the first step on the moon",
  },
  {
    id: 202,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Bei der eigenen Geburt zugucken",
    en: "Watch your own birth",
  },
  {
    id: 203,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Napeleon auf den Kopf spucken",
    en: "Spit on Napeleons head",
  },
  {
    id: 204,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Cäser abstechen",
    en: "Stab cesar",
  },
  {
    id: 205,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Mammut streicheln",
    en: "Stroke a mammooth",
  },
  {
    id: 206,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Verhindern dass die Engländer Amerika kolonialisieren",
    en: "Prevent the English from colonializing America",
  },
  {
    id: 207,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Mit den Indianeren einen rauchen",
    en: "Smoke a pipe withe the native americans",
  },
  {
    id: 208,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "So tun als wär man Jesus",
    en: "Pretend to be Jesus",
  },
  {
    id: 209,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Verhindern dass Donald Trump Präsident wird",
    en: "Prevent Donald Trump from becoming president",
  },
  {
    id: 210,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "9/11 verhindern",
    en: "prevent 9/11",
  },
  {
    id: 211,
    category: "timeMachine",
    config: { familyFriendly: true },
    de: "Mit seinem Uropa reden",
    en: "Talk to your great-grandfather",
  },
  {
    id: 212,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Perfektes Gedächtnis",
    en: "Perfect memory",
  },
  {
    id: 213,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "super hoch springen",
    en: "Super jump",
  },
  {
    id: 214,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Durch die Zeit reisen können",
    en: "time travel",
  },
  {
    id: 215,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Nie wieder müde sein/schlafen müssen",
    en: "Never having to sleep again",
  },
  {
    id: 216,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Extreme stärke",
    en: "Extreme strength",
  },
  {
    id: 217,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Jede politische Wahl entscheiden können",
    en: "Decide every election",
  },
  {
    id: 218,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Fliegen können",
    en: "flying",
  },
  {
    id: 219,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Personenwandler",
    en: "Shapeshifting",
  },
  {
    id: 220,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Unsterblichkeit",
    en: "immortality",
  },
  {
    id: 221,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Jede Verletzung und Krankheit heilen können",
    en: "Healing of injuries and illnesses",
  },
  {
    id: 222,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Alles zerbeißen können",
    en: "Being able to bite through everything",
  },
  {
    id: 223,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Dämonen beschwören können",
    en: "Summoning demons",
  },
  {
    id: 224,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Gedankenkontrolle",
    en: "Mind control",
  },
  {
    id: 225,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Abnehmbare Arme und Beine",
    en: "Detachable limbs",
  },
  {
    id: 226,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Jede Sprache sprechen können",
    en: "Being fluent in every language",
  },
  {
    id: 227,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Superschnell rennen können",
    en: "super speed",
  },
  {
    id: 228,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Empathie",
    en: "empathy",
  },
  {
    id: 229,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Selbstbewusstsein",
    en: "Confidence",
  },
  {
    id: 230,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Sich klonen können",
    en: "Being able to clone themselves",
  },
  {
    id: 231,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Unter Wasser atmen",
    en: "Under-water breathing",
  },
  {
    id: 232,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Mit den Toten sprechen können",
    en: "Talking to the deceased",
  },
  {
    id: 233,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Extremes Glück",
    en: "Extreme luck",
  },
  {
    id: 234,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Eine Armee an Tieren haben",
    en: "Controlling an army of animals",
  },
  {
    id: 235,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Stahlhaut",
    en: "Steel skin",
  },
  {
    id: 236,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Sehr ruhige Hände",
    en: "Very still hands",
  },
  {
    id: 237,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Superintelligenz",
    en: "Superintelligence",
  },
  {
    id: 238,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Schwerter als Hände",
    en: "Sword-hands",
  },
  {
    id: 239,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Teleportation",
    en: "Teleportation",
  },
  {
    id: 240,
    category: "superpowers",
    config: { familyFriendly: true },
    de: "Unverwundbarkeit",
    en: "invulnerability",
  },
  {
    id: 241,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Steine von der Autobahnbrücke werfen",
    en: "Throw stones from a highway bridge",
  },
  {
    id: 242,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Im Krankenhaus zwei Neugeborene vertauschen ",
    en: "Swap two newborns at the hospital",
  },
  {
    id: 243,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Einen Tag in einem Müllsack herumlaufen",
    en: "Walk around dressed in trash bag for a day",
  },
  {
    id: 244,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Nach Syrien fliegen und ein Selfie mit einem IS-Anhänger machen",
    en: "Fly to syria and take a selfie with an Isis Supporter",
  },
  {
    id: 245,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Nackt um den Block rennen",
    en: "Run naked around the block once",
  },
  {
    id: 246,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Jemand Hübschen auf der nach seiner Nummer fragen",
    en: "Ask someone pretty on the streets for their number",
  },
  {
    id: 247,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Den Boden ablecken",
    en: "Lick the floor",
  },
  {
    id: 248,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "An einer Haustür klingeln und wegrennen",
    en: "Ring a doorbell and run away",
  },
  {
    id: 249,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Einem Polizisten den Mittelfinger zeigen",
    en: "Flip off a police officer",
  },
  {
    id: 250,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Ein Huhn schlachten",
    en: "Decapitate a chicken",
  },
  {
    id: 251,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Eine Packung Kaugummis klauen",
    en: "Steal chewing gums at the super market",
  },
  {
    id: 252,
    category: "dareYou",
    config: { familyFriendly: false },
    de: "Nudes auf seinem Social Media posten",
    en: "Post nudes on social media",
  },
  {
    id: 253,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Mit Graffiti eine lokale Wand einsprühen",
    en: "Spray a grafitti onto a local wall",
  },
  {
    id: 254,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Einen schlafenden Obdachlosen tättowieren",
    en: "Put a tattoo on a sleeping homeless person",
  },
  {
    id: 255,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Sich von einem Obdachlosen für 5€ tättowieren lassen",
    en: "Getting a tattoo by a homeless guy for 5€",
  },
  {
    id: 256,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Einen Horrorfilm gucken (gruselig)",
    en: "Watching a horror movie (scary)",
  },
  {
    id: 257,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Ungescheschützten Geschlechtsverkehr mit einer Dame der Nacht haben",
    en: "Have unsafe sexual intercourse with a lady of the night",
  },
  {
    id: 258,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Sich von einer Kobra beissen lassen",
    en: "Get bitten by a cobra",
  },
  {
    id: 259,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Fallschirmspringen",
    en: "Skydiving",
  },
  {
    id: 260,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Sich betrinken",
    en: "Get drunk",
  },
  {
    id: 261,
    category: "dareYou",
    config: { familyFriendly: false },
    de: "Seinen Eltern sagen man seie homosexuell",
    en: "Tell their parents that they are gay",
  },
  {
    id: 262,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Eine Geschlechtsumwandlung machen lassen",
    en: "Transition between genders",
  },
  {
    id: 263,
    category: "dareYou",
    config: { familyFriendly: false },
    de: "Sich Brustimplantate machen lassen",
    en: "Get a boob-job",
  },
  {
    id: 264,
    category: "dareYou",
    config: { familyFriendly: false },
    de: "Koks ziehen",
    en: "Draw a line of coke",
  },
  {
    id: 265,
    category: "dareYou",
    config: { familyFriendly: false },
    de: "LSD nehmen",
    en: "Take LSD",
  },
  {
    id: 266,
    category: "dareYou",
    config: { familyFriendly: false },
    de: "Sex mit einem lebendigen Bären haben (angekettet)",
    en: "Have sex with a living bear (chained)",
  },
  {
    id: 267,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Sich in einem Paket auf einen anderen Kontinent verschicken lassen",
    en: "Be sent to another continent in a package",
  },
  {
    id: 268,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Oberkörperfrei herum laufen",
    en: "Walk around with naked upper body",
  },
  {
    id: 269,
    category: "dareYou",
    config: { familyFriendly: true },
    de: "Im Club einen Fortnite Tanz machen",
    en: "Do a Fortnite dance at the club",
  },
  {
    id: 270,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Anwalt",
    en: "lawyer",
  },
  { id: 271, category: "occupations", config: { familyFriendly: true }, de: "Arzt", en: "doctor" },
  {
    id: 272,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Pornodarsteller",
    en: "Porn actor",
  },
  { id: 273, category: "occupations", config: { familyFriendly: true }, de: "Bauer", en: "Farmer" },
  {
    id: 274,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Schneider",
    en: "Tailor",
  },
  {
    id: 275,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Schmied",
    en: "Blacksmith",
  },
  {
    id: 276,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Lehrer",
    en: "teacher",
  },
  {
    id: 277,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Hebamme",
    en: "midwife",
  },
  {
    id: 278,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Manager",
    en: "Manager",
  },
  {
    id: 279,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Ingenieur",
    en: "engineer",
  },
  {
    id: 280,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Influencer",
    en: "Influencer",
  },
  {
    id: 281,
    category: "occupations",
    config: { familyFriendly: true },
    de: "professioneller Boxer",
    en: "professional boxer",
  },
  {
    id: 282,
    category: "occupations",
    config: { familyFriendly: true },
    de: "professioneller Schachspieler",
    en: "professional chess player",
  },
  {
    id: 283,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Politiker",
    en: "Politician",
  },
  {
    id: 284,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Kammerjäger",
    en: "exterminator",
  },
  {
    id: 285,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Umweltaktivist",
    en: "Environmental activist",
  },
  {
    id: 286,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Elektriker",
    en: "electrician",
  },
  {
    id: 287,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Klempner",
    en: "plumber",
  },
  { id: 288, category: "occupations", config: { familyFriendly: true }, de: "Bäcker", en: "baker" },
  {
    id: 289,
    category: "occupations",
    config: { familyFriendly: true },
    de: "CIA Agent",
    en: "CIA agent",
  },
  {
    id: 290,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Soldat",
    en: "soldier",
  },
  {
    id: 291,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Popstar",
    en: "Pop star",
  },
  {
    id: 292,
    category: "occupations",
    config: { familyFriendly: true },
    de: "K-Popstar",
    en: "K-popstar",
  },
  {
    id: 293,
    category: "occupations",
    config: { familyFriendly: true },
    de: "LKW Fahrer",
    en: "truck driver",
  },
  {
    id: 294,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Busfahrer",
    en: "bus driver",
  },
  { id: 295, category: "occupations", config: { familyFriendly: true }, de: "Pilot", en: "pilot" },
  {
    id: 296,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Programmierer",
    en: "Programmer",
  },
  {
    id: 297,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Mathe-Professor",
    en: "Math professor",
  },
  {
    id: 298,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Englisch-Professor",
    en: "English professor",
  },
  {
    id: 299,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Altgriechisch-Professor",
    en: "Ancient Greek professor",
  },
  {
    id: 300,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Richter",
    en: "Judge",
  },
  {
    id: 301,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Spezialeinheit (SEK)",
    en: "Special unit (SWAT)",
  },
  {
    id: 302,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Zuhälter",
    en: "pimp",
  },
  {
    id: 303,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Escort",
    en: "Escort",
  },
  {
    id: 304,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Nachrichtensprecher",
    en: "news reporter",
  },
  {
    id: 305,
    category: "occupations",
    config: { familyFriendly: true },
    de: "Youtuber",
    en: "Youtuber",
  },
  {
    id: 306,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Streit mit Geschwistern",
    en: "A quarrel with their siblings",
  },
  {
    id: 307,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man muss als Familie ein kleines Zelt teilen",
    en: "They have to share a tiny tent with their whole family",
  },
  {
    id: 308,
    category: "familyVacation",
    config: { familyFriendly: false },
    de: "Man wird von einer Qualle gestochen und die Oma muss einen anpinkeln",
    en: "They get stung by a jellyfish and have to be peed on by their grandma",
  },
  {
    id: 309,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Handyladekabel vergessen",
    en: "They forgot their phone charger",
  },
  {
    id: 310,
    category: "familyVacation",
    config: { familyFriendly: false },
    de: "Man erwischt den Vater mit der Rezeptionistin",
    en: "They catch their father in action with the receptionist",
  },
  {
    id: 311,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man erwischt seine Geschwister beim LSD nehmen",
    en: "They catch their siblings doing LSD",
  },
  {
    id: 312,
    category: "familyVacation",
    config: { familyFriendly: false },
    de: "100er Packung Kondome in Omas Handgepäck",
    en: "They find a pack of 100 condoms in their grandmas luggage",
  },
  {
    id: 313,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Der Flug wird spontan um 10 Stunden nach hinten verlegt",
    en: "The flight gets postponed for 10 hours spontaneously",
  },
  {
    id: 314,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Der Mietwagen bleibt im Nirgedndwo stehen",
    en: "Their rental car breaks down in the middle of nowhere",
  },
  {
    id: 315,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man findet am letzten Tag eine Kamera in seinem Hotelzimmer",
    en: "They find a hidden camera in their hotel room on the last day",
  },
  {
    id: 316,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man bekommt einen Hitzeschlag",
    en: "They get a heat stroke",
  },
  {
    id: 317,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man wird von 1 000 Mücken gestochen",
    en: "They're stung by 1 000 mosquitoes",
  },
  {
    id: 318,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Ein Delphin klaut einem die GoPro",
    en: "A dolphin steals their GoPro",
  },
  {
    id: 319,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Im Flugzeug merkt man, dass man den Herd nicht ausgemacht hat",
    en: "On the plane they realize that they haven't turned off the stove",
  },
  {
    id: 320,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Reiseziel: Nordkorea",
    en: "Travel destination: North Korea",
  },
  {
    id: 321,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Die Stiefschwester bleibt im Trockner stecken",
    en: "Their step sister gets stuck in the dryer",
  },
  {
    id: 322,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man muss den ganzen Urlaub remote arbeiten",
    en: "They have to remote work all the time",
  },
  {
    id: 323,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: 'Das Hotel spielt in jedem Zimmer auf 24/7 Loop "Despacito"',
    en: 'The hotel plays "Despacito" in every room on a 24/7 loop',
  },
  {
    id: 324,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man bekommt Hautkrebs",
    en: "They get skincancer",
  },
  {
    id: 325,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man bricht sich den Arm",
    en: "They break their arm",
  },
  {
    id: 326,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man wird gekidnapt",
    en: "They get kidnaped",
  },
  {
    id: 327,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Kein Fitnessstudio",
    en: "No gym",
  },
  {
    id: 328,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Das Airbnb existiert einfach nicht",
    en: "The Airbnb simply does not exist",
  },
  {
    id: 329,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Im Lüftungsschacht des Hotelzimmers lebt ein Obdachloser",
    en: "A homeless person lives in the ventilation shaft of the hotel room",
  },
  {
    id: 330,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Im Land bricht ein Zombie Virus aus",
    en: "A zombie virus breaks out in the country",
  },
  {
    id: 331,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Die Einwohner des Landes sind super rassistisch und hassen Touristen",
    en: "The native people are super racist and hate tourists",
  },
  {
    id: 332,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Riesenspinnen im Hotelzimmer",
    en: "Huge spiders in the hotel room",
  },
  {
    id: 333,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man wird von einem Bus angefahren",
    en: "They git hit by a bus, not run over tho",
  },
  {
    id: 334,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man ist die ganze Zeit unglaublich horny",
    en: "They're extremeley horny all the time",
  },
  {
    id: 335,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man bekommt Tollwut",
    en: "They get rabies",
  },
  {
    id: 336,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Man wird ausgeraubt",
    en: "They get robbed",
  },
  {
    id: 337,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Der Rückflug wird spontan wegen Corona gestrichen",
    en: "The return flight gets spontaneously canceled due to corona",
  },
  {
    id: 338,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Der Hotelpool ist voll mit Blut",
    en: "The hotel pool is full of blood",
  },
  {
    id: 339,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Im Land bricht Krieg aus",
    en: "A war breaks out in the country",
  },
  {
    id: 340,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Das Flugzeug wird von Terroristen gekidnapt",
    en: "The aircraft is kidnaped by terrorists",
  },
  {
    id: 341,
    category: "familyVacation",
    config: { familyFriendly: true },
    de: "Alle Koffer gehen verloren",
    en: "All suitcases are lost on the flight",
  },
  {
    id: 342,
    category: "vacation",
    config: { familyFriendly: true },
    de: "1 Woche in einer Partyvilla in Miami",
    en: "1 week in a party villa in Miami",
  },
  {
    id: 343,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Meditationsschule im Himalaya",
    en: "Meditation school in the Himalaya",
  },
  {
    id: 344,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Roadtrip durch die East Coast der USA",
    en: "Road trip through the East Coast of the USA",
  },
  {
    id: 345,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Roadtrip durch die West Coast der USA",
    en: "Road trip through the West Coast of the USA",
  },
  {
    id: 346,
    category: "vacation",
    config: { familyFriendly: true },
    de: "1 Wochenende in Las Vegas mit 100 000€ die man ausgeben muss",
    en: "1 weekend in Las Vegas with € 100,000 that you have to spend",
  },
  {
    id: 347,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Tauchcamp in Sri Lanka",
    en: "Diving camp in Sri Lanka",
  },
  {
    id: 348,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Para Gliden in den Alpen",
    en: "Para gliding in the Alps",
  },
  {
    id: 349,
    category: "vacation",
    config: { familyFriendly: true },
    de: "In Amsterdam Gras rauchen gehen",
    en: "Smoke some weed in Amsterdam",
  },
  {
    id: 350,
    category: "vacation",
    config: { familyFriendly: true },
    de: "zu Hause bleiben",
    en: "stay at home",
  },
  {
    id: 351,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Urlaub im Metaverse",
    en: "Vacation in the metaverse",
  },
  {
    id: 352,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Disneyland",
    en: "Disneyland",
  },
  {
    id: 353,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Surfcamp auf Bali",
    en: "Surfcamp in Bali",
  },
  {
    id: 354,
    category: "vacation",
    config: { familyFriendly: true },
    de: "1 Woche bei den russischen Truppen dabei sein",
    en: "1 week with the Russian troops",
  },
  {
    id: 355,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Reisebus durch die Uckermark",
    en: "Travelbus through the outbacks",
  },
  {
    id: 356,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Kreuzfahrt über den Atlantik",
    en: "Cruise over the Atlantic",
  },
  {
    id: 357,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Kajüte auf einem Walfängerbot",
    en: "A room an a whale hunting boat",
  },
  {
    id: 358,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Safari in Südafrika",
    en: "Safari in South Africa",
  },
  {
    id: 359,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Zelten in einem verlassenen Bergwerk",
    en: "Camps in an abandoned mine",
  },
  {
    id: 360,
    category: "vacation",
    config: { familyFriendly: true },
    de: "In Bangkok einen Ladyboy kennenlernen",
    en: "Get to know a ladyboy in Bangkok",
  },
  {
    id: 361,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Den Mount Everest erklimmen",
    en: "Climb the Mount Everest",
  },
  {
    id: 362,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Durch die Alpen wandern",
    en: "Wander through the alps",
  },
  {
    id: 363,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Saufboot entlang der Küste von Costa Rica",
    en: "Booze cruise along the coast of costa rica",
  },
  {
    id: 364,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Rotlichtviertel in Amsterdam besuchen",
    en: "Check out the red light district in amsterdam",
  },
  {
    id: 365,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Per Anhalter durch Südchina",
    en: "Tramp through southern china",
  },
  {
    id: 366,
    category: "vacation",
    config: { familyFriendly: false },
    de: "3 Tage Motel mit 3 Prostituierten",
    en: "3 Days in a motel with 3 prostitutes",
  },
  {
    id: 367,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Über den Pazifik segeln",
    en: "Sail across the pacific",
  },
  {
    id: 368,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Den Louvre in Paris besuchen gehen",
    en: "Go to visit the Louvre in Paris",
  },
  {
    id: 369,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Ein Billie Eilish Konzert in New York besuchen gehen",
    en: "Going to a Billie Eilish concert in New York",
  },
  {
    id: 370,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Mit einem Känguru in Australien boxen",
    en: "Boxing with a kangoroo in Australioa",
  },
  {
    id: 371,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Hahnenkämpfe angucken",
    en: "Watch some cock fights",
  },
  {
    id: 372,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Einfach saufen",
    en: "Just get drunk",
  },
  {
    id: 373,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Skatepark chillen",
    en: "Chill at the skatepark",
  },
  {
    id: 374,
    category: "vacation",
    config: { familyFriendly: true },
    de: "Nach Mekka pilgern",
    en: "Mecca pilgrimage",
  },
  {
    id: 375,
    category: "quarrel",
    config: { familyFriendly: false },
    de: "Hat mit deinem Partner geschlafen",
    en: "They've slept with your significant other",
  },
  {
    id: 376,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat den letzten Chicken Wing gegessen ohne zu fragen",
    en: "They've eaten the last chicken wing withour asking",
  },
  {
    id: 377,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dein Bier leergetrunken",
    en: "They've drank the rest of your beer",
  },
  {
    id: 378,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat sich von dir Geld geliehen und nicht zurückgegeben",
    en: "They've borrowed money from you and not returned",
  },
  {
    id: 379,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dich gefragt warum du denn so schlecht gelaunt bist",
    en: "They asked you why you're in such a bad mood",
  },
  {
    id: 380,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Ist Querdenker geworden",
    en: "They've become a QAnon supporter",
  },
  {
    id: 381,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dich seine Hausaufgaben nicht anschreiben lassen",
    en: "They didn't let you copy their homework",
  },
  {
    id: 382,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat in deinen Garten gepinkelt",
    en: "Peed into your garden",
  },
  {
    id: 383,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat deiner Mutter eine Briefbombe geschickt",
    en: "They've sent your mother a mail bomb",
  },
  {
    id: 384,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat ein intimes Geheimnis über dich ausgeplaudert",
    en: "They leaked an intimate secret about you",
  },
  {
    id: 385,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dich betrunken fotografiert",
    en: "They took a photo of you drunk",
  },
  {
    id: 386,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dich angemalt als du betrunken in der Ecke lagst",
    en: "They drew on you when you laid drunk in the corner",
  },
  {
    id: 387,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dich im Club unter Drogen alleine gelassen",
    en: "They left you alone in the club when you were under the influence",
  },
  {
    id: 388,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dich geschlagen",
    en: "They hit you",
  },
  {
    id: 389,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dein Haus angezündet",
    en: "They lit your house on fire",
  },
  {
    id: 390,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Sagt du seist kein guter Rapper",
    en: "They said you're not a good rapper",
  },
  {
    id: 391,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat gesagt dass du nicht gut malen kannst",
    en: "They said you can't draw well",
  },
  {
    id: 392,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat deine Nachrichten 3 Tage nicht beantwortet aber gelesen",
    en: "They didn't answer your messages for 3 days but read it",
  },
  {
    id: 393,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat mit dir einen großen Urlaub und im letzten Moment abgesagt",
    en: "They planned  a big vacation with you and canceled at the last moment",
  },
  {
    id: 394,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dein Freundschaftsband abgelegt",
    en: "They've put off your friendship-bracelet",
  },
  {
    id: 395,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dein Auto geschrottet",
    en: "They've crashed your car",
  },
  {
    id: 396,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat auf einer Party bei dir in den Flur gepinkelt",
    en: "They peed into your hallway at a party",
  },
  {
    id: 397,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat die Asche deiner Oma durch die Nase gezogen",
    en: "They sniffed your grandmas ashes like coke",
  },
  {
    id: 398,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dich auf facebook als Freund entfernt",
    en: "They removed you as a friend on facebook",
  },
  {
    id: 399,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dir nicht zum Geburtstag gratuliert",
    en: "They didn't congratulate you on your birthday",
  },
  {
    id: 400,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat dich bei der Polizei verpetzt",
    en: "They snitched on you at the police",
  },
  {
    id: 401,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Denkt Ananas auf Pizza schmeckt gut",
    en: "They think pineapple on pizza is a good idea",
  },
  {
    id: 402,
    category: "quarrel",
    config: { familyFriendly: true },
    de: "Hat sich 3 Tage genau so angezogen wie du",
    en: "They've dressed like you for 3 days in a row",
  },
  {
    id: 403,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Der Aufstieg",
    en: "Ascension",
  },
  {
    id: 404,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Irgendwie hab ich überlebt",
    en: "Somehow I survived",
  },
  { id: 405, category: "episodes", config: { familyFriendly: true }, de: "Randale", en: "Riots" },
  {
    id: 406,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Der Untergang",
    en: "The downfall",
  },
  {
    id: 407,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Radikalisierung",
    en: "Radicalization",
  },
  {
    id: 408,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Zuckerbrot und Peitsche",
    en: "Carrot and Stick",
  },
  {
    id: 409,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Der größte Fehler meines Lebens",
    en: "The biggest mistake of my life",
  },
  {
    id: 410,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Chancen nach Chancen",
    en: "Opportunities after opportunities",
  },
  {
    id: 411,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Just vibin'",
    en: "Just vibin'",
  },
  { id: 412, category: "episodes", config: { familyFriendly: true }, de: "Terror", en: "Terror" },
  {
    id: 413,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Der beste Sommer aller Zeiten",
    en: "The best summer of all time",
  },
  {
    id: 414,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Die Jagd",
    en: "The hunt",
  },
  {
    id: 415,
    category: "episodes",
    config: { familyFriendly: false },
    de: "Juckt mich alles nicht",
    en: "I don't give a fuck",
  },
  {
    id: 416,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Ich habe jetzt SWAG",
    en: "I now have Swag",
  },
  {
    id: 417,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Innerer Frieden",
    en: "Inner peace",
  },
  {
    id: 418,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Ich kann das einfach nicht",
    en: "I can't do this",
  },
  {
    id: 419,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Wie bin ich den hier gelandet",
    en: "How did I get here",
  },
  {
    id: 420,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Der Rausch",
    en: "The rush",
  },
  {
    id: 421,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Gerade so geschafft",
    en: "Just made it",
  },
  {
    id: 422,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Schikane",
    en: "chicanery",
  },
  {
    id: 423,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Endlich aufatmen",
    en: "Finally I can breathe",
  },
  {
    id: 424,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Achterbahnfahrt",
    en: "Roller coaster ride",
  },
  {
    id: 425,
    category: "episodes",
    config: { familyFriendly: true },
    de: "rein und raus",
    en: "hit and run",
  },
  {
    id: 426,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Meine Muskeln werden größer",
    en: "My muscles are getting bigger",
  },
  {
    id: 427,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Unbesiegbar",
    en: "Invincible",
  },
  {
    id: 428,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Mein Portemonnaie wird dicker",
    en: "My wallet is getting thicker",
  },
  {
    id: 429,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Auf der Überholspur",
    en: "In the fast lane",
  },
  {
    id: 430,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Bitte bring mich einfach um",
    en: "Just kill me please",
  },
  {
    id: 431,
    category: "episodes",
    config: { familyFriendly: true },
    de: "*Verwirrtes Geschrei*",
    en: "*Confused screaming*",
  },
  {
    id: 432,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Schweres Atmen",
    en: "Heavy Breathing",
  },
  {
    id: 433,
    category: "episodes",
    config: { familyFriendly: true },
    de: "It's Britney, Bitch!",
    en: "It's Britney, Bitch!",
  },
  {
    id: 434,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Der Nervenkitzel",
    en: "The Thrill",
  },
  {
    id: 435,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Zu gut für einen Titel",
    en: "Too amazing for a title",
  },
  { id: 436, category: "episodes", config: { familyFriendly: true }, de: "Wasted", en: "Wasted" },
  {
    id: 437,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Warten auf den Drop",
    en: "Waiting for the drop",
  },
  {
    id: 438,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Zu heiss um damit umzugehen",
    en: "Too hot to handle",
  },
  {
    id: 439,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Hormonkrieg",
    en: "Hormonal war",
  },
  {
    id: 440,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Harmonischer Zweifel",
    en: "harmonic disbelief",
  },
  {
    id: 441,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Am rumhuren",
    en: "Hoeing around",
  },
  {
    id: 442,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Self-Care",
    en: "Self-Care",
  },
  {
    id: 443,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Betäubende Einsamkeit",
    en: "Mind-Numbing loneliness",
  },
  {
    id: 444,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Liebreizender Schmerz",
    en: "Lovely pain",
  },
  {
    id: 445,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Das Streben nach Normalität",
    en: "Pursuit of Normality",
  },
  {
    id: 446,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Besser spät als nie",
    en: "Better late than never",
  },
  {
    id: 447,
    category: "episodes",
    config: { familyFriendly: true },
    de: "broke af",
    en: "broke af",
  },
  {
    id: 448,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Der trockene Fleck",
    en: "The dry spot",
  },
  {
    id: 449,
    category: "episodes",
    config: { familyFriendly: false },
    de: "Big Dick energy",
    en: "Big dick energy",
  },
  {
    id: 450,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Lichtblick",
    en: "a ray of hope",
  },
  {
    id: 451,
    category: "episodes",
    config: { familyFriendly: false },
    de: "STOP DADDY !!! 😖",
    en: "STOP IT DADDY!!! 😖",
  },
  {
    id: 452,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Jung genug dass es einem egal ist",
    en: "Young enough not to care",
  },
  {
    id: 453,
    category: "episodes",
    config: { familyFriendly: true },
    de: "AAAAAAAAAAAAAAAAAAAA",
    en: "AAAAAAAAAAAAAAAAAAAA",
  },
  {
    id: 454,
    category: "episodes",
    config: { familyFriendly: true },
    de: "I wurde geprankt",
    en: "I got pranked",
  },
  {
    id: 455,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Kreise drehen",
    en: "Drawing circles",
  },
  {
    id: 456,
    category: "episodes",
    config: { familyFriendly: true },
    de: "Der Versuch sich lebendig zu fühlen",
    en: "Trying to feel alive",
  },
  {
    id: 457,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Ist Mayonnaise auch ein Instrument?" -Patrick Star',
    en: '"Is mayonnaise an instrument?" -Patrick Star',
  },
  {
    id: 458,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Sei nett zu Leuten; Vielleicht sind sie ja eines Tages reich." -Felix Kjellberg',
    en: '"Be nice to people; Maybe one day they*ll be rich" -Felix Kjellberg',
  },
  {
    id: 459,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Jeder hat einen Plan bis er einen Schlag ins Gesicht abbekommt" -Mike Tyson',
    en: '"Everyone has a plan until they get punched in the face" -Mike Tyson',
  },
  {
    id: 460,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Wahre Schönheit ist etwas das angreift, überwältigt, raubt und zuletzt zerstört" -Yukio Mishima',
    en: '"True beauty is something that attacks, overpowers, robs, and finally destroys" -Yukio Mishima',
  },
  {
    id: 461,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Es is eine Schande für einen Mann alt zu werden ohne jemals die Schönheit und Stärke zu sehen zu der sein Körper fähig ist" -Socrates',
    en: '"It\'s a shame for a man to grow old without seeing the beauty and strength of which his body is capable" -Socrates',
  },
  {
    id: 462,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Der Pfad des rechtschaffenden Mannes ist von allen Seiten umgeben von den Ungerechtigkeiten der Eigennützigen und der Tyrannei der bösen Männer." -Pulp Fiction',
    en: '"The path of the righteous man is beset on all sides by the inequities of the selfish and the tyranny of evil men." -Pulp Fiction',
  },
  {
    id: 463,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Ich denke ich bin sehr viel bescheidener als du verstehen würdest" -Donald Trump',
    en: '"I think I am much more humble than you would understand" -Donald Trump',
  },
  {
    id: 464,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Ich gehe langsam, aber nie zurück" -Abraham Lincoln',
    en: '"I am a slow walker but I never walk back" -Abraham Lincoln',
  },
  {
    id: 465,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Blick ist leer, Dick ist schwer" -Yin Kalle',
    en: '"My gaze is empty, my dick is heavy" -Yin Kalle',
  },
  {
    id: 466,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Die Glücklichkeit deines Lebens hängt von der Qualität deiner Gedanken ab." -Marcus Aurelius',
    en: '"The happiness of your life depends upon the quality of your thoughts." -Marcus Aurelius',
  },
  {
    id: 467,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Sei ängstlich, wenn andere gierig sind und sei gierig, wenn andere ängstlich sind"  -Warren Buffet',
    en: '"Be fearful when others are greedy and greedy only when others are fearful" -Warren Buffet',
  },
  {
    id: 468,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Mehr Geld, mehr Probleme" -Biggie',
    en: '"More money more problems" -Biggie',
  },
  {
    id: 469,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Pessimisten hören sich klug an, Optimisten machen Geld" -Nat Friedman',
    en: '"Pessimists sound smart. Optimists make money." -Nat Friedman',
  },
  {
    id: 470,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Die Proletarier dieser Welt haben nichts zu verlieren als ihre Ketten." -Karl Marx',
    en: '"The working class has nothing to lose but their chains." -Karl Marx',
  },
  {
    id: 471,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Freiheit ist immer nur die Freiheit des anders Denkenden" -Rosa Luxemburg',
    en: '"Freedom is always the freedom of dissenters" -Rosa Luxemburg',
  },
  {
    id: 472,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Gott ist tot" -Friedrich Nietzsche',
    en: '"God is dead" -Friedrich Nietzsche',
  },
  {
    id: 473,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Wenn man von 400 Jahre langer Sklaverei hört... 400 Jahre lang? Das klingt wie eine Entscheidung" -"Kanye West"',
    en: '"When you hear about slavery for 400 years ... For 400 years? That sounds like a choice." -Kanye West',
  },
  {
    id: 474,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Wenn du durch die Hölle gehst, geh weiter" -Winston Churchil',
    en: '"If you’re going through hell, keep going" -Winston Churchill',
  },
  {
    id: 475,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Große Geister diskutieren Ideen, durchschnittliche Geister diskutieren Ereignisse, kleine Geister diskutieren Leute." -Eleanor Roosevelt',
    en: '"Great minds discuss ideas; average minds discuss events; small minds discuss people." -Eleanor Roosevelt',
  },
  {
    id: 476,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Wissen ist Macht" -Sir Francis Bacon',
    en: '"Knowledge is power" -Sir Francis Bacon',
  },
  {
    id: 477,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Wenn du Leute verurteilst hast du keine Zeit mehr sie zu lieben" -Mutter Teresa',
    en: '"If you judge people, you have no time to love them." -Mother Teresa',
  },
  {
    id: 478,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Oger sind wie Zwiebeln, sie haben Schichten" -Shrek',
    en: '"Ogers are like onions, they have layers" -Shrek',
  },
  {
    id: 479,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Nicht jeder kann ein großer Künstler werden, aber ein großer Künstler kann von überall herkommen"  -Ratatouille',
    en: '"Not everyone can become a great artist, but a great artist can come from anywhere." -Ratatouille',
  },
  {
    id: 480,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Bis in die Unendlichkeit... und noch viel weiter" -Toy Story',
    en: "“To infinity … and beyond!” –Toy Story",
  },
  {
    id: 481,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Warum lieg hier eigentlich stroh?" -Mann mit Maske',
    en: '"Why is there hay laying around here?" -Masked Man',
  },
  {
    id: 482,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Heutzutage wollen die Leute immer noch der Typ in der Limo sein aber jetzt bewerfen sie die Limo mit Eiern" -Billions',
    en: '"Nowadays people still want to be the guy in the limo, but now they throw eggs at the limo" -Billions',
  },
  {
    id: 483,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Fakten interessieren sich nicht für deine Gefühle" -Ben Shapiro',
    en: '"Facts don\'t care about your feelings" -Ben Shapiro',
  },
  {
    id: 484,
    category: "quotes",
    config: { familyFriendly: false },
    de: '"Schluck bitch, in Afrika verhungern Leute" -Bo Burnham',
    en: '"Swallow bitch, there\'s people starving in Africa." -Bo Burnham',
  },
  {
    id: 485,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Ein Zimmer ohne Bücher ist wie ein Körper ohne Seele" -Cicero',
    en: '"A room without books is like a body without a soul" -Cicero',
  },
  {
    id: 486,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Die industrielle Revolution und ihre Konsequenzen sind ein Disaster für die Menschheit" -Theodore Kaczynski',
    en: '"The Industrial Revolution and its consequences have been a disaster for the human race" -Theodore Kaczynski',
  },
  {
    id: 487,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Das unterhaltsamste Ergebnis ist das Wahrscheinlichste" -Elon Musk',
    en: '"The most entertaining outcome is the most likely" -Elon Musk',
  },
  {
    id: 488,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Ich wäre lieber tot als cool" -Kurt Cobain',
    en: '"I\'d rather be dead than cool" -Kurt Cobain',
  },
  {
    id: 489,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Ich bin keine Frau. Ich bin eine Naturgewalt" -Courtney Love',
    en: "\"I'm not a woman. I'm a force of nature\" -Courtney Love",
  },
  {
    id: 490,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Nicht jeder der wandert ist verloren" -Der Herr der Ringe',
    en: '"Not all those who wander are lost" -The Lord of the Rings',
  },
  {
    id: 491,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Obwohl du genug hast musst du den Kopf hochalten" -Tupac Shakur',
    en: '"Even though you\'re fed up, you got to keep ya head up" -Tupac Shakur',
  },
  {
    id: 492,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Ich will dass ihr euch so verhaltet als würde euer Haus brennt. Weil es das tut" -Greta Thunberg',
    en: '"I want you to act as if your house is on fire. Because it is" -Greta Thunberg',
  },
  {
    id: 493,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Bleib hungrig, bleib albern" -Steve Jobs',
    en: '"Stay hungry, stay foolish" -Steve Jobs',
  },
  {
    id: 494,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Hoffnung ist diese sture Sache in uns, die darauf besteht, dass uns trotz all den Anzeichen des Gegenteils etwas Besseres erwartet. " -Obama',
    en: '"Hope is that stubborn thing inside us that insists, despite all the evidence to the contrary, that something better awaits us" -Obama',
  },
  {
    id: 495,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Die Wahrheit ist, dass die meisten von uns herausfinden wo wir hinwollen wenn wir bereits angekommen sind" -Calvin & Hobbes',
    en: '"The truth is most of us discover where we are heading when we arrive"  -Calvin & Hobbes',
  },
  {
    id: 496,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"WIR WERDEN ES ALLE SCHAFFEN, BRUDIS" -Zyzz',
    en: '"WE\'RE ALL GONNA MAKE IT, BRAHS" -Zyzz',
  },
  {
    id: 497,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Der beste Rat den ich jemals bekommen habe war "Nimm keinen Rat an"." -deadmau5',
    en: '"The best advice I ever received was "don\'t take advice"." -deadmau5',
  },
  {
    id: 498,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Ein Mann versorgt. Und er tut dies auch wenn er nicht wertgeschätzt oder respektiert oder nichtmal geliebt wird." -Breaking Bad',
    en: '"A man provides. And he does it even when he\'s not appreciated or respected or even loved." -Breaking Bad',
  },
  {
    id: 499,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Ich habe nur Befehle befolgt" -Friedrich Engel',
    en: '"I was just following orders" -Friedrich Engel',
  },
  {
    id: 500,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Ich kann faule Menschen nicht verstehen. Wir sprechen nicht dieselbe Sprache" -Kobe Bryant',
    en: "\"I can't relate to lazy people. We don't speak the same language\" -Kobe Bryant",
  },
  {
    id: 501,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Jemand anderes Erfolg bedeutet nicht dass du gescheitert bist" -Joe Rogan',
    en: '"Someone else\'s success does not equal a failure for you" -Joe Rogan',
  },
  {
    id: 502,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Sei die Veränderung die du in der Welt sehen willst." -Gandhi',
    en: '"Be the change you want to see in the world" -"Gandhi"',
  },
  {
    id: 503,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Niemand gehört irgendwo hin. Wir werden alle sterben. Komm Ferngucken" -Rick & Morty',
    en: '"Nobody belongs anywhere. Everybody\'s gonna die. Come watch TV" -Rick & Morty',
  },
  {
    id: 504,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"sHE\'s brOKen" -Billie Eilish',
    en: '"sHE\'s brOKen" -Billie Eilish',
  },
  {
    id: 505,
    category: "quotes",
    config: { familyFriendly: true },
    de: '"Das ist für alle die gerade durch schlechte Zeiten gehen, glaubt mir ich war da..." -Pitbull',
    en: '"This for everybody going through tough times, believe me, been there, done that..."-Pitbull',
  },
  {
    id: 506,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du schwul seist",
    en: "That you're gay",
  },
  {
    id: 507,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du mal Sex mit einem lebendigen Bären hattest",
    en: "That you've had sex with a live bear",
  },
  {
    id: 508,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du mal zum Spaß einen Obdachlosen getötet hättest",
    en: "That you killed a homeless guy for fun",
  },
  {
    id: 509,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du dich in der Öffentlichkeit eingekackt hättest",
    en: "That you shit themselves in public",
  },
  {
    id: 510,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du in Wirklichkeit vom anderen Geschlecht bist",
    en: "That you're actually of the opposite gender ",
  },
  {
    id: 511,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du mal Drogen gedealt hast",
    en: "That you used to deal drugs",
  },
  {
    id: 512,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du regelmäßig ein Bordell besuchst",
    en: "That you go to the brothel regularly",
  },
  {
    id: 513,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du nicht gut kochen könntest",
    en: "That you can't cook well",
  },
  {
    id: 514,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass all dein Designer fake sei",
    en: "That your designer clothes are all fake",
  },
  {
    id: 515,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du deine Instagram Follower gekauft hättest",
    en: "That they bought their instagram followers",
  },
  {
    id: 516,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du Jungfrau seist",
    en: "That you are a virgin",
  },
  {
    id: 517,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du anfängst zu schwitzen wenn du horny bist",
    en: "That you start sweating when they're horny",
  },
  {
    id: 518,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du ein Lügner wärst",
    en: "That you are a compulsive liar",
  },
  {
    id: 519,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du ein Doppelleben mit einer anderen Familie führen würdest",
    en: "That you lead a double life with another family",
  },
  {
    id: 520,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du adoptiert seist",
    en: "That you are adopted",
  },
  {
    id: 521,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du große Schulden hättest",
    en: "That you are in huge debt",
  },
  {
    id: 522,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du bei deinen Examen geschummelt hättest",
    en: "That you cheated on their exams ",
  },
  {
    id: 523,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du nicht viel Alkohol vertragen würdest",
    en: "That you cant handle a lot of alcohol",
  },
  {
    id: 524,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du dich nicht kontrollieren könntest wenn du betrunken bist",
    en: "That you can not control themselves when drunk",
  },
  {
    id: 525,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du mal ein Kind missbraucht hättest",
    en: "That you molested a child",
  },
  {
    id: 526,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du mal im Gefängnis warst",
    en: "That you were in prison once",
  },
  {
    id: 527,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du rassistisch seist",
    en: "That you are racist",
  },
  {
    id: 528,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du BWL studieren würdest",
    en: "That you study business",
  },
  {
    id: 529,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du mit mehr als 20 Menschen Sex hattest",
    en: "That you had sex with more than twenty people",
  },
  {
    id: 530,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du nie Hände wäschst",
    en: "That you never wash hands",
  },
  {
    id: 531,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du ein Stalker seist",
    en: "That you are a stalker",
  },
  {
    id: 532,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du ein Internet Troll seist",
    en: "That you are an internet troll",
  },
  {
    id: 533,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du online Dating benutzt",
    en: "That you use online dating",
  },
  {
    id: 534,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du linksextrem gewählt hast",
    en: "That you voted far-left",
  },
  {
    id: 535,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du rechtsextrem gewählt hast",
    en: "That you voted far-right",
  },
  {
    id: 536,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du nicht duschst",
    en: "That you don't shower",
  },
  {
    id: 537,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du ein wilden Fußfetisch hättest",
    en: "That you have a raging foot fetish",
  },
  {
    id: 538,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du dir eine Frau aus Russland bestellen wolltest",
    en: "That you plan on buying a russian mail-in bride",
  },
  {
    id: 539,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du einen Hust-Fetisch hättest",
    en: "That you have a cough fetish",
  },
  {
    id: 540,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du einen Drogen-Markt im Darknet betreiben würdest",
    en: "That you run a drug market place in the darknet",
  },
  {
    id: 541,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du nicht mehr mit deinen Eltern reden würdest",
    en: "That you do not talk to your parents anymore",
  },
  {
    id: 542,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du in deinem Keller leben würdest weil sich deine Familie für dich schämen würde",
    en: "That you live in your basement because your family is ashamed of you",
  },
  {
    id: 543,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du obdachlos seist",
    en: "That you're homeless",
  },
  {
    id: 544,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du deperate af bist",
    en: "That you're desperate af",
  },
  {
    id: 545,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du broke af bist",
    en: "That you're broke af",
  },
  {
    id: 546,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du mit niemandem vom anderen Geschlecht Augenkontakt halten könntest",
    en: "That you cannot hold eye contact with a person of the opposite sex",
  },
  {
    id: 547,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du Kinder hassen würdest",
    en: "That you hate children",
  },
  {
    id: 548,
    category: "rumors",
    config: { familyFriendly: true },
    de: "Dass du deine Familienmitglieder auf den Mund küssen würdest",
    en: "That you open-mouth kiss your family members",
  },
  {
    id: 549,
    category: "rumors",
    config: { familyFriendly: false },
    de: "Dass du dich nicht rasieren würdest",
    en: "That you don't shave",
  },
  {
    id: 550,
    category: "children",
    config: { familyFriendly: true },
    de: "Umgang mit Linux Computern",
    en: "Being able to use linux",
  },
  {
    id: 551,
    category: "children",
    config: { familyFriendly: true },
    de: "Dass alle Menschen quasi Affen sind und vollkommen irrelevant für dein Leben",
    en: "That people are basically a monkeys and completely irrelevant for your life",
  },
  { id: 552, category: "children", config: { familyFriendly: true }, de: "Geduld", en: "Patience" },
  {
    id: 553,
    category: "children",
    config: { familyFriendly: true },
    de: "3 mal gucken bevor man die Straße überquert",
    en: "Looking both ways before crossing the street",
  },
  {
    id: 554,
    category: "children",
    config: { familyFriendly: true },
    de: "Körperlich stark werden",
    en: "Acquire a strong body",
  },
  {
    id: 555,
    category: "children",
    config: { familyFriendly: true },
    de: "Sich vegan ernähren ",
    en: "Eating vegan",
  },
  {
    id: 556,
    category: "children",
    config: { familyFriendly: true },
    de: "Richtig saufen",
    en: "Getting drunk",
  },
  {
    id: 557,
    category: "children",
    config: { familyFriendly: true },
    de: "Wie man Mädchen anspricht",
    en: "How to talk to girls",
  },
  {
    id: 558,
    category: "children",
    config: { familyFriendly: true },
    de: "Noble Tischmanieren",
    en: "noble table manners",
  },
  {
    id: 559,
    category: "children",
    config: { familyFriendly: true },
    de: "Mindestens eine Fremdprache",
    en: "Speaking at least one foreign language",
  },
  {
    id: 560,
    category: "children",
    config: { familyFriendly: true },
    de: "Sehr gut in der Schule zu sein",
    en: "Be very good at school",
  },
  { id: 561, category: "children", config: { familyFriendly: true }, de: "Glaube", en: "Faith" },
  {
    id: 562,
    category: "children",
    config: { familyFriendly: true },
    de: "Für sich selber aufstehen zu können",
    en: "Being able to stand up for themselves",
  },
  { id: 563, category: "children", config: { familyFriendly: true }, de: "Schach", en: "Chess" },
  {
    id: 564,
    category: "children",
    config: { familyFriendly: true },
    de: "Gut massieren können",
    en: "Being able to give good massages",
  },
  {
    id: 565,
    category: "children",
    config: { familyFriendly: true },
    de: "Empathie",
    en: "Empathy",
  },
  {
    id: 566,
    category: "children",
    config: { familyFriendly: true },
    de: "Vorsichtiger Umgang mit social media",
    en: "Being cautious with social media",
  },
  {
    id: 567,
    category: "children",
    config: { familyFriendly: true },
    de: "Verantwortung für seine Gesundheit zu übernehmen",
    en: "Taking on responsibility for their own health",
  },
  {
    id: 568,
    category: "children",
    config: { familyFriendly: true },
    de: "Wie man wen verprügelt",
    en: "How to beat someone up",
  },
  {
    id: 569,
    category: "children",
    config: { familyFriendly: true },
    de: "Tierliebe",
    en: "Love for Animals",
  },
  {
    id: 570,
    category: "children",
    config: { familyFriendly: true },
    de: "Reiselust",
    en: "Wanderlust",
  },
  {
    id: 571,
    category: "children",
    config: { familyFriendly: false },
    de: "Keine Angst davor zu haben neue Dinge auszuprobieren (sexuell)",
    en: "Not being afraid of trying new sexual stuff",
  },
  {
    id: 572,
    category: "children",
    config: { familyFriendly: true },
    de: "Loyalität zum Vaterland",
    en: "Loyalty to the motherland",
  },
  {
    id: 573,
    category: "children",
    config: { familyFriendly: true },
    de: "Liebe zum lokalen Fußballverein",
    en: "Love for the local Footballteam",
  },
  {
    id: 574,
    category: "children",
    config: { familyFriendly: true },
    de: "Ein Instrument spielen können",
    en: "Being able to play an Instrument",
  },
  {
    id: 575,
    category: "children",
    config: { familyFriendly: true },
    de: "Der Neugierde über Regeln hinweg folgen",
    en: "Follow ones curiosity beyond rules",
  },
  {
    id: 576,
    category: "children",
    config: { familyFriendly: true },
    de: "Ein Journal führen",
    en: "Journaling",
  },
  {
    id: 577,
    category: "children",
    config: { familyFriendly: true },
    de: "Meditation",
    en: "Meditation",
  },
  {
    id: 578,
    category: "children",
    config: { familyFriendly: true },
    de: "Persönliche Haushaltsführung",
    en: "Personal Finance",
  },
  {
    id: 579,
    category: "children",
    config: { familyFriendly: true },
    de: "Immer zu hinterfragen",
    en: "To always question",
  },
  {
    id: 580,
    category: "children",
    config: { familyFriendly: true },
    de: "Überzeugend lügen",
    en: "To lie convincingly",
  },
  {
    id: 581,
    category: "children",
    config: { familyFriendly: true },
    de: "Aktiv gegen den Klimawandel arbeiten",
    en: "To actively work against climate change",
  },
  {
    id: 582,
    category: "children",
    config: { familyFriendly: true },
    de: "Solidarität",
    en: "Solidarity",
  },
  {
    id: 583,
    category: "children",
    config: { familyFriendly: true },
    de: "Autos reparieren können",
    en: "Repair a car",
  },
  { id: 584, category: "children", config: { familyFriendly: true }, de: "Respekt", en: "Respect" },
  {
    id: 585,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Eine Schwangere Frau die Vodka trinkt",
    en: "A pregnant woman drinking vodka",
  },
  {
    id: 586,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Vater der sein Kind schlägt",
    en: "A father hitting their child",
  },
  {
    id: 587,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Ein 28 jähriger der aggressiv eine 14-jährige anbaggert",
    en: "A 28 year old dude aggresively hitting on a 14 year old",
  },
  {
    id: 588,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Ein Mädchen dass ohne BH rumläuft",
    en: "A girl walking around without a bra",
  },
  {
    id: 589,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein paar Kids die Fortnite Tänze machen",
    en: "A bunch of kids doing fortnite dances",
  },
  {
    id: 590,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Typ der einen streunenden Hund tritt",
    en: "A guy kicking a stray dog",
  },
  {
    id: 591,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Mann der seiner Frau verbietet in der Öffentlichkeit zu sprechen ",
    en: "A man forbidding his wife to speak in public",
  },
  {
    id: 592,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Eine Gruppe Jugendliche lässt ihren Müll auf der Parkbank liegen",
    en: "A group of juveniles leaving their trash on the park bench",
  },
  {
    id: 593,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Kind wird von einem unangeleintem Hund im Park gebissen",
    en: "A child being bitten by an unleashed dog at the park",
  },
  {
    id: 594,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Teenager der im Supermarkt Alkohol klaut",
    en: "A teen stealing liquor at the supermarket",
  },
  {
    id: 595,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Priester der auf dem Marktplatz steht predigt den Leuten dass sie alle Sündner sind",
    en: "A priest standing at the market, preaching to people that they are all sinners",
  },
  {
    id: 596,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Kind wird gezwungen in die Kirche zu gehen",
    en: "A child being forced to go to church",
  },
  {
    id: 597,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Ein Typ der im vorbeigehen Fotos unter den Röcken von Mädchen macht",
    en: "A guy taking pictures beneath womens skirts while passing them",
  },
  {
    id: 598,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Typ der sich auszieht und auf alle seine Muskeln flext",
    en: "A guy taking off his shirt and flexing his muscles",
  },
  {
    id: 599,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Jemand wird von seiner Freundesgruppe gezwungen Alkohol zu trinken",
    en: "Someone being forced to drink by their friend group",
  },
  {
    id: 600,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Sportwagenfahrer rast über eine belebte Kreuzung obwohl er rot hat",
    en: "A sportscar driving over a red light at a busy crossing",
  },
  {
    id: 601,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Kinder die Gras rauchen",
    en: "Kids smoking weed",
  },
  {
    id: 602,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Jemandem wird aufgrund seiner Rasse verboten ein Geschäft zu betreten",
    en: "A store owner refuses to let somone in because of their race",
  },
  {
    id: 603,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Jemand sagt der laut einen Holocaust Witz",
    en: "Someone cracks a joke about the holocaust very loudly",
  },
  {
    id: 604,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Ein Mann macht einer Frau einen Antrag aber sie tritt ihm in die Eier und geht",
    en: "A guy proposes to a woman but she just kicks him into the balls and leaves",
  },
  {
    id: 605,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: 'Ein Typ gießt Bleichmittel in den Dorfbrunnen als "Streich"',
    en: 'Some guy pours bleach into the villages fountain as a "prank"',
  },
  {
    id: 606,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Jugendlicher weigert sich für eine alte Person in der Bahn aufzustehen",
    en: "A juvenile refuses to get up for an elderly person in the tram",
  },
  {
    id: 607,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Ein Typ pinkelt einem Obdachlosen in den Bettelbecher",
    en: "A guy pissing into the begging cup of a homeless guy",
  },
  {
    id: 608,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Jemand hat Sex vor der Ehe",
    en: "Someone having premarital sex",
  },
  {
    id: 609,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Jemand wird verhaftet fürs betrunkene Autofahren",
    en: "Someone is being arrested for being a drunk driver",
  },
  {
    id: 610,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Jemand stellt sein seinen Einkaufswagen nicht zurück",
    en: "Someone does not put back their shopping cart",
  },
  {
    id: 611,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Eine 40-Jahre alte Frau schreit einen Teenager am McDonalds Schalter an weil dieser die Bestellung verhauen hat",
    en: "A 40 year old woman screaming at a teen working at the McDonalds counter for messing up her order",
  },
  {
    id: 612,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Paar kinder klauen einem Blinden den Blindenstock",
    en: "A few kids steal the cane of a blind person",
  },
  {
    id: 613,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Paar Typen schubsen einen Schwulen herum",
    en: "A few guys push around a gay guy",
  },
  {
    id: 614,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Moslem reisst ein öffentliches Plakat auf dem Mohammed dargestellt ist von der Wand",
    en: "A muslim tears down a public poster depicting prophet mohammed",
  },
  {
    id: 615,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Jemand verbrennt die Flagge deines Landes",
    en: "Somebody burning the flag of your country",
  },
  {
    id: 616,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein kleiner Rapper rappt auf der Bühne aber ein Paar Leute in der ersten Reihe buen ihn aus",
    en: "A small local rapper is rapping onstage but a few guys in the first row are booing him",
  },
  {
    id: 617,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Jemand macht ein Selfie vor einem Holocaustdenkmal",
    en: "Somebody taking a selfie in front of holocaust memorial",
  },
  {
    id: 618,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Jemand sagt in der Öffentlichkeit dass es deinen Gott nicht gäbe",
    en: 'Somebdy calls your god "not real" in public',
  },
  {
    id: 619,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Politische Aktivisten blockieren den Verkehr um Aufmerksamkeit für ihr Thema zu schaffen",
    en: "Political activists block traffic to raise awareness for their topic",
  },
  {
    id: 620,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Mann verkauft auf dem Schulhof Drogen",
    en: "A man sells drugs at the school yard",
  },
  {
    id: 621,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Typ raucht auf dem Kinderspielplatz",
    en: "A guy smoking on a childrens playground",
  },
  {
    id: 622,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: "Ein Mädchen flext mit Fake Designer",
    en: "A girl flexing with fake designer",
  },
  {
    id: 623,
    category: "badRoadside",
    config: { familyFriendly: true },
    de: 'Ein Typ geht zu einem Mädchen auf einer Party und sagt: "Hey, lach doch mal"',
    en: 'A guy walks up to a girl at a party and says: "Hey, you should smile!"',
  },
  {
    id: 624,
    category: "badRoadside",
    config: { familyFriendly: false },
    de: "Jemand läuft in einer Nazi Uniform herum",
    en: "Someone walking around in a nazi uniform",
  },
  {
    id: 625,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Strandhaus in Malibu",
    en: "A beachhouse in Malibu",
  },
  {
    id: 626,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Lamborghini",
    en: "A Lamborghini",
  },
  {
    id: 627,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Die Mona Lisa",
    en: "The Mona Lisa",
  },
  {
    id: 628,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Kreuzfahrtschiff",
    en: "A cruise ship",
  },
  {
    id: 629,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein endloses Visum für ein Land deiner Wahl",
    en: "An unlimited visa for a country of your choice",
  },
  {
    id: 630,
    category: "posessions",
    config: { familyFriendly: true },
    de: 'Das Schwert "Excalibur"',
    en: 'The sword "Excalibur"',
  },
  {
    id: 631,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Die Unabhängigkeitserklärung (original)",
    en: "The original declaration of independence",
  },
  {
    id: 632,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine Eigentumsurkunde für das Land auf dem Jerusalem liegt",
    en: "A cerrificate of ownership of the land Jerusalem is situated on",
  },
  {
    id: 633,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Einen schwarzen BMW",
    en: "A black BMW",
  },
  {
    id: 634,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine E-Gitarre mit Flammen drauf",
    en: "An electric guitar with flames on it",
  },
  {
    id: 635,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Pony",
    en: "A Pony",
  },
  {
    id: 636,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Mammut",
    en: "A Mammooth",
  },
  {
    id: 637,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine Froschfarm",
    en: "A frog-farm",
  },
  {
    id: 638,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Gucci Outfit",
    en: "A Gucci Outfit",
  },
  {
    id: 639,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Steven Hawkins Rollstuhl",
    en: "Steven Hawkins Wheelchair",
  },
  {
    id: 640,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Einen Privatjet",
    en: "A private Jet",
  },
  {
    id: 641,
    category: "posessions",
    config: { familyFriendly: false },
    de: "Einen Sklaven",
    en: "A slave",
  },
  {
    id: 642,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine Penispumpe",
    en: "A penis pump",
  },
  {
    id: 643,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine Insel in der Karibik",
    en: "An island in the carribean",
  },
  {
    id: 644,
    category: "posessions",
    config: { familyFriendly: true },
    de: "20kg Heroin",
    en: "40 pounds of heroin",
  },
  {
    id: 645,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Einen Shiba Inu Hund",
    en: "A Shiba Inu Dog",
  },
  {
    id: 646,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Einen Quanten-Computer",
    en: "A quantum computer",
  },
  {
    id: 647,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine dicke Machete",
    en: "A thick machete",
  },
  {
    id: 648,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein iPhone auf dem Flappy Bird installiert ist",
    en: "An iPhone with Flappy Bird installed",
  },
  {
    id: 649,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein neues MacBook",
    en: "A new MacBook",
  },
  {
    id: 650,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Waffeleisen, das 3 Waffeln parallel machen kann",
    en: "A waffle maker which can make 3 waffles at parallel",
  },
  {
    id: 651,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Fake Jordans",
    en: "Fake Jordans",
  },
  {
    id: 652,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine Nvidia geforce 3080 gtx Grafikkarte",
    en: "An Nvidia geforce 3080 gtx graphics card",
  },
  {
    id: 653,
    category: "posessions",
    config: { familyFriendly: true },
    de: 'Ein "Bored Ape" NFT ',
    en: 'A "Bored Ape" NFT',
  },
  {
    id: 654,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein eigenes Museum über einen selber",
    en: "Their own museum about themselves",
  },
  {
    id: 655,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Harem",
    en: "A Harem",
  },
  {
    id: 656,
    category: "posessions",
    config: { familyFriendly: true },
    de: "1000 Kokosnüsse",
    en: "1000 Coconuts",
  },
  {
    id: 657,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Den Chelsea Fussball Club",
    en: "The Chelsea Soccer Club",
  },
  {
    id: 658,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine Penthouse Wohnung in Manhatten",
    en: "A Penthouse apartment in Manhatten",
  },
  {
    id: 659,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Professionelle Pinsel und Farben",
    en: "Professional Brushes and Paint",
  },
  {
    id: 660,
    category: "posessions",
    config: { familyFriendly: true },
    de: 'Die Website "rank5.io"',
    en: 'The website "rank5.io"',
  },
  {
    id: 661,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine Designer Couch",
    en: "A designer couch",
  },
  {
    id: 662,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Subwoofer der so groß ist wie ein Kleinwagen",
    en: "A subwoofer the size of a small car",
  },
  {
    id: 663,
    category: "posessions",
    config: { familyFriendly: true },
    de: "1 kg Gras",
    en: "2 Pounds of weed",
  },
  {
    id: 664,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine Flasche Grey Goose",
    en: "A bottle of grey goose",
  },
  {
    id: 665,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine Rolex",
    en: "A Rolex",
  },
  {
    id: 666,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Einen Wald",
    en: "A forest",
  },
  {
    id: 667,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Eine Achterbahn",
    en: "A rollercoaster",
  },
  {
    id: 668,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Spaceshuttle",
    en: "A spaceshuttle",
  },
  {
    id: 669,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Einen 3d-Drucker",
    en: "A 3d printer",
  },
  {
    id: 670,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein bewusster, empfindungsfähiger Roboter",
    en: "A sentient robot",
  },
  {
    id: 671,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Welpe",
    en: "A puppy",
  },
  {
    id: 672,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Einen 2m Wolf der auf einen Hört",
    en: "A 2m tall wolf who listens to you",
  },
  {
    id: 673,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Heim-Fitnessstudio",
    en: "A homegym",
  },
  {
    id: 674,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Haustier-Stein",
    en: "A pet rock",
  },
  {
    id: 675,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein undendlicher Lagerbestand an Zigaretten",
    en: "An infinte supply of cigarettes",
  },
  {
    id: 676,
    category: "posessions",
    config: { familyFriendly: false },
    de: "Eine echte Anime-Freundin",
    en: "A real anime girlfriend",
  },
  {
    id: 677,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Obdachlosenheim",
    en: "A homeless shelter",
  },
  {
    id: 678,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein 15m hoher Flaggenpfal mit der Flagge deines Landes",
    en: "A 15m tall flagpole with the flag of your country on it",
  },
  {
    id: 679,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Astronautenanzug",
    en: "An astronaut suit",
  },
  {
    id: 680,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Schwimmbad",
    en: "A swimming pool",
  },
  {
    id: 681,
    category: "posessions",
    config: { familyFriendly: false },
    de: "Ein echtes Laserschwert",
    en: "A real lightsaber",
  },
  {
    id: 682,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Das Gegenmittel zu AIDS",
    en: "The cure for AIDS",
  },
  {
    id: 683,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Apache Kampfhubschrauber",
    en: "An Apache attack helicopter",
  },
  {
    id: 684,
    category: "posessions",
    config: { familyFriendly: false },
    de: "Eine Fotowand von jeder Person mit der du jemals Sex hattest",
    en: "A photo wall with pictures of everyone you ever had sex with",
  },
  {
    id: 685,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Anime Bodypillow",
    en: "An anime bodypillow",
  },
  {
    id: 686,
    category: "posessions",
    config: { familyFriendly: true },
    de: "Ein Einhorn",
    en: "A unicorn",
  },
  {
    id: 687,
    category: "sports",
    config: { familyFriendly: true },
    de: "Beer Pong",
    en: "Beer Pong",
  },
  { id: 688, category: "sports", config: { familyFriendly: true }, de: "Lacrosse", en: "Lacrosse" },
  {
    id: 689,
    category: "sports",
    config: { familyFriendly: true },
    de: "Pferdepolo",
    en: "Horseback polo",
  },
  {
    id: 690,
    category: "sports",
    config: { familyFriendly: true },
    de: "Schwimmen",
    en: "Swimming",
  },
  {
    id: 691,
    category: "sports",
    config: { familyFriendly: true },
    de: "Basketball",
    en: "Basketball",
  },
  {
    id: 692,
    category: "sports",
    config: { familyFriendly: true },
    de: "Fußball",
    en: "Football (for the foot)",
  },
  { id: 693, category: "sports", config: { familyFriendly: true }, de: "Lasertag", en: "Lasertag" },
  {
    id: 694,
    category: "sports",
    config: { familyFriendly: true },
    de: "Leichtathletik",
    en: "Track & Field",
  },
  {
    id: 695,
    category: "sports",
    config: { familyFriendly: true },
    de: "American Football",
    en: "American Football",
  },
  {
    id: 696,
    category: "sports",
    config: { familyFriendly: true },
    de: "Skispringen",
    en: "Ski jump",
  },
  {
    id: 697,
    category: "sports",
    config: { familyFriendly: true },
    de: "Eishockey",
    en: "Ice hockey",
  },
  { id: 698, category: "sports", config: { familyFriendly: true }, de: "Capoeira", en: "Capoeira" },
  { id: 699, category: "sports", config: { familyFriendly: true }, de: "Karate", en: "Karate" },
  { id: 700, category: "sports", config: { familyFriendly: true }, de: "Boxen", en: "Boxing" },
  {
    id: 701,
    category: "sports",
    config: { familyFriendly: true },
    de: "Ringen",
    en: "Olympic Wrestling",
  },
  {
    id: 702,
    category: "sports",
    config: { familyFriendly: true },
    de: "Gewichtheben",
    en: "Weightlifting",
  },
  {
    id: 703,
    category: "sports",
    config: { familyFriendly: true },
    de: "Tischtennis",
    en: "Tennis",
  },
  { id: 704, category: "sports", config: { familyFriendly: true }, de: "e-Sports", en: "e-Sports" },
  {
    id: 705,
    category: "sports",
    config: { familyFriendly: true },
    de: "Badminton",
    en: "Badminton",
  },
  { id: 706, category: "sports", config: { familyFriendly: true }, de: "Cricket", en: "Cricket" },
  { id: 707, category: "sports", config: { familyFriendly: true }, de: "Baseball", en: "Baseball" },
  { id: 708, category: "sports", config: { familyFriendly: true }, de: "Golf", en: "Golf" },
  {
    id: 709,
    category: "sports",
    config: { familyFriendly: true },
    de: "Volleyball",
    en: "Volleyball",
  },
  { id: 710, category: "sports", config: { familyFriendly: true }, de: "Handball", en: "Handball" },
  {
    id: 711,
    category: "sports",
    config: { familyFriendly: true },
    de: "Bogenschießen",
    en: "Archery",
  },
  {
    id: 712,
    category: "sports",
    config: { familyFriendly: true },
    de: "Skateboarden",
    en: "Skateboarding",
  },
  { id: 713, category: "sports", config: { familyFriendly: true }, de: "Klettern", en: "Climbing" },
  { id: 714, category: "sports", config: { familyFriendly: true }, de: "Segeln", en: "Sailing" },
  { id: 715, category: "sports", config: { familyFriendly: true }, de: "Paddeln", en: "Paddeling" },
  { id: 716, category: "sports", config: { familyFriendly: true }, de: "Rudern", en: "Rowing" },
  { id: 717, category: "sports", config: { familyFriendly: true }, de: "BMX Bike", en: "BMX Bike" },
  { id: 718, category: "sports", config: { familyFriendly: true }, de: "Turnen", en: "Gymnastics" },
  { id: 719, category: "sports", config: { familyFriendly: true }, de: "Curling", en: "Curling" },
  {
    id: 720,
    category: "sports",
    config: { familyFriendly: true },
    de: "Eislaufen",
    en: "Figure skating",
  },
  {
    id: 721,
    category: "sports",
    config: { familyFriendly: true },
    de: "Rennradfahren",
    en: "Road cycling",
  },
  {
    id: 722,
    category: "sports",
    config: { familyFriendly: true },
    de: "Mointainbiking",
    en: "Mountainbiking",
  },
  {
    id: 723,
    category: "sports",
    config: { familyFriendly: true },
    de: "Ultimate Frisbee",
    en: "Ultimate Frisbee",
  },
  {
    id: 724,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇦🇫 Afghanistan 🇦🇫",
    en: "🇦🇫  Afghanistan 🇦🇫",
  },
  {
    id: 725,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇦🇱 Albanien 🇦🇱",
    en: "🇦🇱  Albania 🇦🇱",
  },
  {
    id: 726,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇦🇶 Antarktis 🇦🇶",
    en: "🇦🇶  Antarctica 🇦🇶",
  },
  {
    id: 727,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇦🇷 Argentinien 🇦🇷",
    en: "🇦🇷  Argentina 🇦🇷",
  },
  {
    id: 728,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇦🇹 Österreich 🇦🇹",
    en: "🇦🇹  Austria 🇦🇹",
  },
  {
    id: 729,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇦🇺 Australien 🇦🇺",
    en: "🇦🇺  Australia 🇦🇺",
  },
  {
    id: 730,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇧🇩 Bangladesch 🇧🇩",
    en: "🇧🇩  Bangladesh 🇧🇩",
  },
  {
    id: 731,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇧🇪 Belgien 🇧🇪",
    en: "🇧🇪  Belgium 🇧🇪",
  },
  {
    id: 732,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇧🇬 Bulgarien 🇧🇬",
    en: "🇧🇬  Bulgaria 🇧🇬",
  },
  {
    id: 733,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇧🇴 Bolivien 🇧🇴",
    en: "🇧🇴  Bolivia 🇧🇴",
  },
  {
    id: 734,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇧🇷 Brasilien 🇧🇷",
    en: "🇧🇷  Brazil 🇧🇷",
  },
  {
    id: 735,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇨🇦 Kanada 🇨🇦",
    en: "🇨🇦  Canada 🇨🇦",
  },
  {
    id: 736,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇨🇭 Schweiz 🇨🇭",
    en: "🇨🇭  Switzerland 🇨🇭",
  },
  {
    id: 737,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇨🇱 Chile 🇨🇱",
    en: "🇨🇱  Chile 🇨🇱",
  },
  {
    id: 738,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇨🇳 China 🇨🇳",
    en: "🇨🇳  China 🇨🇳",
  },
  {
    id: 739,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇨🇴 Kolumbien 🇨🇴",
    en: "🇨🇴  Colombia 🇨🇴",
  },
  {
    id: 740,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇨🇷 Costa Rica 🇨🇷",
    en: "🇨🇷  Costa Rica 🇨🇷",
  },
  {
    id: 741,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇨🇺 Kuba 🇨🇺",
    en: "🇨🇺  Cuba 🇨🇺",
  },
  {
    id: 742,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇩🇪 Deutschland 🇩🇪",
    en: "🇩🇪  Germany 🇩🇪",
  },
  {
    id: 743,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇩🇰 Dänemark 🇩🇰",
    en: "🇩🇰  Denmark 🇩🇰",
  },
  {
    id: 744,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇩🇿 Algerien 🇩🇿",
    en: "🇩🇿  Algeria 🇩🇿",
  },
  {
    id: 745,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇪🇬 Ägypten 🇪🇬",
    en: "🇪🇬  Egypt 🇪🇬",
  },
  {
    id: 746,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇪🇸 Spanien 🇪🇸",
    en: "🇪🇸  Spain 🇪🇸",
  },
  {
    id: 747,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇫🇮 Finnland 🇫🇮",
    en: "🇫🇮  Finland 🇫🇮",
  },
  {
    id: 748,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇫🇯 fiji 🇫🇯",
    en: "🇫🇯  Fiji 🇫🇯",
  },
  {
    id: 749,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇫🇷 Frankreich 🇫🇷",
    en: "🇫🇷  France 🇫🇷",
  },
  {
    id: 750,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇬🇧 Vereinigtes Königreich 🇬🇧",
    en: "🇬🇧  United Kingdom 🇬🇧",
  },
  {
    id: 751,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇬🇷 Griechenland 🇬🇷",
    en: "🇬🇷  Greece 🇬🇷",
  },
  {
    id: 752,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇭🇷 Kroatien 🇭🇷",
    en: "🇭🇷  Croatia 🇭🇷",
  },
  {
    id: 753,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇭🇺 Ungarn 🇭🇺",
    en: "🇭🇺  Hungary 🇭🇺",
  },
  {
    id: 754,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇮🇩 Indonesien 🇮🇩",
    en: "🇮🇩  Indonesia 🇮🇩",
  },
  {
    id: 755,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇮🇪 Irland 🇮🇪",
    en: "🇮🇪  Ireland 🇮🇪",
  },
  {
    id: 756,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇮🇱 Israel 🇮🇱",
    en: "🇮🇱  Israel 🇮🇱",
  },
  {
    id: 757,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇮🇳 Indien 🇮🇳",
    en: "🇮🇳  India 🇮🇳",
  },
  {
    id: 758,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇮🇷 Iran 🇮🇷",
    en: "🇮🇷  Iran 🇮🇷",
  },
  {
    id: 759,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇮🇸 Island 🇮🇸",
    en: "🇮🇸  Iceland 🇮🇸",
  },
  {
    id: 760,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇮🇹 Italien 🇮🇹",
    en: "🇮🇹  Italy 🇮🇹",
  },
  {
    id: 761,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇯🇲 Jamaika 🇯🇲",
    en: "🇯🇲  Jamaica 🇯🇲",
  },
  {
    id: 762,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇯🇵 Japan 🇯🇵",
    en: "🇯🇵  Japan 🇯🇵",
  },
  {
    id: 763,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇰🇪 Kenia 🇰🇪",
    en: "🇰🇪  Kenya 🇰🇪",
  },
  {
    id: 764,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇰🇭 Kambodscha 🇰🇭",
    en: "🇰🇭  Cambodia 🇰🇭",
  },
  {
    id: 765,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇰🇵 Nordkorea 🇰🇵",
    en: "🇰🇵  North Korea 🇰🇵",
  },
  {
    id: 766,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇰🇷 Südkorea 🇰🇷",
    en: "🇰🇷  South Korea 🇰🇷",
  },
  {
    id: 767,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇰🇿 Kasakhstan 🇰🇿",
    en: "🇰🇿  Kazakhstan 🇰🇿",
  },
  {
    id: 768,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇱🇰 Sri Lanka 🇱🇰",
    en: "🇱🇰  Sri Lanka 🇱🇰",
  },
  {
    id: 769,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇱🇾 Libyen 🇱🇾",
    en: "🇱🇾  Libya 🇱🇾",
  },
  {
    id: 770,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇲🇦 Marokko 🇲🇦",
    en: "🇲🇦  Morocco 🇲🇦",
  },
  {
    id: 771,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇲🇬 Madagaskar 🇲🇬",
    en: "🇲🇬  Madagascar 🇲🇬",
  },
  {
    id: 772,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇲🇲 Myanmar (Burma) 🇲🇲",
    en: "🇲🇲  Myanmar (Burma) 🇲🇲",
  },
  {
    id: 773,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇲🇳 Mongolei 🇲🇳",
    en: "🇲🇳  Mongolia 🇲🇳",
  },
  {
    id: 774,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇲🇻 Malediven 🇲🇻",
    en: "🇲🇻  Maldives 🇲🇻",
  },
  {
    id: 775,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇲🇽 Mexiko 🇲🇽",
    en: "🇲🇽  Mexico 🇲🇽",
  },
  {
    id: 776,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇲🇾 Malaysia 🇲🇾",
    en: "🇲🇾  Malaysia 🇲🇾",
  },
  {
    id: 777,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇳🇬 Nigeria 🇳🇬",
    en: "🇳🇬  Nigeria 🇳🇬",
  },
  {
    id: 778,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇳🇱 Niederlande 🇳🇱",
    en: "🇳🇱  Netherlands 🇳🇱",
  },
  {
    id: 779,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇳🇴 Norwegen 🇳🇴",
    en: "🇳🇴  Norway 🇳🇴",
  },
  {
    id: 780,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇳🇵 Nepal 🇳🇵",
    en: "🇳🇵  Nepal 🇳🇵",
  },
  {
    id: 781,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇳🇿 Neuseeland 🇳🇿",
    en: "🇳🇿  New Zealand 🇳🇿",
  },
  {
    id: 782,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇵🇪 Peru 🇵🇪",
    en: "🇵🇪  Peru 🇵🇪",
  },
  {
    id: 783,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇵🇭 Philippinen 🇵🇭",
    en: "🇵🇭  Philippines 🇵🇭",
  },
  {
    id: 784,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇵🇰 Pakistan 🇵🇰",
    en: "🇵🇰  Pakistan 🇵🇰",
  },
  {
    id: 785,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇵🇱 Polen 🇵🇱",
    en: "🇵🇱  Poland 🇵🇱",
  },
  {
    id: 786,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇵🇸 Palästina 🇵🇸",
    en: "🇵🇸  Palestine 🇵🇸",
  },
  {
    id: 787,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇵🇹 Portugal 🇵🇹",
    en: "🇵🇹Portugal 🇵🇹",
  },
  {
    id: 788,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇷🇴 Rumänien 🇷🇴",
    en: "🇷🇴  Romania 🇷🇴",
  },
  {
    id: 789,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇷🇺 Russland 🇷🇺",
    en: "🇷🇺  Russia 🇷🇺",
  },
  {
    id: 790,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇸🇦 Saudi -Arabien 🇸🇦 🇸🇦",
    en: "🇸🇦  Saudi Arabia 🇸🇦",
  },
  {
    id: 791,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇸🇪 Schweden 🇸🇪",
    en: "🇸🇪  Sweden 🇸🇪",
  },
  {
    id: 792,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇸🇬 Singapur 🇸🇬",
    en: "🇸🇬  Singapore 🇸🇬",
  },
  {
    id: 793,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇸🇴 Somalia 🇸🇴",
    en: "🇸🇴  Somalia 🇸🇴",
  },
  {
    id: 794,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇸🇾 Syrien 🇸🇾",
    en: "🇸🇾  Syria 🇸🇾",
  },
  {
    id: 795,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇹🇭 Thailand 🇹🇭",
    en: "🇹🇭  Thailand 🇹🇭",
  },
  {
    id: 796,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇹🇷 Türkei 🇹🇷",
    en: "🇹🇷  Turkey 🇹🇷",
  },
  {
    id: 797,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇹🇼 Taiwan 🇹🇼",
    en: "🇹🇼  Taiwan 🇹🇼",
  },
  {
    id: 798,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇺🇦 Ukraine 🇺🇦",
    en: "🇺🇦  Ukraine 🇺🇦",
  },
  {
    id: 799,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇺🇬 Uganda 🇺🇬",
    en: "🇺🇬  Uganda 🇺🇬",
  },
  {
    id: 800,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇺🇸 Vereinigte Staaten 🇺🇸",
    en: "🇺🇸  United States 🇺🇸",
  },
  {
    id: 801,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇻🇦 Vatikanstadt 🇻🇦 🇻🇦",
    en: "🇻🇦  Vatican City 🇻🇦",
  },
  {
    id: 802,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇻🇪 Venezuela 🇻🇪",
    en: "🇻🇪  Venezuela 🇻🇪",
  },
  {
    id: 803,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇻🇳 Vietnam 🇻🇳",
    en: "🇻🇳  Vietnam 🇻🇳",
  },
  {
    id: 804,
    category: "countries",
    config: { familyFriendly: true },
    de: "🇿🇦 Südafrika 🇿🇦",
    en: "🇿🇦  South Africa 🇿🇦",
  },
];
module.exports = {
  answers,
};

import CSS from "csstype"
import "./PlayerTab.css"
import "./../../index.css"
import { BsPersonCircle } from 'react-icons/bs'
import clsx from "clsx"

export interface PlayerTabProps{
    name:String;
    displayScore: boolean;
    score?:number;
    width?:number;
    style?:CSS.Properties;
}

export default function PlayerTab(props: PlayerTabProps){
    const delay = Math.random()*5
    return(
        <div className={"player raise-on-hover"} style={{...props.style, animationDelay:delay.toString(), width:props.width+"vw"}}>
            <span className="tabName">{props.name}</span>{props.displayScore?<span className="tabScore">{props.score}</span>:<></>}
        </div>
    )
}
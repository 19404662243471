import React, { useContext, useEffect, useRef, useState } from "react";

import Dialog from "../../components/Dialog/Dialog";
import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import QuestionHeader from "../../components/QuestionHeader/QuestionHeader";

import Feedback from "../../shared/api/FeedbackApi";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CasinoIcon from "@mui/icons-material/Casino";
import Paper from "@mui/material/Paper";
import { GameContext } from "../../shared/GameContext";
import _ from "underscore";
import { Box, Grid, useTheme } from "@mui/material";
import Background from "../../shared/img/abstractbackground.webp";
import LobbyApi from "../../shared/api/LobbyApi";
import { getRandomPlayerName } from "../../shared/helper/functions";
import useGameContext from "../../shared/useGameContext";

export default function Start() {
  const history = useHistory();
  const [name, setName] = useState("");

  const [enteringCode, setEnteringCode] = useState(false);
  const [codeLetter1, setCodeLetter1] = useState("");
  const [codeLetter2, setCodeLetter2] = useState("");
  const [codeLetter3, setCodeLetter3] = useState("");
  const [codeLetter4, setCodeLetter4] = useState("");
  const [codeLetter5, setCodeLetter5] = useState("");
  const [justDeletedCharacter, setJustDeletedCharacter] = useState(true);
  const [invalidName, setInvalidName] = useState(false);
  const setLobbyKey = useGameContext().setLobbyKey;
  const nameInputEl = useRef<HTMLInputElement>(null);
  const inputEl1 = useRef<HTMLInputElement>(null);
  const inputEl2 = useRef<HTMLInputElement>(null);
  const inputEl3 = useRef<HTMLInputElement>(null);
  const inputEl4 = useRef<HTMLInputElement>(null);
  const inputEl5 = useRef<HTMLInputElement>(null);
  const [invalidLobbyKey, setInvalidLobbyKey] = useState(false);

  const handleCodeLetterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setCodeLetter: (codeLetter: string) => void
  ) => {
    setCodeLetter(event.target.value.toUpperCase());
  };

  useEffect(() => {
    if (codeLetter1.length !== 0) {
      inputEl2.current?.focus();
    } else {
      setJustDeletedCharacter(true);
    }
  }, [codeLetter1]);

  useEffect(() => {
    if (codeLetter2.length === 0) {
      setJustDeletedCharacter(true);
    } else {
      inputEl3.current?.focus();
    }
  }, [codeLetter2]);

  useEffect(() => {
    if (codeLetter3.length === 0) {
      setJustDeletedCharacter(true);
    } else {
      inputEl4.current?.focus();
    }
  }, [codeLetter3]);

  useEffect(() => {
    if (codeLetter4.length === 0) {
      setJustDeletedCharacter(true);
    } else {
      inputEl5.current?.focus();
    }
  }, [codeLetter4]);

  useEffect(() => {
    if (codeLetter4.length === 0) {
      setJustDeletedCharacter(true);
    }
  }, [codeLetter5]);

  const [globalName, setGlobalName] = [
    useContext(GameContext).nameState,
    useContext(GameContext).setNameState,
  ];

  const DiceButton = () => {
    return (
      <IconButton
        onClick={() => {
          setName(getRandomPlayerName());
          
        }}
      >
        <CasinoIcon />
      </IconButton>
    );
  };

  const handleExitCodeInput = () => {
    setCodeLetter1("");
    setCodeLetter2("");
    setCodeLetter3("");
    setCodeLetter4("");
    setCodeLetter5("");
    setEnteringCode(false);
  };
  const checkIfPlayerNameValid = () => {
    if (name.trim().length === 0) {
      setInvalidName(false);
      nameInputEl.current?.focus();
      return false;
    } else {
      setGlobalName(name);
      return true;
    }
  };
  const handleStartJoin = () => {
    if (checkIfPlayerNameValid()) {
      setEnteringCode(true);
    }
  };

  const handleFinishJoin = async () => {
    let lobbyKey = codeLetter1 + codeLetter2 + codeLetter3 + codeLetter4 + codeLetter5;
    console.log("key: " + lobbyKey);
    if (lobbyKey.length === 5) {
      let canJoin = (await LobbyApi.checkLobbyKey(lobbyKey)).canJoin;
      console.log("canJoin: " + canJoin);
      if (canJoin) {
        setLobbyKey(lobbyKey);
        history.push("/lobby");
      } else {
        setInvalidLobbyKey(true);
        setTimeout(() => {
          if (invalidLobbyKey) {
            setInvalidLobbyKey(false);
          }
        }, 1200);
      }
    }
  };

  const handleCreateNewLobby = async () => {
    if (checkIfPlayerNameValid()) {
      let newLobbyKey = await LobbyApi.newLobby();
      if (newLobbyKey) {
        setLobbyKey(newLobbyKey);
        history.push("/lobby");
      }
    }
  };

  const handleCodeLetterChangeOnKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    previousLetterRef: React.RefObject<HTMLInputElement>
  ) => {
    if (e.key == "Backspace" && !justDeletedCharacter) {
      previousLetterRef.current?.focus();
      setJustDeletedCharacter(false);
    } else if (e.key == "Enter") {
      console.log("executing finishJoin ");
      handleFinishJoin();
    }
  };

  const handleCodeLetterChangeOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key == "Backspace") {
      setJustDeletedCharacter(false);
    }
  };

  return (
    <Stack
      alignItems="center"
      sx={{ backgroundImage: "url(" + Background + ")", backgroundSize: "cover", height: "100vh" }}
      spacing={2}
    >
      <Stack
        direction="column"
        spacing={2}
        justifyContent="flex-start"
        paddingTop={10}
        alignItems="center"
      >
        <FrontPageLogo></FrontPageLogo>
        {enteringCode ? (
          <Stack spacing={3}>
            <Stack direction="row" spacing={1}>
              <TextField
                value={codeLetter1}
                onChange={(e) => {
                  handleCodeLetterChange(e, setCodeLetter1);
                }}
                sx={{ width: "3em", height: "3em" }}
                inputProps={{ ref: inputEl1, style: { textAlign: "center" }, maxLength: 1 }}
              />
              <TextField
                value={codeLetter2}
                onChange={(e) => {
                  handleCodeLetterChange(e, setCodeLetter2);
                }}
                sx={{ width: "3em", height: "3em" }}
                inputProps={{
                  ref: inputEl2,
                  style: { textAlign: "center" },
                  maxLength: 1,
                  onKeyDown: (e) => handleCodeLetterChangeOnKeyDown(e),
                  onKeyUp: (e) => handleCodeLetterChangeOnKeyUp(e, inputEl1),
                }}
              />
              <TextField
                value={codeLetter3}
                onChange={(e) => {
                  handleCodeLetterChange(e, setCodeLetter3);
                }}
                sx={{ width: "3em", height: "3em" }}
                inputProps={{
                  ref: inputEl3,
                  style: { textAlign: "center" },
                  maxLength: 1,
                  onKeyDown: (e) => handleCodeLetterChangeOnKeyDown(e),
                  onKeyUp: (e) => handleCodeLetterChangeOnKeyUp(e, inputEl2),
                }}
              />
              <TextField
                value={codeLetter4}
                onChange={(e) => {
                  handleCodeLetterChange(e, setCodeLetter4);
                }}
                sx={{ width: "3em", height: "3em" }}
                inputProps={{
                  ref: inputEl4,
                  style: { textAlign: "center" },
                  maxLength: 1,
                  onKeyDown: (e) => handleCodeLetterChangeOnKeyDown(e),
                  onKeyUp: (e) => handleCodeLetterChangeOnKeyUp(e, inputEl3),
                }}
              />
              <TextField
                value={codeLetter5}
                onChange={(e) => {
                  handleCodeLetterChange(e, setCodeLetter5);
                }}
                sx={{ width: "3em", height: "3em" }}
                inputProps={{
                  ref: inputEl5,
                  style: { textAlign: "center" },
                  maxLength: 1,
                  onKeyDown: (e) => handleCodeLetterChangeOnKeyDown(e),
                  onKeyUp: (e) => handleCodeLetterChangeOnKeyUp(e, inputEl4),
                }}
              />
            </Stack>
            <Stack spacing={2}>
              <Button
                color={invalidLobbyKey ? "error" : "primary"}
                variant="contained"
                disabled={
                  !(
                    (codeLetter1 + codeLetter2 + codeLetter3 + codeLetter4 + codeLetter5).length ===
                    5
                  )
                }
                onClick={handleFinishJoin}
              >
                JOIN
              </Button>

              <Button variant="outlined" onClick={handleExitCodeInput}>
                EXIT
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack sx={{ width: { md: "40vw", xs: "80vw" } }} alignItems="center" spacing={2}>
            <Paper sx={{ width: "100%" }}>
              <TextField
                color={invalidName ? "error" : "primary"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                variant="outlined"
                label="Name"
                InputLabelProps={{ style: { fontSize: 22 } }}
                InputProps={{ endAdornment: <DiceButton />, style: { fontSize: 22 } }}
                inputProps={{ ref: nameInputEl, maxLength: 18 }}
              />
            </Paper>
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{ minHeight: { md: "4em", xs: "" }, width: "100%" }}
              justifyContent="space-between"
              spacing={2}
            >
              <Button
                sx={{ width: { xs: "100%", md: "40%" }, height: { xs: "4em", md: "100%" } }}
                onClick={handleCreateNewLobby}
                variant="contained"
              >
                {t("create_new_lobby")}
              </Button>

              <Button
                sx={{ width: { xs: "100%", md: "40%" }, height: { xs: "4em", md: "100%" } }}
                onClick={handleStartJoin}
                variant="contained"
              >
                {t("join_lobby")}
              </Button>
              <Button
                variant="contained"
                sx={{ width: { xs: "100%", md: "20%" }, height: { xs: "4em", md: "100%" } }}
                onClick={() => history.push("")}
              >
                Exit
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

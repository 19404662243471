import CSS from "csstype";
import { useEffect, useState } from "react";
import ChoiceNest from "../ChoiceNest/ChoiceNest";
import SubmitButton from "../SubmitButton/SubmitButton";
import { convertColorsToNumberSequence } from "./SequenceBarLogic";
import "./SequenceBar.css";
import { Socket } from "socket.io-client";
import _, { subtract, zip } from "lodash";

import { Colors } from "../../routes/OldLobby/Colors";
import { BsHandThumbsDown, BsHandThumbsUp } from "react-icons/bs";
import { CgArrowLongRight } from "react-icons/cg";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { t } from "i18next";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Cue } from "../../shared/interfaces/GameLogicTypes";
import { Paper, Stack, Typography, Button } from "@mui/material";
import useSocketContext from "../../shared/useSocketContext";
import { Trans } from "react-i18next";
import { getColorStylebyNumber, getFontSizeByAnswer } from "../../shared/helper/functions";

export interface SequenceBarProps {
  sequence: Array<number>;
  reset: () => void;
  style?: CSS.Properties;
  canSkip: boolean;
  decreaseRemainingSkips: () => void;
  remainingSkips: number;
  cue1: Cue;
  cue2: Cue;
  cue3: Cue;
  cue4: Cue;
  cue5: Cue;
  isMobile?: boolean;
}

export default function SequenceBar(props: SequenceBarProps) {
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showThumbs, setShowThumbs] = useState(true);
  const [disableCardAnimation, setDisableCardAnimation] = useState(true);
  let socket = useSocketContext().socket;

  useEffect(() => {
    socket.on("forceSubmit", () => {
      if (valid) {
        socket.emit("submit", props.sequence);
      }
    });
    return () => {
      socket.removeListener("forceSubmit");
    };
  }, []);

  const getBallStyle = (listItem: number, isDragging: boolean, draggableStyle: any) => ({
    backgroundColor: getColorStylebyNumber(listItem),
    ...draggableStyle,
  });

  const getCueByNumber = (number: number) => {
    let cue = props.cue1;
    switch (number) {
      case 1: {
        cue = props.cue1;
        break;
      }
      case 2: {
        cue = props.cue2;
        break;
      }
      case 3: {
        cue = props.cue3;
        break;
      }
      case 4: {
        cue = props.cue4;
        break;
      }
      case 5: {
        cue = props.cue5;
        break;
      }
    }
    return cue;
  };

  let standardSequence = [1, 2, 3, 4, 5];

  useEffect(() => {
    if (
      props.sequence.length === 5 &&
      _.difference(standardSequence, props.sequence).length === 0
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
    setSubmitted(false);

    if (props.sequence.length > 0) {
      setTimeout(() => {
        setShowThumbs(false);
      }, 200);
      setTimeout(() => {
        setDisableCardAnimation(false);
      }, 500);
    } else {
      console.log("deactivating animation");
      setShowThumbs(true);
      setDisableCardAnimation(true);
    }
  }, [props.sequence]);

  useEffect(() => {
    if (submitted) {
      socket.emit("submit", props.sequence);
    } else {
      socket.emit("unsubmit");
    }
  }, [submitted]);

  return (
    <>
      {props.isMobile ? (
        <>
          <Paper sx={{width:"99vw", paddingBottom:"1vh"}}>
            <Stack alignItems={"center"}>
              {/* Thumb/arrow Stack */}
              <Stack
                direction={"row"}
                sx={{ width: "80%", height: "4vh" }}
                alignItems="center"
                justifyContent="space-around"
              >
                <FiThumbsDown
                  style={{
                    display: props.sequence.length > 0 ? "flex" : "none",
                    transform: "scale(1.5)",
                    color: "var(--gradient-colour-two)",
                  }}
                />
                <CgArrowLongRight
                  style={{ transform: "scale(2)", color: "var(--gradient-colour-two)" }}
                />
                <FiThumbsUp
                  style={{
                    display: props.sequence.length > 0 ? "flex" : "none",
                    transform: "scale(1.5)",
                    color: "var(--gradient-colour-two",
                  }}
                />
              </Stack>
                
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems="center"
                
              >
                <Droppable direction="horizontal" droppableId="sequenceBar">
                  {(provided, snapshot) => (
                    // CardHoldingStack
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      
                      spacing={{ xs: 1, xl: 3 }}
                      sx={{
                        height: "10vh",
                        width: "90vw",
                        paddingInline: ".5em",
                        
                        borderRadius: "var(--border-radius)",
                        backgroundColor: snapshot.isDraggingOver ? "primary.main" : "neutral.main",
                        marginTop: "1vh",
                        marginBottom: "1vh",
                        transition: "all .5s ease-out",
                        paddingBlock: ".5em",
                        position:"relative"
                      }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                     
                      {props.sequence.length === 0 && !snapshot.isDraggingOver ? (
                        <Typography variant="h6" sx={{ color: "primary.main" }} className="">
                          {t("drag_balls_here")}
                        </Typography>
                      ) : (
                        <></>
                      )}
                      {props.sequence.map((item, index) => (
                        <Draggable
                          key={item + "draggableKey"}
                          draggableId={item + "draggable"}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Paper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getBallStyle(
                                item,
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              sx={{
                                backgroundColor: getColorStylebyNumber(item),
                                width: { md: "18%", xs: "15vw" },
                                height: "100%",
                              }}
                            ></Paper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      <FiThumbsDown
                        style={{
                          transform: props.sequence.length != 0 ? "scale(0)" : "scale(3)",
                          color: "var(--gradient-colour-two)",
                          display: showThumbs ? "flex" : "none",
                          margin: "0",
                          transition: "all .5s",
                          position:"absolute",
                          left: "6vw"
                        }}
                      />
                      <FiThumbsUp
                        style={{
                          transform: props.sequence.length != 0 ? "scale(0)" : "scale(3)",
                          color: "var(--gradient-colour-two",
                          display: showThumbs ? "flex" : "none",
                          margin: "0",
                          transition: "all .5s",
                          position:"absolute",
                          right: "6vw"
                        }}
                      />
                    </Stack>
                  )}
                </Droppable>
              </Stack>
              <Stack direction="row" justifyContent={"space-around"} alignItems="stretch" spacing={1} sx={{height:"9vh", width: "90%" }}>
                <Stack justifyContent="center" sx={{ width: "20%", height:"100%" }} spacing={1}>
                  {props.canSkip ? (
                    <Button
                      variant="contained"
                      disabled={props.remainingSkips == 0}
                      onClick={() => {
                        socket.emit("skipQuestion");
                        props.decreaseRemainingSkips();
                      }}
                      sx={{height:"50%", borderRadius: "1.5vw" }}
                    >
                      <Trans values={{ remainingSkips: props.remainingSkips }}>{t("skip")}</Trans>
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Button

                    variant="contained"
                    onClick={() => {
                      props.reset();
                    }}
                    sx={{height:props.canSkip ?"50%":"100%", borderRadius: "1.5vw" }}
                  >
                    {t("clear")}
                  </Button>
                </Stack>
                <Button
                  variant={valid && submitted ? "outlined" : "contained"}
                  disabled={!valid}
                  onClick={() => {
                    if (valid) {
                      setSubmitted(!submitted);
                    }
                  }}
                  sx={{ width: "80%" }}
                >
                  {submitted ? t("waiting") : t("submit")}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </>
      ) : (
        <Paper sx={{ height: { md: "35vh", xs: "calc(80vh - 100vw)" } }}>
           <Stack alignItems={"center"}>
            <Stack
              direction={"row"}
              sx={{ width: "80%", height: "4vh" }}
              alignItems="center"
              justifyContent="space-around"
            >
              <FiThumbsDown
                style={{
                  display: props.sequence.length > 0 ? "flex" : "none",
                  transform: "scale(1.5)",
                  color: "var(--gradient-colour-two)",
                }}
              />
              <CgArrowLongRight
                style={{ transform: "scale(2)", color: "var(--gradient-colour-two)" }}
              />
              <FiThumbsUp
                style={{
                  display: props.sequence.length > 0 ? "flex" : "none",
                  transform: "scale(1.5)",
                  color: "var(--gradient-colour-two",
                }}
              />
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              spacing={2}
              sx={{ width: "100%", height: "27vh" }}
            >
              <Stack justifyContent="center" spacing={2} sx={{ width: "8%" }}>
                {props.canSkip ? (
                  <Button
                    variant="contained"
                    disabled={props.remainingSkips == 0}
                    onClick={() => {
                      socket.emit("skipQuestion");
                      props.decreaseRemainingSkips();
                    }}
                    sx={{ width: "100%", height: "5em", borderRadius: "1.5vw" }}
                  >
                    <Trans values={{ remainingSkips: props.remainingSkips }}>{t("skip")}</Trans>
                  </Button>
                ) : (
                  <></>
                )}
                <Button
                  variant="contained"
                  onClick={() => {
                    props.reset();
                  }}
                  sx={{ width: "100%", height: "5em", borderRadius: "1.5vw" }}
                >
                  {t("clear")}
                </Button>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems="center"
                sx={{ width: "75%", height: "100%" }}
              >
                <FiThumbsDown
                  style={{
                    transform: props.sequence.length != 0 ? "scale(0)" : "scale(3)",
                    color: "var(--gradient-colour-two)",
                    display: showThumbs ? "flex" : "none",
                    marginRight: props.sequence.length > 1 ? "0vw" : "3vw",
                    transition: "all .5s",
                  }}
                />
                <Droppable direction="horizontal" droppableId="sequenceBar">
                  {(provided, snapshot) => (
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      className={props.sequence.length === 0 ? "shortSequence " : "longSequence "}
                      spacing={{ xs: 1, xl: 3 }}
                      sx={{
                        height: "calc(90%-1e)",
                        paddingInline: ".5em",
                        width: props.sequence.length === 0 ? "30%" : "calc(100%) ",
                        borderRadius: "var(--border-radius)",
                        backgroundColor: snapshot.isDraggingOver ? "primary.main" : "neutral.main",
                        marginTop: "1vh",
                        marginBottom: "1vh",
                        transition: "all .5s ease-out",
                        paddingBlock: ".5em",
                      }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {props.sequence.length === 0 && !snapshot.isDraggingOver ? (
                        <Typography variant="h6" sx={{ color: "primary.main" }} className="">
                          {t("drag_balls_here")}
                        </Typography>
                      ) : (
                        <></>
                      )}
                      {props.sequence.map((item, index) => (
                        <Draggable
                          key={item + "draggableKey"}
                          draggableId={item + "draggable"}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Paper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getBallStyle(
                                item,
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              sx={{
                                backgroundColor: getColorStylebyNumber(item),
                                width: disableCardAnimation
                                  ? { lg: "8em", md: "6em" }
                                  : { xs: "8em", md: "18%" },
                                height: "100%",
                              }}
                            >
                              <Stack sx={{padding:".5em"}}>
                                <Typography sx={{fontSize:getFontSizeByAnswer(getCueByNumber(item).content)}} variant="h6">{getCueByNumber(item).content}</Typography>

                              </Stack>
                            </Paper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
                <FiThumbsUp
                  style={{
                    transform: props.sequence.length != 0 ? "scale(0)" : "scale(3)",
                    color: "var(--gradient-colour-two",
                    display: showThumbs ? "flex" : "none",
                    marginLeft: props.sequence.length > 1 ? "0vw" : "3vw",
                    transition: "all .5s",
                  }}
                />
              </Stack>

              <Button
                variant={valid && submitted ? "outlined" : "contained"}
                disabled={!valid}
                onClick={() => {
                  if (valid) {
                    setSubmitted(!submitted);
                  }
                }}
                sx={{ width: "8%", height: "5em", borderRadius: "1.5vw" }}
              >
                {submitted ? t("waiting") : t("submit")}
              </Button>
            </Stack>
          </Stack> 
        </Paper>
      )}
    </>
  );
}

import { ReactNode, useEffect, useRef, useState } from "react";

import CSS from "csstype";
import "./../ResultsDialog/ResultsDialog.css";
import { disconnect, setMaxListeners } from "process";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import Dialog from "../Dialog/Dialog";

import {
  TurnInfo,
  Submissions,
  Results,
  PlayerEndResults,
  PlayerResultInfo,
  Cue,
  ScoredSwap,
  CueTypes,
  Question,
} from "../../shared/interfaces/GameLogicTypes";
import {
  hasPlayerAnswered,
  getPlayerName,
  getPlayerResults,
  getPlayerSequence,
  getPlayerSwaps,
  getPlayerScore,
  applyScoredSwap,
  getPlayerSubmissionResultList,
} from "./../ResultsDialog/ResultsDialogLogic";
import ResultsSequence from "../ResultsSequence/ResultsSequence";
import _ from "underscore";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Flipped, Flipper } from "react-flip-toolkit";
import {
  Backdrop,
  ClickAwayListener,
  Paper,
  Stack,
  Button,
  Divider,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { getColorStylebyNumber } from "../../shared/helper/functions";
import QuestionHeader from "../AlternateQuestionHeader/AlternateQuestionHeader";
import useSocketContext from "../../shared/useSocketContext";
import MobileResultsSequence from "../MobileResultsSequence/MobileResultsSequence";

export interface MobileResults {
  isTsar: boolean;
  isHost: boolean;
  tsarId: string;
  question: Question;
  playerId: string;
  results: Results;

  cues: Cue[];
}

export default function MobileResults(props: MobileResults) {
  const [tsarName, setTsarName] = useState("");
  const [tsarAnswered, setTsarAnswered] = useState(false);
  const [tsarSequence, setTsarSequence] = useState<number[]>([]);
  const [playerName, setPlayerName] = useState("");
  const [playerAnswered, setPlayerAnswered] = useState(false);
  const [playerSequence, setPlayerSequence] = useState<number[]>([]);
  const [playerSwaps, setPlayerSwaps] = useState<ScoredSwap[]>([]);
  const [playerScore, setPlayerScore] = useState(2000);
  const [currentSwapIndex, setCurrentSwapIndex] = useState(0);
  //wether the traversion over the swap array is forwards or backwards
  const [swapForward, setSwapForward] = useState(true);
  const [currentScore, setCurrentScore] = useState(2000);
  const [readyForAnimation, setReadyForAnimation] = useState(false);
  //"Myresults, Allresults
  const [showFinalScore, setShowFinalScore] = useState(false);
  const [currentView, setCurrentView] = useState("M");
  const [changingSequence, setChangingSequence] = useState(false);
  const [showFinalScoreTimerId, setShowFinalScoreTimerId] = useState<NodeJS.Timeout | undefined>(
    undefined
  );
  const [imageSequence, setImageSequence] = useState([1, 2, 3, 4, 5]);
  const [sortedNonTsarResults, setSortedNonTsarResults] = useState<
    {
      playerId: string;
      playerName: string;

      score: number;
      sequence: number[];
    }[]
  >([]);
  let socket = useSocketContext().socket;

  useEffect(() => {
    //constructor

    console.log("executing results constructor");
    setSwapForward(true);
    setTsarName(getPlayerName(props.results, props.tsarId));
    setTsarAnswered(hasPlayerAnswered(props.results, props.tsarId));
    setTsarSequence(getPlayerSequence(props.results, props.tsarId));
    setPlayerName(getPlayerName(props.results, props.playerId));
    setPlayerAnswered(hasPlayerAnswered(props.results, props.playerId));
    setPlayerSequence(getPlayerSequence(props.results, props.playerId));
    //inserting Pseudo Element to have an animation startingPoint
    let emptySwapSequence: Array<ScoredSwap> = [];
    setPlayerSwaps(
      _.union(
        [{ score: 0, swap: [0, 0] }],
        hasPlayerAnswered(props.results, props.playerId)
          ? getPlayerSwaps(props.results, props.playerId)
          : emptySwapSequence
      )
    );
    setImageSequence(
      hasPlayerAnswered(props.results, props.tsarId)
        ? getPlayerSequence(props.results, props.tsarId)
        : [1, 2, 3, 4, 5]
    );
    console.log(
      "set images sequence to " +
        JSON.stringify(
          hasPlayerAnswered(props.results, props.tsarId)
            ? getPlayerSequence(props.results, props.tsarId)
            : [1, 2, 3, 4, 5]
        ) +
        " by using id " +
        props.tsarId +
        " and not " +
        props.playerId
    );
    console.log(
      "getplayerSequence for me " + JSON.stringify(getPlayerSequence(props.results, props.playerId))
    );
    console.log(
      "getplayerSequence for tsar " + JSON.stringify(getPlayerSequence(props.results, props.tsarId))
    );
    setPlayerScore(2000);
    setReadyForAnimation(true);
    setSortedNonTsarResults(getPlayerSubmissionResultList(props.results, props.tsarId));

    return () => {
      setSwapForward(true);
      setCurrentSwapIndex(0);
      setCurrentView("M");
    };
    //deconstructor
  }, []);

  useEffect(
    () => {
      let animationTimer: NodeJS.Timeout;
      if (playerSwaps.length != 0 && readyForAnimation) {
        animationTimer = setTimeout(() => {
          if (playerSwaps.length > currentSwapIndex + 1) {
            setCurrentSwapIndex(currentSwapIndex + 1);
          }
        }, 1100);
      }
      return () => {
        clearTimeout(animationTimer);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [readyForAnimation, playerSequence]
  );

  useEffect(() => {
    if (playerSequence.length != 0) {
      console.log("playerSwaps: " + JSON.stringify(playerSwaps));
      if (swapForward) {
        console.log("trying to swap forward with currentswapIndex: " + currentSwapIndex);
        const currentSwap = playerSwaps[currentSwapIndex];
        console.log("currentswap: " + JSON.stringify(currentSwap));
        const newSequence = applyScoredSwap(playerSequence.slice(), currentSwap);
        setPlayerSequence(newSequence);
        setPlayerScore(playerScore - currentSwap.score);
      } else {
        console.log("trying to swap backwards with currentswapIndex: " + currentSwapIndex);
        const nextSwap = playerSwaps[currentSwapIndex + 1];
        console.log("nextSwap: " + JSON.stringify(nextSwap));
        const newSequence = applyScoredSwap(playerSequence.slice(), nextSwap);
        setPlayerSequence(newSequence);
        setPlayerScore(playerScore + nextSwap.score);
      }
      console.log("completed sequenceChange sucessfully");
    }
  }, [currentSwapIndex]);

  //playerScore taking damage animation
  useEffect(() => {
    let animationTimer: NodeJS.Timeout;

    setChangingSequence(true);
    animationTimer = setTimeout(() => {
      setChangingSequence(false);
    }, 30);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(animationTimer);
    };
  }, [playerScore]);

  useEffect(() => {
    console.log("sequence changed " + playerSequence);
  }, [playerSequence]);

  const handleGotoAllResults = () => {
    setCurrentView("A");
    setSwapForward(true);
    setCurrentSwapIndex(0);
    setPlayerSequence(getPlayerSequence(props.results, props.playerId));
    setReadyForAnimation(false);
    setPlayerScore(2000);
    console.log("reset everything");
  };

  useEffect(() => {
    if (!(playerSwaps.length > currentSwapIndex + 1)) {
      setShowFinalScoreTimerId(
        setTimeout(() => {
          setShowFinalScore(true);
        }, 1200)
      );
    } else {
      setShowFinalScore(false);
      if (showFinalScoreTimerId) {
        clearTimeout(showFinalScoreTimerId);
      }
    }
  }, [currentSwapIndex]);

  return (
    <div>
      <Stack justifyContent="center" alignItems={"center"}>
        <Paper
          sx={{
            height: "78vh",
            width: "90vw",
            padding: "1em",
          }}
        >
          {currentView == "M" ? (
            //MYRESULTS
            <Stack
              justifyContent={"space-between"}
              alignItems="center"
              sx={{ width: "100%", height: "100%" }}
            >
                {/* props.isTsar */}
              {props.isTsar ? (
                //TSARVIEW
                tsarAnswered == true ? (
                  <Stack alignItems={"center"} justifyContent="space-around" sx={{height:"40%"}} >
                    <Typography  variant="h6" sx={{ fontSize: 22, userSelect:"none" }}>{t("your_answer")}</Typography>
                    <MobileResultsSequence
                      inputSequence={tsarSequence}
                 
                      cues={props.cues}
                    />
                    <Typography  variant="h6" sx={{ fontSize: 22, userSelect:"none" }}>{t("explain_decision")}</Typography>
                  </Stack>
                ) : (
                  <Stack
                    alignItems={"center"}
                    sx={{ paddingTop: "1em", width: "100%", minHeight: "60%" }}
                  >
                    <Typography variant="h3">{t("did_not_answer")}</Typography>
                  </Stack>
                )
                
              ) : playerAnswered == true ? (
                //PLAYERVIEW
                <Stack alignItems={"center"} sx={{ width: "100%" }}>
                  <Typography variant="h6"  sx={{userSelect:"none"}}>Round's over!</Typography>
                  <MobileResultsSequence
                    playerName={tsarName}
                    inputSequence={tsarSequence}
                    cues={props.cues}
                  />

                  <Typography textAlign={"center"} variant="h6" fontSize={18} sx={{userSelect:"none"}}>
                    <Trans
                      components={{
                        h: (
                          <Typography
                            component="span"
                            variant="h6"
                            fontSize={18}
                            sx={{
                              userSelect:"none",
                              color: changingSequence
                                ? swapForward
                                  ? "error"
                                  : "success"
                                : "primary.main",
                              transition: changingSequence ? "all ease-out 1.2s" : "all 0s",
                              transform: changingSequence ? "scale(1,3) rotate(15)" : "",
                            }}
                            
                          />
                        ),
                      }}
                      values={{
                        score: !showFinalScore ? 2000 - playerScore : playerScore,
                      }}
                    >
                      {!showFinalScore ? "calculating_error" : "you_scored"}
                    </Trans>
                  </Typography>
                  <MobileResultsSequence
                    playerName={playerName}
                    inputSequence={playerSequence}
                    cues={props.cues}
                  />
                  <Stack
                    direction={"row"}
                    alignItems="flex-start"
                    justifyContent="center"
                    sx={{ width: "100%" }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        width: "7em",
                        height: { md: "3em" },
                        margin: "1vh",
                      }}
                      onClick={() => {
                        if (currentSwapIndex > 0) {
                          setReadyForAnimation(false);
                          setSwapForward(false);
                          setCurrentSwapIndex(currentSwapIndex - 1);
                          console.log("changed swapindex to " + (currentSwapIndex - 1));
                        }
                      }}
                    >
                      <BiArrowToLeft fontSize={"22"} />
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        width: "7em",
                        height: { md: "3em" },
                        margin: "1vh",
                      }}
                      onClick={() => {
                        if (currentSwapIndex < playerSwaps.length - 1) {
                          setReadyForAnimation(false);
                          setSwapForward(true);
                          setCurrentSwapIndex(currentSwapIndex + 1);
                          console.log("changed swapindex to " + (currentSwapIndex + 1));
                        }
                      }}
                    >
                      <BiArrowToRight fontSize={"22"} />
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <Typography textAlign={"center"} sx={{ fontSize: 30 , userSelect:"none"}} variant="h1">
                  {t("you_did_not_answer")}
                </Typography>
              )}

              <Button
                variant="contained"
                sx={{
                  height: "3em",
                  alignSelf: "flex-end",
                }}
                onClick={handleGotoAllResults}
              >
                {t("see_all")}
              </Button>
            </Stack>
          ) : (
            //ALL RESULTS

            <>
              <Stack justifyContent={"space-between"} sx={{ height: "100%", width: "100%" }}>
                <Stack
                  justifyContent={"flex-start"}
                  sx={{ height: "calc(100% - 3em)", width: "100%" }}
                >
                  <Stack justifyContent={"space-around"}>
                    <MobileResultsSequence
                      inputSequence={tsarSequence}
                      playerName={tsarName}
                      cues={props.cues}
                    />

                    <Divider orientation="horizontal" sx={{ width: "90%" }} />
                  </Stack>
                  <Stack
                    justifyContent={"flex-start"}
                    sx={{
                      overflowY: "scroll",
                      width: "100%",
                      paddingBlock: "2vh",
                      borderRadius: "var(--border-radius)",
                    }}
                    spacing={2}
                    
                  >
                    {sortedNonTsarResults
                      .map((results) => {
                        return (
                          <MobileResultsSequence
                            inputSequence={results.sequence}
                            playerName={results.playerName}
                            score={results.score}
                            cues={props.cues}
                          />
                        );
                      })}
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"flex-end"}
                  sx={{ width: "100%", height: "3em" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      height: { md: "3em", xl: "3em" },
                    }}
                    onClick={() => {
                      setCurrentView("M");
                      setReadyForAnimation(true);
                    }}
                  >
                    {t("see_mine")}
                  </Button>
                  {props.isHost ? (
                    <Button
                      variant="contained"
                      sx={{
                        height: { md: "3em", xl: "3em" },
                      }}
                      onClick={() => socket.emit("nextTurn")}
                    >
                      {t("start_next_round")}
                    </Button>
                  ) : (
                    <Typography  sx={{userSelect:"none"}} variant="h6">{t("waiting_next_round")} </Typography>
                  )}
                </Stack>
              </Stack>
            </>
          )}
        </Paper>
      </Stack>
    </div>
  );

  // if (interpretedResults != undefined && interpretedResults["iAnswered"]) {
  // }
}

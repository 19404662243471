import { useContext } from "react";
import { GameContext } from "./GameContext";

export default function useGameContext() {
  const gameContext = useContext(GameContext);
  return {
    nameState: gameContext.nameState,
    setNameState: gameContext.setNameState,
    lastEditedDeckState: gameContext.lastEditedDeckState,
    setLastEditedDeckState: gameContext.setLastEditedDeckState,
    lobbyKey: gameContext.lobbyKey,
    setLobbyKey: gameContext.setLobbyKey,
  };
}

import CSS from "csstype";
import { DndProvider, DragPreviewImage, useDrop } from "react-dnd";
import { useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { ItemTypes } from "../../routes/OldLobby/ItemTypes";
import "../../index.css";
import clsx from "clsx";
import $ from "jquery";
import { CSSProperties, useEffect, useState } from "react";
import { Colors } from "../../routes/OldLobby/Colors";
import { Draggable, DropAnimation, Droppable } from "react-beautiful-dnd";
import { Cue, CueTypes } from "../../shared/interfaces/GameLogicTypes";
import { t } from "i18next";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Theme as MuiTheme } from "@mui/material/styles";
import { getColorName, getColorStyle, getFontSizeByAnswer } from "../../shared/helper/functions";

export interface AlternateChoiceWidgetProps {
  cue: Cue;
  color: string;
  hasCard: boolean;
  style?: CSS.Properties;
  isImage: boolean;
  isMobile?: boolean;
}

export default function AlternateChoiceWidget(props: AlternateChoiceWidgetProps) {
  const [enlarged, setEnlarged] = useState(false);
  const theme = useTheme();

  const getDraggingStyle = (
    color: string,
    isDragging: boolean,
    isDropAnimating: boolean,
    dropAnimation: DropAnimation | undefined,
    draggableStyle: any
  ) => {
    let style = {
      backgroundColor: getColorStyle(props.color),
      width: "3vw",
      height: "3vw",
      ...draggableStyle,
    };

    if (isDropAnimating && dropAnimation?.moveTo.y != 0) {
      style.transitionDuration = "0.001s";
    }
    // if(isDragging) {
    //   style.transform = "size(2)";
    // }
    return style;
  };


  
  return (
    <Droppable isDropDisabled={true} droppableId={getColorName(props.color)}>
      {(provided, snapshot) => (
        <Box
          {...provided.droppableProps}
          ref={provided.innerRef}
          //A high z-index is crucial to ensure the balls being draggable over long text

          sx={{
            // boxShadow: "inset 0px 0px 85px 8px " + props.color,
            borderStyle: "dashed",
            borderColor: props.color,
            padding:
              props.hasCard && !snapshot.draggingFromThisWith
                ? props.isMobile
                  ? "2px"
                  : "4px"
                : "0px",
            borderWidth:
              props.hasCard && !snapshot.draggingFromThisWith
                ? "0px"
                : props.isMobile
                ? "2px"
                : "4px",
            
            width: { md: "10vw", xs: "29vw" },
            height: { md: "28vh", xs: "46vw" },
            borderRadius: "var(--border-radius)",
            transition: "all .05s linear",
            ...(props.isMobile && {backgroundColor: getColorStyle(props.color, true)})
          }}
        >
          {props.isMobile && (!props.hasCard || snapshot.draggingFromThisWith) ? (
            <Stack sx={{padding:{xs:"0.2em"}}}>
            <Typography sx={{ fontSize: getFontSizeByAnswer(props.cue.content),
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",  }} variant="h6">
              {props.cue.content}
            </Typography></Stack>
          ) : (
            <></>
          )}
          {props.hasCard ? (
            <Draggable key={props.color + "draggableKey"} draggableId={props.color} index={0}>
              {(provided, snapshot) => (
                <Paper
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getDraggingStyle(
                    props.color,
                    snapshot.isDragging,
                    snapshot.isDropAnimating,
                    snapshot.dropAnimation,
                    provided.draggableProps.style
                  )}
                  sx={{
                    backgroundColor: getColorStyle(props.color),
                    minWidth: { md: "10vw", xs: "29vw" },
                    minHeight: { md: "28vh", xs: "46vw" },
                    zIndex: "20",
                    
                  }}
                >
                  <Stack sx={{padding:{xs:"0.2em"}}}>
                    <Typography
                      component={"pre"}
                      sx={{ fontSize: getFontSizeByAnswer(props.cue.content),whiteSpace: "pre-wrap",
                      wordWrap: "break-word", }}
                      variant="h6"
                      
                    >
                      {props.cue.content}
                    </Typography>
                    {/* {props.cue.type === CueTypes.image ? (
                      <img
                        className={enlarged ? "enlarged" : "choiceImage"}
                        src={props.cue.content}
                      />
                    ) : ( */}

                    {/* )} */}

                    <div></div>
                  </Stack>
                </Paper>
              )}
            </Draggable>
          ) : (
            <></>
          )}
        </Box>
      )}
    </Droppable>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";

import Dialog from "../../components/Dialog/Dialog";
import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import QuestionHeader from "../../components/QuestionHeader/QuestionHeader";

import Feedback from "../../shared/api/FeedbackApi";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CasinoIcon from "@mui/icons-material/Casino";
import Paper from "@mui/material/Paper";
import { GameContext } from "../../shared/GameContext";
import _ from "underscore";
import { Box, Grid, useTheme } from "@mui/material";
import Background from "../../shared/img/abstractbackground.webp";
import LobbyApi from "../../shared/api/LobbyApi";
import { getRandomPlayerName } from "../../shared/helper/functions";
import useGameContext from "../../shared/useGameContext";
import { useLocation } from "react-router";
export default function Start() {
  const history = useHistory();
  const [name, setName] = useState("");

  const [enteringCode, setEnteringCode] = useState(false);
  const [codeLetter1, setCodeLetter1] = useState("");
  const [codeLetter2, setCodeLetter2] = useState("");
  const [codeLetter3, setCodeLetter3] = useState("");
  const [codeLetter4, setCodeLetter4] = useState("");
  const [codeLetter5, setCodeLetter5] = useState("");
  const [justDeletedCharacter, setJustDeletedCharacter] = useState(true);
  const [invalidName, setInvalidName] = useState(false);
  const setLobbyKey = useGameContext().setLobbyKey;
  const nameInputEl = useRef<HTMLInputElement>(null);
  let lobbyKey = useLocation().pathname.split("/").pop() as unknown as string;
  const [invalidLobbyKey, setInvalidLobbyKey] = useState(false);
  const [globalName, setGlobalName] = [
    useContext(GameContext).nameState,
    useContext(GameContext).setNameState,
  ];

  const DiceButton = () => {
    return (
      <IconButton onClick={() => setName(getRandomPlayerName())}>
        <CasinoIcon />
      </IconButton>
    );
  };

  const checkIfPlayerNameValid = () => {
    if (name.trim().length === 0) {
      setInvalidName(false);
      nameInputEl.current?.focus();
      return false;
    } else {
      setGlobalName(name);
      return true;
    }
  };

  useEffect(() => {}, []);

  const handleJoin = async () => {
    if (checkIfPlayerNameValid()) {
      if (lobbyKey.length === 5) {
        let canJoin = (await LobbyApi.checkLobbyKey(lobbyKey)).canJoin;
        if (canJoin) {
          setLobbyKey(lobbyKey);
          history.push("/lobby");
        }else {
          setInvalidLobbyKey(true);
          setTimeout(()=>{if(invalidLobbyKey){setInvalidLobbyKey(false)}}, 1200);
        }
      }
    }
  };

  return (
    <Stack
      alignItems="center"
      sx={{ backgroundImage: "url(" + Background + ")", backgroundSize: "cover", height: "100vh" }}
      spacing={2}
    >
      <Stack
        direction="column"
        spacing={2}
        justifyContent="flex-start"
        paddingTop={10}
        alignItems="center"
      >
        <FrontPageLogo></FrontPageLogo>

        <Stack sx={{ width: { md: "40vw", xs: "80vw" } }} alignItems="center" spacing={2}>
          <Paper sx={{ width: "100%" }}>
            <TextField
              color={invalidName ? "error" : "primary"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              variant="outlined"
              label="Name"
              InputLabelProps={{ style: { fontSize: 22 } }}
              InputProps={{ endAdornment: <DiceButton />, style: { fontSize: 22 } }}
              inputProps={{
                ref: nameInputEl,
                maxLength: 18,
                onKeyDown: (e) => {
                  if (e.key === "Enter") handleJoin();
                },
              }}
            />
          </Paper>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ minHeight: { md: "4em", xs: "" }, width: "100%" }}
            justifyContent="space-between"
            spacing={2}
          >
            <Button
            color={invalidLobbyKey ? "error" : "primary"}
              sx={{ width: { xs: "100%", md: "80%" }, height: { xs: "4em", md: "100%" } }}
              onClick={handleJoin}
              variant="contained"
            >
              {t("join_lobby")}
            </Button>
            <Button
              variant="contained"
              sx={{ width: { xs: "100%", md: "20%" }, height: { xs: "4em", md: "100%" } }}
              onClick={() => history.push("")}
            >
              Exit
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";

import FrontPageLogo from "../Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import QuestionHeader from "../AlternateQuestionHeader/AlternateQuestionHeader";
import Switch from "@mui/material/Switch";
import Feedback from "../../shared/api/FeedbackApi";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  InputLabel,
  FormControl,
  MenuItem,
  Autocomplete,
  AutocompleteChangeReason,
  Backdrop,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Slider,
  Tab,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Badge,
} from "@mui/material";
import _ from "underscore";
import CardDeck from "../CardDeck/CardDeck";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import PersonIcon from "@mui/icons-material/Person";
import { BsFillPersonFill } from "react-icons/bs";
import { FaCrown } from "react-icons/fa";
import numeral from "numeral";

export interface PlayerCardProps {
  name: string;
  score?: number;
  inGame?: boolean;
  isHost: boolean;
  isTsar?: boolean;
}


const formatPoints = (n:number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6) return +(n / 1e6).toFixed(1) + "M";
};

export default function PlayerCard(props: PlayerCardProps) {
  let playerIcon = <BsFillPersonFill style={{ fontSize: "4em" }} />;

  if (props.isHost) {
    playerIcon = (
      <Badge
        overlap="circular"
        badgeContent={<FaCrown />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        
        }}
        color="primary"
        sx={{zIndex:0}}
      >
        {playerIcon}
      </Badge>
    );
  }

  return (
    <>
      {props.inGame && props.score !== undefined ? (
        <Paper sx={{ width: "calc(100% )", height: "4em",  }} elevation={3}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "100%", paddingInline: ".5em" }}
          >
            <Typography sx={{color:(props.isTsar?"primary.main":"")}} variant="h6">{props.name}</Typography>
            <Typography  variant="h6">{props.score>1000?numeral(props.score).format('0.0a'):props.score}</Typography>
          </Stack>
        </Paper>
      ) : (
        <Paper sx={{ width: "7em", height: "9em" }} elevation={3}>
          <Stack
            direction="column"
            sx={{ height: "calc( 100% - 1em )", paddingBottom: "1em" }}
            justifyContent="flex-end"
            spacing={2}
            alignItems="center"
          >
            <Stack justifyContent={"center"}>{playerIcon}</Stack>

            <Typography variant="h6">{props.name}</Typography>
          </Stack>
        </Paper>
      )}
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import QuestionHeader from "../QuestionHeader/QuestionHeader";
import Switch from "@mui/material/Switch";
import Feedback from "../../shared/api/FeedbackApi";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import _ from "underscore";

export interface SettingSelectorProps {
  setting: string;
  inputLabel:string
  selectedChoice: string;
  select: (event: SelectChangeEvent)=>void;
  choices: string[];
  readonly: boolean;
}

export default function SettingSelector(props: SettingSelectorProps) {
 
  return (
    <Stack
      direction="row"
      sx={{ width: "100%" }}
      justifyContent="space-between"
      alignItems="center"
      margin=".2em"
    >
      <Typography sx={{ width: "50%", marginBottom: 0 }}>{props.setting}</Typography>
      
      <FormControl sx={{ width: "50%" }}>
        <InputLabel id="demo-simple-select-label" sx={{zIndex:0}}>{props.inputLabel}</InputLabel>

        <Select
          sx={{ height: "3em" }}
          labelId="demo-simple-select-label"
          id={"demo-simple-select-" + props.setting}
          value={props.selectedChoice}
          label="Rounds"
          onChange={props.select}
          readOnly={props.readonly}
        >
          {_.map(props.choices, (choice) => {
            return <MenuItem key={choice} value={choice}>{choice}</MenuItem>;
          })}

          {/* <MenuItem value={"10"}>10</MenuItem> */}
        </Select>
      </FormControl>
    </Stack>
  );
}

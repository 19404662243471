import CSS from "csstype";
import "./FAQ.css";
import { Trans, useTranslation } from "react-i18next";
import i18n from "i18next";
import { useState } from "react";
import BelaQuote from "../../components/BelaQuote/BelaQuote";
import { GiEntryDoor } from "react-icons/gi";
import { useHistory } from "react-router";
import Button from "../../components/Button/Button";

export default function FAQ() {
  const [belaQuote, setBelaQuote] = useState(NaN);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="faq column rank5background">
      <h1 className="faqHeader">{t("FAQ")}</h1>
      <div className="faqQuestion">
        <h2>{t("faq_q_1")}</h2>
        <p>{t("faq_a_1")}</p>
      </div>
      <div className="faqQuestion">
        <h2>{t("faq_q_2")}</h2>
        <p>{t("faq_a_2")}</p>
      </div>

      <div className="faqQuestion">
        <h2>{t("faq_q_3")}</h2>
        <p>{t("faq_a_3")}</p>
      </div>
      <div className="faqQuestion">
        <h2>{t("faq_q_4")}</h2>
        <p>{t("faq_a_4")}</p>
      </div>
      <div className="faqQuestion">
        <h2>{t("faq_q_4")}</h2>
        <p>{t("faq_a_4")}</p>
      </div>
      <div className="honorableMention">
        <div className="bela" />
        <h2 className="honorableMentionText">{t("honorable_mention")}</h2>
        <p className="thankYouMessage">{t("bela_thanks")}</p>
        {/* <BelaQuote/> */}
      </div>
      <div style={{ justifySelf: "flex-end", marginTop: "5vh", alignSelf: "flex-end" }}>
        <button
          className="button button-square"
          style={{ marginLeft: "1vw", width: "5rem" }}
          onClick={() => {
            history.push("");
          }}
        >
          <GiEntryDoor style={{ transform: "scale(2)" }} />
        </button>
      </div>
    </div>
  );
}

import CSS from "csstype";
import "./EndPanel.css";
import { PlayerEndResults } from "../../shared/interfaces/GameLogicTypes";
import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";

import { GiEntryDoor } from "react-icons/gi";
import { BiMailSend } from "react-icons/bi";
import { useHistory } from "react-router";
import Dialog from "../Dialog/Dialog";
import Feedback from "../../shared/api/FeedbackApi";
import { Box, Typography, Paper, Button, TextField, IconButton, Icon, Grid } from "@mui/material";
import Background from "../../shared/img/abstractbackground.webp";
import { Stack } from "@mui/system";
import useSocketContext from "../../shared/useSocketContext";

export interface EndPanelProps {
  playerName: string;
  playerEndResults: undefined | PlayerEndResults;
  isHost: boolean;

}

export default function ConfigPanel(props: EndPanelProps) {
  const [feedBackDialog, setFeedBackDialog] = useState(false);
  const history = useHistory();
  const messageInputEl = useRef<HTMLTextAreaElement>(null);
  const [sent, setSent] = useState(false);
  const [valid, setValid] = useState(false);
  const socket = useSocketContext().socket;
  useEffect(() => {
    if ((messageInputEl.current?.value as unknown as string) !== "") {
      setValid(true);
    }
  }, [messageInputEl.current?.value as unknown as string]);

  return (
    <Box
      id="box1"
      sx={{
        position: { xs: "relative", md: "auto" },
        height: "100vh",

        overflow: { md: "auto", xs: "auto" },
        backgroundImage: "url(" + Background + ")",
        backgroundSize: "cover",
        paddingBottom: 0,
      }}
    >
      <Stack justifyContent="space-around" alignItems={"center"} sx={{ height: "100%" }}>
        <Stack alignItems={"center"}>
          <Typography variant="h1" sx={{ fontSize: 26 }}>
            {t("thanks_for_playing")}
          </Typography>
          <Typography variant="h1" sx={{ fontSize: 26 }}>
            {t("more_stuff")}
          </Typography>
        </Stack>
        {props.playerEndResults != undefined ? (
          <Stack
            justifyContent={"center"}
            direction={"column"}
            sx={{ width: "90%" }}
            spacing={2}
            alignItems="center"
          >
            <Paper sx={{ width: { md: "40%", xs: "70vw" }, maxHeight: "20vh" }}>
              <Stack sx={{ padding: ".5em" }}>
                <Typography variant="h6" sx={{ fontSize: 22 }}>
                  <Trans
                    components={{ h: <span className="highlightedText" /> }}
                    values={{ winnerName: props.playerEndResults["winnerName"] }}
                  >
                    {"winner"}
                  </Trans>
                </Typography>
                <Typography variant="subtitle1">
                  <Trans
                    components={{ h: <span className="highlightedText" /> }}
                    values={{ winnerScore: props.playerEndResults["winnerScore"] }}
                  >
                    {"winning_score"}
                  </Trans>
                </Typography>
              </Stack>
            </Paper>
            <Paper sx={{ width: { md: "40%", xs: "70vw" }, maxHeight: "20vh" }}>
              <Stack sx={{ padding: ".5em" }}>
                <Typography variant="h6" sx={{ fontSize: 22 }}>
                  <Trans
                    components={{ h: <span className="highlightedText" /> }}
                    values={{
                      bestPlayerName: props.playerEndResults["bestPlayerName"],
                    }}
                  >
                    {"knows_you_best"}
                  </Trans>
                </Typography>
                <Typography variant="subtitle1">
                  <Trans
                    components={{ h: <span className="highlightedText" /> }}
                    values={{
                      bestPlayerAccuracy: props.playerEndResults["bestPlayerAccuracy"],
                    }}
                  >
                    {"best_accuracy"}
                  </Trans>
                </Typography>
              </Stack>
            </Paper>
            <Paper sx={{ width: { md: "40%", xs: "70vw" }, maxHeight: "20vh" }}>
              <Stack sx={{ padding: ".5em" }}>
                <Typography variant="h6" sx={{ fontSize: 22 }}>
                  <Trans
                    components={{ h: <span className="highlightedText" /> }}
                    values={{
                      worstPlayerName: props.playerEndResults["worstPlayerName"],
                    }}
                  >
                    {"knows_you_worst"}
                  </Trans>
                </Typography>
                <Typography variant="subtitle1">
                  <Trans
                    components={{ h: <span className="highlightedText" /> }}
                    values={{
                      worstPlayerAccuracy: props.playerEndResults["worstPlayerAccuracy"],
                    }}
                  >
                    {"worst_accuracy"}
                  </Trans>
                </Typography>
              </Stack>
            </Paper>
          </Stack>
        ) : (
          <></>
        )}
        <Paper
          sx={{
            width: { xs: "90%", md: "50vw" },
            height: { xs: "20vh", md: "20vh" },
            padding: ".5em",
          }}
        >
          {!sent ? (
            <>
              <Stack
                alignItems={"center"}
                justifyContent="space-around"
                sx={{ width: "100%", height: "100%" }}
              >
                <Typography variant="h6" sx={{ fontSize: 18 }}>
                  {/* question for tsar and question for player */}
                  <Trans components={{ h: <span className="highlightedText" /> }}>
                    {t("quickFeedBackQuestion")}
                  </Trans>
                </Typography>
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <TextField
                    onChange={() => {
                      setValid((messageInputEl.current?.value as unknown as string) !== "");
                    }}
                    placeholder={t("quick_feedback")}
                    className="quickFeedbackText"
                    inputProps={{ ref: messageInputEl, maxLength: 2000 }}
                    sx={{ height: "100%", width: "100%" }}
                    multiline
                    rows={4}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          disabled={!valid}
                          onClick={() => {
                            Feedback.submitFeedback(
                              messageInputEl.current?.value as unknown as string,
                              props.playerName
                            );
                            setSent(true);
                          }}
                        >
                          <BiMailSend fontSize={25}></BiMailSend>
                        </IconButton>
                      ),
                    }}
                  />
                </Stack>
              </Stack>
            </>
          ) : (
            <Stack alignItems={"center"} justifyContent="center">
            <Typography variant="h6" sx={{ fontSize: 18 }}>
              {t("thx4Impro")}
            </Typography>
            </Stack>
          )}
        </Paper>
        <Grid container columns={3} justifyContent={"row-reverse"} sx={{ width: "90%" }}>
          <Grid item xs={1} />
          {props.isHost ? (
            <Grid item xs={1}>
              <Stack alignItems={"center"}>
                <Button variant="contained" onClick={() => socket.emit("restartLobby")}>
                  {t("restart_lobby")}
                </Button>
              </Stack>
            </Grid>
          ) : (
            <Grid item xs={1}>
              <Stack alignItems={"center"}>
                <Typography variant="h1" sx={{ fontSize: 18 }}>
                  {t("wait_for_restart")}
                </Typography>
              </Stack>
            </Grid>
          )}
          <Grid item xs={1}>
            <Stack sx={{ alignItems: "flex-end" }}>
              <Button
                variant="contained"
                onClick={() => {
                  history.push("");
                }}
              >
                <GiEntryDoor fontSize={25} />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}

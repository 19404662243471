import CSS from "csstype";
import "./HowTo.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Button from "../../components/Button/Button";
import { GiEntryDoor } from "react-icons/gi";
import { useHistory } from "react-router";

export default function FAQ() {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="column rank5background howTo">
      <h1 className="howToHeader">{t("how_to_play")}</h1>
      <div className="faqQuestion">
        <h2>{t("how_to_h_1")}</h2>
        <p>{t("how_to_p_1")}</p>
      </div>
      <div className="faqQuestion">
        <h2>{t("how_to_h_2")}</h2>
        <p>{t("how_to_p_2")}</p>
      </div>
      <div className="faqQuestion">
        <h2>{t("how_to_h_3")}</h2>
        <p>{t("how_to_p_3")}</p>
      </div>
      <div className="faqQuestion">
        <h2>{t("how_to_h_4")}</h2>
        <p>{t("how_to_p_4")}</p>
      </div>
      <div className="faqQuestion">
        <h2>{t("how_to_h_5")}</h2>
        <p>{t("how_to_p_5")}</p>
      </div>
      <div className="faqQuestion">
        <h2>{t("how_to_h_6")}</h2>
        <p>{t("how_to_p_6")}</p>
      </div>
      <div style={{justifySelf:"flex-end", marginTop:"5vh", alignSelf:"flex-end"}}>
        <button className="button button-square"
          style={{ marginLeft: "1vw", width: "5rem" }}
          onClick={() => {
            history.push("");
          }}
        >
          <GiEntryDoor style={{ transform: "scale(2)" }} />
        </button>
      </div>
    </div>
  );
}

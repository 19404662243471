import PlayerTab from "../PlayerTab/PlayerTab";
import CSS from "csstype";
import "./PlayerList.css";
import { Player } from "../../routes/OldLobby/OldLobbyLogic";
import clsx from "clsx";
import internal from "stream";

export interface PlayerListProps {
  players: Record<string, Player>;
  displayScore: boolean;
  style?: CSS.Properties;
  tabStyle?: CSS.Properties;
  tabWith?: number;
}

export default function PlayerList(props: PlayerListProps) {
  const players = props.players;
  if (Object.keys(players).length === 0) {
    return <div className="playerList" style={props.style} />;
  }
  const playerTabs = Object.keys(players).map((key) => (
    <PlayerTab
      name={players[key].name}
      displayScore={props.displayScore}
      score={players[key].score}
      key={players[key].joinTime}
      width={props.tabWith}
    ></PlayerTab>
  ));
  return (
    <ul className={clsx("playerList")} style={props.style}>
      {playerTabs}
    </ul>
  );
  return <div />;
}

import PlayerConfig from "../../components/PlayerConfig/PlayerConfigPanel";

export type Player = {
  name: string;
  score: number;
  joinTime: number;
  remainingSkips:number;
 
  config: PlayerConfig;
};

export interface PlayerConfig {
  [deckId: string]: number;
}

export interface TurnInfo {
  tsarId: string;
  tsarJoinTime:number;
  cues: Cue[];
  question: Question;
  timer: number;
  startTime: number;
  submissions:  { [playerId: string]: Array<number> };
  results: Results;
  roundLimit: number;

}

export interface Question {
  forTsar: string;
  aboutTsar: string;
}

export interface Cue {
  type: CueTypes;
  content: string;
}

export enum CueTypes {
  text,
  image,
}

export interface Results {
  [playerId: string]: PlayerResultInfo;
}

export interface PlayerResultInfo {
  playerInfo: Player;
  playerTurnResults: PlayerTurnResults;
}
export interface PlayerTurnResults {
  sequence: Array<number>;
  swaps: Array<ScoredSwap>; //swap distance first, then swap indices
  points: number;
}

export interface ScoredSwap {
  score: number;
  swap: number[];
}

export interface PlayerEndResults {
  winnerName: string;
  winnerScore: number;
  worstPlayerName: string;
  worstPlayerAccuracy: number;
  bestPlayerName: string;
  bestPlayerAccuracy: number;
}

export interface Submissions {
  kek: number;
}

export interface GameConfig {
  roundTimer: number;
  roundLimit: number;

  maxSkips: number;
  allAboutMe: boolean;
  waitForHost: boolean;
  enableJoinDuringRound: boolean;
}


export interface CardInfo {
  id: number;
  probability: number;
  categories: Array<string>;
}

export interface QuestionCardInfo extends CardInfo {
 
  forTsar: string;
  aboutTsar: string;
  
}

export interface AnswerCardInfo extends CardInfo{
  answer: string;
  
}

export interface CardDeckJSON {
  deckInfo: CardDeckInfo,
  questionCards: QuestionCardInfo[],
  answerCards: AnswerCardInfo[],
}

export enum CardType {
  QUESTION,
  ANSWER,
}


export enum Role {
  CREATOR,
  MODERATOR,
  ADMIN
}

export interface CardDeckInfo {
  id: string;
  name: string;
  description: string;
  language: Language;
  featured: boolean;
}

export interface CardDeckPreview extends CardDeckInfo{
  questionCardCount: number,
  answerCardCount: number,
  categoriesCount:number,
  creatorName: string,
}

export interface Language {
  id:string;
  name:string;
}

export interface LanguagesType {
  [key:string]:Language
}
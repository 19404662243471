import CSS from "csstype";
import { split } from "lodash";
import { useEffect, useState } from "react";
import { questions } from "../../shared/text/People";
import { t } from "i18next";
import { Trans } from "react-i18next";
import {
  Badge,
  Chip,
  Container,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Theme,
  Typography,
  Tooltip,
  MenuItem,
  Menu,
} from "@mui/material";
import _ from "underscore";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getFontSizeByAnswer } from "../../shared/helper/functions";

export interface PreviewCardProps {
  text: string;
  tsarName: string;
  bigPreview?: boolean;
  categories?: string[];
  sx?: SxProps<Theme>;
  previewCategory?: (category: string) => void;
  onDelete?: () => void;
  probability?: number;
  options?: Array<{ name: string; function: () => void }>;
}

export default function PreviewCard(props: PreviewCardProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let component = (
    <Paper
      sx={Object.assign(
        {
          position: "relative",
          height: props.bigPreview
            ? { md: "18em", sm: "14em", xs: "12em" }
            : { md: "14em", sm: "9em", xs: "12em" },
          width: props.bigPreview
            ? { md: "14em", sm: "11em", xs: "9em" }
            : { xl: "9em", md: "10em", sm: "7em", xs: "40vw" },
          overflow: "visible",
          ...(props.bigPreview && { flexShrink: 0 }),
        },
        props.sx
      )}
    >
      {props.options ? (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          sx={{ position: "absolute", top: "0", right: "0" }}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <></>
      )}
      <Stack
        justifyContent={"space-between"}
        sx={{ width: "calc(100% - 1em)", height: "calc(100% - 1em )", padding: ".5em" }}
      >
        <Typography
          component={"pre"}
          variant="body1"
          sx={{
            fontSize: props.bigPreview
              ? { xs: "16px", sm: "18px", md: "22px" }
              : getFontSizeByAnswer(props.text),

            width: "calc(100% - 1em)",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
        >
          <Trans
            components={{
              h: (
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    fontSize: props.bigPreview
                    ? { xs: "16px", sm: "18px", md: "22px" }
                    : getFontSizeByAnswer(props.text),
                  }}
                  color="primary"
                />
              ),
            }}
            values={{ tsar: props.tsarName }}
          >
            {props.text}
          </Trans>
        </Typography>
        {props.categories != undefined ? (
          <Container
            sx={{
              margin: ".5em",
              width: { md: "9em", sm: "6em", xs: "6em" },
              padding: "0 !important",
            }}
          >
            {props.categories.map((category) => {
              return (
                <Chip
                  key={category}
                  onClick={() => {
                    if (props.previewCategory) {
                      props.previewCategory(category);
                    }
                  }}
                  label={category}
                  sx={{
                    margin: ".05em",
                    fontSize: { xs: "8px", sm: "8px", md: "10px" },
                    height: { xs: "2em" },
                    padding: { xs: "0", md: "" },
                  }}
                />
              );
            })}
          </Container>
        ) : (
          <></>
        )}
      </Stack>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {props.options ? (
          props.options.map((option) => (
            <MenuItem
              key={option.name}
              onClick={() => {
                option.function();
                handleClose();
              }}
            >
              {option.name}
            </MenuItem>
          ))
        ) : (
          <></>
        )}
      </Menu>
    </Paper>
  );

  //DeleteBadge
  // if (props.onDelete) {
  //   component = (
  //     <Badge
  //       sx={{
  //         userSelect: "none",
  //         marginBottom: ".5em",
  //         marginRight: "1em",
  //         marginTop: "1em",
  //       }}
  //       badgeContent={
  //         <IconButton
  //           onClick={props.onDelete}
  //           aria-label="Add"
  //           sx={{
  //             height: { md: ".5em", xs: ".5em" },
  //             width: { md: ".5em", xs: ".5em" },
  //           }}
  //           disableRipple
  //         >
  //           <CloseIcon color="secondary" />
  //         </IconButton>
  //       }
  //       color="primary"
  //     >
  //       {component}
  //     </Badge>
  //   );
  // }

  if (props.probability) {
    component = (
      <Tooltip placement="bottom" arrow title={"probability: " + props.probability + "/100"}>
        {component}
      </Tooltip>
    );
  }

  return component;
}

import CSS from "csstype";
import "./ChoiceWidget.css";
import { DndProvider, DragPreviewImage, useDrop } from "react-dnd";
import { useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { ItemTypes } from "../../routes/OldLobby/ItemTypes";
import "../../index.css";
import clsx from "clsx";
import $ from "jquery";
import { CSSProperties, useEffect, useState } from "react";
import { Colors } from "../../routes/OldLobby/Colors";
import { Draggable, DropAnimation, Droppable } from "react-beautiful-dnd";
import { Cue, CueTypes } from "../../shared/interfaces/GameLogicTypes";
import { t } from "i18next";
import { getColorStyle } from "../../shared/helper/functions";

export interface ChoiceWidgetProps {
  cue: Cue;
  color: string;
  sequence: Array<string>;
  style?: CSS.Properties;
  isImage: boolean;
}

export default function ChoiceWidget(props: ChoiceWidgetProps) {
  const [enlarged, setEnlarged] = useState(false);



  const getBallStyle = (
    color: string,
    isDragging: boolean,
    isDropAnimating: boolean,
    dropAnimation: DropAnimation | undefined,
    draggableStyle: any
  ) => {
    let style = {
      backgroundColor: "var(--" + getColorStyle(color) + "Ball)",
      width: "3vw",
      height: "3vw",
      ...draggableStyle,
    };
   
    if (isDropAnimating && dropAnimation?.moveTo.y != 0) {
      style.transitionDuration = "0.001s";
    }
    // if(isDragging) {
    //   style.transform = "size(2)";
    // }
    return style;
  };

  return (
    <div className={"choiceWidget " + getColorStyle(props.color)}>
      <div
        onMouseDown={() => setEnlarged(true)}
        onMouseUp={() => setEnlarged(false)}
        onMouseOut={() => setEnlarged(false)}
        // style={enlarged ? { transform: "scale(2)" } : undefined}
        className="imageWrapper"
      >
        <div className="placeHolder" />
        {props.cue.type === CueTypes.image ? (
          <img className={enlarged ? "enlarged" : "choiceImage"} src={props.cue.content} />
        ) : (
          <div className={"choiceTextContainer ChoiceTextContainer" + getColorStyle(props.color)}>
            <p className={t("a"+ props.cue.content).length > 45?"smallChoiceText":"bigChoiceText"}>{t("a"+ props.cue.content)}</p>
          </div>
        )}
      </div>
      <Droppable isDropDisabled={true} droppableId={getColorStyle(props.color)}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            //A high z-index is crucial to ensure the balls being draggable over long text
            style={{ width: "3vw", height: "3vw", zIndex:"10"}}
          >
            {props.sequence.map((item, index) => (
              <Draggable key={item + "draggableKey"} draggableId={props.color} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getBallStyle(
                      props.color,
                      snapshot.isDragging,
                      snapshot.isDropAnimating,
                      snapshot.dropAnimation,
                      provided.draggableProps.style
                    )}
                    className={"choiceBall "}
                  ></div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

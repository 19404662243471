export const questions = [
  { "id": 0, "Category": "people", "description": "date", "familyFriendly": false, "genericness": 40, "config": {romanticity: 80, allowKids: false}, "aboutTsarDe": "Wen würde <h>{{tsar}}</h> am, liebsten daten?", "forTsarDe": "Wen würdest <h>du</h> am liebsten daten?", "aboutTsarEn": "Who would <h>{{tsar}}</h> like want to date most?", "forTsarEn": "Who would <h>you</h> want to date most?" }
  ,{ "id": 1, "Category": "people", "description": "choose as surgeon", "familyFriendly": true, "genericness": 60, "config": {romanticity: 0, allowKids: false}, "aboutTsarDe": "<h>{{tsar}}</h> liegt im Sterben! Wer soll die überlebensnotwendige OP durchführen?", "forTsarDe": "<h>Du</h> liegst im Sterben! Wer soll der Chirug für die überlebensnotwendige OP sein?", "aboutTsarEn": "<h>{{tsar}}</h> is dying! Who do they choose to be the surgeon for their life-saving surgery?", "forTsarEn": "<h>You</h> are dying! Who do <h>you</h> pick as the surgeon for <h>your</h> life-saving surgery?" }
  ,{ "id": 2, "Category": "people", "description": "hübsch", "familyFriendly": true, "genericness": 55, "config": {romanticity: 60, allowKids: true}, "aboutTsarDe": "Wen findet <h>{{tsar}}</h> am hübschesten?", "forTsarDe": "Wen findest <h>du</h> am hübschesten?", "aboutTsarEn": "Who does <h>{{tsar}}</h> find the prettiest?", "forTsarEn": "Who do <h>you</h> find the prettiest?" }
  ,{ "id": 3, "Category": "people", "description": "sex", "familyFriendly": false, "genericness": 30, "config": {romanticity: 90, allowKids: false}, "aboutTsarDe": "Mit wem würde <h>{{tsar}}</h> am liebsten die Nacht verbringen?", "forTsarDe": "Mit wem würdest <h>du</h> am liebsten die Nacht verbringen?", "aboutTsarEn": "Who would <h>{{tsar}}</h> want to spend the night with most?", "forTsarEn": "Who would <h>you</h> want to spend the night with most?" }
  ,{ "id": 4, "Category": "people", "description": "Model", "familyFriendly": true, "genericness": 40, "config": {romanticity: 30, allowKids: false}, "aboutTsarDe": "<h>{{tsar}}</h> gründet eine Modemarke. Wen nimmt er/sie als Model?", "forTsarDe": "<h>Du</h> gründest eine Modemarke. Wen nimmst <h>du</h> als Model?", "aboutTsarEn": "<h>{{tsar}}</h> is founding a fashion brand. Who do they take as model?", "forTsarEn": "<h>You</h> are founding a fashion brand. Who do <h>you</h> take as model?" }
  ,{ "id": 5, "Category": "activities", "description": "first date", "familyFriendly": false, "genericness": 40, "config": {together: true, alone:false}, "aboutTsarDe": "Was macht <h>{{tsar}}</h> am liebsten als erstes Date?", "forTsarDe": "Was machst <h>du</h> als erstes Date?", "aboutTsarEn": "What does <h>{{tsar}}</h> do for the first date?", "forTsarEn": "What do <h>you</h> do for the first date?" }
  ,{ "id": 6, "Category": "activities", "description": "24 hours", "familyFriendly": true, "genericness": 60, "config": {together: true, alone:true}, "aboutTsarDe": "Was macht <h>{{tsar}}</h> am liebsten wenn er/sie nur noch 24 Stunden zu leben hat?", "forTsarDe": "Was machst <h>du</h> am liebsten wenn <h>du</h> nur noch 24 Stunden zu leben hat?", "aboutTsarEn": "What would <h>{{tsar}}</h> do if they only had 24 hours left to live?", "forTsarEn": "What would <h>you</h> do if <h>you</h> only had 24 hours left to live?" }
  ,{ "id": 7, "Category": "activities", "description": "sex with you", "familyFriendly": false, "genericness": 30, "config": {together: true, alone:false}, "aboutTsarDe": "Welche Aktivität führt am ehesten zu Sex mit <h>{{tsar}}</h>?", "forTsarDe": "Welche Aktivität führt am ehesten zu Sex mit <h>dir</h>?", "aboutTsarEn": "Which activity most likely leads to sex with <h>{{tsar}}</h>?", "forTsarEn": "Which activity most likely leads to sex with <h>you</h>?" }
  ,{ "id": 8, "Category": "activities", "description": "dream partner", "familyFriendly": false, "genericness": 40, "config": {together: true, alone:true}, "aboutTsarDe": "Worin ist der Traumpartner von <h>{{tsar}}</h> ein Profi?", "forTsarDe": "Worin ist der Traumpartner von <h>dir</h> ein Profi?", "aboutTsarEn": "Which of these activities is the lover of <h>{{tsar}}</h>'s dreams a very good at?", "forTsarEn": "Which of these activities is the lover of <h>your</h> dreams a very good at?" }
  ,{ "id": 9, "Category": "locations", "description": "fotoshooting", "familyFriendly": true, "genericness": 40, "config": {landscapes: false, places: true, rooms: false}, "aboutTsarDe": "Wo würde <h>{{tsar}}</h> am liebsten ein fotoshooting machen?", "forTsarDe": "Wo würdest <h>du</h> am liebsten ein fotoshooting machen?", "aboutTsarEn": "Where would <h>{{tsar}}</h> go to do a photoshoot?", "forTsarEn": "Where would <h>you</h> go to do a photoshoot?" }
  ,{ "id": 10, "Category": "locations", "description": "ausblick", "familyFriendly": true, "genericness": 30, "config": {landscapes: true, places: false, rooms: false}, "aboutTsarDe": "Welchen Ausblick findet <h>{{tsar}}</h> am schönsten?", "forTsarDe": "Welchen Ausblick findest <h>du</h> am schönsten?", "aboutTsarEn": "Which view does <h>{{tsar}}</h> find prettiest?", "forTsarEn": "Which view do <h>you</h> find prettiest?" }
  ,{ "id": 11, "Category": "locations", "description": "erstes date", "familyFriendly": false, "genericness": 30, "config": {landscapes: false, places: true, rooms: false}, "aboutTsarDe": "Wo geht <h>{{tsar}}</h> für sein erstes Date hin?", "forTsarDe": "Wo gehst <h>du</h> für sein erstes Date hin?", "aboutTsarEn": "Where would <h>{{tsar}}</h> take their first date?", "forTsarEn": "Where would <h>you</h> go for a first date?" }
  ,{ "id": 12, "Category": "locations", "description": "flitterwochen", "familyFriendly": false, "genericness": 30, "config": {landscapes: true, places: false, rooms: false}, "aboutTsarDe": "Wo geht <h>{{tsar}}</h> in die Flitterwochen?", "forTsarDe": "Wo gehst <h>du</h> in die Flitterwochen?", "aboutTsarEn": "Where would <h>{{tsar}}</h> go for honeymoon?", "forTsarEn": "Where would <h>you</h> go for honeymoon?" }
  ,{ "id": 13, "Category": "locations", "description": "wo freitag abend", "familyFriendly": true, "genericness": 40, "config": {landscapes: false, places: true, rooms: false}, "aboutTsarDe": "Wo geht <h>{{tsar}}</h> am Freitag am liebsten hin?", "forTsarDe": "Wo gehst <h>du</h> am Freitag am liebsten hin?", "aboutTsarEn": "Where would <h>{{tsar}}</h> like most to go on a friday?", "forTsarEn": "Where would <h>you</h> like most to go on a friday?" }
  ,{ "id": 14, "Category": "locations", "description": "entspannen", "familyFriendly": true, "genericness": 40, "config": {landscapes: true, places: true, rooms: true}, "aboutTsarDe": "Wo geht <h>{{tsar}}</h> hin um zu entspannen?", "forTsarDe": "Wo gehst <h>du</h> hin um zu entspannen?", "aboutTsarEn": "Where would <h>{{tsar}}</h> go to relax?", "forTsarEn": "Where would <h>you</h> go to relax?" }
  ,{ "id": 15, "Category": "companies", "description": "ceo", "familyFriendly": true, "genericness": 40, "config": "null", "aboutTsarDe": "Von welcher Firma wäre <h>{{tsar}}</h> gerne CEO?", "forTsarDe": "Von welcher Firma wärest <h>du</h> gerne CEO?", "aboutTsarEn": "Which company would <h>{{tsar}}</h> most want to be the CEO of?", "forTsarEn": "Which company would <h>you</h> most want to be the CEO of?" }
  ,{ "id": 16, "Category": "companies", "description": "worker", "familyFriendly": true, "genericness": 40, "config": "null", "aboutTsarDe": "Bei welcher Firma würde <h>{{tsar}}</h> am liebsten arbeiten?", "forTsarDe": "Bei welcher Firma würdest <h>du</h> am liebsten arbeiten?", "aboutTsarEn": "Which company would <h>{{tsar}}</h> most want to work for?", "forTsarEn": "Which company would <h>you</h> most want to work for?" }
  ,{ "id": 17, "Category": "companies", "description": "commercial", "familyFriendly": true, "genericness": 40, "config": "null", "aboutTsarDe": "In wessen Werbevideo wäre <h>{{tsar}}</h> am liebsten?", "forTsarDe": "In wessen Werbevideo wärest <h>du</h> am liebsten?", "aboutTsarEn": "In whose commercial would <h>{{tsar}}</h> most want to star in as an actor?", "forTsarEn": "In whose commercial would <h>you</h> most want to star in as an actor?" }
  ,{ "id": 18, "Category": "brands", "description": "model", "familyFriendly": true, "genericness": 50, "config": "null", "aboutTsarDe": "Für welche Marke wäre <h>{{tsar}}</h> am liebsten Model?", "forTsarDe": "Für welche Marke wärest <h>du</h> am liebsten Model?", "aboutTsarEn": "Which brand would <h>{{tsar}}</h> most want to be a model for?", "forTsarEn": "Which brand would <h>you</h> most want to be a model for?" }
  ,{ "id": 19, "Category": "brands", "description": "unlimited credit", "familyFriendly": true, "genericness": 30, "config": "null", "aboutTsarDe": "Bei wessen Shop hätte <h>{{tsar}}</h> am liebsten unlimitiertes Guthaben?  (ohne Weiterverkaufen)", "forTsarDe": "Bei wessen Shop hättest <h>du</h> am liebsten unlimitiertes Guthaben?  (ohne Weiterverkaufen)", "aboutTsarEn": "For which brands shop would <h>{{tsar}}</h> most want to have an unlimited gift card? (no reselling allowed)", "forTsarEn": "For which brands shop would <h>you</h> most want to have an unlimited gift card? (no reselling allowed)" }
  ,{ "id": 20, "Category": "paintings", "description": "hang in room", "familyFriendly": true, "genericness": 40, "config": "null", "aboutTsarDe": "Welches dieser Kunstwerke würde sich <h>{{tsar}}</h> am ehesten ins Schlafzimmer hängen?", "forTsarDe": "Welches dieser Kunstwerke würdest <h>du</h> dir am ehesten ins Schlafzimmer hängen?", "aboutTsarEn": "Which one of these artworks would <h>{{tsar}}</h> most want to put in their bedroom?", "forTsarEn": "Which one of these artworks would <h>you</h> most want to put in <h>your</h> bedroom?" }
  ,{ "id": 21, "Category": "paintings", "description": "wear as t-shirt", "familyFriendly": true, "genericness": 20, "config": "null", "aboutTsarDe": "Welches dieser Kunstwerke würde sich <h>{{tsar}}</h> am ehesten auf <h>sein/ihr</h> Shirt drucken?", "forTsarDe": "Welches dieser Kunstwerke würdest <h>du</h> am ehesten auf <h>dein</h> Shirt drucken?", "aboutTsarEn": "Which one of these artworks would <h>{{tsar}}</h> most want to have printed on <h>their</h> t-shirt?", "forTsarEn": "Which one of these artworks would <h>you</h> most want to have printed on <h>your</h> t-shirt?" }
  ,{ "id": 22, "Category": "possessions", "description": "valentines day", "familyFriendly": true, "genericness": 45, "config": "null", "aboutTsarDe": "Oh nein! <h>{{tsar}}</h> hat vergessen dass heute Valentinstag ist. Was kauft <er/sie> als romantisches last-minute Geschenk?", "forTsarDe": "Oh nein! <h>du</h> hast vergessen dass heute Valentinstag ist. Was kaufst <h>du</h> als romantisches last-minute Geschenk?", "aboutTsarEn": "Oh no! <h>{{tsar}}</h> forgot about Valentines day. What do <h>they</h> buy as last-minute romantic gift?", "forTsarEn": "Oh no! <h>you</h> forgot about Valentines day. What do <h>you</h> buy as last-minute romantic gift?" }
  ,{ "id": 23, "Category": "animals", "description": "club", "familyFriendly": true, "genericness": 45, "config": "null", "aboutTsarDe": "Welches dieser Tiere würde <h>{{tsar}}</h> am ehesten angeleint zum Clubeingang mitführen um zu flexen?", "forTsarDe": "Welches dieser Tiere würdest <h>du</h> am ehesten angeleint zum Clubeingang mitführen um zu flexen?", "aboutTsarEn": "Which one of these animals would <h>{{tsar}}</h> most likely put on a leash to flex at the club entrance with?", "forTsarEn": "Which one of these animals would <h>you</h> most likely put on a leash to flex at the club entrance with?" }
  ,{ "id": 24, "Category": "animals", "description": "spirit animal", "familyFriendly": true, "genericness": 60, "config": "null", "aboutTsarDe": "Welches dieser Tiere ist das Spirit Animal von <h>{{tsar}}</h>?", "forTsarDe": "Welches dieser Tiere ist <h>dein</h> Spirit Animal?", "aboutTsarEn": "Which one is the spirit animal of <h>{{tsar}}</h>?", "forTsarEn": "Which one is <h>your</h> spirit animal?" }
  ,{ "id": 25, "Category": "animals", "description": "fight", "familyFriendly": true, "genericness": 30, "config": "null", "aboutTsarDe": "Gegen welches dieser Tiere würde <h>{{tsar}}</h> am ehesten 1 gegen 1 kämpfen?", "forTsarDe": "Gegen welches dieser Tiere würdest <h>du</h> am ehesten 1 gegen 1 kämpfen?", "aboutTsarEn": "Which one would <h>{{tsar}}</h> most likely want to fight in a 1v1?", "forTsarEn": "Which one would <h>you</h> most likely want to fight in a 1v1?" }
  ,{ "id": 26, "Category": "animals", "description": "hauswappen", "familyFriendly": true, "genericness": 50, "config": "null", "aboutTsarDe": "Welches dieser Tiere wäre am ehesten das Hauswappen der Familie von <h>{{tsar}}</h>?", "forTsarDe": "Welches dieser Tiere wäre am ehesten das Hauswappen <h>deiner</h> Familie?", "aboutTsarEn": "Which one would be the house crest of <h>{{tsar}}s</h> family?", "forTsarEn": "Which one would be the house crest of <h>your</h> family?" }
  ,{ "id": 27, "Category": "animals", "description": "sex", "familyFriendly": false, "genericness": 15, "config": "null", "aboutTsarDe": "Mit welchem Tier hat <h>{{tsar}}</h> am ehesten Sex?", "forTsarDe": "Mit welchem Tier hättest <h>du</h> am ehesten Sex?", "aboutTsarEn": "Which one would <h>{{tsar}}</h> most likely have sex with?", "forTsarEn": "Which one would <h>you</h> most likely have sex with?" }
  ,{ "id": 28, "Category": "animals", "description": "pet", "familyFriendly": true, "genericness": 45, "config": "null", "aboutTsarDe": "Welches Tier würde <h>{{tsar}}</h> am ehesten als Haustier halten?", "forTsarDe": "Welches Tier würdest <h>du</h> am ehesten als Haustier halten?", "aboutTsarEn": "Which one would <h>{{tsar}}</h> most likely have as a pet?", "forTsarEn": "Which one would <h>you</h> most likely have as a pet?" }
  ,{ "id": 29, "Category": "animals", "description": "eat ", "familyFriendly": true, "genericness": 40, "config": "null", "aboutTsarDe": "Welches Tier würde <h>{{tsar}}</h> am ehesten essen?", "forTsarDe": "Welches Tier würdest <h>du</h> am ehesten essen?", "aboutTsarEn": "Which one would <h>{{tsar}}</h> most likely eat?", "forTsarEn": "Which one would <h>you</h> most likely eat?" }
  ,{ "id": 30, "Category": "countries", "description": "language", "familyFriendly": true, "genericness": 55, "config": "null", "aboutTsarDe": "Welche Sprache würde <h>{{tsar}}</h> jetzt am liebsten können? (Akzent inkludiert)", "forTsarDe": "Welche Sprache würdest <h>du</h> jetzt am liebsten können? (Akzent inkludiert)", "aboutTsarEn": "Which language would <h>{{tsar}}</h> like to be able to speak right now? (Accent included)", "forTsarEn": "Which language would  <h>you</h> like to be able to speak right now? (Accent included)" }
  ,{ "id": 31, "Category": "countries", "description": "live", "familyFriendly": true, "genericness": 50, "config": "null", "aboutTsarDe": "Wo würde <h>{{tsar}}</h> am liebsten leben?", "forTsarDe": "Wo würdest <h>du</h> am liebsten leben?", "aboutTsarEn": "Where would <h>{{tsar}}</h> most want to live?", "forTsarEn": "Where would  <h>you</h> most want to live?" }
  ,{ "id": 32, "Category": "countries", "description": "cuisine", "familyFriendly": true, "genericness": 60, "config": "null", "aboutTsarDe": "Welche Kultur findet <h>{{tsar}}</h> am ansprechendsten?", "forTsarDe": "Welche Kultur findest <h>du</h> am ansprechendsten?", "aboutTsarEn": "Which culture does  <h>{{tsar}}</h> find most appealing?", "forTsarEn": "Which culture do <h>you</h> find most appealing?" }
  ,{ "id": 33, "Category": "countries", "description": "culture", "familyFriendly": true, "genericness": 65, "config": "null", "aboutTsarDe": "Welche Küche findet <h>{{tsar}}</h> am leckersten?", "forTsarDe": "Welche Küche findest <h>du</h> am leckersten?", "aboutTsarEn": "Which cuisine does <h>{{tsar}}</h> find most appealing?", "forTsarEn": "Which cuisine do <h>you</h> find most appealing?" }
  ,{ "id": 34, "Category": "countries", "description": "partnter", "familyFriendly": false, "genericness": 65, "config": "null", "aboutTsarDe": "Woher kommt der Traumpartner von <h>{{tsar}}</h>?", "forTsarDe": "Woher kommt <h>dein</h> Traumpartner?", "aboutTsarEn": "Where does the dream partner of <h>{{tsar}}</h> come from?", "forTsarEn": "Where does the dream partner of <h>{{tsar}}</h> come from?" }
  ,{ "id": 35, "Category": "books", "description": "like", "familyFriendly": true, "genericness": 60, "config": "null", "aboutTsarDe": "Welches dieser Bücher würde <h>{{tsar}}</h> am ehesten lesen?", "forTsarDe": "Welches dieser Bücher würdest <h>du</h> am ehesten lesen?", "aboutTsarEn": "Which one of these books would <h>{{tsar}}</h> most likely read?", "forTsarEn": "Which one of these books would <h>you</h> most likely read?" }
  ,{ "id": 36, "Category": "books", "description": "everybody should read it", "familyFriendly": true, "genericness": 50, "config": "null ", "aboutTsarDe": "Von welchem dieser Bücher denkt <h>{{tsar}}</h>, dass jeder Mensch es mal gelesen haben sollte?", "forTsarDe": "Von welchem dieser Bücher denkst <h>du</h>, dass jeder Mensch es mal gelesen haben sollte?", "aboutTsarEn": "Of which of these books does <h>{{tsar}}</h> think that everybody in the world should have read it?", "forTsarEn": "Of which of these books do <h>you</h> think that everybody in the world should have read it?" }
  ,{ "id": 37, "Category": "compliments", "description": "appreciate", "familyFriendly": true, "genericness": 70, "config": "null", "aboutTsarDe": "Über welches Kompliment würde <h>{{tsar}}</h> sich am ehesten freuen?", "forTsarDe": "Über welches Kompliment würdest <h>du</h> dich am ehesten freuen?", "aboutTsarEn": "Which compliment would <h>{{tsar}}</h> most appreciate receiving?", "forTsarEn": "Which compliment would <h>you</h> most appreciate receiving?" }
  ,{ "id": 38, "Category": "redFlags", "description": "red flag", "familyFriendly": true, "genericness": 80, "config": "null", "aboutTsarDe": "Welche dieser Eigenschaften eines Dates würde <h>{{tsar}}</h> am meisten abschrecken", "forTsarDe": "Welche dieser eigenschaften würde <h>dich</h> am ehesten von deinem Date abschrecken?", "aboutTsarEn": "Which one of these would be the biggest red flag for <h>{{tsar}}</h> ? (dating)", "forTsarEn": "Which one of these traits would most likely put <h>you</h> off someone <h>you</h> are dating?" }
  ,{ "id": 39, "Category": "badTraits", "description": "bad trait", "familyFriendly": true, "genericness": 40, "config": "null", "aboutTsarDe": "Welche dieser Eigenschaften bemerkt <h>{{tsar}}</h> am häufigsten bei anderen wenn <h>er/sie</h> negative Erfahrungen mit Menschen macht?", "forTsarDe": "Welche dieser Eigenschaften bemerkst <h>du</h> am häufigsten bei anderen wenn <h>du</h> negative Erfahrungen mit Menschen machst?", "aboutTsarEn": "Which one of these traits does <h>{{tsar}}</h> most frequently see themselves confronted with when making negative experiences with other humans?", "forTsarEn": "Which one of these traits do <h>you</h> most frequently see yourself confronted with when making negative experiences with other humans?" }
  ,{ "id": 40, "Category": "lifeGoals", "description": "lifeGoal", "familyFriendly": true, "genericness": 60, "config": "null", "aboutTsarDe": "Was würde <h>{{tsar}}</h> am meisten am Ende seines Lebens erfüllen?", "forTsarDe": "Was würde <h>dich</h> am meisten am Ende seines Lebens erfüllen?", "aboutTsarEn": "What would be the most fulfilling thing for <h>{{tsar}}</h> at the end of <h>their</h> life?", "forTsarEn": "What would be the most fulfilling thing for <h>you</h> at the end of <h>your</h> life?" }
  ,{ "id": 41, "Category": "timeMachine", "description": "timeTravel", "familyFriendly": true, "genericness": 50, "config": "null", "aboutTsarDe": "Was würde <h>{{tsar}}</h> am ehesten tun wenn <h>er/sie</h> eine Zeitmaschine hätte?", "forTsarDe": "Was würdest <h>du</h> am ehesten tun wenn <h>du</h> eine Zeitmaschine hättest?", "aboutTsarEn": "What would <h>{{tsar}}</h> most want to do with a time machine?", "forTsarEn": "What would <h>you</h> most want to do with a time machine?" }
  ,{ "id": 42, "Category": "superpowers", "description": "superpowers", "familyFriendly": true, "genericness": 50, "config": "null", "aboutTsarDe": "Welche dieser Superkräfte hätte <h>{{tsar}}</h> am liebsten", "forTsarDe": "Welche dieser Superkräfte hättest <h>du</h> am liebsten?", "aboutTsarEn": "Which super power would <h>{{tsar}}</h> most want to have?", "forTsarEn": "Which super power would <h>you</h> most want to have?" }
  ,{ "id": 43, "Category": "dareYou", "description": "dareYou", "familyFriendly": true, "genericness": 65, "config": "null", "aboutTsarDe": "Zu welcher dieser Mutproben könnte man <h>{{tsar}}</h> am ehesten treiben?", "forTsarDe": "Zu welcher dieser Mutproben könnte man <h>dich</h> am ehesten treiben?", "aboutTsarEn": "To which one of these dares would <h>{{tsar}}</h> most likely commit?", "forTsarEn": "To which one of these dares would <h>you</h> most likely commit?" }
  ,{ "id": 44, "Category": "occupations", "description": "children", "familyFriendly": true, "genericness": 60, "config": "null", "aboutTsarDe": "Von welchem dieser Berufe hätte <h>{{tsar}}</h> am liebsten haben dass <h>sein/ihr</h> Kind ihn annimmt?", "forTsarDe": "Von welchem dieser Berufe würdest <h>du</h> am liebsten haben dass <h>dein</h> Kind ihn annimmt?", "aboutTsarEn": "Of which one of these professions would <h>{{tsar}}</h> most want their kids to take it on?", "forTsarEn": "Of which one of these professions, which one would <h>you</h> most want your kids to take it on?" }
  ,{ "id": 45, "Category": "occupations", "description": "spouse", "familyFriendly": true, "genericness": 55, "config": "null", "aboutTsarDe": "Von welchem dieser Berufe hätte <h>{{tsar}}</h> am liebsten haben dass <h>sein/ihr</h> Partner ihn ausübt?", "forTsarDe": "Von welchem dieser Berufe würdest <h>du</h> am liebsten haben dass <h>dein</h> Partner ihn ausübt?", "aboutTsarEn": "What's the profession of <h>{{tsar}}</h> 's dream partner?", "forTsarEn": "What's the profession of <h>your</h> dream partner?" }
  ,{ "id": 46, "Category": "familyVacation", "description": "badVacations", "familyFriendly": true, "genericness": 75, "config": "null", "aboutTsarDe": "Was wäre am schlimmsten für <h>{{tsar}}</h> auf einem Familienurlaub?", "forTsarDe": "Was wäre am schlimmsten für <h>dich</h> auf einem Familienurlaub?", "aboutTsarEn": "What would be most tragic for <h>{{tsar}}</h> during a family vacation?", "forTsarEn": "What would be most tragic for <h>you</h> during a family vacation?" }
  ,{ "id": 47, "Category": "vacation", "description": "goodVacation", "familyFriendly": true, "genericness": 60, "config": "null", "aboutTsarDe": "Über welchen Urlaub würde sich <h>{{tsar}}</h> am meisten freuen?", "forTsarDe": "Über welchen Urlaub würdest <h>du</h> dich am ehesten freuen?", "aboutTsarEn": "Which vaction plans would make <h>{{tsar}}</h> most happy?", "forTsarEn": "Which vaction plans would make <h>you</h> most happy?" }
  ,{ "id": 48, "Category": "quarrel", "description": "quarrel", "familyFriendly": true, "genericness": 65, "config": "null", "aboutTsarDe": "Was würde <h>{{tsar}}</h> bei einem Freund am wütendsten machen?", "forTsarDe": "Was würde <h>dich</h> bei einem Freund am wütendsten machen?", "aboutTsarEn": "What would make <h>{{tsar}}</h> most angry at a friend?", "forTsarEn": "What would make <h>you</h> most angry at a friend?" }
  ,{ "id": 49, "Category": "episodes", "description": "current life episode", "familyFriendly": true, "genericness": 70, "config": "null", "aboutTsarDe": "Wie würde <h>{{tsar}}</h> seinen/ihren jetztigen Lebensabschnitt betiteln?", "forTsarDe": "Wie würdest <h>du</h> deinen jetztigen Lebensabschnitt betiteln?", "aboutTsarEn": "What would <h>{{tsar}}</h> choose as a title for the current episode of <h>their</h> life?", "forTsarEn": "What would <h>you</h> choose as a title for the current episode of <h>your</h> life?" }
  ,{ "id": 50, "Category": "episodes", "description": "most recent relationship", "familyFriendly": true, "genericness": 70, "config": "null", "aboutTsarDe": "Wie würde <h>{{tsar}}</h> seine/ihre jüngste Beziehung betiteln?", "forTsarDe": "Wie würdest <h>du</h> deine jüngste Beziehung betiteln?", "aboutTsarEn": "What would <h>{{tsar}}</h> choose as a title for <h>their</h> most recent relationship?", "forTsarEn": "What would <h>you</h> choose as a title for <h>your</h> most recent relationship?" }
  ,{ "id": 51, "Category": "episodes", "description": "first relationship", "familyFriendly": true, "genericness": 70, "config": "null", "aboutTsarDe": "Wie würde <h>{{tsar}}</h> seine/ihre erste Beziehung betiteln?", "forTsarDe": "Wie würdest <h>du</h> deine erste Beziehung betiteln?", "aboutTsarEn": "What would <h>{{tsar}}</h> choose as a title for <h>their</h> first relationship?", "forTsarEn": "What would <h>you</h> choose as a title for <h>your</h> first relationship?" }
  ,{ "id": 52, "Category": "quotes", "description": "room", "familyFriendly": true, "genericness": 60, "config": "null", "aboutTsarDe": "Beim Betreten jemandes Zimmers, welches an die Wand geschriebene Zitat würde den besten Eindruck auf <h>{{tsar}}</h> machen?", "forTsarDe": "Wenn <h>du</h> das Zimmer von jemandem betrittst, welches an die Wand geschriebene Zitat würde den besten Eindruck auf <h>dich</h> machen?", "aboutTsarEn": "When entering someone's room, which quote, written on the wall, would make the best impression onto <h>{{tsar}}</h>?", "forTsarEn": "When entering the someone's room, which quote, written on the wall, would make the best impression onto <h>you</h>?" }
  ,{ "id": 53, "Category": "quotes", "description": "tattoo", "familyFriendly": true, "genericness": 75, "config": "null", "aboutTsarDe": "<h>{{tsar}}</h> muss sich ein Zitat auf den Arm tättowieren lassen. Welches nimmt <h>er/sie</h>?", "forTsarDe": "Du musst <h>dir</h> ein Zitat auf den Arm tättowieren lassen. Welches nimmst <h>du</h>?", "aboutTsarEn": "<h>{{tsar}}</h> has to get one of these quotes tattoed on their arm. Which one do <h>they</h> choose?", "forTsarEn": "You have to get one of these quotes tattoed on their arm. Which one do <h>you</h> choose?" }
  ,{ "id": 54, "Category": "rumors", "description": "about you", "familyFriendly": true, "genericness": 60, "config": "null", "aboutTsarDe": "Welches dieser Gerüchte fände <h>{{tsar}}</h> am schlimmsten über <h>sich</h> zu hören?", "forTsarDe": "Welches dieser Gerüchte fändest <h>du</h> am schlimmsten über <h>dich</h> zu hören?", "aboutTsarEn": "Which one of these rumors would <h>{{tsar}}</h> be most shocked by hearing about <h>themselves</h>?", "forTsarEn": "Which one of these rumors would <h>you</h> be most shocked by hearing about <h>yourself</h>?" }
  ,{ "id": 55, "Category": "children", "description": "teach", "familyFriendly": true, "genericness": 70, "config": "null", "aboutTsarDe": "Welche dieser Werte/Fähigkeiten würde <h>{{tsar}}</h> <h>seinen/ihren</h> Kindern am dringlichsten vermitteln wollen", "forTsarDe": "Welche dieser Werte/Fähigkeiten würdest <h>du</h> deinen Kindern am dringlichsten vermitteln wollen", "aboutTsarEn": "Which one of these skills/values would <h>{{tsar}}</h> most want <h>their</h> children to have?", "forTsarEn": "Which one of these skills/values would <h>you</h> most want <h>your</h> children to have?" }
  ,{ "id": 56, "Category": "badRoadside", "description": "upset you", "familyFriendly": true, "genericness": 65, "config": "null", "aboutTsarDe": "Was würde <h>{{tsar}}</h> am ehesten wütend machen, wenn <h>er/sie</h> es bezeugen würde?", "forTsarDe": "Was würde <h>dich</h> am ehesten wütend machen, wenn <h>du</h> es bezeugen würdest?", "aboutTsarEn": "What would upset <h>{{tsar}}</h> the most if they were to witness it?", "forTsarEn": "What would upset <h>you</h> the most if they were to witness it?" }
  ,{ "id": 57, "Category": "possessions", "description": "what to own", "familyFriendly": true, "genericness": 65, "config": "null", "aboutTsarDe": "Welche dieser Dinge würde <h>{{tsar}}</h> am liebsten besitzen? (Ohne Weiterverkaufen)", "forTsarDe": "Welche dieser Dinge würdest <h>du</h> am liebsten besitzen? (Ohne Weiterverkaufen)", "aboutTsarEn": "Which one of these things would <h>{{tsar}}</h> most want to own? (no reselling)", "forTsarEn": "Which one of these things would <h>you</h> most want to own? (no reselling)" }
  ,{ "id": 58, "Category": "sports", "description": "watch", "familyFriendly": true, "genericness": 65, "config": "null", "aboutTsarDe": "Welche Sportart findet <h>{{tsar}}</h> am coolsten?", "forTsarDe": "Welche Sportart findest <h>du</h> am coolsten?", "aboutTsarEn": "Which kind of sports does <h>{{tsar}}</h> find the coolest?", "forTsarEn": "Which kind of sports do <h>you</h> find the coolest?" }
  ,{ "id": 59, "Category": "sports", "description": "proathlete", "familyFriendly": false, "genericness": 40, "config": "null", "aboutTsarDe": "Von welcher dieser Sportarten fände <h>{{tsar}}</h> es am besten wenn <h>sein/ihr</h> Kind professioneller Athlet wird?", "forTsarDe": "Von welcher dieser Sportarten fändest <h>du</h> es am besten wenn dein Kind professioneller Athlet wird?", "aboutTsarEn": "Which one of these sports would <h>{{tsar}}</h> most want their child to be a professional in?", "forTsarEn": "Which one of these sports would <h>you</h> most want their child to be a professional in?" }
  ];

import React, { useEffect, useRef, useState } from "react";
import "./FrontPage.css";

import Dialog from "../../components/Dialog/Dialog";
import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import QuestionHeader from "../../components/QuestionHeader/QuestionHeader";
import { useAuth0 } from "@auth0/auth0-react";
import User from "../../shared/api/AuthApi";
import jwt_decode from "jwt-decode";
import { Button, Stack, Box } from "@mui/material";
import Background from "../../shared/img/abstractbackground.webp";
import SpinningLogo from "../../shared/img/spinningBallsUltra.webp";

export default function FrontPage() {
  const {
    error,
    user,
    loginWithRedirect,
    isAuthenticated,
    logout,
    loginWithPopup,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isLoading,
  } = useAuth0();
  const history = useHistory();
  const { t } = useTranslation();
  // useEffect(() => {
  //   if(!isLoading && !isAuthenticated) loginWithPopup()
  // }, [isLoading, isAuthenticated]);
  //only one of the pop-ups can be open at a time

  const getAccessToken = async () => {
    let token = "";
    if (isAuthenticated) {
      getAccessTokenSilently({ audience: "rank5.io" })
        .then((value) => {
          console.log("printing silent value " + value);
          token = value;
        })
        .catch((e) => {
          console.log("executing catch code");
          getAccessTokenWithPopup({ audience: "rank5.io" }).then((value) => (token = value));
        })
        .then(() => {
          return token;
        });
    }
  };

  return (
    <Stack
      alignItems="center"
      sx={{ backgroundImage: "url(" + Background + ")", backgroundSize: "cover", height: "100vh" }}
      spacing={2}
    >
      {/* <Logo /> */}
      <FrontPageLogo />
      <Stack
        sx={{ width: { xs: "60vw", sm: "60vw", md: "15em", lg: "15em", xl: "10em" } }}
        spacing={1}
      >
        <Button sx={{ height: "3em" }} variant="contained" onClick={() => history.push("/play")}>
          {t("go_to_play")}
        </Button>
        <Button sx={{ height: "3em" }} variant="contained" onClick={() => history.push("/howTo")}>
          {t("go_to_how_to_play")}
        </Button>
        <Button sx={{ height: "3em" }} variant="contained" onClick={() => history.push("/faq")}>
          {t("go_to_FAQ")}
        </Button>
        <Button
          sx={{ height: "3em" }}
          variant="contained"
          onClick={() => history.push("/feedback")}
        >
          {t("give_feedback")}
        </Button>
        <Button sx={{ height: "3em" }} variant="contained" onClick={() => history.push("/profile")}>
          Profile
        </Button>
        <Button
          sx={{ height: "3em" }}
          variant="contained"
          className="Button Button-rectangular"
          onClick={async () => {
            isAuthenticated ?logout(): await loginWithRedirect({appState: {returnTo: "/"}});
          }}
        >
          {isAuthenticated ? "Log out" : "Log in"}
        </Button>

        {/* {isAuthenticated ? (
            <>
              <Button variant="contained"
                className="button button-rectangular"
                onClick={() => {
                  logout({ returnTo: window.location.origin + "/" });
                }}
              >
                {" "}
                {"Log out"}
              </Button>
              <Button variant="contained"
                className="Button Button-rectangular"
                onClick={async () => {
                  console.log("kein pop up");
                  console.log("value von getAccessTokenSilently: ");
                  const token = await getAccessTokenSilently();
                 
                }}
              >
                {"get Token"}
              </Button>
            </>
          ) : (
            <>
              <Button variant="contained"
                className="Button Button-rectangular"
                onClick={() => {
                  console.log("trying to log in");
                  // loginWithRedirect({ appState: { returnTo: "/authCallback" }, audience:"rank5.io" });
                  loginWithPopup();
                }}
              >
                {"Log in"}
              </Button>
            </>
          )}
          <Button variant="contained"
            className="Button Button-rectangular"
            onClick={() => {
              console.log("error " + error);
              console.log("user" + JSON.stringify(user));
              console.log("isLoading " + isLoading);
              console.log("isAuth " + isAuthenticated);
              // loginWithRedirect({ appState: { returnTo: "/authCallback" } });
            }}
          >
            {"am i logged in?"}
          </Button>
          <Button variant="contained"
            className="Button Button-rectangular"
            onClick={async () => {
              let token = "";
              console.log("pop up?");
              if (isAuthenticated) {
                token = await getAccessTokenSilently({ audience: "rank5.io" });
                console.log("pop up?");
                // token = await getAccessToken() ; is requesting access to your dev-zhp84jsk account.
              }
              console.log("sending token: " + token);
              User.testAuth(token);
            }}
          >
            {"send token to backend"}
          </Button>
          <Button variant="contained"
            className="Button Button-rectangular"
            onClick={() => {
              logout({ returnTo: window.location.origin + "/" });
            }}
          >
            {" "}
            {"Log out"}
          </Button> */}
      </Stack>
    </Stack>
  );
}

import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";

export const GameContext = React.createContext({
  nameState: "",
  setNameState: (newName: string) => {},
  lastEditedDeckState: "",
  setLastEditedDeckState: (newDeckId: string) => {},
  lobbyKey: "",
  setLobbyKey: (newLobbyKey: string) => {},
});

// <[name:string, setName:(newName:string)=>void]>

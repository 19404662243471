import { ReactNode, useEffect, useRef, useState } from "react";
import Slider, { Handle, Range } from "rc-slider";
import "rc-slider/assets/index.css";
import "./PlayerConfigPanel.css";
import { CSSProperties } from "react";
import { PlayerConfig } from "../../shared/interfaces/GameLogicTypes";
import { t } from "i18next";
import chroma from "chroma-js";

export interface PlayerConfigPanelProps {
  sendConfig: (config: PlayerConfig) => void;
  style?: CSSProperties;
}

export default function PlayerConfigPanel(props: PlayerConfigPanelProps) {
  const [playerAge, setPlayerAge] = useState(21);
  const [isDragging, setIsDragging] = useState(false);
  const [playerOrientation, setPlayerOrientation] = useState(50);
  const ageInputEl = useRef<HTMLInputElement>(null);
  const [provideInfo, setProvideInfo] = useState(true);

  useEffect(() => {
    if (!isDragging) {
      props.sendConfig({ age: playerAge, orientation: playerOrientation });
    } 
  }, [playerAge, isDragging]);

  useEffect(() => {
    if (!provideInfo) {
      props.sendConfig({ age: 21, orientation: 50 });
    } else {
      props.sendConfig({ age: playerAge, orientation: playerOrientation });
    }
  }, [provideInfo]);

  return (
    <div className="playerConfig column" style={props.style}>
      <h1 className="configHeader">{t("player_config")}</h1>
      <div
        className={"column playerConfigInput configBorder " + (provideInfo ? "" : "disabledConfig")}
      >
        <div className="row personalQuestion">
          <h1 className="ageQuestion">{t("my_age")}</h1>
          <input
            disabled={!provideInfo}
            maxLength={2}
            className="ageInput"
            placeholder="21"
            onChange={() => setPlayerAge(ageInputEl.current?.value as unknown as number)}
            ref={ageInputEl}
          ></input>
        </div>
        <h1>{t("attracted_to")}</h1>
        {/*Woah is that Pi???*/}
        <div className="sliderParent">
          <Slider
            //state management to send the orientation only after dragging is finished
            onBeforeChange={(value) => {
              setIsDragging(true);
            }}
            onChange={(value) => {
              setPlayerOrientation(value);
            }}
            onAfterChange={(value) => {
              setIsDragging(false);
            }}
            disabled={!provideInfo}
            min={0}
            max={100}
            defaultValue={50}
            railStyle={{ backgroundColor: "var(--female)" }}
            trackStyle={{ backgroundColor: "var(--male)" }}
            handleStyle={{
              backgroundColor: chroma
                .mix("rgba(255, 93, 255, 1)", "rgba(3, 207, 252,1)", playerOrientation / 100)
                .css(),
            }}
          ></Slider>
        </div>
        <div className="row genderScale">
          <h1 className="traditionalGender">♀️</h1>
          <h1 className="unicorn">🦄</h1>
          <h1 className="traditionalGender">♂️</h1>
        </div>
      </div>
      <div className="row permissionQuestion">
        <p>{t("provide_info")}</p>
        <input
          className="permissionChecker"
          type="checkbox"
          checked={provideInfo}
          onChange={() => setProvideInfo(!provideInfo)}
        />
      </div>
    </div>
  );
}

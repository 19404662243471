import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import QuestionHeader from "../../components/QuestionHeader/QuestionHeader";
import Feedback from "../../shared/api/FeedbackApi";
import "./FeedbackPage.css";

export default function FeedbackPage() {
  const messageInputEl = useRef<HTMLTextAreaElement>(null);
  const authorInputEl = useRef<HTMLInputElement>(null);
  const [sent, setSent] = useState(false);
  const [valid, setValid] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  //only one of the pop-ups can be open at a time
  return (
    <div className="rank5background column feedbackPage">
      <div className="feedbackPanel column">
        {!sent ? (
          <>
            <h1 className="feedbackHeader">{t("feedback")}</h1>
            <textarea
              onChange={()=>{setValid(messageInputEl.current?.value as unknown as string !== "")}}
              placeholder="..."
              className="feedbackText"
              ref={messageInputEl}
              maxLength={5000}
            />
            <h1 className="feedbackSignature">{t("from")}</h1>
            <input
              maxLength={40}
              placeholder={t("secret_admirer")}
              // authorInputEl.current?.value as unknown as string
              ref={authorInputEl}
              className="feedbackInput"
            ></input>
            <div className="row feedbackSubmissionbuttons">
              <button onClick={() => history.push("")} className="button button-square">
                <GiEntryDoor style={{ transform: "scale(2)" }} />
              </button>
              <button
                onClick={() => {
                  Feedback.submitFeedback(
                    messageInputEl.current?.value as unknown as string,
                    authorInputEl.current?.value as unknown as string
                  );
                  setSent(true);
                }}
                className="button button-square feedbackSendButton"
                disabled={!valid}
              >
                {t("send")}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="column sentMessage">
              <h1>{t("thx4Impro")}</h1>
              <h1>{t("get_back")}</h1>
              <button
                onClick={() => history.push("")}
                className="button button-square"
                style={{ width: "5rem", height: "5rem" }}
              >
                <GiEntryDoor style={{ width: "3rem", height: "3rem" }} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

import { couldStartTrivia } from "typescript";
import Urls from "./Urls";

export default class Lobby {
  static async newLobby() {
    console.log(Urls.CREATE_LOBBY);
    const resp = await fetch(Urls.CREATE_LOBBY, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).catch((e) => ({ error: e }));
    console.log("created lobby");
    if ("error" in resp) {
      console.log("fetch failed", resp.error);
    } else if (resp.ok) {
      const json = await resp.json();
      if (!json) {
        console.log("decoding json failed");
      }
      console.log(json);
      return json["lobbyKey"];
    } else {
      console.log("fetch succeeded, server not okay");
    }
  }



  static async checkLobbyKey(lobbyKey: string) {
    if (lobbyKey == "") {
      return false;
    } else {
      console.log("fetching " + Urls.CHECK_LOBBY_KEY + lobbyKey);
      const resp = await fetch(Urls.CHECK_LOBBY_KEY + lobbyKey, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }).catch((e) => ({ error: e }));
      if ("error" in resp) {
        console.log("fetch failed", resp.error);
      } else if (resp.ok) {
        console.log("success");
        const json = await resp.json().catch(() => undefined);
        if (!json) {
          console.log("decoding json failed");
        } else {
          
          return json;
        }
      } else {
        console.log("fetch succeeded, server not okay");
      }
    }
  }

}

import { ReactNode, useEffect, useRef, useState } from "react";

import CSS from "csstype";
import { disconnect, setMaxListeners } from "process";
import Dialog from "../Dialog/Dialog";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import PlayerConfigPanel from "../PlayerConfig/PlayerConfigPanel";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { CgArrowLongRight } from "react-icons/cg";
import "./RoundStartDialog.css";
import {
  Backdrop,
  ClickAwayListener,
  Paper,
  Button,
  Stack,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
export interface RoundStartDialogProps {
  isOpen: boolean;
  isTsar: boolean;
  tsarName: string;
  setClose: () => void;
}

export default function RoundStartDialog(props: RoundStartDialogProps) {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    if (dontShowAgain) {
      setHidden(true);
    }
  }, [props.isOpen]);
  //
  return (
    <Backdrop open={props.isOpen && !hidden}>
      <ClickAwayListener onClickAway={() => console.log("yeet")}>
        <Stack justifyContent="center" alignItems="center">
          <Paper sx={{ width: {md:"50rem",xs:"90vw"}, height: {md:"27rem",xs:"70vh"} }}>
            <Stack justifyContent="space-around" alignItems="center" sx={{ height: "100%" }}>
              <Typography textAlign={"center"} variant="h3">
                <Trans
                  components={{
                    h: (
                      <Typography
                        sx={{ marginBottom: "0" }}
                        component="span"
                        variant="h3"
                        color="primary"
                      />
                    ),
                  }}
                  values={{ tsar: props.tsarName }}
                >
                  {props.isTsar ? t("intro_for_tsar") : t("intro_about_tsar")}
                </Trans>
              </Typography>
              <Typography variant="h4" textAlign={"center"}>
                <Trans
                  components={{
                    h: (
                      <Typography
                        sx={{ marginBottom: "0" }}
                        component="span"
                        variant="h4"
                        color="primary"
                      />
                    ),
                  }}
                  values={{ tsar: props.tsarName }}
                >
                  {props.isTsar ? t("how_to_drag_for_tsar") : t("how_to_drag_about_tsar")}
                </Trans>
              </Typography>
              <div className="row ratherScale">
                <FiThumbsDown style={{ transform: "scale(2)", color: "var(--highlighted)" }} />
                <CgArrowLongRight style={{ transform: "scale(3)", color: "var(--highlighted)" }} />
                <FiThumbsUp style={{ transform: "scale(2)", color: "var(--highlighted)" }} />
              </div>
              {!props.isTsar ? (
                <Typography variant="h4" className="normalIntroText" textAlign={"center"}>
                  <Trans
                    components={{
                      h: (
                        <Typography
                          sx={{ marginBottom: "0" }}
                          component="span"
                          variant="h4"
                          color="primary"
                        />
                      ),
                    }}
                    values={{ tsar: props.tsarName }}
                  >
                    {t("ambiguity_about_tsar")}
                  </Trans>
                </Typography>
              ) : (
                <></>
              )}
            
             <Stack alignItems={"center"} > 
            <FormControlLabel
              label="Don't show again"
              control={
                <Checkbox
                  checked={dontShowAgain}
                  onChange={() => setDontShowAgain(!dontShowAgain)}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                />
              }
            />
            <Button variant="contained" onClick={() => props.setClose()}>
              Ok
            </Button></Stack>
            </Stack>
          </Paper>
        </Stack>
      </ClickAwayListener>
    </Backdrop>
  );
}

import CSS from "csstype";
import "./Timer.css";
import "../../index.css";

import { useEffect, useState } from "react";
import { t } from "i18next";

export interface TimerProps {
  timeLeft: number;
  round: number;
  roundLimit: number;
  style?: CSS.Properties;
}

export default function Timer(props: TimerProps) {
  return (
    <div className="timer">
      <h1 className="roundCounter">
        {t("round") + " "} {props.round} {" " + t("out_of") + " "} {props.roundLimit}
      </h1>
      <h1 className="timeCounter highlightedText">{props.timeLeft}</h1>
    </div>
  );
}

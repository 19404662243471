import React, { useContext, ReactNode, useEffect, useRef, useState } from "react";

import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import QuestionHeader from "../../components/QuestionHeader/QuestionHeader";
import { useAuth0 } from "@auth0/auth0-react";
import DoneIcon from "@mui/icons-material/Done";
import {
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Divider,
  Paper,
  Grid,
  Box,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Background from "../../shared/img/abstractbackground.webp";
import Deck from "../../shared/api/DeckApi";
import { CardDeckPreview, Language, Role } from "../../shared/interfaces/GameLogicTypes";
import CardDeck from "../../components/CardDeck/CardDeck";
import {
  convertBackendToFrontendDecks,
  getLanguageByLanguageId,
} from "../../shared/helper/functions";
import { Languages } from "../../shared/language/languages";
import { GameContext } from "../../shared/GameContext";
import _ from "underscore";
import CardWarning from "../../components/CardWarning/CardWarning";
import CardDeckCreator from "../../components/CardDeckCreator/CardDeckCreator";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from "@emotion/react";
import { Theme as MuiTheme } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { sortDecks } from "./ProfilePageLogic";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import SpinningLogo from "../../shared/img/spinningBallsUltra.webp";

declare module "@emotion/react" {
  export interface Theme extends MuiTheme {}
}

const GridItem = (props: { children: ReactNode }) => {
  return (
    <Grid item xs={1}>
      {props.children}
    </Grid>
  );
};

export default function ProfilePage() {
  const [name, setName] = useState("");
  const [returnedName, setReturnedName] = useState("");
  const [role, setRole] = useState(Role.CREATOR);
  const [decks, setDecks] = useState<Array<CardDeckPreview>>([]);
  const setLastEditedDeck = useContext(GameContext).setLastEditedDeckState;
  const lastEditedDeck = useContext(GameContext).lastEditedDeckState;
  const [stagedDeletion, setStagedDeletion] = useState<() => void>(() => {});
  const [showDeleteDeckWarning, setShowDeleteDeckWarning] = useState(false);
  const [dontShowDeleteDeckWarningAgain, setDontShowDeleteDeckWarningAgain] = useState(false);
  const [createDecks, setCreateDecks] = useState(false);
  const [lastTimer, setLastTimer] = useState<NodeJS.Timer | undefined>(undefined);
  const [isLoadingName, setIsLoadingName] = useState(false);
  const [canChange, setCanChange] = useState(false);
  const inputEl = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  let theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleNameChangeSubmit = async () => {
    let token = await getAccessTokenSilently({ audience: "rank5.io" });
    let newName = await Deck.changeUserName(token, name);
    setName(newName);
    setReturnedName(newName);
  };

  useEffect(() => {
    setCanChange(false);
    setIsLoadingName(true);

    if (lastTimer) {
      clearTimeout(lastTimer);
    }
    if (name.trim().length === 0 || name === returnedName) {
      setCanChange(false);
      setIsLoadingName(false);
    } else {
      setLastTimer(
        setTimeout(async () => {
          let token = await getAccessTokenSilently({ audience: "rank5.io" });

          let canChange = !(await Deck.checkUserName(token, name));
          setIsLoadingName(false);
          setCanChange(canChange);
          console.log("username available " + canChange);
        }, 500)
      );
    }
  }, [name]);

  const handleDelete = async (deckId: string) => {
    const stagedDeletion = async () => {
      let token = await getAccessTokenSilently({ audience: "rank5.io" });

      let success = await Deck.deleteDeck(token, deckId);
      if (success) {
        let index = _.findIndex(decks, (deck: CardDeckPreview) => {
          return deck.id == deckId;
        });
        if (index !== -1) {
          let newDeckStack = [...decks];
          newDeckStack.splice(index, 1);
          setDecks(newDeckStack);
        }
      }
    };
    if (dontShowDeleteDeckWarningAgain) {
      stagedDeletion();
    } else {
      setStagedDeletion(() => stagedDeletion);
      setShowDeleteDeckWarning(true);
    }
  };

  const handleCreateNewDeck = async () => {
    let token = "";
    token = await getAccessTokenSilently({ audience: "rank5.io" });
    let deckId = await Deck.createDeck(token, "en");
    if (deckId) {
      console.log("received deck id " + JSON.stringify(deckId));
      setLastEditedDeck(deckId);
      setCreateDecks(true);
    }
  };

  //Staged Deletion Garbage collector
  useEffect(() => {
    if (!showDeleteDeckWarning) setStagedDeletion(() => {});
  }, [showDeleteDeckWarning]);

  const {
    error,
    user,
    loginWithRedirect,
    isAuthenticated,
    logout,
    loginWithPopup,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  useEffect(() => {
    if (!createDecks) {
      const loadDecks = async () => {
        let token = await getAccessTokenSilently({ audience: "rank5.io" });
        let profileInfo = (await Deck.loadOwnProfile(token)).profileInfo;
        setName(profileInfo.name);
        setReturnedName(profileInfo.name);
        setRole(profileInfo.role);
        console.log("loaded own profile deks: " + JSON.stringify(profileInfo));
        
        console.log(
          "converted decks: " + JSON.stringify(convertBackendToFrontendDecks(profileInfo.decks))
        );
        console.log("loaded own profile deks 2: " + JSON.stringify(profileInfo));
        console.log(
          "converted decks a second time : " + JSON.stringify(convertBackendToFrontendDecks(profileInfo.decks))
        );
        setDecks(convertBackendToFrontendDecks(profileInfo.decks));
      };
      loadDecks();
    }
  }, [createDecks]);

  useEffect(() => {
    console.log("decks are currently set to:" + JSON.stringify(decks));
  }, [decks]);

  const nameDisplay = () => {
    return (
      <Stack direction="column" alignItems={"center"}>
        <TextField
          variant={"outlined"}
          color={
            isLoadingName || name == returnedName ? "primary" : canChange ? "success" : "error"
          }
          inputProps={{
            style: { textAlign: "center" },
            border: "none",
            ref: inputEl,
            maxLength: 18,
          }}
          InputProps={{
            style: { fontSize: 40 },

            endAdornment: (
              <InputAdornment position="start">
                {returnedName === name ? (
                  <IconButton
                    onClick={() => {
                      console.log("focusing");
                      console.log(inputEl.current);
                      inputEl.current?.focus();
                    }}
                  >
                    <EditIcon fontSize="large" />
                  </IconButton>
                ) : isLoadingName ? (
                  <>
                    <CircularProgress />
                  </>
                ) : canChange ? (
                  <>
                    <IconButton onClick={handleNameChangeSubmit}>
                      <DoneIcon
                        fontSize="large"
                        sx={{ animation: `${wiggle} .3s infinite linear` }}
                      />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    onClick={() => {
                      setName(returnedName);
                    }}
                  >
                    <ClearIcon fontSize="large" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          sx={{ width: { lg: "27em", xs: "100%" } }}
          value={name}
          onChange={handleNameChange}
        />
      </Stack>
    );
  };

  const createNewDeckButton = () => {
    return (
      <Button
        variant="contained"
        onClick={handleCreateNewDeck}
        sx={{ height: { md: "100%", xs: "4em" }, width: "100%" }}
      >
        <Typography>Create New Deck</Typography>
      </Button>
    );
  };

  const exitButton = () => {
    return (
      <Button
        variant="contained"
        sx={{ height: { md: "100%", xs: "4em" }, width: "100%" }}
        onClick={() => history.push("")}
      >
        <Typography>Exit</Typography>
      </Button>
    );
  };

  const wiggle = keyframes`
  0% {
    transform: rotate(0deg);
    
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

  const fadeIn = keyframes`
0% {
  
  opacity: 0
}

}
100% {
  opacity: 1

}
`;

  return (
    <>
      <Stack
        sx={{
          position: { xs: "relative", md: "auto" },
          height: { md: "100vh", xs: "" },
          minHeight: { md: 0, xs: "100vh" },
          overflow: { md: "auto", xs: "scroll" },
          backgroundImage: "url(" + Background + ")",
          backgroundSize: "cover",
          paddingBottom: { md: 0, xs: 0 },
          alignItems: "center",
        }}
      >
        {createDecks ? (
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              flex: 1,
              overflowY: { md: "scroll", xs: "auto" },
              paddingBottom: "2em",
            }}
          >
            <Box
              sx={{
                backgroundImage: "url(" + SpinningLogo + ")",
                backgroundSize: "contain",
                height: { lg: "20vh", md: "20vh", xs: "20vh" },
                width: { lg: "30vw", md: "40vw", xs: "70vw" },
                marginTop: { lg: "0vh", md: "3vh", xs: "0vh" },
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                backgroundPositionY: "center",
              }}
            />
            <CardDeckCreator
              goBack={() => {
                setCreateDecks(false);
              }}
            />
          </Stack>
        ) : (
          <Stack
            alignItems={"center"}
            justifyContent="center"
            sx={{ paddingInline: "1em", height: "100vh", width: { md: "70vw", xs: "90vw" } }}
            spacing={2}
          >
            {isMobile ? (
              <>
                <Grid container columns={6} spacing={2}>
                  <Grid item xs={6} alignContent="center" justifyContent={"center"}>
                    {nameDisplay()}
                  </Grid>

                  <Grid item xs={3} alignItems="stretch">
                    {exitButton()}
                  </Grid>
                  <Grid item xs={3}>
                    {createNewDeckButton()}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container columns={7} spacing={2}>
                <Grid item xs={1} />
                <Grid item xs={1} alignItems="stretch">
                  {exitButton()}
                </Grid>
                <Grid item xs={3} alignContent="center" justifyContent={"center"}>
                  {nameDisplay()}
                </Grid>

                <Grid item xs={1}>
                  {createNewDeckButton()}
                </Grid>
                <Grid item xs={1} />
              </Grid>
            )}
            <Paper
              sx={{
                height: "70vh",
                width: "100%",
                overflowY: "scroll",
                paddingInline: "1em",
              }}
            >
              {decks.length === 0 ? (
                <Stack
                  direction="column"
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{ height: "100%", width: "100%", animation: `${fadeIn} 2s` }}
                >
                  <DoNotDisturbAltIcon sx={{ fontSize: { md: 150, xs: 100 } }} />
                  <Typography sx={{ fontSize: 40 }}>No decks yet</Typography>
                </Stack>
              ) : (
                <Stack>
                  <Grid
                    container
                    wrap="wrap"
                    justifyContent="flex-start"
                    alignItems={"flex-start"}
                    spacing={2}
                    columns={{ xs: 2, sm: 3, md: 4, lg: 5 }}
                    sx={{
                      height: "100%",
                    }}
                  >
                    {decks.sort(sortDecks).map((deck, index) => {
                      return (
                        <GridItem key={"deck-no" + index}>
                          <CardDeck
                            featured={deck.featured}
                            title={deck.name}
                            questions={deck.questionCardCount}
                            answers={deck.answerCardCount}
                            categories={deck.categoriesCount}
                            language={deck.language}
                            id={deck.id}
                            options={[
                              {
                                name: "Delete",
                                function: () => {
                                  handleDelete(deck.id);
                                },
                              },
                              {
                                name: "Edit",
                                function: () => {
                                  setLastEditedDeck(deck.id);
                                  setCreateDecks(true);
                                },
                              },
                            ]}
                          />
                        </GridItem>
                      );
                    })}
                  </Grid>
                </Stack>
              )}
            </Paper>
          </Stack>
        )}
      </Stack>
      <CardWarning
        canEnable
        enableFeature={() => {
          stagedDeletion();
        }}
        setDontShowAgain={(dontShowAgain: boolean) =>
          setDontShowDeleteDeckWarningAgain(dontShowAgain)
        }
        warningTitle="Delete Deck"
        warningDescription="Do you really want to delete this deck?"
        showWarning={showDeleteDeckWarning}
        setShowWarning={(show: boolean) => setShowDeleteDeckWarning(show)}
        enableAnywayField="Delete"
      />
    </>
  );
}

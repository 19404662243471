import { useAuth0 } from "@auth0/auth0-react";
import Urls from "./Urls";

const sendRequest = async (url: string, method: "POST" | "GET", token?: string, body?: object) => {
  let stringifiedBody = JSON.stringify(body);
  let params: any = {};
  params.method = method;
  params.headers = { "Content-Type": "application/json" };
  if (token) {
    params.headers.authorization = `Bearer ${token}`;
  }
  if (method == "POST" && body) {
    params.body = stringifiedBody;
  }
  const resp = await fetch(url, params).catch((e) => ({ error: e }));
  if ("error" in resp) {
    console.log("fetch failed", resp.error);
  } else if (resp.ok) {
    const json = await resp.json();
    if (!json) {
      console.log("decoding json failed");
    }
    return json;
  }
};

export default class Deck {
  static async createDeck(token: string, language: string) {
    return (await sendRequest(Urls.CREATE_DECK, "POST", token, { language: language })).deckId;
  }
  static async createQuestionCard(
    token: string,
    deckId: string,
    categories: string[],
    forTsar: string,
    aboutTsar: string,
    probability: number
  ) {
    let response = await sendRequest(Urls.CREATE_QUESTIONCARD, "POST", token, {
      deckId,
      categories,
      forTsar,
      aboutTsar,
      probability,
    });
    
    return response.questionCardId;
  }

  static async createAnswerCard(
    token: string,
    deckId: string,
    categories: string[],
    answer: string,
    probability: number
  ) {
    let response = await sendRequest(Urls.CREATE_ANSWERCARD, "POST", token, {
      deckId,
      categories,
      answer,
      probability,
    });
  
    return response.answerCardId;
  }

  static async changeDeckName(token: string, deckId: string, newName: string) {
    let response = await sendRequest(Urls.CHANGE_DECK_NAME, "POST", token, {deckId:deckId,newName:newName} )
    return response.newName
  }

  static async changeDeckDescription(token: string, deckId: string, newDescription: string) {
    let response = await sendRequest(Urls.CHANGE_DECK_DESCRIPTION, "POST", token, {deckId:deckId,newDescription:newDescription} )
    return response.newDescription
  }

  static async changeDeckLanguage(token: string, deckId: string, newLanguageId: string) {
    let response = await sendRequest(Urls.CHANGE_DECK_LANGUAGE, "POST", token, {deckId:deckId,newLanguageId:newLanguageId} )
    return response.newLanguageId
  }

  static async deleteQuestionCard(token: string, questionCardId:number) {
    let response = await sendRequest(Urls.DELETE_QUESTIONCARD, "POST", token, {questionCardId:questionCardId} )
    return response.success
  }

  static async deleteAnswerCard(token: string, answerCardId:number) {
    let response = await sendRequest(Urls.DELETE_ANSWERCARD, "POST", token, {answerCardId:answerCardId} )
    return response.success
  }

  static async deleteDeck(token:string, deckId: string) {
    let response = await sendRequest(Urls.DELETE_DECK, "POST", token, {deckId:deckId} )
    return response.success
  }

  static async loadDeck(deckId:string) {
    console.log("sending request to load specific deck");
    let response = await sendRequest(Urls.LOAD_DECK + deckId, "GET");
    console.log("res: " + JSON.stringify(response));
    return response;
  }

  static async loadOwnProfile(token:string) {
    console.log("sending request to load own profile");
    let response = await sendRequest(Urls.LOAD_OWN_PROFILE, "GET", token);
    console.log("res: " + JSON.stringify(response));
    return response;
  }


  static async loadDeckInfos(deckIds:string[]) {    
    console.log("attempting to fetch with deckIds: " + JSON.stringify(deckIds));
    let response = await sendRequest(Urls.LOAD_DECK_INFOS, "POST", "",{deckIds:deckIds} );
    console.log("res: " + JSON.stringify(response));
    if (response.success) {
       return response.deckInfos;
    }else{
      return undefined;
    }
   
  }

  static async checkUserName(token:string, name:string) {
    console.log("sending request");
    let response = await sendRequest(Urls.CHECK_USER_NAME + name , "GET", token);
    console.log("response = " + JSON.stringify(response));
    return response.exists
  }

  static async changeUserName(token:string, newName:string) {
    let response = await sendRequest(Urls.CHANGE_USER_NAME, "POST", token, {newName:newName} );
    return response.newName
  }

  static async searchDecks(type:string, query: string, languageIds:string[]) {
    console.log("trying to search decks");
    let response = await sendRequest(Urls.SEARCH_DECKS,"POST",undefined,{type:type, query:query, languages:languageIds});
    console.log("found: " + JSON.stringify(response));
    return response.deckInfos;
  }


  static async getRandomDecks(languageIds:string[], featuredOnly:boolean) {
    console.log("fetching random decks");
    let response = await sendRequest(Urls.GET_RANDOM_DECKS,"POST",undefined,{languages:languageIds, featuredOnly:featuredOnly});
    console.log("found: " + JSON.stringify(response));
    return response.deckInfos;
  } 
}


import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import FrontPageLogo from "../../components/Logo/Logo";
import { GiEntryDoor } from "react-icons/gi";
import { Redirect, useHistory } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import QuestionHeader from "../QuestionHeader/QuestionHeader";
import Switch from "@mui/material/Switch";
import Feedback from "../../shared/api/FeedbackApi";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { Divider, FormControl, InputLabel, MenuItem, Paper, Typography } from "@mui/material";
import _ from "underscore";
import { Language } from "../../shared/interfaces/GameLogicTypes";
import StarIcon from "@mui/icons-material/Star";
import Menu from "@mui/material/Menu";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getFontSizeByAnswer, getFontSizeByTitle } from "../../shared/helper/functions";

interface CardDeckProps {
  title: string;
  questions?: number;
  answers?: number;
  categories?: number;
  language?: Language;
  creatorName?: string;
  id: string;
  showMenu?: boolean;
  expanded?: boolean;
  options?: Array<{ name: string; function: () => void }>;
  selected?: boolean;
  onlyTitle?: boolean;
  featured?: boolean;
  // onDelete?: ()=>void;
  // onEdit?: ()=>void;
}

// const options = [
//   "Delete",
//   "Edit",
// ];

export default function CardDeck(props: CardDeckProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let length = props.title.length;
  return (
    // <Paper sx={{ height: "10em" }}>
    //   <Stack sx={{ margin: ".5em", width: "10em", height: "100%" }} justifyContent="space-between">

    <Paper
      sx={{
        position: "relative",
        border: "solid",
        borderWidth: props.selected ? "3px" : "0px",
        borderColor: "primary.main",
        transition: "border-width .1s ease-in-out",
      }}
    >
      {props.options ? (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          sx={{ position: "absolute", top: "0", right: "0" }}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <></>
      )}
      {props.featured ? (
        <StarIcon sx={{ position: "absolute", top: "0", left: "0", color: "primary.main" }} />
      ) : (
        <></>
      )}
      <Stack
        sx={{
          margin: ".5em",
          height: props.onlyTitle ? "5em" : props.creatorName ? "16em" : "12em",
        }}
        justifyContent="space-between"
      >
        <Stack
          sx={{ height: props.onlyTitle ? "100%" : "45%", paddingInline: "1em" }}
          justifyContent="center"
          alignItems={"center"}
        >
          {/* Fit the Typography size to the text length */}

          {(() => {
            return (
              <Typography
                component={"pre"}
                sx={{
                  marginBottom: "0em",
                  fontSize: getFontSizeByTitle(props.title, props.onlyTitle),
                  fontWeight: "bold",
                  height: "45%",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
                align="center"
                paragraph
                variant={"body1"}
              >
                {props.title}
              </Typography>
            );
          })()}

          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {props.options ? (
              props.options.map((option) => (
                <MenuItem
                  key={option.name}
                  onClick={() => {
                    option.function();
                    handleClose();
                  }}
                >
                  {option.name}
                </MenuItem>
              ))
            ) : (
              <></>
            )}
          </Menu>
        </Stack>
        {!props.onlyTitle &&
        props.language !== undefined &&
        props.questions !== undefined &&
        props.answers !== undefined &&
        props.categories !== undefined ? (
          <>
            <Divider />
            <Stack
              justifyContent="space-between"
              sx={{ height: "50%", marginBottom: ".2em" }}
              spacing={0}
            >
              <Typography sx={{ margin: 0 }} align="center" paragraph variant="body1">
                <Trans
                  values={{
                    language: props.language.name,
                  }}
                >
                  {t("card_deck_specs_language")}
                </Trans>
              </Typography>
              <Typography sx={{ margin: 0 }} align="center" paragraph variant="body1">
                <Trans
                  values={{
                    questions: props.questions,
                  }}
                >
                  {t("card_deck_specs_questions")}
                </Trans>
              </Typography>
              <Typography sx={{ margin: 0 }} align="center" paragraph variant="body1">
                <Trans
                  values={{
                    answers: props.answers,
                  }}
                >
                  {t("card_deck_specs_answers")}
                </Trans>
              </Typography>
              <Typography sx={{ margin: 0 }} align="center" paragraph variant="body1">
                <Trans
                  values={{
                    categories: props.categories,
                  }}
                >
                  {t("card_deck_specs_categories")}
                </Trans>
              </Typography>

              {props.creatorName ? (
                <>
                  <Divider />
                  <Typography
                    sx={{ margin: 0, color: "primary.main" }}
                    align="center"
                    paragraph
                    variant="body1"
                  >
                    <Trans
                      values={{
                        categories: props.categories,
                      }}
                    >
                      {props.creatorName}
                    </Trans>
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Stack>
          </>
        ) : (
          <></>
        )}
      </Stack>
    </Paper>
  );
}

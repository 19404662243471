import CSS from "csstype";
import React, { Component, useEffect } from "react";
import Button from "../Button/Button";
import "./GameConfigPanel.css";
import Select, { ActionMeta, GroupBase, StylesConfig, SingleValue } from "react-select";
import clsx from "clsx";
import $ from "jquery";
import { CSSProperties, useState } from "react";
import { Colors } from "../../routes/OldLobby/Colors";
import { GameConfig } from "../../shared/interfaces/GameLogicTypes";
import { useTranslation } from "react-i18next";
import _ from "underscore";

export interface GameConfigPanelProps {
  sendGameModeConfig: (gameMode: string) => void;
  sendTimerConfig: (timer: string) => void;
  sendRoundLimitConfig: (roundLimit: number) => void;
  style?: CSSProperties;
}

export default function GameConfigPanel(props: GameConfigPanelProps) {
  const { t } = useTranslation();

  const gameMode = [
    { value: "F", label: t("family_friendly") },
    { value: "P", label: t("party") },
  ];

  const speed = [
    { value: "S", label: t("slow") },
    { value: "M", label: t("medium") },
    { value: "F", label: t("fast") },
  ];

  const roundLimit = [
    { value: 1, label: t("one_round") },
    { value: 2, label: t("two_rounds") },
    { value: 3, label: t("three_rounds") },
    { value: 5, label: t("five_rounds") },
  ];

  const [selectedGameMode, setSelectedGameMode] = useState<{ value: string; label: string } | null>(
    gameMode[1]
  );
  const [selectedSpeed, setSelectedSpeed] = useState<{ value: string; label: string } | null>(
    speed[0]
  );
  const [selectedRoundLimit, setSelectedRoundLimit] = useState<{
    value: number;
    label: string;
  } | null>(roundLimit[2]);

  useEffect(() => {
    props.sendGameModeConfig(
      selectedGameMode == null ? gameMode[1]["value"] : selectedGameMode["value"]
    );
  }, [selectedGameMode]);

  useEffect(() => {
    props.sendTimerConfig(selectedSpeed == null ? speed[0]["value"] : selectedSpeed["value"]);
  }, [selectedSpeed]);

  useEffect(() => {
    props.sendRoundLimitConfig(
      selectedRoundLimit == null ? roundLimit[2]["value"] : selectedRoundLimit["value"]
    );
  }, [selectedRoundLimit]);

  const customStyles: StylesConfig<object, boolean, GroupBase<object>> = {
    menu: ({ width, ...css }) => ({
      ...css,
      width,
      maxWidth: "100%",
    }),
  };
  // const customStyles = {
  //   menu: ({ width, ...css }) => ({
  //     ...css,
  //     width: "max-content",
  //     minWidth: "100%",
  //   }),
  // };

  return (
    <div className="column configPanel" style={props.style}>
      <h1 className="configHeader">{t("game_config")}</h1>
      <div className="column configFields configBorder">
        <div className="row gameModeButtons">
          <p style={{ marginRight: "1vw" }}>{t("game_mode")}</p>
          <div className="selectWrapper">
            <Select
              //styles={customStyles}
              theme={(theme) => ({
                ...theme,

                width: 100,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary: "var(--highlighted)",
                  primary50: "var(--dark-highlighted)",
                  primary25: "var(--very-dark-highlighted)",
                },
              })}
              options={gameMode}
              defaultValue={gameMode[1]}
              onChange={(
                option: { value: string; label: string } | null,
                actionMeta: ActionMeta<object>
              ) => {
                setSelectedGameMode(option);
              }}
            />
          </div>
        </div>
        <div className="row gameModeButtons">
          <p style={{ marginRight: "1vw" }}>{t("speed")}</p>
          <div className="selectWrapper">
            <Select
              theme={(theme) => ({
                ...theme,

                width: 100,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary: "var(--highlighted)",
                  primary50: "var(--dark-highlighted)",
                  primary25: "var(--very-dark-highlighted)",
                },
              })}
              options={speed}
              defaultValue={speed[0]}
              onChange={(
                option: { value: string; label: string } | null,
                actionMeta: ActionMeta<object>
              ) => {
                setSelectedSpeed(option);
              }}
            />
          </div>
        </div>
        <div className="row gameModeButtons">
          <p style={{ marginRight: "1vw" }}>{t("rounds")}</p>
          <div className="selectWrapper">
            <Select
              // styles={customStyles}
              theme={(theme) => ({
                ...theme,

                width: 100,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary: "var(--highlighted)",
                  primary50: "var(--dark-highlighted)",
                  primary25: "var(--very-dark-highlighted)",
                },
              })}
              options={roundLimit}
              defaultValue={roundLimit[2]}
              onChange={(
                option: { value: number; label: string } | null,
                actionMeta: ActionMeta<object>
              ) => {
                setSelectedRoundLimit(option);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
function rgb(arg0: number, arg1: number, arg2: number): string {
  throw new Error("Function not implemented.");
}

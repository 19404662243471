import CSS from "csstype";
import { split } from "lodash";
import { useEffect, useState } from "react";
import "./AlternateQuestionHeader.css";
import { questions } from "../../shared/text/People";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Button, Paper, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Question } from "../../shared/interfaces/GameLogicTypes";
import { getFontSizeByQuestion } from "../../shared/helper/functions";

export interface frontendQuestion {
  id: number;
  aboutTsarKey: string;
  forTsarKey: string;
}

export interface QuestionHeaderProps {
  question: Question;
  tsarName: string;
  isTsar: boolean;
  resultsDisplay?: boolean;
  preview?: boolean;
  isMobile?: boolean;
}

export default function QuestionHeader(props: QuestionHeaderProps) {
  return (
    <Paper sx={{ height: "12vh", width: props.resultsDisplay? "100%":props.isMobile ? "90vw" : "70vw" }}>
      <Stack justifyContent="center" sx={{ height: "100%" }}>
        <Typography
          paragraph
          variant="h1"
          align="center"
          sx={{
            marginBottom: "0",
            fontSize: props.resultsDisplay
              ? { md: 18, xl: 22 }
              : props.isMobile
              ? getFontSizeByQuestion(
                  props.isTsar ? props.question.forTsar : props.question.aboutTsar
                )
              : { md: 22, xl: 28 },
          }}
        >
          {/* question for tsar and question for player */}
          <Trans
            components={{
              h: (
                <Typography
                  sx={{
                    marginBottom: "0",
                    fontSize: props.resultsDisplay
                    ? { md: 18, xl: 22 }
                    : props.isMobile
                    ? getFontSizeByQuestion(
                        props.isTsar ? props.question.forTsar : props.question.aboutTsar
                      )
                    : { md: 22, xl: 28 },
                  }}
                  component="span"
                  variant="h1"
                  color="primary"
                />
              ),
            }}
            values={{ tsar: props.tsarName }}
          >
            {props.isTsar ? props.question.forTsar : props.question.aboutTsar}
          </Trans>
        </Typography>
      </Stack>
    </Paper>
  );
}

import React, { ReactNode } from 'react';
import CSS from "csstype";
import "./../../index.css"
import { Button, Stack, Box } from "@mui/material";
import clsx from 'clsx';
import SpinningLogo from "../../shared/img/spinningBallsUltra.webp";



export default function FrontPageLogo( ){
    return(
        <Box
        sx={{
          backgroundImage: "url(" + SpinningLogo + ")",
          backgroundSize: "contain",
          height: { lg:"30vh", md:"30vh",xs:"20vh"},
          width:{lg:"40vw", md:"50vw", xs:"70vw"},
          marginTop:{lg:"5vh", md:"3vh", xs:"4vh"},
          backgroundRepeat: "no-repeat",
          backgroundPositionX:"center",
          backgroundPositionY:"center",
        }}
      />
    );
}

import Button from "../Button/Button";
import { AiOutlineCopy } from "react-icons/ai";
import CSS from "csstype";
import "./InvitationLinkField.css";
import "../../index.css";
import clsx from "clsx";
import { BsFillPersonPlusFill } from "react-icons/bs";
import Lobby from "../../shared/api/LobbyApi";
import { useRef } from "react";

export default function InvitationLinkField() {
  return (
    <div className={clsx("invitationLinkField", "raise-on-hover")}>
      <BsFillPersonPlusFill
        className="icon"
        style={{ transform: "scale(2)", color: "white", marginLeft: "2.5vw", marginRight:"1vw" }}
      />
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            height:"75%",
            fontSize:"1.8rem",
            paddingRight:"1em",
            paddingLeft:"1em",
            marginRight: "1vw",
            borderRadius: "var(--border-radius)",
            alignItems: "center",
       
          }}
        >
          {window.location.href}
        </div>
        <button className="button button-square"
          onClick={()=>
            navigator.clipboard.writeText(window.location.href)
          }
          style={{
            marginTop: "1vh",
            marginBottom: "1vh",
            marginRight: "1vw",
            width: "4vw",
          }}
        >
          <AiOutlineCopy style={{ transform: "scale(2)" }} />
        </button>
      
    </div>
  );
}

export default class Urls {
  public static CORE_URL: string = window.location.origin + "/";
  public static CREATE_LOBBY: string = Urls.CORE_URL + "api/newLobby/";
  public static CHECK_LOBBY_KEY: string = Urls.CORE_URL + "api/checkLobbyKey/";
  public static LEAVE_LOBBY: string = Urls.CORE_URL + "leaveLobby/";
  public static SUBMIT_FEEDBACK: string = Urls.CORE_URL + "api/submitFeedback/";
  public static TEST_AUTH: string = Urls.CORE_URL + "api/testAuth/";
  public static CREATE_DECK: string = Urls.CORE_URL + "api/createDeck";
  public static CREATE_QUESTIONCARD: string = Urls.CORE_URL + "api/createQuestionCard";
  public static CREATE_ANSWERCARD: string = Urls.CORE_URL + "api/createAnswerCard";
  public static DELETE_QUESTIONCARD : string = Urls.CORE_URL + "api/deleteQuestionCard";
  public static DELETE_ANSWERCARD : string = Urls.CORE_URL + "api/deleteAnswerCard";
  public static LOAD_DECK : string = Urls.CORE_URL + "api/loadDeck/";
  public static CHANGE_DECK_NAME : string = Urls.CORE_URL + "api/changeDeckName/";
  public static CHANGE_DECK_DESCRIPTION : string = Urls.CORE_URL + "api/changeDeckDescription/";
  public static CHANGE_DECK_LANGUAGE : string = Urls.CORE_URL + "api/changeDeckLanguage/";
  public static LOAD_OWN_PROFILE : string = Urls.CORE_URL + "api/loadOwnProfile/";
  public static DELETE_DECK : string = Urls.CORE_URL + "api/deleteDeck/";
  public static CHANGE_USER_NAME  : string = Urls.CORE_URL + "api/changeUserName/";
  public static CHECK_USER_NAME : string = Urls.CORE_URL + "api/checkUserName/";
  public static SEARCH_DECKS : string = Urls.CORE_URL + "api/searchDecks/";
  public static LOAD_DECK_INFOS : string = Urls.CORE_URL + "api/loadDeckInfos/";
  public static GET_RANDOM_DECKS : string = Urls.CORE_URL + "api/getRandomDecks/";
  //public static  : string = Urls.CORE_URL + "api/";

}

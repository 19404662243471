import { CardDeckPreview } from "../../shared/interfaces/GameLogicTypes";

export function sortDecks(deck1: CardDeckPreview, deck2: CardDeckPreview) {
  if (deck1.featured && !deck2.featured) {
    return -1;
  } else if (!deck1.featured && deck2.featured) {
    return 1;
  } else if (deck1.categoriesCount > deck2.categoriesCount) {
    return -1;
  } else if (deck1.categoriesCount < deck2.categoriesCount) {
    return 1;
  } else if (deck1.name.length > deck2.name.length) {
    return -1;
  } else {
    return 1;
  }
}

import React, { useEffect, useRef, useState } from "react";
import io, { Socket } from "socket.io-client";
    let socket: Socket;
export default function Counter(){

    const [message, setMessage] = useState(0);
    useEffect(() => {
        socket = io("ws://localhost:8000/counter")
        socket.on("clock", number => {setMessage(number)})
        return () => {socket.disconnect()}
    }, [])

    
    return(
        <div><div>{message}</div><button onClick={()=>{
            if (socket.connected) {
                
                socket.emit("decrease")
        }}}
        >click me</button></div>
    )
}
import io, { Socket } from "socket.io-client";
import React, { useEffect, useRef, useState } from "react";
import "./AdminMessage.css"
let socket: Socket;

export default function AdminMessage() {
  const passwordInputEl = useRef<HTMLInputElement>(null);
  const messageInputEl = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // console.log("trying to connect WS");
    // console.log("origin : " + window.location.origin);
    // console.log("host : " + window.location.host);
    // console.log("href : " + window.location.href);
    // console.log("pathname : " + window.location.pathname);
    // console.log("lobbyKey: " + lobbyKey);

    socket = io("/adminMessage");
    return () => {
      console.log("disconnecting socket");
      socket.disconnect();
    };
  }, []);

  return (
    <div className="column gradient-background adminMessage">
      <input ref={passwordInputEl} style={{ width: "30vw" ,fontSize:"3rem"}}></input>
      <input ref={messageInputEl} style={{ width: "70vw",fontSize:"2rem" }}></input>
      <button
      style={{height:"5vh", width:"10vw"}}
        onClick={() => (socket.emit("adminMessage",{password:passwordInputEl.current?.value as string, message:messageInputEl.current?.value as string}))}
      >send</button>
    </div>
  );
}

import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import "./index.css";
import AdminMessage from "./routes/AdminMessage/AdminMessage";
import FAQ from "./routes/FAQ/FAQ";
import Start from "./routes/Start/Start";
import Lobby from "./routes/Lobby/Lobby";
import OldLobby from "./routes/OldLobby/OldLobby";
import HowTo from "./routes/HowTo/HowTo";

import FeedbackPage from "./routes/FeedbackPage/FeedbackPage";
import FrontPage from "./routes/FrontPage/FrontPage";
import Counter from "./routes/Counter/Counter";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { convertToLanguageKeys } from "./shared/text/questionConverter";
import DragAndDrop from "./routes/DragAndDrop/DragAndDrop";
import AlternateLobby from "./routes/Lobby/Lobby";
import { createTheme, ThemeProvider } from "@mui/material";
import { orange, red } from "@mui/material/colors";
import { colors } from "react-select/dist/declarations/src/theme";
import GameContextProvider from "./components/GameContextProvider/GameContextProvider";
import { Auth0Provider, useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Auth0ProviderWithHistory from "./components/auth/auth0-provider-with-history";
import ProfilePage from "./routes/ProfilePage/ProfilePage";
import PrivateRoute from "./components/auth/PrivateRoute";
import SocketContextProvider from "./components/SocketContextProvider/SocketContextProvider";
import JoinPage from "./routes/JoinPage/JoinPage";

var translationEn = {
  create_new_lobby: "Create new lobby",
  join_lobby: "Join lobby",
  switch_language: "German",
  invalid_lobbyKey: "Invalid Lobby. If this happened unexpectedly you can submit feedback here:",
  host_is_configuring: "The host is configuring the game...",
  start_game: "Start Game",
  see_all: "See all results",
  you_did_not_answer: "You did not answer the question",
  calculate_results: "Calculate my results",
  set_your_name: "Set your Name",
  confirm: "Confirm",
  recommendation: "We recommend using your real name, but it's not necessary",
  thanks_for_playing: "Thanks for playing",
  more_stuff: "More stuff coming soon",
  knows_you_best: "Knows you Best: <h>{{bestPlayerName}}</h>",
  best_accuracy: "Predicted you with: <h>{{bestPlayerAccuracy}}%</h> accuracy",
  winner: "Winner: <h>{{winnerName}}</h>",
  winning_score: "Score: <h>{{winnerScore}}</h>",
  knows_you_worst: "Knows you Worst: <h>{{worstPlayerName}}</h>",
  worst_accuracy: "Predicted you with: <h>{{worstPlayerAccuracy}}%</h> accuracy",
  attracted_to: "I'm attracted to",
  provide_info:
    "Yes, I want to provide further information about me to enhance the game experience",
  my_age: "My Age is",
  player_config: "Player Configuration",
  your_answer: "You answered:",
  did_not_answer: "You did not answer the Question",
  see_others: "See what others predicted",
  you_scored: "You scored <h>{{score}}</h> Points!",
  calculating_error: "Calculating point loss: <h>-{{score}}</h>",
  player_submissons: "Player Submissions",
  see_mine: "See my Results",
  start_next_round: "Start next round",
  waiting_next_round: "Waiting for Host",
  explain_decision: "Care to explain your decision?",
  game_config: "Game Configuration",
  game_mode: "Game Mode:",
  speed: "Speed:",
  rounds: "Rounds:",
  family_friendly: "Family Friendly",
  party: "Normal",
  slow: "Slow (recommended)",
  medium: "Medium",
  fast: "Fast",
  one_round: "1",
  two_rounds: "2",
  three_rounds: "3 (recommended)",
  five_rounds: "5",
  go_to_play: "Play",
  go_to_how_to_play: "How to play",
  how_to_play: "How to play",
  go_to_FAQ: "FAQ",
  FAQ: "Frequently asked Questions",
  faq_q_1: "Who are these people, that are shown to me?",
  faq_a_1:
    'All the portraits of people were generated using the artificial intelligence "Style GAN" by Nvidia.',
  faq_q_2: "So none of the people in the portraits are real?",
  faq_a_2: "Exactly, so no one's feelings get hurt.",
  faq_q_3: "What Technology did you use to build this project?",
  faq_a_3: "NodeJS, ReactTS, NvidiaGAN, Blender3d, Docker",
  faq_q_4: "Will there ever be an app version?",
  faq_a_4: "Maybe.",
  faq_q_5: "When I try to drag the balls, they won't move",
  faq_a_5:
    "If you happen to play on a touchscreen the game will only register touchscreen-dragging",

  how_to_h_1: "1. Talking is better",
  how_to_h_2: "2. Answer honestly",
  how_to_h_3: "3. Empathize with your friends",
  how_to_h_5: "5. Explain your decision",
  how_to_h_4: "4. Submit your answer",
  how_to_h_6: "6. Compare your understanding",
  how_to_p_1:
    "Invite your friends to a voice call (e.g. Zoom, Discord).  You need at least one additional player. Rank5.io is currently playable on PC and Tablet.",
  how_to_p_2:
    "Drag-and-Drop the color of each person to their corresponding place on the scale. Mind the direction!",
  how_to_p_3:
    "If the question is about a friend, try to predict how they will answer it. The better your call, the more points you get.",
  how_to_p_5: "Why did you rank them this way? People want to know!",
  how_to_p_4:
    "Click the big red button to submit your answer. The algorithm will now determine how well you know each other.",
  how_to_p_6: "Who knows you best, Who knows you worst and who is the best people-predictor?",
  honorable_mention: "Honorable Mention",
  bela_thanks:
    "I want to sincerly thank my friend Bela, who mentored this code project in his freetime and helped me with his expertise in programming, deployment and networking.",
  oh_no: "Oh No",
  switch_device:
    "It seems like you're using a mobile device. Rank5.io is currently only available on desktop and tablet.",
  clear: "RESET",
  submit: "SUBMIT",
  waiting: "WAITING",
  intro_for_tsar: "This round is all about <h>you</h>!",
  intro_about_tsar: "This round is all about <h>{{tsar}}</h>!",
  how_to_drag_for_tsar: "<h>Drag</h> the colored <h>cards</h> into the <h>bar</h> at the bottom and order them.",
  how_to_drag_about_tsar:
    "<h>Drag</h> the colored <h>cards</h> into the <h>bar</h> at the bottom and order them the way <h>{{tsar}}</h> would do it.",
  ambiguity_about_tsar: "If a field is ambiguous, ask <h>{{tsar}}</h> how they understand it.",
  round: "Round",
  out_of: "out of",
  wait_for_restart: "Waiting for Host to restart",
  restart_lobby: "Next Game",
  secret_admirer: "A secret admirer",
  send: "send",
  feedback: "Feedback:",
  from: "From:",
  thx4Impro: "Thanks for improving the page :)",
  get_back: "Now get back to playing",
  give_feedback: "Feedback",
  feedback_leave_warning: "You have to leave the lobby to give feedback",
  abort: "Abort",
  proceed: "Ok",
  drag_balls_here: "Drag Cards Here",
  quickFeedBackQuestion: "What would <h>you</h> improve about the game?",
  quick_feedback: "Quick Feedback",
  skip: "skip({{remainingSkips}})",
  card_deck_specs: "questions: {{questions}}\nanswers: {{answers}}\ncategories: {{categories}}",
  card_deck_specs_questions: "questions: {{questions}}",
  card_deck_specs_answers: "answers: {{answers}}",
  card_deck_specs_categories: "categories: {{categories}}",
  card_deck_specs_language: "language: {{language}}",
};
var translationDe = {
  create_new_lobby: "Neue Lobby erstellen",
  join_lobby: "Lobby beitreten",
  switch_language: "English",
  invalid_lobbyKey:
    "Ungültige Lobby. Wenn das unerwartet passiert kannst du ein feedback schreiben:",
  host_is_configuring: "Der Host konfiguriert das Spiel...",
  start_game: "Spiel starten",
  see_all: "Alle Ergebnisse sehen",
  you_did_not_answer: "You did not answer the question",
  calculate_results: "Berechne mein Ergebnis",
  you_scored: "Du hast <h>{{score}}</h> Punkte erreicht!",
  calculating_error: "Berechne Punktverlust: <h>-{{score}}</h>",
  set_your_name: "Dein Name:",
  confirm: "Bestätigen",
  recommendation: "Wir empfehlen deinen richtigen Namen zu wählen, aber es ist nicht notwendig",
  thanks_for_playing: "Danke fürs spielen",
  more_stuff: "Bald kommt mehr",
  knows_you_best: "Kennt dich am besten: <h>{{bestPlayerName}}</h>",
  best_accuracy: "Hat dich mit: <h>{{bestPlayerAccuracy}}%</h> Genauigkeit vorrausgesagt",
  winner: "Gewinner: <h>{{winnerName}}</h>",
  winning_score: "Punkte: <h>{{winnerScore}}</h>",
  knows_you_worst: "Kennt dich am schlechtesten: <h>{{worstPlayerName}}</h>",
  worst_accuracy: "Hat dich mit: <h>{{worstPlayerAccuracy}}%</h> Genauigkeit vorrausgesagt",
  attracted_to: "Ich stehe auf:",
  provide_info:
    "Ja, ich will mehr informationen über mich bereitstellen um das Spielerlebnis zu verbessern",
  my_age: "Mein Alter ist",
  player_config: "Spieler Konfiguration",
  your_answer: "Du hast geantwortet:",
  did_not_answer: "Du hast die Frage nicht beantwortet",
  see_others: "Sieh, was andere gesagt haben",
  player_submissons: "Spielereinsendungen",
  see_mine: "Zeig meine Ergebnisse",
  start_next_round: "Starte die nächste Runde",
  waiting_next_round: "Warte auf den Host",
  explain_decision: "Magst du erklären wieso?",
  game_config: "Spieleinstellungen",
  game_mode: "Spielmodus:",
  speed: "Geschwindigkeit:",
  rounds: "Runden:",
  family_friendly: "Familienfreundlich",
  party: "Normal",
  slow: "Langsam (empfohlen)",
  medium: "Mittel",
  fast: "Schnell",
  one_round: "1",
  two_rounds: "2",
  three_rounds: "3 (empfohlen)",
  five_rounds: "5",
  go_to_play: "Spielen",
  go_to_how_to_play: "Spielanleitung",
  how_to_play: "Spielanleitung",
  go_to_FAQ: "FAQ",
  FAQ: "Oft gestellte Fragen",
  faq_q_1: "Wer sind die Menschen in den Portraits, die mir gezeigt werden?",
  faq_a_1:
    'Alle Portraits wurden mit der künstlichen Intelligenz "Style GAN" von Nvidia generiert.',
  faq_q_2: "Also ist keiner dieser Menschen echt?",
  faq_a_2: "Exakt, also werden niemandes Gefühle verletzt.",
  faq_q_3: "Welche Programmiersprachen und Programme hast du für dieses Projekt benutzt?",
  faq_a_3: "NodeJS, ReactTS, NvidiaGAN, Blender3d, Docker",
  faq_q_4: "Wird es eine App version geben?",
  faq_a_4: "Vielleicht.",
  how_to_h_1: "1. Sprechen ist besser",
  how_to_h_2: "2. Antworte ehrlich",
  how_to_h_3: "3. Versetze dich in deine Mitspieler",
  how_to_h_4: "4. Sende deine Antwort ein",
  how_to_h_5: "5. Erkläre deine Entscheidung",
  how_to_h_6: "6. Vergleicht euer Verständnis",
  how_to_p_1:
    "Lade deine Freunde zu einem Sprachanruf ein (z.B. Zoom, Discord). Du brauchst mindestens einen Mitspieler. Das Spiel ist auf Computer und Tablet spielbar.",
  how_to_p_2: "Zieh die Farbe der Personen auf ihren Platz auf der Skala. Achte auf die Richtung!",
  how_to_p_3:
    "Falls die Frage um einen Freund geht, versuche vorherzusagen wie er/sie antworten wird. Je besser deine Vorhersage, desto mehr Punkte.",
  how_to_p_4:
    "Drücke den großen roten Knopf um deine Antwort einzuschicken. Der Algorithmus berechnet dann wie gut ihr euch kennt.",
  how_to_p_5: "Warum hast du sie so angeordnet? Die Leute wollen es wissen!",
  how_to_p_6:
    "Wer kennt dich am besten, wer am schlechtesten und wer ist der beste Menschenversteher?",
  bela_q_1: "Erweiterbarkeit, dies das",
  bela_q_2: "Das ist ja auch nicht schwer",
  bela_q_3: "Das wird dir beim deployment vermutlich um die ohren fliegen.",
  bela_q_4: "Ich würde ja rust empfehlen, aber du willst ja noch vor weihnachten fertig werden.",
  bela_q_5: "Ich würde immer noch das Backend abfackeln und neu bauen.",
  bela_q_6: "Du bist wie gesagt fernab von den Situationen, für die das designed wurde.",
  bela_q_7:
    "Mach dir halt keine Gedanken um Skalierung, wenn du keine Probleme mit Skalierung hast.",
  bela_q_8:
    "Ja, das ist halt ehrlich gesagt gerade die falsche Metrik.\nDas Ding wird umfallen, bei mehr Benutzern, dafür musst du es nicht testen.",
  bela_q_9: "Ja also das ist bescheidenes design",
  bela_q_10:
    "Das funktioniert super, solange du nur mit einer datenbank kommunizierst\naber genau in deinem fall bricht es halt auseinander",
  oh_no: "Oh Nein",
  switch_device:
    "Es scheint als würdest du versuchen die Seite über dein Handy zu erreichen. Rank5.io ist im Moment nur über einen Computer oder ein Tablet spielbar",
  clear: "RESET",
  submit: "FERTIG",
  waiting: "WARTEN",
  intro_for_tsar: "Diese Runde geht's nur um <h>dich</h>!",
  intro_about_tsar: "Diese Runde geht's nur um <h>{{tsar}}</h>!",
  how_to_drag_for_tsar:
    "<h>Zieh</h> die farbigen <h>Karten</h> in die <h>Leiste</h> am unteren Rand und ordne sie.",
  how_to_drag_about_tsar:
    "<h>Zieh</h> die farbigen <h>Karten</h> so in die <h>Leiste</h> am unteren Rand, wie du denkst dass <h>{{tsar}}</h> sie einordnen würde.",
  ambiguity_about_tsar: "Wenn ein Feld unklar ist, frag <h>{{tsar}}</h> wie er/sie es versteht.",
  round: "Runde",
  out_of: "von",
  wait_for_restart: "Warte auf den Host",
  restart_lobby: "Nächstes Spiel",
  secret_admirer: "Ein geheimer Verehrer",
  send: "senden",
  feedback: "Feedback:",
  from: "Von:",
  thx4Impro: "Danke fürs Feedback :)",
  get_back: "Spiel weiter!",
  give_feedback: "Feedback",
  feedback_leave_warning: "Um Feedback zu geben musst du die Lobby verlassen",
  abort: "Abbruch",
  proceed: "Ok",
  bela_thanks:
    "Mein Dank geht an meinen Freund Bela, welcher dieses Projekt in seiner Freizeit gementort hat und mir mit seiner Expertise in Programmierung, Deployment und Networking geholfen hat.",
  drag_balls_here: "Karten hierhin ziehen",
  quickFeedBackQuestion: "Was würdest <h>du</h> am Spiel verbessern?",
  quick_feedback: "Feedack",
  skip: "neu({{remainingSkips}})",
  card_deck_specs_language: "sprache: {{language}}",
};

const questionKeys = convertToLanguageKeys();

translationEn = Object.assign({}, translationEn, questionKeys["en"]);
translationDe = Object.assign({}, translationDe, questionKeys["de"]);

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    de: { translation: translationDe },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

const socketContext = React.createContext("counter");

const neutralColor: string = "#ffffffaf";

const theme = createTheme({
  status: {
    danger: orange[500],
    sus: red[100],
  },
  shape: {
    borderRadius: 10,
  },
  palette: {
    primary: { main: "#ec7777" },
    secondary: { main: "#e2e2e2" },
    neutral: { main: neutralColor },
    darkNeutral: { main: "#adadad59" },
    yellow: {main: "#fff355", secondary: "#FFF9AA"},
    blue: {main: "#608ad6", secondary:"#B0C5EB"},
    red: {main: "#e75151", secondary: "#F3A8A8" },
    green: {main: "#92dc67", secondary: "#C9EEB3" },
    orange: {main: "#e17d47", secondary: "#F0BEA3"},
    gray: {main: "#8c8c8c",secondary: "#8c8c8c"},
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: neutralColor,
        },
      },
    },
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 450,
  //     md: 600,
  //     lg: 900,
  //     xl: 1200
  //   }
  // }
  
});

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
      sus: string;
    };
    // breakpoints: {
    //   values: {
    //     xs: number;
    //     sm: number;
    //     md: number;
    //     lg: number;
    //     xl: number;
    //   }
    // }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
      sus?: string;
    };
    // breakpoints: {
    //   values: {
    //     xs: number;
    //     sm: number;
    //     md: number;
    //     lg: number;
    //     xl: number;
    //   }
    // }
  }
 
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    darkNeutral?: PaletteOptions["primary"];
    yellow?: PaletteOptions["primary"];
  
    blue?: PaletteOptions["primary"];
    red?:PaletteOptions["primary"];
    green?: PaletteOptions["primary"];
    orange?: PaletteOptions["primary"];
    gray?: PaletteOptions["primary"];
  }
  interface SimplePaletteColorOptions {
    // extra?: string;
    secondary?: string;
  }
}

const ProtectedProfilePage = withAuthenticationRequired(ProfilePage);

function resetHeight(){
  // reset the body height to that of the inner browser
  document.body.style.height = window.innerHeight + "px";
}
// reset the height whenever the window's resized
window.addEventListener("resize", resetHeight);
// called to initially set the height.
resetHeight();

function App() {
  // const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  // useEffect(() => {
  //   const upsertUser = async () => {
  //     if (isAuthenticated) {
  //       let token = await getAccessTokenSilently({ audience: "rank5.io" });
  //       //send Access token to api to create user if not exists
  //     }

  //   };
  //   upsertUser();
  // }, [isAuthenticated]);

  return (
    <Suspense fallback="Loading...">
      <ThemeProvider theme={theme}>
        <GameContextProvider>
          <Router>
            <Auth0ProviderWithHistory>
              <Switch>
                <Route path="/play">
                  <Start />
                </Route>
                <Route path="/oldlobby">
                  <OldLobby />
                </Route>
                <Route path="/counter">
                  <Counter />
                </Route>
                <Route path="/faq">
                  <FAQ />
                </Route>
                <Route path="/howTo">
                  <HowTo />
                </Route>
                <Route path="/adminMessage">
                  <AdminMessage />
                </Route>
                <Route path="/feedback">
                  <FeedbackPage />
                </Route>
                <Route path="/lobby">
                  <SocketContextProvider>
                    <Lobby />
                  </SocketContextProvider>
                </Route>
                <Route path="/join">
                  <JoinPage/>
                </Route>
                <Route path="/profile">
                  <ProtectedProfilePage />
                </Route>
                <Route path="">
                  <FrontPage />
                </Route>
              </Switch>
            </Auth0ProviderWithHistory>
          </Router>
        </GameContextProvider>
      </ThemeProvider>
    </Suspense>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
